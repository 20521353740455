import paths from 'routing/utils';

interface Predicate {
  areTermsAccepted: boolean;
  isAuthorizedOnOtherTab: boolean;
  isUserLoggedIn: boolean;
  shouldInitializeFacility: boolean;
  isFacilityInitializationInProgress: boolean;
}

interface NavigatorRule {
  matches(predicate: Predicate): boolean;
  apply(): string;
}

class BaseRule implements NavigatorRule {
  constructor(private readonly matchCondition: (predicate: Predicate) => boolean, private readonly applyRule: () => string) {}

  public matches(predicate: Predicate): boolean {
    return this.matchCondition(predicate);
  }

  public apply(): string {
    return this.applyRule();
  }
}

class TermsNotAcceptedRule extends BaseRule {
  constructor() {
    super(
      predicate => predicate.isUserLoggedIn && !predicate.areTermsAccepted,

      () => paths.dashboard.terms
    );
  }
}

class UserUnauthorizedOnOtherTabRule extends BaseRule {
  constructor() {
    super(
      predicate => !predicate.isAuthorizedOnOtherTab && predicate.isUserLoggedIn,

      () => paths.logout
    );
  }
}

class FacilityNotCreatedRule extends BaseRule {
  constructor() {
    super(
      predicate =>
        predicate.isUserLoggedIn &&
        predicate.shouldInitializeFacility &&
        predicate.areTermsAccepted &&
        !predicate.isFacilityInitializationInProgress,

      () => paths.dashboard.wards.create.names
    );
  }
}

const rules: NavigatorRule[] = [new TermsNotAcceptedRule(), new UserUnauthorizedOnOtherTabRule(), new FacilityNotCreatedRule()];

class NavigatorRuleEngine {
  private rules: NavigatorRule[];
  constructor(rules: NavigatorRule[]) {
    this.rules = rules;
  }

  public getRedirectPath(predicate: Predicate): string | undefined {
    const matchingRule = this.rules.find(rule => {
      const result = rule.matches(predicate);
      return result;
    });
    return matchingRule?.apply();
  }
}

const ruleEngine = new NavigatorRuleEngine(rules);
export default ruleEngine;

import { Ward } from 'shared/types/ward';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateWard: builder.mutation<Ward, Ward>({
      query: body => ({
        url: '/wards',
        method: HTTP_METHODS.PUT,
        body
      }),
      invalidatesTags: [{ type: 'Ward' }]
    }),
    getWards: builder.query<Ward[], void>({
      query: () => ({
        url: `/wards`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: 'Ward' as const, id })), { type: 'Ward', id: 'LIST' }] : [{ type: 'Ward', id: 'LIST' }]
    }),
    getWardById: builder.query<Ward, string>({
      query: id => ({
        url: `/wards/${id}`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result => (result ? [{ type: 'Ward' as const, id: result.id }] : [])
    })
  }),
  overrideExisting: false
});

export const { useUpdateWardMutation, useGetWardsQuery, useGetWardByIdQuery } = extendedApi;

import _ from 'lodash';
import { DayOfWeek, DayTimeRange, WeeklyShiftPattern } from 'shared/types/shiftBucket';
import { ShiftSlot } from 'shared/types/shiftSlot';
import { v4 as uuid } from 'uuid';
import { daysOfWeekInOrder } from './utils';

export class ShiftSlotConverter {
  static convertShiftSlotsToWeeklyShiftPattern = (shiftSlots: ShiftSlot[]): WeeklyShiftPattern => {
    const dayTimeRanges: DayTimeRange[] = [];
    shiftSlots.forEach(ss => {
      ss.days.forEach(d => {
        dayTimeRanges.push({
          fromDay: d,
          toDay: ss.isOvernight ? ShiftSlotConverter.getFollowingWeekDay(d) : d,
          startTime: ss.startTime,
          endTime: ss.endTime
        });
      });
    });

    return { dayTimeRanges };
  };

  private static getFollowingWeekDay = (day: DayOfWeek): DayOfWeek => {
    const currentDayIndex = daysOfWeekInOrder.indexOf(day);
    const nextDayIndex = (currentDayIndex + 1) % daysOfWeekInOrder.length;
    return daysOfWeekInOrder[nextDayIndex];
  };

  static convertWeeklyShiftPatternToShiftSlots = (weeklyShiftPattern: WeeklyShiftPattern): ShiftSlot[] => {
    const [overnightShifts, sameDayShifts] = _.partition(weeklyShiftPattern.dayTimeRanges, dtr => dtr.fromDay !== dtr.toDay);

    return [
      ...ShiftSlotConverter.groupDateTimeRanges(overnightShifts, true),
      ...ShiftSlotConverter.groupDateTimeRanges(sameDayShifts, false)
    ];
  };

  private static groupDateTimeRanges = (dateTimeRanges: DayTimeRange[], isOvernight: boolean) => {
    return _.chain(dateTimeRanges)
      .groupBy(dtr => `${dtr.startTime.hour}:${dtr.startTime.minute}-${dtr.endTime.hour}:${dtr.endTime.minute}`)
      .map(group => {
        const days = group.map(g => g.fromDay);
        return { id: uuid(), days, startTime: group[0].startTime, endTime: group[0].endTime, isOvernight };
      })
      .value();
  };
}

import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { RowData } from '../utils';
import { theme, pxToRem } from '@medsi/mui-theme';
import { InvitationStatus } from 'shared/types/invitation';
import { addNewUniqueInvitee } from 'store/slices/invitationSlice';
import { useAppDispatch } from 'store/hooks';
import { isAfter } from 'date-fns';

interface UsersCardsProps {
  rowData: RowData[];
  renderJobTitleCell: (rowData: RowData) => JSX.Element;
  onTrashIconClick: (row: RowData) => void;
}

export const UsersCards = (props: UsersCardsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Box pb={3}>
      {props.rowData.map(row => {
        const canReinvite =
          row.status &&
          row.invitee &&
          ([InvitationStatus.NOT_SENT, InvitationStatus.CANCELED].includes(row.status) ||
            (row.expiration && isAfter(new Date(), new Date(row.expiration))));
        return (
          <Card
            key={row.email ?? row.phoneNumber}
            sx={{ boxShadow: theme.boxShadows.elevation1, my: 2, px: pxToRem(11), pt: pxToRem(9), pb: pxToRem(20) }}
          >
            <Box display="flex" alignItems="center" pb={3}>
              <Typography variant="caption">{row.name}</Typography>
              <Box sx={{ marginLeft: 'auto' }}>
                {canReinvite && (
                  <IconButton color="primary" onClick={() => dispatch(addNewUniqueInvitee(row.invitee!))} sx={{ p: '0 1rem 0 0' }}>
                    <PersonAddIcon />
                  </IconButton>
                )}
                <IconButton size="small" color="secondary" onClick={() => props.onTrashIconClick(row)} sx={{ p: 0 }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={pxToRem(13)}>
              {props.renderJobTitleCell(row)}
              {row.phoneNumber && <Typography variant="caption">{row.phoneNumber}</Typography>}
              {row.email && <Typography variant="caption">{row.email}</Typography>}
            </Box>
          </Card>
        );
      })}
    </Box>
  );
};

import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const YearCalendarStylesOverrides = styled(Box)<BoxProps>(({ theme }) => {
  const { palette, typography, functions } = theme;

  const { primary, light, text } = palette;
  const { fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    height: '100%',
    '& .fc-multimonth': {
      borderTop: `2px solid ${light.main}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      padding: pxToRem(30),
      justifyContent: 'center'
    },
    '& .fc-multimonth-daygrid-table': {
      height: 'auto !important',
      width: 'auto',
      display: 'flex',
      justifyContent: 'center'
    },
    '& .fc-multimonth-month': {
      flexBasis: 0,
      padding: pxToRem(16)
    },
    '& .fc-multimonth-title': {
      color: `${text.primary} !important`,
      fontWeight: fontWeightRegular,
      textTransform: 'capitalize'
    },
    '& .fc-col-header-cell': {
      display: 'none'
    },
    // necessary selectors to remove border from around of calendar
    '& .fc-scrollgrid-section > td': {
      border: 'none !important'
    },
    '& .fc-scrollgrid-section-header': {
      '& .fc-scroller': {
        overflow: 'hidden !important'
      }
    },
    '& .fc-day': {
      border: 'none',
      cursor: 'pointer ',
      '& .fc-daygrid-day-top': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .fc-daygrid-day-number': {
          color: `${text.primary} !important`
        },
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        '&:hover': {
          backgroundColor: `${light.main} !important`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%'
        }
      }
    },
    '& .date-is-free': {
      cursor: 'default !important'
    },
    '& .selected-date': {
      backgroundColor: `${light.main} !important`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: pxToRem(32),
      minHeight: pxToRem(32),
      borderRadius: '50%',
      cursor: 'pointer'
    },
    // "other" means days in previous and next months
    '& .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number': {
      color: `${text.secondary} !important`
    },
    '& .fc-day-today': {
      backgroundColor: 'transparent !important',
      '& .fc-daygrid-day-top .fc-daygrid-day-number': {
        color: `white !important`,
        backgroundColor: `${primary.main} !important`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: pxToRem(28),
        height: pxToRem(28),
        borderRadius: '50%'
      }
    }
  };
});

const paths = {
  root: '/',
  signIn: '/',
  signUp: '/sign-up',
  resetPassword: '/reset-password',
  activate: '/activate',
  newPassword: '/new-password',
  logout: '/logout',
  dashboard: {
    base: '/dashboard',
    settings: {
      base: '/dashboard/settings',
      user: '/dashboard/settings/user',
      shift: '/dashboard/settings/shift',
      staff: '/dashboard/settings/staff',
      robot: '/dashboard/settings/robot',
      ward: '/dashboard/settings/ward'
    },
    wards: {
      base: '/dashboard/wards',
      create: {
        base: '/dashboard/wards/create',
        names: '/dashboard/wards/create/names',
        address: '/dashboard/wards/create/address',
        weeklyShiftPattern: '/dashboard/wards/create/weekly-shift-pattern',
        wardDeadline: '/dashboard/wards/create/ward-deadline',
        invite: '/dashboard/wards/create/invite'
      },
      management: {
        base: '/dashboard/wards/management'
      }
    },
    calendar: '/dashboard/calendar',
    terms: '/dashboard/terms',
    planner: '/dashboard/planner',
    impersonation: '/dashboard/impersonation'
  }
};

export default paths;

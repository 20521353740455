import { pxToRem, theme } from '@medsi/mui-theme';
import { WarningAmberOutlined } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, Button, Typography } from '@mui/material';
import { To, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { Notification } from '../../shared/types/notification';
import { grey } from '@mui/material/colors';

type Props = {
  notification: Notification;
  onCloseNotificationsCallback: VoidFunction;
  onViewDetailsCallback: (notification: Notification) => void;
};

export const NotificationListItem = (props: Props): JSX.Element => {
  const { boxShadows, palette, borders, typography } = theme;

  const navigate = useNavigate();
  const getIcon = () => {
    switch (props.notification.severity) {
      case 'ERROR':
        return <ErrorOutlineIcon fontSize="large" color="error" />;
      case 'WARN':
        return <WarningAmberOutlined fontSize="large" color="warning" />;
      case 'INFO':
        return <InfoOutlineIcon fontSize="large" color="info" />;
      case 'DEFAULT':
        return <StarOutlineIcon fontSize="large" color="secondary" />;
      default:
        return null;
    }
  };

  const navigateTo = (to: To, replace: boolean) => {
    props.onCloseNotificationsCallback();
    navigate(to, { replace });
  };

  const generateActionButtons = () => {
    switch (props.notification.connectedObject.type) {
      case 'OFFER':
        return (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => props.onViewDetailsCallback(props.notification)}
            sx={{ alignSelf: 'flex-end' }}
          >
            Zobacz szczegóły
          </Button>
        );

      case 'PLAN':
        return (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => navigateTo(paths.dashboard.planner, true)}
            sx={{ alignSelf: 'flex-end' }}
          >
            Przejdź do plannera
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      py={pxToRem(14)}
      pr={4}
      pl={3}
      boxShadow={props.notification.read ? 'none' : boxShadows.pinkSmall}
      borderRadius={borders.borderRadius.xxxl}
      sx={{
        backgroundColor: props.notification.read ? grey[50] : palette.neutralExpand[200]
      }}
      mb={pxToRem(8)}
    >
      <Box display="flex" gap={pxToRem(14)}>
        {getIcon()}
        <Box display="flex" flexDirection="column" gap={pxToRem(14)} width={1}>
          <Typography
            variant="h5"
            color={props.notification.read ? theme.palette.text.secondary : theme.palette.text.primary}
            fontWeight={typography.fontWeightBold}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {props.notification.title}
          </Typography>
          {props.notification.content && <Typography variant="body1">{props.notification.content}</Typography>}
          {generateActionButtons()}
        </Box>
      </Box>
    </Box>
  );
};

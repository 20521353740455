import { Alert, Box, Button, Typography } from '@mui/material';
import { theme } from '@medsi/mui-theme';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import { Loadable } from 'features/ui/loadable/Loadable';
import { useEffect } from 'react';
import { Offer } from 'shared/types/offer';
import { ShiftBuckets } from 'shared/types/shiftBucket';
import { findById } from 'shared/utils/commonUtils';
import { useGetFacilityByIdQuery } from 'store/api/endpoints/facilityEndpoints';
import { useCancelOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

interface AbsenceReportDetailsProps {
  activeOffer: Offer;
}

export const AbsenceReportDetails = (props: AbsenceReportDetailsProps): JSX.Element => {
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const dispatch = useAppDispatch();
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const { data: activeFacility } = useGetFacilityByIdQuery(facilitySlice?.activeFacilityId ?? skipToken);
  const [cancelOffer, { isSuccess, isError, isLoading }] = useCancelOfferMutation();
  // other
  const { shiftBuckets } = useGetShiftBuckets();

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się anulować prośby wymiany dyżuru' }));
  }, [isError, dispatch]);

  useEffect(() => {
    isSuccess && dispatch(addAlert({ color: 'success', text: 'Anulowano prośbę wymiany dyżuru' }));
  }, [isSuccess, dispatch]);

  const onCancelAbsence = () => {
    props.activeOffer.id
      ? cancelOffer(props.activeOffer.id)
      : dispatch(addAlert({ color: 'error', text: 'Nie udało się anulować prośby wymiany dyżuru' }));
  };

  const cGrey200 = theme.palette.grey[200];
  const elevation1 = theme.boxShadows.elevation1;
  const borderRadiusXxxl = theme.borders.borderRadius.xxxl;

  const facilityName = activeFacility?.name ?? 'Brak nazwy placówki';
  const wardName = activeWard?.name ?? 'Brak nazwy oddziału';
  const offeredShift = findById(shiftBuckets, props.activeOffer.offerRequest?.offeredShift);
  const description = props.activeOffer.description;

  return (
    <Loadable loading={isLoading}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={3} pt={3}>
        <Alert severity="info" sx={{ width: '100%', borderRadius: borderRadiusXxxl, textAlign: 'start' }}>
          Zgłoszenie nieobecności zostało wysłane
        </Alert>
        <Box
          width={1}
          textAlign={'start'}
          borderRadius={2}
          boxShadow={elevation1}
          p={3}
          sx={{
            backgroundColor: cGrey200
          }}
        >
          <Typography variant="body3" display="block">
            Dyżur
          </Typography>
          <Typography variant="body3">{ShiftBuckets.getShiftBucketTimeLabel(offeredShift)}</Typography>
          <Typography variant="body1" pt={2}>
            {wardName}
          </Typography>
          <Typography variant="body1">{facilityName}</Typography>
          <Typography variant="body1" pt={2}>
            Powód nieobecności
          </Typography>
          <Typography variant="body3">{description}</Typography>
        </Box>
        <Box py={1} alignSelf={'flex-end'}>
          <Button variant="text" color="primary" onClick={onCancelAbsence}>
            Anuluj nieobecność
          </Button>
        </Box>
      </Box>
    </Loadable>
  );
};

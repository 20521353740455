import FullCalendar from '@fullcalendar/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { RefObject } from 'react';

type Props = {
  calendarRef?: RefObject<FullCalendar>;
};

export const DesktopImpersonationCalendarToolbar = (props: Props) => {
  const calendarApi = props.calendarRef?.current?.getApi();
  if (!calendarApi) {
    return <></>;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" p={3} gap={4}>
      <Button variant="outlined" size="large" onClick={() => calendarApi.today()}>
        Dziś
      </Button>

      <Box display="flex" gap={3} justifyContent={'center'} alignItems={'center'} flexGrow={1}>
        <Typography variant="h5">{_.capitalize(calendarApi?.view?.title ?? '')}</Typography>

        <IconButton aria-label="back" onClick={() => calendarApi.prev()}>
          <ArrowBackIcon fontSize="small" color="secondary" />
        </IconButton>

        <IconButton aria-label="forward" onClick={() => calendarApi.next()}>
          <ArrowForwardIcon fontSize="small" color="secondary" />
        </IconButton>
      </Box>
    </Box>
  );
};

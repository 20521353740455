import { theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';

interface ShiftDurationProps {
  shiftDuration?: string | null;
  areShiftsSlotsConflicting: boolean;
  isShiftTimeInvalid: boolean;
}

export const ShiftDuration = (props: ShiftDurationProps) => {
  const { functions, palette, typography } = theme;
  const { pxToRem, rgba } = functions;
  const { secondary, info } = palette;
  const { fontWeightRegular } = typography;
  return (
    <Box mb={2}>
      <Typography
        variant="body1"
        sx={{
          color: `${rgba(secondary.main, 0.6)} !important`,
          fontSize: '0.75rem !important',
          lineHeight: pxToRem(28)
        }}
      >
        Czas trwania dyżuru
      </Typography>
      {props.shiftDuration && (
        <Typography variant="h3" fontWeight={fontWeightRegular}>
          {props.shiftDuration}
        </Typography>
      )}

      {props.isShiftTimeInvalid && (
        <Typography variant="body2" color={info.main}>
          Wybrane godziny dyżuru, są niemożliwe do zrealizowania. Wybież inna godzinę zakończenia dyżuru lub zaznacz zakończenie dyżuru
          następnego dnia
        </Typography>
      )}

      {props.areShiftsSlotsConflicting && (
        <Typography variant="body2" color={info.main}>
          Wybrane godziny dyżuru, są niemożliwe do zrealizowania. Istnieją już dyżury w tych samych dniach w tych godzinach.
        </Typography>
      )}
    </Box>
  );
};

import { isValidDate } from '@fullcalendar/core/internal';
import { theme } from '@medsi/mui-theme';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Time } from 'shared/types/shiftBucket';
import { ShiftSlot, WeekDaysState } from 'shared/types/shiftSlot';
import { v4 as uuid } from 'uuid';
import { NewSlotData, useGetConflictingDays } from '../hooks/useGetConflictingDays';
import { ShiftWeekDays } from '../shiftWeekDays';
import { daysOfWeekInOrder } from '../utils';
import { ShiftTitle } from './shiftColor';
import { ShiftDuration } from './shiftDuration';
import { ShiftTimePicker } from './shiftTimePicker';
import { SwitchOvernight } from './switchOvernight';
import './timePicker.css';

const defaultDaysState: WeekDaysState = {
  MONDAY: false,
  TUESDAY: false,
  WEDNESDAY: false,
  THURSDAY: false,
  FRIDAY: false,
  SATURDAY: false,
  SUNDAY: false
};

type Props = {
  shiftSlotInEdit: ShiftSlot | null;
  shiftIndex: number;
  existingSlots: ShiftSlot[];
  onSaveCallback: (shiftSlot: ShiftSlot) => void;
  onCloseCallback: () => void;
};

const createDateWithHours = (time: Time): Date => {
  const date = new Date();
  date.setHours(time.hour);
  date.setMinutes(time.minute);
  return date;
};

export const ShiftSlotCreator = (props: Props): JSX.Element => {
  const [id, setId] = useState<string>(uuid());
  const [startTime, setStartTime] = useState<Date | null>(createDateWithHours({ hour: 8, minute: 0 }));
  const [endTime, setEndTime] = useState<Date | null>(createDateWithHours({ hour: 16, minute: 0 }));
  const [isOvernight, setIsOvernight] = useState(false);
  const [weekDaysState, setWeekDaysState] = useState<WeekDaysState>(defaultDaysState);

  const newSlotData: NewSlotData = useMemo(() => {
    return { id, startTime, endTime, isOvernight, weekDaysState };
  }, [id, startTime, endTime, isOvernight, weekDaysState]);

  const conflictingDays = useGetConflictingDays(newSlotData, props.existingSlots);

  const { functions, palette, typography } = theme;
  const { pxToRem, rgba } = functions;
  const { secondary } = palette;
  const { fontWeightMedium, fontWeightRegular } = typography;

  useEffect(() => {
    if (props.shiftSlotInEdit) {
      const days = { ...defaultDaysState };
      props.shiftSlotInEdit.days.forEach(d => (days[d] = true));

      setId(props.shiftSlotInEdit.id);
      setStartTime(createDateWithHours(props.shiftSlotInEdit.startTime));
      setEndTime(createDateWithHours(props.shiftSlotInEdit.endTime));
      setIsOvernight(props.shiftSlotInEdit.isOvernight);
      setWeekDaysState(days);
    }
  }, [props.shiftSlotInEdit]);

  const addShiftSlot = () => {
    if (startTime && endTime) {
      props.onSaveCallback({
        id,
        startTime: {
          hour: startTime.getHours(),
          minute: startTime.getMinutes()
        },
        endTime: {
          hour: endTime.getHours(),
          minute: endTime.getMinutes()
        },
        isOvernight,
        days: daysOfWeekInOrder.filter(d => weekDaysState[d])
      });
    }
  };

  const getShiftDuration = (): string | null => {
    if (endTime && startTime && isValidDate(startTime) && isValidDate(endTime)) {
      const diff = differenceInMinutes(endTime, startTime);
      const hours = (isOvernight ? 24 : 0) + Math.floor(diff / 60);
      if (hours <= 0) {
        return null;
      }
      const minutes = Math.abs(diff % 60);
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} godz.`;
    } else {
      return null;
    }
  };

  const shiftDuration = getShiftDuration();
  const areShiftsSlotsConflicting = !_.isEmpty(conflictingDays);
  const isShiftTimeInvalid = !shiftDuration;
  const isNoDaySelected = Object.values(weekDaysState).every(value => value === false);

  return (
    <Box p={`${pxToRem(36)} ${pxToRem(24)} ${pxToRem(24)}`}>
      <Box mb={4.75} textAlign="center">
        <Typography variant="h3" color="primary">
          Edycja dyżuru
        </Typography>
      </Box>
      <Box mb={4.5} textAlign="center">
        <Typography variant="body1" sx={{ color: `${rgba(secondary.main, 0.87)}` }}>
          Określanie godzin oraz dni dyżuru.
        </Typography>
      </Box>
      <ShiftTitle shiftIndex={props.shiftIndex} />
      <Box mt={1.5} mb={1.5} maxWidth={pxToRem(186)}>
        <ShiftTimePicker value={startTime} onChange={value => setStartTime(value)} label="Godzina rozpoczęcia dyżuru" />
      </Box>
      <Box mb={1.75} maxWidth={pxToRem(186)}>
        <ShiftTimePicker
          value={endTime}
          onChange={value => setEndTime(value)}
          label="Godzina zakończenia dyżuru"
          error={isShiftTimeInvalid}
        />
      </Box>
      <Box mb={2.25}>
        <SwitchOvernight checked={isOvernight} onChange={checked => setIsOvernight(checked)} />
      </Box>
      <ShiftDuration
        shiftDuration={shiftDuration}
        areShiftsSlotsConflicting={areShiftsSlotsConflicting}
        isShiftTimeInvalid={isShiftTimeInvalid}
      />
      <Box mb={5} display="flex" flexWrap="wrap" sx={{ maxWidth: pxToRem(400) }}>
        <Box display={'flex'} alignItems={'center'} justifyContent="center" gap={1} mb={2}>
          <Typography variant="caption" lineHeight={0} fontWeight={fontWeightRegular} color={rgba(secondary.main, 0.6)}>
            Dni obowiązywania dyżuru
          </Typography>
          <Tooltip title="Musisz zaznaczyć przynajmniej jeden dzień dla dyżuru" placement="top">
            <InfoOutlined color="primary" fontSize="small" />
          </Tooltip>
        </Box>
        <ShiftWeekDays
          days={weekDaysState}
          onChangeDays={weekDaysState => setWeekDaysState(weekDaysState)}
          conflictingDays={conflictingDays}
        />
      </Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={addShiftSlot}
          fullWidth
          disabled={isShiftTimeInvalid || areShiftsSlotsConflicting || isNoDaySelected}
        >
          Zapisz
        </Button>
        <Button onClick={() => props.onCloseCallback()} fullWidth sx={{ marginTop: '1rem' }}>
          <Typography
            color="secondary"
            lineHeight="1.8rem"
            fontSize={pxToRem(14)}
            fontWeight={fontWeightMedium}
            sx={{ display: 'block', textAlign: 'center', textTransform: 'none' }}
          >
            Wstecz
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

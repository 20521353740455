import { pxToRem } from '@medsi/mui-theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Avatar, Box, Button, Chip, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Doctor } from 'shared/types/planning';
import { ShiftBucket, ShiftBuckets } from 'shared/types/shiftBucket';
import { Users } from 'shared/types/users';
import { useRejectOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useUpdateAssignedDoctorsMutation } from 'store/api/endpoints/shiftBucketEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

type Props = {
  doctor: Doctor;
  shiftBucket: ShiftBucket;
  isAssigned: boolean;
  isAssignable: boolean;
};

export const ShiftBucketCandidate = (props: Props): JSX.Element => {
  // redux
  const bucketOffers = useAppSelector(state => state.offerSlice.bucketOffers.find(bo => bo.bucketId === props.shiftBucket.id));
  const dispatch = useAppDispatch();
  // rtk
  const [updateShiftBucket] = useUpdateAssignedDoctorsMutation();
  const [rejectOffer] = useRejectOfferMutation();
  // other
  const {
    palette,
    borders: { borderRadius }
  } = useTheme();

  const onAssign = () => {
    updateAssignee([props.doctor.id]);
  };

  const onUnassign = () => {
    updateAssignee([]);
  };

  const updateAssignee = (doctors: string[]) => {
    updateShiftBucket({ id: props.shiftBucket.id, doctors })
      .unwrap()
      .then(() => {
        bucketOffers?.offers.forEach(bo => {
          bo.id &&
            rejectOffer(bo.id)
              .unwrap()
              .catch(() => {
                dispatch(addAlert({ color: 'error', text: 'Nie udało się anulować oferty przypisanej do dyżuru' }));
              });
        });
      })
      .catch(() => {
        dispatch(addAlert({ color: 'error', text: 'Nie udało się przypisać lekarza do dyżuru' }));
      });
  };

  const userPreference = props.shiftBucket.preferences?.find(p => p.userId === props.doctor?.id);
  const absenceInfo = props.isAssignable ? ShiftBuckets.getAbsenceInfo(userPreference) : null;

  const actionButton = props.isAssigned ? (
    <IconButton color="primary" onClick={onUnassign}>
      <DeleteIcon />
    </IconButton>
  ) : (
    <Button startIcon={<CheckCircleIcon />} onClick={onAssign} variant="outlined">
      Wybierz
    </Button>
  );

  return (
    <Box py={1} width="100%">
      <Grid
        container
        borderRadius={borderRadius.xxxl}
        p={1}
        gap={1}
        flexWrap="nowrap"
        sx={{
          backgroundColor: palette.neutral[200]
        }}
      >
        <Grid item lg={1} display="flex" alignItems="center">
          <Avatar sx={{ bgcolor: palette.grey[500] }}>{Users.getInitials<Doctor>(props.doctor)}</Avatar>
        </Grid>
        <Grid item lg={11} display="flex" justifyContent="space-between" flexGrow={1}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <Typography variant="body3">{Users.toString<Doctor>(props.doctor)}</Typography>
            <Typography variant="body1">Lekarz</Typography>
            {absenceInfo?.reason && (
              <Box pt={pxToRem(2)}>
                <Chip label={absenceInfo.reason} color="secondary" size="small" />
              </Box>
            )}
            {absenceInfo?.note && (
              <Typography sx={{ paddingTop: 1 }} variant="caption" color={palette.text.primary}>
                {absenceInfo.note}
              </Typography>
            )}
          </Box>
          <Box>{props.isAssignable && actionButton}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

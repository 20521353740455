import { ActivateAccount } from 'features/authentication/ActivateAccount';
import { NewPassword } from 'features/authentication/NewPassword';
import { ResetPassword } from 'features/authentication/ResetPassword';
import { SignIn } from 'features/authentication/SignIn';
import { SignUp } from 'features/authentication/SignUp';
import { Logout } from 'features/authentication/logout';
import { MainCalendar } from 'features/calendar/mainCalendar';
import { Dashboard } from 'features/dashboard/dashboard';
import { AddressAndContactForm } from 'features/facilities/wards/creator/form/addressAndContactForm';
import { InviteForm } from 'features/facilities/wards/creator/form/inviteForm';
import { WardDeadlineForm } from 'features/facilities/wards/creator/form/wardDeadlineForm';
import { WardForm } from 'features/facilities/wards/creator/form/wardForm';
import { WardCreator } from 'features/facilities/wards/creator/wardCreator';
import { ShiftSlots } from 'features/facilities/wards/creator/weekly-shift-pattern/shiftSlots';
import { Wards } from 'features/facilities/wards/wards';
import { ImpersonationPanel } from 'features/impersonation-panel/impersonationPanel';
import { PlannerCalendar } from 'features/planner/plannerCalendar';
import { RobotSettings } from 'features/settings/robot-settings/robotSettings';
import { Settings } from 'features/settings/settings';
import { ShiftSettings } from 'features/settings/shiftSettings';
import { StaffSettings } from 'features/settings/staff-settings/staffSettings';
import { UserSettings } from 'features/settings/userSettings';
import { WardSettings } from 'features/settings/wardSettings';
import { Terms } from 'features/terms/terms';
import { ErrorPage } from 'features/ui/error-page/errorPage';
import { createBrowserRouter } from 'react-router-dom';
import { AuthorityType } from 'shared/types/account';
import { facilitiesGuard, securedGuard } from './guards/utils';
import { RoutingRoot } from './routingRoot';
import paths from './utils';

export const router = createBrowserRouter([
  {
    path: paths.root,
    element: <RoutingRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <SignIn />
      },
      {
        path: paths.signUp,
        element: <SignUp />
      },
      {
        path: paths.resetPassword,
        element: <ResetPassword />
      },
      {
        path: paths.logout,
        element: <Logout />
      },
      {
        path: paths.dashboard.base,
        element: securedGuard(<Dashboard />),
        children: [
          {
            path: paths.dashboard.calendar,
            element: securedGuard(<MainCalendar />)
          },
          {
            path: paths.dashboard.terms,
            element: securedGuard(<Terms />)
          },
          {
            path: paths.dashboard.planner,
            element: securedGuard(<PlannerCalendar />, AuthorityType.WARD_HEAD)
          },
          {
            path: paths.dashboard.impersonation,
            element: securedGuard(<ImpersonationPanel />, AuthorityType.WARD_HEAD)
          },
          {
            path: paths.dashboard.settings.base,
            element: securedGuard(<Settings />),
            children: [
              {
                path: paths.dashboard.settings.user,
                element: securedGuard(<UserSettings />)
              },
              {
                path: paths.dashboard.settings.shift,
                element: securedGuard(<ShiftSettings />, AuthorityType.WARD_HEAD)
              },
              {
                path: paths.dashboard.settings.staff,
                element: securedGuard(<StaffSettings />, AuthorityType.WARD_HEAD)
              },
              {
                path: paths.dashboard.settings.robot,
                element: securedGuard(<RobotSettings />, AuthorityType.WARD_HEAD)
              },
              {
                path: paths.dashboard.settings.ward,
                element: securedGuard(<WardSettings />, AuthorityType.WARD_HEAD)
              }
            ]
          },
          {
            path: paths.dashboard.wards.base,
            element: securedGuard(facilitiesGuard(<Wards />), AuthorityType.WARD_HEAD),
            children: [
              {
                path: paths.dashboard.wards.create.base,
                element: securedGuard(facilitiesGuard(<WardCreator />), AuthorityType.WARD_HEAD),
                children: [
                  {
                    path: paths.dashboard.wards.create.names,
                    element: securedGuard(facilitiesGuard(<WardForm />), AuthorityType.WARD_HEAD)
                  },
                  {
                    path: paths.dashboard.wards.create.address,
                    element: securedGuard(facilitiesGuard(<AddressAndContactForm />), AuthorityType.WARD_HEAD)
                  },
                  {
                    path: paths.dashboard.wards.create.invite,
                    element: securedGuard(facilitiesGuard(<InviteForm />), AuthorityType.WARD_HEAD)
                  },
                  {
                    path: paths.dashboard.wards.create.weeklyShiftPattern,
                    element: securedGuard(facilitiesGuard(<ShiftSlots />), AuthorityType.WARD_HEAD)
                  },
                  {
                    path: paths.dashboard.wards.create.wardDeadline,
                    element: securedGuard(facilitiesGuard(<WardDeadlineForm />), AuthorityType.WARD_HEAD)
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: paths.activate,
        element: <ActivateAccount />
      },
      {
        path: paths.newPassword,
        element: <NewPassword />
      }
    ]
  }
]);

import { Box } from '@mui/material';
import { daysOfWeekInOrder, getDayLabel } from './utils';
import { DayOfWeek } from 'shared/types/shiftBucket';
import { WeekDaysState } from 'shared/types/shiftSlot';
import { WeekDayChip } from './weekDayChip';

interface ShiftWeekDaysProps {
  days: WeekDaysState;
  conflictingDays?: Set<DayOfWeek>;
  readOnly?: boolean;
  onChangeDays?: (days: WeekDaysState) => void;
}

export const ShiftWeekDays = (props: ShiftWeekDaysProps): JSX.Element => {
  const getDayColor = (day: DayOfWeek) => {
    return props.days[day] ? 'primary' : 'secondary';
  };
  const changeDaysState = (day: DayOfWeek) => {
    props.onChangeDays?.({ ...props.days, [day]: !props.days[day] });
  };

  return (
    <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
      {daysOfWeekInOrder.map(d => (
        <WeekDayChip
          key={d}
          label={getDayLabel(d)}
          color={getDayColor(d)}
          onClick={() => changeDaysState(d)}
          readOnly={props.readOnly}
          isConflicting={props.conflictingDays?.has(d)}
        />
      ))}
    </Box>
  );
};

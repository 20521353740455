import { pxToRem } from '@medsi/mui-theme';
import { Autocomplete, Box, FilterOptionsState, TextField, createFilterOptions, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Controller, useFormContext } from 'react-hook-form';
import { Facility } from 'shared/types/facility';
import { validationMessage } from 'shared/utils/formUtils';
import { useGetPredefinedFacilitiesQuery } from 'store/api/endpoints/facilityEndpoints';
import { FormError } from '../formError/FormError';

type Props = {
  isClinicInputVisible: boolean;
};

export interface AutocompleteOption {
  id?: string;
  name?: string;
  isSuggestion?: boolean;
}

const filter = createFilterOptions<AutocompleteOption>();

const defaultPredefinedFacilities: Facility[] = [];

export const WardFormFields = (props: Props) => {
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption | null>(null);
  // rtk
  const { data: predefinedFacilities = defaultPredefinedFacilities } = useGetPredefinedFacilitiesQuery();
  // other
  const { control, setValue, getValues, watch } = useFormContext();
  const predefinedFacilityName = watch('predefinedFacilityName');
  const theme = useTheme();
  const { palette } = theme;

  useEffect(() => {
    setValue('predefinedFacilityName', selectedOption?.name, { shouldDirty: true, shouldValidate: true });
  }, [selectedOption, setValue]);

  useEffect(() => {
    predefinedFacilityName && setSelectedOption({ name: predefinedFacilityName });
  }, [predefinedFacilityName]);

  const autocompleteOptions: AutocompleteOption[] = useMemo(() => {
    const options: AutocompleteOption[] = predefinedFacilities.map(facility => ({ id: facility.id, name: facility.name }));
    getValues('predefinedFacilityName') && options.push({ name: getValues('predefinedFacilityName') });
    return options;
  }, [predefinedFacilities, getValues]);

  const getOptionLabel = (option: string | AutocompleteOption) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.isSuggestion) {
      return `Dodaj: ${option.name}`;
    }

    return option.name ?? '';
  };

  const isAutocompleteOption = (obj: Object): obj is AutocompleteOption => {
    return obj.hasOwnProperty('id') && obj.hasOwnProperty('name');
  };

  const isOptionEqualToValue = (option: AutocompleteOption, value: AutocompleteOption) => {
    return isAutocompleteOption(option) && isAutocompleteOption(value) ? option.id === value.id : false;
  };

  const onAutocompleteChange = (_: unknown, option: null | string | AutocompleteOption) => {
    if (typeof option === 'string') {
      setSelectedOption({
        name: option
      });
    } else if (option && option.name) {
      delete option.isSuggestion;
      setSelectedOption(option);
    } else {
      setSelectedOption(null);
    }
  };

  const onFilterOptions = (options: AutocompleteOption[], params: FilterOptionsState<AutocompleteOption>) => {
    const filtered = filter(options, params);

    const { inputValue } = params;

    const shouldSuggestAddingNewOption = !options.some(option => inputValue === option.name);
    if (inputValue !== '' && shouldSuggestAddingNewOption) {
      filtered.unshift({
        name: inputValue,
        isSuggestion: true
      });
    }

    return filtered;
  };

  return (
    <Box display="flex" flexDirection="column" gap={pxToRem(20)} pt={pxToRem(20)}>
      <Controller
        name="predefinedFacilityName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <Autocomplete
              {...field}
              disablePortal
              value={selectedOption}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={autocompleteOptions}
              onChange={onAutocompleteChange}
              filterOptions={onFilterOptions}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              sx={{
                '& button': {
                  color: 'secondary.main',
                  transition: 'all .3s ease-in-out'
                }
              }}
              renderInput={params => (
                <>
                  <TextField
                    {...params}
                    className={fieldState.invalid ? 'error' : ''}
                    inputProps={params.inputProps}
                    label="Wybierz placówkę lub dodaj własną"
                    required
                    fullWidth
                    sx={{
                      '& .MuiAutocomplete-endAdornment': {
                        right: `${pxToRem(3)} !important`
                      },
                      '& .MuiOutlinedInput-root': {
                        padding: 0
                      }
                    }}
                  />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              renderOption={(props, option, state) => (
                <Box component="li" key={option.id} {...props}>
                  {option.isSuggestion ? (
                    `Dodaj: ${option.name}`
                  ) : (
                    <Highlighter
                      searchWords={state.inputValue.split(' ')}
                      autoEscape={true}
                      textToHighlight={option.name ?? ''}
                      highlightStyle={{ backgroundColor: 'transparent', color: palette.primary.main }}
                    />
                  )}
                </Box>
              )}
            />
          );
        }}
        rules={{ required: validationMessage.required }}
      />
      {props.isClinicInputVisible && (
        <Controller
          name="clinicName"
          control={control}
          render={({ field }) => {
            return <TextField {...field} label="Nazwa kliniki" fullWidth />;
          }}
        />
      )}
      <Controller
        name="wardName"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Pełna nazwa oddziału" required fullWidth />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
        rules={{ required: validationMessage.required }}
      />
      <Controller
        name="shortWardName"
        control={control}
        render={({ field }) => {
          return <TextField {...field} label="Skrócona nazwa oddziału" fullWidth />;
        }}
      />
    </Box>
  );
};

import { ShiftPlan } from 'shared/types/planning';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getShiftPlan: builder.query<ShiftPlan, string>({
      query: wardId => ({
        url: `/wards/${wardId}/shift-plans`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result => (result ? [{ type: 'ShiftPlan' as const, id: result.wardId }] : [])
    }),
    updateShiftPlan: builder.mutation<ShiftPlan, ShiftPlan>({
      query: shiftPlan => ({
        url: `/shift-plans`,
        method: HTTP_METHODS.PUT,
        body: shiftPlan
      }),
      invalidatesTags: ['ShiftPlan']
    }),
    acceptShiftPlan: builder.mutation<void, string>({
      query: wardId => ({
        url: `/wards/${wardId}/shift-plans/accept`,
        method: HTTP_METHODS.PUT
      }),
      invalidatesTags: ['ShiftPlan', 'ShiftBucket']
    })
  }),
  overrideExisting: false
});

export const { useGetShiftPlanQuery, useUpdateShiftPlanMutation, useAcceptShiftPlanMutation } = extendedApi;

import { Theme, rgba } from '@medsi/mui-theme';
import makeStyles from '@mui/styles/makeStyles';

export const useStylesDatePicker = makeStyles((theme: Theme) => {
  const { palette, typography } = theme;

  return {
    root: {
      border: 'none',
      '& .react-datepicker__day, & .react-datepicker__week, & .react-datepicker__month, & .react-datepicker__quarter, & .react-datepicker__year':
        {
          color: palette.text.primary,
          fontWeight: typography.fontWeightBold,
          borderRadius: 0,
          margin: '0.0625rem 0',
          width: '2rem',
          lineHeight: '2rem'
        },
      '& .react-datepicker__day--disabled': {
        backgroundColor: palette.grey[200],
        color: palette.text.secondary
      },
      '& [class*="--selected"]': {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText
      },
      '& [class*="--in-range"],& [class*="--in-selecting-range"]': {
        backgroundColor: rgba(palette.primary.main, palette.action.focusOpacity)
      },
      '& [class*="-range-start"], & [class*="-range-end"]': {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText
      },
      '& [class*="--range-start"]': {
        borderRadius: `1rem 0 0 1rem`
      },
      '& [class*="--range-end"]': {
        borderRadius: `0  1rem 1rem 0`
      },
      '& [class*="--range-end"][class*="--range-start"]': {
        borderRadius: '2rem'
      },
      '& [class*="__current-month"]': {
        textTransform: 'capitalize',
        color: palette.text.primary
      },
      '& [class*="__header"]': {
        backgroundColor: palette.background.default,
        borderBottom: 'none',
        paddingTop: '0.25rem'
      },
      '& [class*="--keyboard-selected"]:not([class*="--in-range"]):not([class*="--in-selecting-range"])': {
        backgroundColor: rgba(palette.primary.main, palette.action.focusOpacity),
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText
        }
      }
    }
  };
});

import { DesktopAcceptedShiftAssignmentEventContent } from 'features/planner/event-content/desktopAcceptedShiftAssignmentEventContent';
import { DesktopShiftAssignmentEventContent } from 'features/planner/event-content/desktopShiftAssignmentEventContent';
import { MobileAcceptedShiftAssignmentEventContent } from 'features/planner/event-content/mobileAcceptedShiftAssignmentEventContent';
import { MobileShiftAssignmentEventContent } from 'features/planner/event-content/mobileShiftAssignmentEventContent';
import { PreferencesEventContent } from 'features/planner/event-content/preferencesEventContent';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { CalendarExtendedProps, CalendarTypeGuard, CalendarView } from 'shared/types/calendar';
import { DesktopShiftBucketEventContent } from './event-content/desktopShiftBucketEventContent';
import { MobileShiftBucketEventContent } from './event-content/mobileShiftBucketEventContent';

type Props = {
  calendarView?: CalendarView;
  extendedProps: CalendarExtendedProps;
  explicitScreenVersion?: 'DESKTOP' | 'MOBILE';
};

export const EventContentRenderer = (props: Props) => {
  const isDesktop = useIsDesktop();
  const extendedProps = props.extendedProps;
  if (!extendedProps) {
    return null;
  }

  const desktop = !props.explicitScreenVersion ? isDesktop : props.explicitScreenVersion === 'DESKTOP';

  if (CalendarTypeGuard.isPreferencesExtendedProps(extendedProps)) {
    return desktop ? <PreferencesEventContent preferencesExtendedProps={extendedProps} /> : <></>;
  }
  if (CalendarTypeGuard.isShiftAssignmentExtendedProps(extendedProps)) {
    return desktop ? (
      <DesktopShiftAssignmentEventContent shiftAssignment={extendedProps.shiftAssignment} />
    ) : (
      <MobileShiftAssignmentEventContent shiftAssignment={extendedProps.shiftAssignment} />
    );
  }
  if (CalendarTypeGuard.isShiftBucketExtendedProps(extendedProps)) {
    return desktop ? (
      <DesktopShiftBucketEventContent
        shiftBucket={extendedProps.shiftBucket}
        eventType={extendedProps.shiftEventType}
        isAssigned={extendedProps.isAssigned}
        calendarView={props.calendarView}
        isOwnerEvent={extendedProps.viewAsOwner}
        eventViewerId={extendedProps.eventViewerId}
      />
    ) : (
      <MobileShiftBucketEventContent
        shiftBucket={extendedProps.shiftBucket}
        eventType={extendedProps.shiftEventType}
        isAssigned={extendedProps.isAssigned}
        calendarView={props.calendarView}
        isOwnerEvent={extendedProps.viewAsOwner}
        eventViewerId={extendedProps.eventViewerId}
      />
    );
  }
  if (CalendarTypeGuard.isAcceptedShiftAssignmentExtendedProps(extendedProps)) {
    return desktop ? (
      <DesktopAcceptedShiftAssignmentEventContent shiftAssignment={extendedProps.shiftAssignment} />
    ) : (
      <MobileAcceptedShiftAssignmentEventContent shiftAssignment={extendedProps.shiftAssignment} />
    );
  }

  return null;
};

import { Box, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { theme } from '@medsi/mui-theme';

interface PasswordRulesProps {
  password: string;
  disabled: boolean;
}

export const PasswordRules = (props: PasswordRulesProps): JSX.Element => {
  const materialTheme = useTheme();
  const medsiTheme = theme;
  const cError = medsiTheme.palette.error.main;
  const cTextSecondary = materialTheme.palette.secondary.main;
  const cSuccess = medsiTheme.palette.success.main;
  const fontWeightMedium = theme.typography.fontWeightMedium;
  const rules = [
    {
      pattern: /[a-z]/,
      message: 'Co najmniej jedna mała litera'
    },
    {
      pattern: /[A-Z]/,
      message: 'Co najmniej jedna wielka litera'
    },
    {
      pattern: /[0-9]/,
      message: 'Co najmniej jedna cyfra'
    },
    {
      pattern: /[^a-zA-Z0-9]/,
      message: 'Co najmniej jednen znak specjalny'
    },
    {
      pattern: /.{8,}/,
      message: 'Minimalna długość hasła to 8 znaków'
    }
  ];
  const getColor = (pattern: RegExp) => {
    if (props.disabled) {
      return cTextSecondary;
    } else {
      return props.password.match(pattern) ? cSuccess : cError;
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h6" color={cTextSecondary} lineHeight="1.8rem" fontSize="0.875rem" fontWeight={fontWeightMedium}>
          Wymagania dotyczące hasła:
        </Typography>
      </Box>
      <List>
        {rules.map((rule, index) => {
          return (
            <ListItem
              key={index}
              sx={{
                paddingLeft: '1rem',
                position: 'relative',
                color: getColor(rule.pattern),
                ':before': {
                  content: '""',
                  position: 'absolute',
                  left: '0.3rem',
                  display: 'inline-block',
                  width: '0.3rem',
                  height: '0.3rem',
                  borderRadius: '50%',
                  backgroundColor: getColor(rule.pattern)
                }
              }}
            >
              <ListItemText primary={rule.message} sx={{ fontWeight: fontWeightMedium }} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

import { pxToRem } from '@medsi/mui-theme';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export const SidenavLogo = (): JSX.Element => {
  const isSidenavHidden = useAppSelector(state => state.sidenavSlice.isSidenavHidden);

  return (
    <Box component={NavLink} to="/">
      {!isSidenavHidden ? (
        <Box component="img" src="/logo.png" width={pxToRem(137)} height={pxToRem(34)} />
      ) : (
        <Box component="img" src="/logo_min.png" width={pxToRem(23)} height={pxToRem(34)} />
      )}
    </Box>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Card, Typography } from '@mui/material';
import paths from 'routing/utils';
import { IconStatus } from '../../shared/types/iconStatus';
import { AuthLayout, AuthLayoutSize } from './layout/AuthLayout';

interface ResultMessageProps {
  icon?: string;
  text: string;
  additionalText: string;
  size?: AuthLayoutSize;
}

export const ResultMessage = (props: ResultMessageProps): JSX.Element => {
  return (
    <AuthLayout size={props.size}>
      <Card sx={{ pt: 2, pb: 1, boxShadow: theme.boxShadows.elevation24, mx: 2 }}>
        <Box px={3} display="flex">
          {props.icon === IconStatus.checked && <CheckCircleOutlineOutlinedIcon fontSize="large" color="success" sx={{ mr: 2 }} />}
          {props.icon === IconStatus.info && <InfoOutlinedIcon fontSize="large" color="info" sx={{ mr: 2 }} />}
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">{props.text}</Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              sx={{ textWrap: 'wrap', display: 'inline-block', pt: 2, pb: pxToRem(20) }}
            >
              {props.additionalText}
            </Typography>
          </Box>
        </Box>

        <Box textAlign="end" px={1}>
          <Button variant="contained" color="primary" type="submit" href={paths.root}>
            Wróć do logowania
          </Button>
        </Box>
      </Card>
    </AuthLayout>
  );
};

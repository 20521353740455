import { theme } from '@medsi/mui-theme';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface PrefFilterState {
  isAcceptedChecked: boolean;
  isUnacceptedChecked: boolean;
  isUnfilledChecked: boolean;
}

type Props = {
  onChange: (state: PrefFilterState) => void;
};

export const PrefFilter = (props: Props) => {
  const [filterState, setFilterState] = useState<PrefFilterState>({
    isAcceptedChecked: true,
    isUnacceptedChecked: true,
    isUnfilledChecked: true
  });

  const { onChange } = props;
  useEffect(() => {
    onChange(filterState);
  }, [filterState, onChange]);

  const getLabel = (text: string, color: string) => {
    return (
      <Box display="flex" justifyItems="center" gap={1}>
        <Typography variant="body1" fontWeight={theme.typography.fontWeightBold} color={color}>
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      py={0.5}
      width="fit-content"
      borderRadius={theme.borders.borderRadius.xxxl}
      sx={{ backgroundColor: theme.palette.white.main }}
    >
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            defaultChecked
            value={filterState.isAcceptedChecked}
            onChange={e => setFilterState(prev => ({ ...prev, isAcceptedChecked: e.target.checked }))}
          />
        }
        label={getLabel('Zatwierdzone', theme.palette.success.main)}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            defaultChecked
            value={filterState.isUnacceptedChecked}
            onChange={e => setFilterState(prev => ({ ...prev, isUnacceptedChecked: e.target.checked }))}
          />
        }
        label={getLabel('Niezatwierdzone', theme.palette.warning.main)}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            defaultChecked
            value={filterState.isUnfilledChecked}
            onChange={e => setFilterState(prev => ({ ...prev, isUnfilledChecked: e.target.checked }))}
          />
        }
        label={getLabel('Nieuzupełnione', theme.palette.error.main)}
      />
    </Box>
  );
};

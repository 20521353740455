import { pxToRem, rgba } from '@medsi/mui-theme';
import ThumbDownIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { Box, Chip, ChipProps, Grid, styled } from '@mui/material';
import { PreferencesExtendedProps } from 'shared/types/calendar';

type Props = {
  preferencesExtendedProps: PreferencesExtendedProps;
};

interface PreferencesChipProps extends ChipProps {
  alpha: number;
}

const PreferencesChip = styled(Chip)<PreferencesChipProps>(({ theme, alpha }) => {
  const cLight = theme.palette.secondary.light;
  const cWhite = theme.palette.white.main;
  return {
    marginBottom: pxToRem(2),
    backgroundColor: rgba(cLight, alpha),
    '& .MuiChip-label': {
      color: cWhite
    },
    '& .MuiChip-icon': {
      color: cWhite
    }
  };
});

export const PreferencesEventContent = (props: Props) => {
  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <Grid display="flex" flexDirection="column">
        <PreferencesChip icon={<ThumbUpIcon />} label={props.preferencesExtendedProps?.presence} alpha={1} size="small" />
        <PreferencesChip icon={<ThumbDownIcon />} label={props.preferencesExtendedProps?.absence} alpha={0.8} size="small" />
      </Grid>
    </Box>
  );
};

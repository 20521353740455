import { SolverStatus } from 'shared/types/planning';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    solve: builder.mutation<SolverStatus, { wardId: string; fromDate: string; toDate: string }>({
      query: params => ({
        url: `/wards/${params.wardId}/planning-problem/solve?fromDate=${params.fromDate}&toDate=${params.toDate}`,
        method: HTTP_METHODS.POST
      })
    }),
    getSolverStatus: builder.query<SolverStatus, string>({
      query: wardId => ({
        url: `/wards/${wardId}/planning-problem`,
        method: HTTP_METHODS.GET
      })
    }),
    stopSolving: builder.query<void, { wardId: string }>({
      query: params => ({
        url: `/wards/${params.wardId}/planning-problem/terminate`,
        method: HTTP_METHODS.POST
      })
    })
  }),
  overrideExisting: false
});

export const { useSolveMutation, useGetSolverStatusQuery, useStopSolvingQuery } = extendedApi;

import { areIntervalsOverlapping, isAfter } from 'date-fns';
import { useMemo } from 'react';
import { DayOfWeek } from 'shared/types/shiftBucket';
import { ShiftSlot, WeekDaysState } from 'shared/types/shiftSlot';
import { isValidDate } from 'shared/utils/calendarUtils';
import { daysOfWeekInOrder } from '../utils';

const YEAR = 2022;
const MONTH = 8; // date is chosen this way for simplicity, because 2022-08-1 is a Monday

interface SlotRange {
  day: DayOfWeek;
  start: Date;
  end: Date;
}
export interface NewSlotData {
  id: string | null;
  startTime: Date | null;
  endTime: Date | null;
  isOvernight: boolean;
  weekDaysState: WeekDaysState;
}

export const useGetConflictingDays = (newSlotData: NewSlotData, existingSlots: ShiftSlot[]): Set<DayOfWeek> => {
  return useMemo(() => {
    const { id, startTime, endTime, isOvernight, weekDaysState } = newSlotData;
    if (!id || !startTime || !endTime || !isValidDate(startTime) || !isValidDate(endTime)) {
      return new Set();
    }
    const selectedDays = daysOfWeekInOrder.filter(d => weekDaysState[d]);
    const conflictingDays = new Set<DayOfWeek>();
    existingSlots
      .filter(slot => slot.id !== id)
      .forEach(slot => {
        const existingSlotRanges: SlotRange[] = [];
        for (let i = 0; i < slot.days.length; i++) {
          const day1 = getDayNumber(slot.days[i]);
          const day2 = slot.isOvernight ? day1 + 1 : day1;

          const start = new Date(YEAR, MONTH, day1, slot.startTime.hour, slot.startTime.minute);
          const end = new Date(YEAR, MONTH, day2, slot.endTime.hour, slot.endTime.minute);

          existingSlotRanges.push({ day: slot.days[i], start, end });
        }

        const newSlotRanges: SlotRange[] = [];
        for (let i = 0; i < selectedDays.length; i++) {
          const day1 = getDayNumber(selectedDays[i]);
          const day2 = isOvernight ? day1 + 1 : day1;

          const start = new Date(YEAR, MONTH, day1, startTime.getHours(), startTime.getMinutes());
          const end = new Date(YEAR, MONTH, day2, endTime.getHours(), endTime.getMinutes());

          newSlotRanges.push({ day: selectedDays[i], start, end });
        }

        const conflictingRanges = newSlotRanges.filter(nsr => {
          return existingSlotRanges.some(esr => {
            if (isAfter(nsr.start, nsr.end) || isAfter(nsr.start, nsr.end)) {
              return false;
            }

            const firstInterval = { start: nsr.start, end: nsr.end };
            const secondInterval = { start: esr.start, end: esr.end };

            return areIntervalsOverlapping(firstInterval, secondInterval, { inclusive: false });
          });
        });

        conflictingRanges.forEach(cr => {
          conflictingDays.add(cr.day);
        });
      });

    return conflictingDays;
  }, [newSlotData, existingSlots]);
};

const getDayNumber = (day: DayOfWeek) => {
  switch (day) {
    case 'MONDAY':
      return 1;
    case 'TUESDAY':
      return 2;
    case 'WEDNESDAY':
      return 3;
    case 'THURSDAY':
      return 4;
    case 'FRIDAY':
      return 5;
    case 'SATURDAY':
      return 6;
    case 'SUNDAY':
      return 7;
  }
};

import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

type Props = {
  onChange: (event: { target: { value: string } }) => void;
  name: string;
};

export const DayOfMonthInput = forwardRef<HTMLInputElement, Props>(function DayOfMonthInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      defaultValue={'1'}
      mask={/\b([1-9]|[12][0-9]|3[01])\b/}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { value } })}
      overwrite
    />
  );
});

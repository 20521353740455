import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EMERGENCY_COLOR, WARNING_LIGHT_COLOR } from 'shared/utils/palette';

export const MobileMonthCalendarStylesOverrides = styled(Box)<BoxProps>(({ theme }) => {
  const { palette, typography, functions, borders } = theme;

  const { primary, secondary, white, text, grey, light, neutral, black } = palette;
  const { fontWeightBold } = typography;
  const { pxToRem } = functions;
  const { borderRadius } = borders;

  const todayCommon = {
    color: `${white.main} !important`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(22),
    height: pxToRem(22),
    borderRadius: '50%',
    margin: 'auto'
  };

  return {
    height: '100%',
    padding: `0 ${pxToRem(28)}`,
    borderTop: `1px solid ${grey[200]}`,
    '& .fc-scrollgrid-sync-table': {
      height: 'auto'
    },
    '& .fc-col-header-cell': {
      padding: `${pxToRem(32)} 0 !important`,
      color: text.disabled
    },
    // necessary selector to remove border from around of calendar
    '& .fc-scrollgrid-section > td': {
      border: 'none !important'
    },
    '& .fc-scrollgrid-section > th': {
      border: 'none !important'
    },
    '& .fc-scrollgrid': {
      border: `none !important`
    },
    '& .fc-day': {
      border: 'none !important',
      padding: 0,
      cursor: 'pointer',
      '& .fc-daygrid-day-top': {
        display: 'flex',
        justifyContent: 'center',
        '& .fc-daygrid-day-number': {
          color: `${secondary.main} !important`,
          fontWeight: fontWeightBold,
          width: '100% !important',
          padding: '0 !important'
        }
      },
      '& .fc-daygrid-day-events': {
        display: 'none'
      },
      '& .fc-daygrid-day-bottom': {
        display: 'none'
      }
    },
    '& .date-is-free': {
      backgroundColor: `${neutral[200]} !important`,
      border: `2px solid ${light.main}`,
      cursor: 'default !important'
    },
    '& .date-has-asap-offer': {
      backgroundColor: `${EMERGENCY_COLOR} !important`
    },
    '& .date-has-any-offer': {
      backgroundColor: `${WARNING_LIGHT_COLOR} !important`
    },
    // "other" means days in previous and next months
    '& .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number': {
      color: `${secondary.main} !important`
    },
    '& .fc .fc-daygrid-day.fc-day-today': {
      backgroundColor: 'transparent'
    },
    '& :not(.date-has-asap-offer) .fc-daygrid-today-override': {
      ...todayCommon,
      backgroundColor: `${primary.main} !important`
    },
    '& .date-has-asap-offer .fc-daygrid-today-override': {
      ...todayCommon,
      backgroundColor: `${black.main} !important`
    },
    '& .fc-event': {
      padding: '0 !important',
      margin: '0 !important',
      borderRadius: `${borderRadius.lg}`,
      '&:focus': {
        backgroundColor: 'transparent !important'
      },
      '&::before': {
        backgroundColor: 'transparent !important'
      },
      '&::after': {
        backgroundColor: 'transparent !important'
      }
    },
    '& .fc-more-popover': {
      display: 'hidden'
    },
    '& .fc-daygrid-more-link': {
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    },
    '& .selected-date': {
      backgroundColor: `${light.main} !important`,
      borderRadius: borderRadius.lg,
      cursor: 'pointer'
    }
  };
});

import { pxToRem, theme } from '@medsi/mui-theme';
import { Box, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { DESKTOP_BACKGROUND_SHIFT, MOBILE_BACKGROUND_SHIFT } from 'features/authentication/layout/AuthLayout';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import useShowAlertBeforeUnload from 'shared/hooks/useShowAlertBeforeUnload';

export const WardCreator = (): JSX.Element => {
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();
  useShowAlertBeforeUnload();

  useEffect(() => {
    router.navigate(paths.dashboard.wards.create.names);
  }, []);

  const fontWeightMedium = theme.typography.fontWeightMedium;

  const titles = [
    { name: 'Nazwa', path: paths.dashboard.wards.create.names },
    { name: 'Adres', path: paths.dashboard.wards.create.address },
    { name: 'Dyżury', path: paths.dashboard.wards.create.weeklyShiftPattern },
    { name: 'Termin złożenia dyspozycyjności', path: paths.dashboard.wards.create.wardDeadline },
    { name: 'Wyślij zaproszenie', path: paths.dashboard.wards.create.invite }
  ];
  const title = titles.find(title => title.path === pathname)?.name;
  const step = titles.findIndex(title => title.path === pathname) + 1;

  const backgroundShift = isDesktop ? DESKTOP_BACKGROUND_SHIFT : MOBILE_BACKGROUND_SHIFT;

  return (
    <Grid container justifyContent="center" alignItems="center" height="100%" mt={0}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: `calc(50% ${backgroundShift}px)`,
          backgroundPositionY: 'bottom'
        }}
      ></Box>
      <Grid item xs={12} md={8} lg={5} xl={4}>
        <Card sx={theme => ({ borderRadius: isDesktop ? theme.borders.borderRadius.xxxl : 0 })}>
          <Box
            mx={2}
            mt={4}
            pr={2}
            pb={{ xs: 0, md: 2 }}
            pl={2}
            mb={1}
            sx={{ backgroundColor: 'info', borderRadius: pxToRem(20), coloredShadow: 'info', textAlign: 'center' }}
          >
            <Typography variant="h3" fontWeight={fontWeightMedium} color="primary">
              {title}
            </Typography>
            <Typography color="primary" variant="body3">
              Krok {step} z {titles.length}
            </Typography>
          </Box>
          <Box pb={{ xs: 0, md: 3 }} px={3}>
            <Box mt={2} mb={2}>
              <Outlet />
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

import { Box, ClickAwayListener, Popper as MuiPopper } from '@mui/material';
import { ReactNode } from 'react';
import { POPPER_Z_INDEX } from 'shared/utils/zindex';

type Props = {
  children: ReactNode;
  anchor: HTMLElement | null;
  isOpen: boolean;
  onClickAwayCallback: () => void;
};

export const Popper = (props: Props) => {
  return (
    <MuiPopper
      sx={{ zIndex: POPPER_Z_INDEX }}
      anchorEl={props.anchor}
      open={props.isOpen}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            rootBoundary: 'document',
            padding: 16
          }
        }
      ]}
    >
      <ClickAwayListener onClickAway={props.onClickAwayCallback}>
        <Box>{props.children}</Box>
      </ClickAwayListener>
    </MuiPopper>
  );
};

import { pxToRem, rgba } from '@medsi/mui-theme';
import { Avatar, Box, Grid, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import { useMemo } from 'react';
import { Doctor, ShiftAssignment } from 'shared/types/planning';
import { Users } from 'shared/types/users';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';
import { JustificationFilter } from '../justificationFilter';

type Props = {
  shiftAssignment: ShiftAssignment;
};

export const DesktopShiftAssignmentEventContent = (props: Props) => {
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  // other
  const theme = useTheme();

  const bucketId = props.shiftAssignment?.shift?.shiftBucketId;
  const justifications = shiftPlan?.justifications;

  const justificationsOnBucket = useMemo(() => {
    if (!bucketId || !justifications) {
      return [];
    }
    return new JustificationFilter(justifications).withBucket(bucketId).getJustifications();
  }, [justifications, bucketId]);

  const { palette, typography } = theme;

  const isModifiedManually = props.shiftAssignment.modifiedManually;
  const isNotAssigned = !props.shiftAssignment.doctor;
  const isWithWarning = !_.isEmpty(justificationsOnBucket);

  let borderColor = '';
  let backgroundColor = '';

  if (isModifiedManually) {
    borderColor = palette.neutral[600];
    backgroundColor = palette.neutral[500];
  } else if (isNotAssigned) {
    borderColor = palette.error.dark;
    backgroundColor = rgba('#FF8946', 0.3);
  } else if (isWithWarning) {
    borderColor = palette.warning.dark;
    backgroundColor = rgba('#FAAD14', 0.3);
  } else {
    borderColor = palette.neutral[600];
    backgroundColor = palette.neutral[400];
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      border={`2px solid ${borderColor}`}
      borderRadius={theme.borders.borderRadius.md}
      sx={{ backgroundColor: backgroundColor, '&:hover': { backgroundColor: rgba(backgroundColor, 0.2) } }}
      height={isNotAssigned ? pxToRem(38) : 'auto'}
      my={pxToRem(2)}
    >
      <Grid display="flex" flexDirection="column" gap={1} p={`${pxToRem(2)} ${pxToRem(4)}`}>
        {props.shiftAssignment?.doctor && (
          <Avatar
            title={Users.toString<Doctor>(props.shiftAssignment.doctor)}
            variant="circular"
            sx={{ bgcolor: palette.components.avatar.fill, width: 32, height: 32, fontSize: typography.size.sm }}
          >
            {Users.getInitials<Doctor>(props.shiftAssignment.doctor)}
          </Avatar>
        )}
      </Grid>
    </Box>
  );
};

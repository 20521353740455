import { InputBaseComponentProps } from '@mui/material';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export const PostalCodeInput = forwardRef<HTMLInputElement, InputBaseComponentProps>(function PostalCodeInput(props, ref) {
  const castedProps = props as unknown as React.HTMLAttributes<HTMLInputElement>;
  return (
    <IMaskInput
      {...castedProps}
      mask="00-000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value: any) => castedProps.onChange?.(value)}
      overwrite
    />
  );
});

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ShiftBucket } from 'shared/types/shiftBucket';
import { useGetShiftBucketsByWardIdBetweenDatesQuery } from 'store/api/endpoints/shiftBucketEndpoint';
import { useAppSelector } from 'store/hooks';

const defaultShiftBuckets: ShiftBucket[] = [];

export const useGetShiftBuckets = () => {
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const currentRange = useAppSelector(state => state.shiftBucketsSlice.currentRange);

  const queryData =
    activeWardId && currentRange?.from && currentRange?.to
      ? { wardId: activeWardId, fromDate: currentRange.from, toDate: currentRange.to }
      : skipToken;

  const { data: shiftBuckets = defaultShiftBuckets, refetch } = useGetShiftBucketsByWardIdBetweenDatesQuery(queryData);

  return { shiftBuckets, refetch };
};

import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { apiSlice } from 'store/api/api';
import { useAppDispatch } from 'store/hooks';
import { authSliceReset } from 'store/slices/authSlice';

export const Logout = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(authSliceReset());
    navigate(paths.root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box position="absolute" width="100%" height="100%">
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress size="1rem" />
      </Box>
    </Box>
  );
};

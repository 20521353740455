import { addMonths, format, isSameMonth } from 'date-fns';
import _ from 'lodash';
import { useMemo } from 'react';

import { CalendarEventData } from 'shared/types/calendar';
import { PreferenceEnum, ShiftBucket } from 'shared/types/shiftBucket';
import { ShiftPlanStatus } from './useGetShiftPlanStatus';

export const useGetPreferencesEvents = (shiftBuckets: ShiftBucket[], shiftPlanStatus: ShiftPlanStatus): CalendarEventData[] => {
  const events = useMemo(() => {
    if (shiftPlanStatus !== ShiftPlanStatus.NOT_EXISTING) {
      return [];
    }
    const nextMonthFromNow = addMonths(new Date(), 1);
    return _.chain(shiftBuckets)
      .filter(sb => isSameMonth(nextMonthFromNow, new Date(sb.start)))
      .groupBy(sb => format(new Date(sb.start), 'yyyy-MM-dd'))
      .map(group => {
        let presence = 0;
        let cyclic = 0;
        let absenceOnDay = 0;
        let absenceOnShift = 0;
        let holiday = 0;

        group.forEach(shiftBucket => {
          const result = _.countBy(shiftBucket.preferences, 'type');
          presence += result[PreferenceEnum.PRESENCE] ?? 0;
          cyclic += result[PreferenceEnum.CYCLIC] ?? 0;
          absenceOnDay += result[PreferenceEnum.ABSENCE_ON_DAY] ?? 0;
          absenceOnShift += result[PreferenceEnum.ABSENCE_ON_SHIFT] ?? 0;
          holiday += result[PreferenceEnum.HOLIDAY] ?? 0;
        });

        const first = _.first(group)!;
        const event: CalendarEventData = {
          id: first.id!,
          start: new Date(first.start),
          end: new Date(first.start),
          extendedProps: { type: 'PREFERENCES', presence: presence + cyclic, absence: absenceOnDay + absenceOnShift + holiday },
          editable: false
        };

        return event;
      })
      .value();
  }, [shiftBuckets, shiftPlanStatus]);

  return events;
};

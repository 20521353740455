import { theme } from '@medsi/mui-theme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import pl from 'date-fns/locale/pl';
import { useGetInputAdornmentRenderer } from 'shared/hooks/useGetInputAdornment';
import './timePicker.css';

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
  label: string;
  error?: boolean;
};

export const ShiftTimePicker = (props: Props) => {
  const { functions, palette } = theme;
  const { pxToRem, rgba } = functions;
  const { error, secondary } = palette;

  const inputAdornment = useGetInputAdornmentRenderer(<AccessTimeIcon color="primary" fontSize="small" />);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <TimePicker
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        format={'HH:mm'}
        slots={{ openPickerButton: () => <AccessTimeIcon fontSize="small" cursor="pointer" /> }}
        slotProps={{
          ...inputAdornment
        }}
        sx={{
          color: secondary.dark,
          '& .MuiInputBase-root': {
            height: pxToRem(34),
            borderRadius: pxToRem(20)
          },
          '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
            color: props.error ? `${error.dark} !important` : `${rgba(secondary.main, 0.6)} !important`
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            backgroundColor: props.error ? `${rgba(error.light, 0.08)} !important` : 'default'
          }
        }}
      />
    </LocalizationProvider>
  );
};

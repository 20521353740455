import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ResultMessageProps {
  error?: string;
}
export const FormError = (props: ResultMessageProps): JSX.Element => {
  const theme = useTheme();

  const fontWeightRegular = theme.typography.fontWeightRegular;
  return (
    <Box position="relative">
      <Typography variant="caption" fontWeight={fontWeightRegular} sx={{ color: theme.palette.error.dark }}>
        {props.error}
      </Typography>
    </Box>
  );
};

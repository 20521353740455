import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const SLICE_NAME = 'sidenavSlice';

interface SidenavState {
  isSidenavHidden: boolean;
}

const initialState: SidenavState = {
  isSidenavHidden: false
};

export const sidenavSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsSidenavHidden(state, action: PayloadAction<boolean>) {
      state.isSidenavHidden = action.payload;
    },
    sidenavSliceReset() {
      return initialState;
    }
  }
});

export const { setIsSidenavHidden, sidenavSliceReset } = sidenavSlice.actions;

export default sidenavSlice.reducer;

import { ShiftAssignment } from './planning';
import { ShiftBucket } from './shiftBucket';

export interface CalendarEventData {
  id: string;
  start: Date;
  end: Date;
  title?: string;
  editable: boolean;
  extendedProps: CalendarExtendedProps;
}

export type CalendarExtendedProps =
  | ShiftBucketExtendedProps
  | CalendarEventExtendedProps
  | ShiftAssignmentExtendedProps
  | PreferencesExtendedProps
  | AcceptedShiftAssignmentExtendedProps;

export interface ShiftBucketExtendedProps {
  type: 'SHIFT_BUCKET';
  shiftBucket: ShiftBucket;
  isAssigned: boolean;
  viewAsOwner: boolean;
  eventViewerId: string;
  shiftEventType: ShiftEventType;
  hasAsapOffer?: boolean;
  hasAnyOffer?: boolean;
}

export type CalendarEventExtendedProps = {
  type: 'CALENDAR_EVENT';
  calendarEventId: string;
};

export interface ShiftAssignmentExtendedProps {
  type: 'SHIFT_ASSIGNMENT';
  shiftAssignment: ShiftAssignment;
}

export interface PreferencesExtendedProps {
  type: 'PREFERENCES';
  presence: number;
  absence: number;
}

export interface AcceptedShiftAssignmentExtendedProps {
  type: 'ACCEPTED_SHIFT_ASSIGNMENT';
  shiftAssignment: ShiftAssignment;
}

export namespace CalendarTypeGuard {
  export const isShiftBucketExtendedProps = (extendedProps: CalendarExtendedProps): extendedProps is ShiftBucketExtendedProps => {
    return extendedProps.type === 'SHIFT_BUCKET';
  };

  export const isCalendarEventExtendedProps = (extendedProps: CalendarExtendedProps): extendedProps is CalendarEventExtendedProps => {
    return extendedProps.type === 'CALENDAR_EVENT';
  };

  export const isShiftAssignmentExtendedProps = (extendedProps: CalendarExtendedProps): extendedProps is ShiftAssignmentExtendedProps => {
    return extendedProps.type === 'SHIFT_ASSIGNMENT';
  };

  export const isPreferencesExtendedProps = (extendedProps: CalendarExtendedProps): extendedProps is PreferencesExtendedProps => {
    return extendedProps.type === 'PREFERENCES';
  };

  export const isAcceptedShiftAssignmentExtendedProps = (
    extendedProps: CalendarExtendedProps
  ): extendedProps is AcceptedShiftAssignmentExtendedProps => {
    return extendedProps.type === 'ACCEPTED_SHIFT_ASSIGNMENT';
  };

  export const isShiftBucketEvent = (e: CalendarEventData): e is CalendarEventData & { extendedProps: ShiftBucketExtendedProps } => {
    return CalendarTypeGuard.isShiftBucketExtendedProps(e.extendedProps) && e.extendedProps.type === 'SHIFT_BUCKET';
  };
}

export const enum CalendarView {
  MULTI_MONTH_YEAR = 'multiMonthYear',
  DAY_GRID_MONTH = 'dayGridMonth',
  TIME_GRID_WEEK = 'timeGridWeek',
  TIME_GRID_DAY = 'timeGridDay'
}

export enum ShiftEventType {
  PAST_SHIFT = 'PAST_SHIFT',
  SHIFT = 'SHIFT',
  PREFERENCE = 'PREFERENCE'
}

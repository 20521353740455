import FullCalendar from '@fullcalendar/react';
import { theme } from '@medsi/mui-theme';
import { Box, Button, Typography } from '@mui/material';
import { format, isSameMonth } from 'date-fns';
import { pl } from 'date-fns/locale';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { fullCalendarSwiped } from 'shared/observers/observers';

type Props = {
  calendarRef?: RefObject<FullCalendar>;
};

export const MobileImpersonationCalendarToolbar = (props: Props): JSX.Element => {
  const [selectedDay, setSelectedDay] = useState(new Date());

  const { functions, palette } = theme;
  const { pxToRem } = functions;

  const onFullCalendarSwiped = useCallback(() => {
    const date = props.calendarRef?.current?.getApi().getDate();
    if (date && !isSameMonth(date, selectedDay)) {
      setSelectedDay(date);
    }
  }, [props.calendarRef, selectedDay]);

  useEffect(() => {
    fullCalendarSwiped.attach(onFullCalendarSwiped);
    return () => fullCalendarSwiped.detach(onFullCalendarSwiped);
  }, [onFullCalendarSwiped]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      <Typography variant="body2" color={palette.text.primary} sx={{ flexGrow: 0, pr: pxToRem(6), textTransform: 'capitalize' }}>
        {format(selectedDay, 'LLLL y', { locale: pl })}
      </Typography>

      <Button variant="outlined" size="large" onClick={() => props.calendarRef?.current?.getApi().today()}>
        Dziś
      </Button>
    </Box>
  );
};

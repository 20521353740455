import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { FormError } from 'features/ui/formError/FormError';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { useSignInMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';
import { AuthLayout } from './layout/AuthLayout';
import { reactNativeWindow } from 'shared/utils/environmentUtils';
import { useChangeDeviceOwnerMutation } from 'store/api/endpoints/userEndpoint';
import { theme } from '@medsi/mui-theme';

interface SignInFormValues {
  email: string;
  password: string;
}

export const SignIn = (): JSX.Element => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginError, setLoginError] = useState('');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [signIn, { error, isLoading }] = useSignInMutation();
  const [changeDeviceOwner] = useChangeDeviceOwnerMutation();
  // other
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<SignInFormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    if (loggedUser && reactNativeWindow?.isNativeApp && reactNativeWindow.mobileDeviceToken) {
      changeDeviceOwner({ deviceToken: reactNativeWindow.mobileDeviceToken, userId: loggedUser.id });
    }
  }, [changeDeviceOwner, loggedUser]);

  useEffect(() => {
    if (!error) {
      setLoginError('');
      return;
    }
    if ('status' in error && error.status === 401) {
      setLoginError('Niepoprawne dane logowania');
    } else {
      setLoginError('Wystąpił błąd podczas logowania');
    }
  }, [error]);

  useEffect(() => {
    loggedUser && navigate(paths.dashboard.calendar, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: SignInFormValues) => {
    signIn({ username: data.email.toLowerCase(), password: data.password });
  };

  return (
    <AuthLayout>
      <Card sx={{ p: 3, boxShadow: theme.boxShadows.elevation24 }}>
        <Box pb={3} textAlign="center" sx={{ backgroundColor: 'info', borderRadius: 'lg', coloredShadow: 'info' }}>
          <Typography variant="h4" fontWeight="medium" color="primary">
            Zaloguj
          </Typography>
        </Box>
        <Box textAlign="center">{loginError ? <FormError error={loginError} /> : ''}</Box>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box mb={2}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Adres email" fullWidth />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                required: validationMessage.required,
                validate: validateEmail
              }}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      className={fieldState.invalid ? 'error' : ''}
                      label="Hasło"
                      autoComplete="off"
                      type={passwordVisible ? 'text' : 'password'}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setPasswordVisible(prev => !prev)} edge="end">
                              {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                required: validationMessage.required
              }}
            />
          </Box>
          <Link to={paths.resetPassword}>
            <Button variant="text" color="secondary">
              Nie pamiętam hasła
            </Button>
          </Link>
          <Box mt={3} mb={2}>
            <Button variant="contained" color="primary" type="submit" disabled={isLoading} fullWidth>
              Zaloguj się
            </Button>
          </Box>
          <Link to={paths.signUp}>
            <Button variant="text" color="secondary" fullWidth>
              Zarejestruj się
            </Button>
          </Link>
        </Box>
      </Card>
    </AuthLayout>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { NotificationAnchor } from 'features/notifications/notificationAnchor';
import { Popper } from 'features/ui/popper/popper';
import { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { Notification } from '../../../shared/types/notification';
import { useGetNotificationsQuery } from '../../../store/api/endpoints/notificationEndpoint';
import { NotificationList } from '../../notifications/notificationList';

const defaultNotifications: Notification[] = [];

export const DashboardNavbar = (): JSX.Element => {
  const [popperAnchor, setPopperAnchor] = useState<HTMLElement | null>(null);
  const [areNotificationsOpen, setAreNotificationsOpen] = useState(false);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data: notifications = defaultNotifications } = useGetNotificationsQuery(loggedUser?.id ?? skipToken);

  const { boxShadows, borders, palette } = theme;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchor(event.currentTarget);
    setAreNotificationsOpen(prev => !prev);
  };

  const cWhite = palette.white.main;

  return (
    <Box position={'relative'} pt={2} pb={1} pr={3} sx={{ backgroundColor: cWhite }}>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        borderRadius={borders.borderRadius.xxxl}
        sx={{ backgroundColor: palette.background.paper }}
      >
        <NotificationAnchor isNotificationListVisible={areNotificationsOpen} onToggleNotificationList={handleOpenMenu} />
        <Popper anchor={popperAnchor} isOpen={areNotificationsOpen} onClickAwayCallback={() => setAreNotificationsOpen(false)}>
          <Box borderRadius={borders.borderRadius.xxxl} sx={{ maxWidth: pxToRem(380), width: 1, backgroundColor: cWhite }}>
            <Box pr={1} pb={pxToRem(28)} pt={1} boxShadow={boxShadows.pinkBig} borderRadius={borders.borderRadius.xxxl}>
              <NotificationList notifications={notifications} onCloseNotificationsCallback={() => setAreNotificationsOpen(false)} />
            </Box>
          </Box>
        </Popper>
      </Box>
    </Box>
  );
};

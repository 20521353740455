import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { Avatar, Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import { useEffect } from 'react';
import { Offer } from 'shared/types/offer';
import { ShiftBucket, ShiftBuckets } from 'shared/types/shiftBucket';
import { User, Users } from 'shared/types/users';
import { findById } from 'shared/utils/commonUtils';
import { ERROR_LIGHT_COLOR, WARNING_LIGHT_COLOR } from 'shared/utils/palette';
import { useAddOfferResponseMutation, useGetSingleOfferQuery, useRejectOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

type Props = {
  offer: Offer;
  isAsap: boolean;
};

const defaultUsers: User[] = [];

export const ShiftOffer = (props: Props): JSX.Element => {
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  const [addResponse, { isSuccess: addResponseSuccess, isError: addResponseError }] = useAddOfferResponseMutation();
  const { isLoading } = useGetSingleOfferQuery(props.offer.id ?? skipToken);
  const [rejectOffer, { isSuccess: rejectOfferSuccess, isError: rejectOfferError }] = useRejectOfferMutation();
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const { shiftBuckets, refetch: refetchShiftBuckets } = useGetShiftBuckets();

  const {
    palette,
    borders: { borderRadius }
  } = useTheme();

  useEffect(() => {
    addResponseSuccess && dispatch(addAlert({ color: 'success', text: 'Prośba została zaakceptowana' }));
  }, [addResponseSuccess, dispatch]);

  useEffect(() => {
    rejectOfferSuccess && dispatch(addAlert({ color: 'success', text: 'Prośba została odrzucona' }));
  }, [rejectOfferSuccess, dispatch]);

  useEffect(() => {
    addResponseError && dispatch(addAlert({ color: 'error', text: 'Nie udało się zaakceptować prośby' }));
  }, [addResponseError, dispatch]);

  useEffect(() => {
    rejectOfferError && dispatch(addAlert({ color: 'error', text: 'Nie udało się odrzucić prośby' }));
  }, [rejectOfferError, dispatch]);

  const onAccept = async () => {
    if (!props.offer.id || !loggedUser?.id) {
      return;
    }
    const response = {
      offerId: props.offer.id,
      offerResponse: {
        responder: loggedUser.id,
        shiftOfferedInReturn: props.offer.offerRequest.wantedShift
      }
    };

    if (props.offer.type !== 'SWAP_ONE_TO_ONE') {
      delete response.offerResponse.shiftOfferedInReturn;
    }

    await addResponse(response);
    refetchShiftBuckets();
  };

  const onReject = () => {
    props.offer.id ? rejectOffer(props.offer.id) : dispatch(addAlert({ color: 'error', text: 'Nie udało się odrzucić prośby' }));
  };

  const generateActionButtons = () => {
    return props.isAsap ? (
      <Button disabled={isLoading} onClick={onAccept} variant="outlined">
        Przejmij
      </Button>
    ) : (
      <Box display="flex" justifyContent="flex-end" gap={1} flexWrap="wrap">
        <Button disabled={isLoading} onClick={onReject} variant="outlined" startIcon={<CloseRoundedIcon />} color="secondary">
          Odrzuć
        </Button>
        <Button disabled={isLoading} onClick={onAccept} variant="outlined" startIcon={<CheckCircleIcon />}>
          Potwierdź
        </Button>
      </Box>
    );
  };

  const requester = findById<User>(users, props.offer.offerRequest.requester);
  const initials = Users.getInitials<User>(requester);
  const stringifiedUser = Users.toString<User>(requester);
  const offeredShiftTime = ShiftBuckets.getShiftBucketDayTimeLabel(
    findById<ShiftBucket>(shiftBuckets, props.offer.offerRequest.wantedShift)
  );

  return (
    <Grid
      container
      py={1}
      my={1}
      width="100%"
      sx={{
        backgroundColor: props.isAsap ? ERROR_LIGHT_COLOR : WARNING_LIGHT_COLOR
      }}
      borderRadius={borderRadius.xxxl}
      p={1}
      gap={1}
    >
      <Grid container item flexWrap="nowrap" gap={1}>
        <Grid item lg={1} display="flex" alignItems="center">
          <Avatar sx={{ bgcolor: palette.grey[500] }}>{initials}</Avatar>
        </Grid>
        <Grid item lg={11} display="flex" justifyContent="space-between" flexGrow={1}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <Typography variant="body3">{stringifiedUser}</Typography>
            <Typography variant="body1">Lekarz</Typography>
          </Box>
          {props.isAsap ? (
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="body3">Przejęcie dyżuru na CITO</Typography>
              <ReportProblemOutlinedIcon fontSize="medium" />
            </Box>
          ) : (
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="body3">Zamiana za: {offeredShiftTime}</Typography>
              <SwapHorizOutlinedIcon fontSize="medium" />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item sm={12} display="flex" justifyContent="flex-end">
        {generateActionButtons()}
      </Grid>
    </Grid>
  );
};

import { Preference, ShiftBucket } from 'shared/types/shiftBucket';
import { HTTP_METHODS, apiSlice } from '../api';

const deserializeCollection = (buckets: ShiftBucket[]) => {
  buckets?.forEach(sb => {
    sb.preferences = [...new Map(Object.entries(sb.preferences)).values()];
  });
  return buckets;
};

const deserializeSingle = (bucket: ShiftBucket) => {
  if (bucket?.preferences) {
    bucket.preferences = [...new Map(Object.entries(bucket.preferences)).values()];
  }
  return bucket;
};

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    generateShiftBuckets: builder.mutation<ShiftBucket[], { wardId: string; months: number }>({
      query: params => ({
        url: `/wards/${params.wardId}/shift-buckets?months=${params.months}`,
        method: HTTP_METHODS.POST
      }),
      transformResponse: deserializeCollection,
      invalidatesTags: [{ type: 'ShiftBucket', id: 'LIST' }]
    }),
    updateAssignedDoctors: builder.mutation<ShiftBucket, { id: string; doctors: string[] }>({
      query: params => ({
        url: `/shift-buckets/${params.id}/doctors`,
        method: HTTP_METHODS.PUT,
        body: params.doctors
      }),
      transformResponse: deserializeSingle,
      invalidatesTags: ['ShiftBucket']
    }),
    getShiftBucketsByWardIdBetweenDates: builder.query<ShiftBucket[], { wardId: string; fromDate: string; toDate: string }>({
      query: params => ({
        url: `/wards/${params.wardId}/shift-buckets?fromDate=${params.fromDate}&toDate=${params.toDate}`,
        method: HTTP_METHODS.GET
      }),
      transformResponse: deserializeCollection,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'ShiftBucket' as const, id })), { type: 'ShiftBucket', id: 'LIST' }]
          : [{ type: 'ShiftBucket', id: 'LIST' }]
    }),
    getShiftBucketById: builder.query<ShiftBucket, string>({
      query: bucketId => ({
        url: `/shift-buckets/${bucketId}`,
        method: HTTP_METHODS.GET
      }),
      transformResponse: deserializeSingle,
      providesTags: result => (result ? [{ type: 'ShiftBucket' as const, id: result.id }] : [])
    }),
    updateShiftPreferences: builder.mutation<
      ShiftBucket[],
      { wardId: string; preferences: Map<string, Preference | null>; userId: string }
    >({
      query: params => ({
        url: `/wards/${params.wardId}/shift-buckets/preferences?userId=${params.userId}`,
        method: HTTP_METHODS.PUT,
        body: Object.fromEntries(params.preferences)
      }),
      transformResponse: deserializeCollection,
      invalidatesTags: result => (result ? [...result.map(({ id }) => ({ type: 'ShiftBucket' as const, id }))] : [])
    }),
    togglePreferencesAcceptance: builder.mutation<ShiftBucket[], { wardId: string; userId: string }>({
      query: params => ({
        url: `/wards/${params.wardId}/shift-buckets/preferences/toggle-acceptance?userId=${params.userId}`,
        method: HTTP_METHODS.PUT
      }),
      transformResponse: deserializeCollection,
      invalidatesTags: result => (result ? [...result.map(({ id }) => ({ type: 'ShiftBucket' as const, id }))] : [])
    })
  }),
  overrideExisting: false
});

export const {
  useGenerateShiftBucketsMutation,
  useUpdateAssignedDoctorsMutation,
  useGetShiftBucketsByWardIdBetweenDatesQuery,
  useUpdateShiftPreferencesMutation,
  useGetShiftBucketByIdQuery,
  useTogglePreferencesAcceptanceMutation
} = extendedApi;

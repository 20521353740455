import FullCalendar from '@fullcalendar/react';
import { theme } from '@medsi/mui-theme';
import CalendarViewDayOutlinedIcon from '@mui/icons-material/CalendarViewDayOutlined';
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAlt';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import { format, isSameMonth } from 'date-fns';
import { pl } from 'date-fns/locale';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { fullCalendarSwiped } from 'shared/observers/observers';
import { CalendarView } from 'shared/types/calendar';
import { CalendarPrinter } from '../../ui/calendar/print/calendarPrinter';
import { Popper } from '../../ui/popper/popper';
import { EventOwnerFilter } from './eventOwnerFilter';

type Props = {
  calendarView: CalendarView;
  calendarRef?: RefObject<FullCalendar>;
  availableViews: CalendarView[];
  onCalendarViewChangeCallback?: (view: CalendarView) => void;
  onDateSelectedCallback: (date: Date) => void;
  onChangeEventsOwnerCallback?: (doctorName: string) => void;
};

export const MobileMainCalendarToolbar = (props: Props): JSX.Element => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState<HTMLElement | null>(null);

  const { functions, palette, borders, boxShadows } = theme;
  const { pxToRem } = functions;

  const onDateSelected = (date: Date) => {
    setSelectedDay(date);
    props.onDateSelectedCallback(date);
  };

  const onFullCalendarSwiped = useCallback(() => {
    const date = props.calendarRef?.current?.getApi().getDate();
    if (date && !isSameMonth(date, selectedDay)) {
      setSelectedDay(date);
    }
  }, [props.calendarRef, selectedDay]);

  useEffect(() => {
    fullCalendarSwiped.attach(onFullCalendarSwiped);
    return () => fullCalendarSwiped.detach(onFullCalendarSwiped);
  }, [onFullCalendarSwiped]);

  const getSelectedViewIcon = () => {
    switch (props.calendarView) {
      case CalendarView.TIME_GRID_DAY:
        return <CalendarViewDayOutlinedIcon />;
      case CalendarView.TIME_GRID_WEEK:
        return <CalendarViewWeekOutlinedIcon />;
      case CalendarView.DAY_GRID_MONTH:
        return <CalendarViewMonthOutlinedIcon />;
      default:
        return <></>;
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchor(event.currentTarget);
    setIsPopperOpen(prev => !prev);
  };

  return (
    <Box>
      <Drawer
        anchor="left"
        open={isViewDrawerOpen}
        onClose={() => setIsViewDrawerOpen(false)}
        sx={{ '& .MuiDrawer-paper': { height: 'fit-content', borderRadius: borders.borderRadius.xxxl } }}
      >
        <Box py={4} px={3} display="flex" flexDirection="column" alignItems="flex-start" gap={3}>
          <Typography variant="body1" color={palette.text.primary}>
            Zmień widok
          </Typography>
          {props.availableViews.includes(CalendarView.TIME_GRID_DAY) && (
            <Button
              color={props.calendarView === CalendarView.TIME_GRID_DAY ? 'primary' : 'secondary'}
              startIcon={<CalendarViewDayOutlinedIcon />}
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.TIME_GRID_DAY)}
              variant="text"
            >
              Dzień
            </Button>
          )}
          {props.availableViews.includes(CalendarView.TIME_GRID_WEEK) && (
            <Button
              color={props.calendarView === CalendarView.TIME_GRID_WEEK ? 'primary' : 'secondary'}
              startIcon={<CalendarViewWeekOutlinedIcon />}
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.TIME_GRID_WEEK)}
              variant="text"
            >
              Tydzień
            </Button>
          )}
          {props.availableViews.includes(CalendarView.DAY_GRID_MONTH) && (
            <Button
              color={props.calendarView === CalendarView.DAY_GRID_MONTH ? 'primary' : 'secondary'}
              startIcon={<CalendarViewMonthOutlinedIcon />}
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.DAY_GRID_MONTH)}
              variant="text"
            >
              Miesiąc
            </Button>
          )}
        </Box>
      </Drawer>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <IconButton onClick={() => setIsViewDrawerOpen(true)} size="small" color="secondary">
            {getSelectedViewIcon()}
          </IconButton>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color={palette.text.primary} sx={{ flexGrow: 0, pr: pxToRem(6), textTransform: 'capitalize' }}>
              {format(selectedDay, 'LLLL y', { locale: pl })}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={() => setIsFilterExpanded(prev => !prev)} size="small" color="secondary">
          <FilterAltOutlinedIcon />
        </IconButton>
        <Box>
          <IconButton size="medium" color="secondary" onClick={handleOpenMenu}>
            <PrintIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            onDateSelected(new Date());
            props.calendarRef?.current?.getApi().today();
          }}
        >
          Dziś
        </Button>
      </Box>

      <Box px={2} pb={2} sx={{ display: isFilterExpanded ? 'block' : 'none' }}>
        <EventOwnerFilter onChangeEventsOwnerCallback={props.onChangeEventsOwnerCallback} />
      </Box>

      <Popper anchor={popperAnchor} isOpen={isPopperOpen} onClickAwayCallback={() => setIsPopperOpen(false)}>
        <Box
          boxShadow={boxShadows.elevation11}
          borderRadius={borders.borderRadius.xxxl}
          px={2}
          py={1}
          sx={{ backgroundColor: palette.white.main }}
        >
          <CalendarPrinter />
        </Box>
      </Popper>
    </Box>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const SLICE_NAME = 'plannerSlice';

interface PlannerState {
  isSolving: boolean;
}

const initialState: PlannerState = {
  isSolving: false
};

export const plannerSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsSolving(state, action: PayloadAction<boolean>) {
      state.isSolving = action.payload;
    },
    plannerSliceReset() {
      return initialState;
    }
  }
});

export const { setIsSolving, plannerSliceReset } = plannerSlice.actions;

export default plannerSlice.reducer;

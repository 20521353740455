import { theme } from '@medsi/mui-theme';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { addMonths, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ShiftsPrintout } from './shiftsPrintout';

interface DateRange {
  start: Date;
  end: Date;
}
enum PredefinedRange {
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH'
}

export const CalendarPrinter = () => {
  const now = new Date();
  const nextMonth = addMonths(now, 1);
  const thisWeekRange = { start: startOfWeek(now), end: endOfWeek(now) };
  const thisMonthRange = { start: startOfMonth(now), end: endOfMonth(now) };
  const nextMonthRange = { start: startOfMonth(nextMonth), end: endOfMonth(nextMonth) };

  const [value, setValue] = useState<PredefinedRange>(PredefinedRange.THIS_MONTH);
  const [range, setRange] = useState<DateRange>(thisMonthRange);
  const printoutRef = useRef(null);

  const print = useReactToPrint({
    content: () => printoutRef.current,
    documentTitle: 'dyzury',
    onPrintError: () => {}
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
      case PredefinedRange.THIS_WEEK:
        setValue(PredefinedRange.THIS_WEEK);
        setRange(thisWeekRange);
        break;
      case PredefinedRange.THIS_MONTH:
        setValue(PredefinedRange.THIS_MONTH);
        setRange(thisMonthRange);
        break;
      case PredefinedRange.NEXT_MONTH:
        setValue(PredefinedRange.NEXT_MONTH);
        setRange(nextMonthRange);
        break;
      default:
        setRange(thisWeekRange);
    }
  };

  return (
    <Box p={1}>
      <FormControl>
        <FormLabel sx={{ paddingBottom: 1 }} focused={false}>
          Wybierz miesiąc
        </FormLabel>
        <RadioGroup
          defaultValue={PredefinedRange.THIS_WEEK}
          name="printout-range"
          value={value}
          onChange={handleChange}
          color={theme.palette.secondary.main}
        >
          <FormControlLabel
            key={PredefinedRange.THIS_MONTH}
            value={PredefinedRange.THIS_MONTH}
            control={<Radio />}
            label={format(new Date(), 'MM.yyyy')}
          />
          <FormControlLabel
            key={PredefinedRange.NEXT_MONTH}
            value={PredefinedRange.NEXT_MONTH}
            control={<Radio />}
            label={format(addMonths(new Date(), 1), 'MM.yyyy')}
          />
        </RadioGroup>
      </FormControl>
      <Box pt={1}>
        <Button variant="contained" onClick={print} fullWidth>
          Wydrukuj
        </Button>
      </Box>
      <Box display="none">
        <ShiftsPrintout ref={printoutRef} start={range.start} end={range.end} />
      </Box>
    </Box>
  );
};

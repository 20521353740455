import { addMonths, isSameMonth } from 'date-fns';
import _ from 'lodash';
import { useMemo } from 'react';
import { ShiftPlan } from 'shared/types/planning';

export enum ShiftPlanStatus {
  ACCEPTED,
  NOT_ACCEPTED,
  NOT_EXISTING
}

export const useGetShiftPlanStatus = (shiftPlan?: ShiftPlan): ShiftPlanStatus => {
  const shiftPlanStatus = useMemo(() => {
    if (!_.isEmpty(shiftPlan?.shiftAssignments)) {
      const sortedAssignments = [...shiftPlan?.shiftAssignments!].sort(
        (a, b) => new Date(a.shift.start).getTime() - new Date(b.shift.start).getTime()
      );

      const first = _.first(sortedAssignments)?.shift.start;
      const last = _.last(sortedAssignments)?.shift.start;
      const nextMonthFromNow = addMonths(new Date(), 1);
      const isPlanForNextMonth =
        first && last ? isSameMonth(nextMonthFromNow, new Date(first)) && isSameMonth(nextMonthFromNow, new Date(last)) : null;

      if (isPlanForNextMonth && shiftPlan?.accepted) {
        return ShiftPlanStatus.ACCEPTED;
      } else if (isPlanForNextMonth && !shiftPlan?.accepted) {
        return ShiftPlanStatus.NOT_ACCEPTED;
      } else {
        return ShiftPlanStatus.NOT_EXISTING;
      }
    } else {
      return ShiftPlanStatus.NOT_EXISTING;
    }
  }, [shiftPlan]);

  return shiftPlanStatus;
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Alerts } from 'features/alerts/alerts';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routing/routes';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';

const App = () => {
  const isDesktop = useIsDesktop();
  return (
    <ThemeProvider theme={theme}>
      <Alerts />
      <CssBaseline />
      <GlobalStyles
        styles={{
          '&::-webkit-scrollbar': {
            width: pxToRem(isDesktop ? 4 : 2),
            height: pxToRem(isDesktop ? 4 : 2),
            backgroundColor: theme.palette.neutral[400]
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.borders.borderRadius.xl
          }
        }}
      />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;

import { InfoOutlined } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { Box, Button, Grid, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { PreferenceType, ShiftBucket } from 'shared/types/shiftBucket';
import { useGetShiftBucketByIdQuery } from 'store/api/endpoints/shiftBucketEndpoint';
import { ShiftPreferenceMenuItem } from './utils';

interface Props {
  uuid: string;
  bucketsWithoutPreference: ShiftBucket[];
  selectedBucketId: string;
  selectedPreferenceType: PreferenceType;
  note: string;
  isEditable: boolean;
  onSelectShiftBucket: (uuid: string, bucketId: string) => void;
  onSelectPreferenceType: (uuid: string, preferenceType: PreferenceType) => void;
  onChangeNote: (uuid: string, note: string) => void;
  onRemoveEntry: (uuid: string) => void;
}

const PREFERENCE_TYPE_MENU_ITEMS: ShiftPreferenceMenuItem[] = [
  {
    id: 'PRESENCE',
    label: 'Chcę ten dyżur',
    icon: <ThumbUpIcon fontSize="small" />
  },
  {
    id: 'CYCLIC',
    label: (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        Chcę ten dyżur cyklicznie
        <Tooltip title="Co tydzień w tym miesiącu" placement="top">
          <InfoOutlined color="primary" fontSize="small" />
        </Tooltip>
      </Box>
    ),
    icon: <RefreshIcon fontSize="small" />
  },
  {
    id: 'ABSENCE_ON_SHIFT',
    label: 'Nie chcę tego dyżuru',
    icon: <ThumbDownIcon fontSize="small" />
  }
];

export const ShiftPreferenceEntry = (props: Props): JSX.Element => {
  const [shifts, setShifts] = useState<ShiftBucket[]>([]);
  // rtk
  const { data: selectedBucket } = useGetShiftBucketByIdQuery(props.selectedBucketId ?? skipToken);

  useEffect(() => {
    const bucketsWithoutPreferenceCopy = [...props.bucketsWithoutPreference];
    if (selectedBucket) {
      bucketsWithoutPreferenceCopy.push(selectedBucket);
    }

    setShifts(bucketsWithoutPreferenceCopy);
  }, [props.bucketsWithoutPreference, selectedBucket]);

  const onSelectShiftBucket = (e: SelectChangeEvent<string>) => {
    props.onSelectShiftBucket(props.uuid, e.target.value);
  };

  const onSelectPreferenceType = (e: SelectChangeEvent<string>) => {
    props.onSelectPreferenceType(props.uuid, e.target.value as PreferenceType);
  };

  const onChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeNote(props.uuid, event.target.value);
  };

  return (
    <Grid container gap={1} justifyContent="space-between">
      <Grid item xs={12} md={4}>
        {!_.isEmpty(shifts) && (
          <Select
            onChange={onSelectShiftBucket}
            value={props.selectedBucketId}
            variant="standard"
            disableUnderline
            disabled={!props.isEditable}
          >
            {_.sortBy(shifts, 'start').map(shiftBucket => (
              <MenuItem key={shiftBucket.id} value={shiftBucket.id}>
                {format(new Date(shiftBucket.start), 'HH:mm')} - {format(new Date(shiftBucket.end), 'HH:mm')}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        <Select
          onChange={onSelectPreferenceType}
          value={props.selectedPreferenceType}
          variant="standard"
          label="Dyspozycyjność"
          disableUnderline
          disabled={!props.isEditable}
        >
          {PREFERENCE_TYPE_MENU_ITEMS.map(menuItem => (
            <MenuItem key={menuItem.id} value={menuItem.id}>
              <Box display="flex" gap={1} alignItems="center">
                {menuItem.icon} {menuItem.label}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Dodaj notatkę"
          fullWidth
          multiline
          minRows={3}
          defaultValue={props.note}
          onChange={onChangeNote}
          disabled={!props.isEditable}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button color="secondary" onClick={() => props.onRemoveEntry(props.uuid)} disabled={!props.isEditable}>
          Usuń dyspozycyjność
        </Button>
      </Grid>
    </Grid>
  );
};

import FullCalendar from '@fullcalendar/react';
import { pxToRem } from '@medsi/mui-theme';
import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { addMonths, format, startOfMonth } from 'date-fns';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import { Calendar } from 'features/ui/calendar/calendar';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useReload } from 'shared/hooks/useReload';
import { CalendarTypeGuard, CalendarView } from 'shared/types/calendar';
import { ShiftAssignment, SolverStatus } from 'shared/types/planning';
import { useGetSolverStatusQuery } from 'store/api/endpoints/planningProblemEndpoint';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsSolving } from 'store/slices/plannerSlice';
import { CalendarOverlay } from './calendarOverlay';
import { ShiftAssignmentDialog } from './dialogs/shiftAssignmentDialog';
import { GlobalJustificationMessage } from './globalJustificationMessage';
import { useGetAcceptedShiftAssignmentEvents } from './hooks/useGetAcceptedShiftAssignmentEvents';
import { useGetPreferencesEvents } from './hooks/useGetPreferencesEvents';
import { useGetShiftAssignmentEvents } from './hooks/useGetShiftAssignmentEvents';
import { ShiftPlanStatus, useGetShiftPlanStatus } from './hooks/useGetShiftPlanStatus';
import { PlannerSpeedDial } from './plannerSpeedDial';
import { PlannerToolbar } from './plannerToolbar';

export const PlannerCalendar = () => {
  const [shiftAssignmentInEditContext, setShiftAssignmentInEditContext] = useState<ShiftAssignment | undefined>();
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const isSolving = useAppSelector(state => state.plannerSlice.isSolving);
  const dispatch = useAppDispatch();
  // rtk
  const { data: shiftPlan, refetch: refetchShiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken, {
    refetchOnMountOrArgChange: true
  });
  const { data: solverStatus, refetch: refetchSolverStatus } = useGetSolverStatusQuery(activeWardId ?? skipToken, {
    refetchOnMountOrArgChange: true
  });
  // other
  const { shiftBuckets } = useGetShiftBuckets();
  const shiftPlanStatus = useGetShiftPlanStatus(shiftPlan);
  const preferencesEvents = useGetPreferencesEvents(shiftBuckets, shiftPlanStatus);
  const shiftAssignmentEvents = useGetShiftAssignmentEvents(shiftPlanStatus, shiftPlan);
  const shiftBucketEvents = useGetAcceptedShiftAssignmentEvents(shiftPlanStatus, shiftPlan);
  const prevIsSolving = usePrevious(isSolving);
  const calendarRef = useRef<FullCalendar | null>(null);
  const reload = useReload();

  useEffect(() => {
    if (prevIsSolving && !isSolving) {
      refetchShiftPlan();
      refetchSolverStatus();
    }
  }, [prevIsSolving, isSolving, refetchShiftPlan, refetchSolverStatus]);

  useEffect(() => {
    dispatch(setIsSolving(solverStatus !== SolverStatus.NOT_SOLVING));
  }, [dispatch, solverStatus]);

  useEffect(() => {
    if (shiftPlan && shiftAssignmentInEditContext) {
      const replacement = shiftPlan.shiftAssignments.find(
        sa => sa.shift.shiftBucketId === shiftAssignmentInEditContext.shift.shiftBucketId
      );
      if (replacement) {
        setShiftAssignmentInEditContext(replacement);
      }
    }
  }, [shiftPlan, shiftAssignmentInEditContext]);

  const getEvents = () => {
    switch (shiftPlanStatus) {
      case ShiftPlanStatus.NOT_EXISTING:
        return preferencesEvents;
      case ShiftPlanStatus.NOT_ACCEPTED:
        return shiftAssignmentEvents;
      case ShiftPlanStatus.ACCEPTED:
        return shiftBucketEvents;
      default:
        return [];
    }
  };

  const onEventClick = (id: string) => {
    const extendedProps = events.find(event => event.id === id)?.extendedProps ?? null;

    if (extendedProps && CalendarTypeGuard.isShiftAssignmentExtendedProps(extendedProps)) {
      setShiftAssignmentInEditContext(extendedProps.shiftAssignment);
    }
  };

  const events = getEvents();
  const workingDays = useMemo(() => events.map(e => format(new Date(e.start), 'yyyy-MM-dd')), [events]);

  return (
    <Box p={{ xs: 0, md: 3 }} height="100%">
      <Box height="100%" position="relative" display="flex" flexDirection="column" gap={{ xs: 0, md: pxToRem(20) }}>
        <ShiftAssignmentDialog
          shiftAssignment={shiftAssignmentInEditContext}
          isOpen={!!shiftAssignmentInEditContext}
          onClose={() => setShiftAssignmentInEditContext(undefined)}
        />
        {shiftPlanStatus === ShiftPlanStatus.NOT_ACCEPTED && <GlobalJustificationMessage justifications={shiftPlan?.justifications} />}

        <CalendarOverlay isVisible={isSolving} />
        <Calendar
          currentView={CalendarView.DAY_GRID_MONTH}
          availableViews={[CalendarView.DAY_GRID_MONTH]}
          toolbar={
            <PlannerToolbar
              month={calendarRef.current?.getApi()?.view?.title ?? 'miesiąc nieznany - wystąpił błąd'}
              shiftPlanStatus={shiftPlanStatus}
            />
          }
          events={events}
          calendarRef={calendarRef}
          workingDays={workingDays}
          getDayCellClassNames={arg => {
            const isFreeDay = !workingDays?.includes(format(arg.date, 'yyyy-MM-dd'));
            return isFreeDay ? 'date-is-free' : '';
          }}
          onEventClick={onEventClick}
          initialDate={format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd')}
          showNonCurrentDates={false}
          onRefresh={reload}
        />
        {!isSolving && <PlannerSpeedDial shiftPlanStatus={shiftPlanStatus} />}
      </Box>
    </Box>
  );
};

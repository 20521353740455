import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import remove from 'lodash/remove';
import { v4 as uuidv4 } from 'uuid';

const SLICE_NAME = 'alertsSlice';

export interface Alert {
  id?: string;
  text: string;
  color: 'success' | 'info' | 'warning' | 'error';
  clearable?: boolean;
}

interface AlertsState {
  alerts: Alert[];
}

const initialState: AlertsState = {
  alerts: []
};

export const alertsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addAlert(state, action: PayloadAction<Alert>) {
      state.alerts.push({
        id: action.payload.id ?? uuidv4(),
        text: action.payload.text,
        color: action.payload.color,
        clearable: _.isNil(action.payload.clearable) ? true : action.payload.clearable
      });
    },
    dismissAlert(state, action: PayloadAction<string | undefined>) {
      remove(state.alerts, a => {
        return a.id === action.payload;
      });
    },
    alertsSliceReset(state) {
      remove(state.alerts, a => a.clearable);
    }
  }
});

export const { addAlert, dismissAlert, alertsSliceReset } = alertsSlice.actions;

export default alertsSlice.reducer;

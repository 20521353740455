import { theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Hidden, IconButton, MobileStepper, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { CalendarEventData, ShiftBucketExtendedProps } from 'shared/types/calendar';
import { getDateWithDayName } from 'shared/utils/calendarUtils';
import { ShiftDetailsTab } from './details/shiftDetailsTab';
import { AbsenceReportTab } from './report-absence/absenceReportTab';
import { SwapShiftTab } from './swap/swapShiftTab';

type Props = {
  isOpen: boolean;
  eventData: CalendarEventData & { extendedProps: ShiftBucketExtendedProps };
  onClose: () => void;
};

enum ShiftEventDialogTab {
  EVENT_DETAILS = 'Szczegóły dyżuru',
  SWAP_SHIFT = 'Wymień dyżur',
  REPORT_ABSENCE = 'Zgłoś nieobecność'
}

export const ShiftEventDialog = (props: Props): JSX.Element => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  // other
  const isDesktop = useIsDesktop();
  const shiftEventDialogTabValues = Object.values(ShiftEventDialogTab);

  useEffect(() => {
    setCurrentTabIndex(0);
  }, [props.isOpen]);

  const getTabContent = () => {
    const bucketId = props.eventData.extendedProps.shiftBucket.id;

    switch (shiftEventDialogTabValues[currentTabIndex]) {
      case ShiftEventDialogTab.EVENT_DETAILS:
        return <ShiftDetailsTab eventData={props.eventData} />;
      case ShiftEventDialogTab.SWAP_SHIFT:
        return <SwapShiftTab bucketId={bucketId} />;
      case ShiftEventDialogTab.REPORT_ABSENCE:
        return <AbsenceReportTab bucketId={bucketId} />;
    }
  };

  const bucketStart = props.eventData.extendedProps.shiftBucket.start;
  if (!bucketStart) {
    return <></>;
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullScreen={!isDesktop} fullWidth={isDesktop}>
      <Box textAlign="center">
        <DialogTitle component={'div'}>
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            {getDateWithDayName(bucketStart)}
          </Typography>
          <IconButton size="small" color="secondary" onClick={props.onClose} sx={{ marginLeft: 'auto' }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Hidden smUp implementation="css">
            <MobileStepper
              backButton={''}
              nextButton={''}
              steps={shiftEventDialogTabValues.length}
              activeStep={currentTabIndex}
              position="static"
              sx={{ justifyContent: 'center' }}
            />
          </Hidden>
          <Box style={{ overflow: 'auto' }}>
            <Tabs
              value={currentTabIndex}
              onChange={(_, index: number) => setCurrentTabIndex(index)}
              variant="scrollable"
              scrollButtons={false}
            >
              {shiftEventDialogTabValues.map((tab, index) => (
                <Tab
                  label={tab}
                  value={index}
                  key={tab}
                  disabled={[ShiftEventDialogTab.REPORT_ABSENCE, ShiftEventDialogTab.SWAP_SHIFT].includes(tab) && !props.eventData.editable}
                />
              ))}
            </Tabs>
          </Box>
          <Box py={3}>{getTabContent()}</Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { FormError } from 'features/ui/formError/FormError';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { validatePassword } from 'shared/utils/formUtils';
import { useSaveNewPasswordMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { IconStatus } from '../../shared/types/iconStatus';
import { PasswordRules } from './PasswordRules';
import { ResultMessage } from './ResultMessage';
import { AuthLayout } from './layout/AuthLayout';

export const NewPassword = (): JSX.Element => {
  const [highlightRules, setHighlightRules] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [saveNewPassword, { isError, isSuccess }] = useSaveNewPasswordMutation();
  // other
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const passwordResetKey = searchParams.get('key');

  const { control, formState, handleSubmit, getValues } = useForm<{ password: string; checkPassword: string }>({
    mode: 'onChange',
    defaultValues: {
      password: ''
    }
  });

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się zmienić hasła.' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setHighlightRules(!!formState.errors.password);
  }, [formState.errors.password]);

  useEffect(() => {
    loggedUser && navigate(paths.dashboard.calendar, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: { password: string }) => {
    if (!!passwordResetKey) {
      saveNewPassword({ newPassword: data.password, key: passwordResetKey });
    }
  };

  return (
    <>
      {isSuccess ? (
        <ResultMessage
          icon={IconStatus.checked}
          text="Nowe hasło zostało utworzone."
          additionalText="Możesz się teraz zalogować do swojego konta."
        />
      ) : (
        <AuthLayout>
          <Card>
            <Box mx={2} mt={2} p={2} textAlign="center" sx={{ backgroundColor: 'info', borderRadius: 'lg', coloredShadow: 'info' }}>
              <Typography variant="h4" fontWeight="medium" color="primary">
                Utwórz nowe hasło
              </Typography>
            </Box>
            <Box pt={2} pb={3} px={3}>
              <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box mb={2}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <TextField
                            {...field}
                            className={fieldState.invalid ? 'error' : ''}
                            label="Hasło"
                            type={passwordVisible ? 'text' : 'password'}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setPasswordVisible(prev => !prev)} edge="end">
                                    {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                        </>
                      );
                    }}
                    rules={{
                      validate: validatePassword
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Controller
                    name="checkPassword"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <TextField
                            {...field}
                            className={fieldState.invalid ? 'error' : ''}
                            label="Powtórz hasło"
                            type={passwordVisible ? 'text' : 'password'}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setPasswordVisible(prev => !prev)} edge="end">
                                    {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                        </>
                      );
                    }}
                    rules={{
                      validate: (value, formValues) => value === formValues.password || 'Hasła muszą być takie same'
                    }}
                  />
                </Box>

                <PasswordRules password={getValues('password')} disabled={!highlightRules} />

                <Box mt={6} mb={1}>
                  <Button variant="contained" color="primary" type="submit" disabled={!formState.isValid} fullWidth>
                    Utwórz nowe hasło
                  </Button>
                </Box>
                <Box textAlign="center">
                  <Link to={paths.root}>
                    <Button variant="text" color="secondary" fullWidth>
                      Wróć do logowania
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Card>
        </AuthLayout>
      )}
    </>
  );
};

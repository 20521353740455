import { Invitation, RenewedInvitation } from 'shared/types/invitation';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    sendInvites: builder.mutation<void, string>({
      query: (wardId: string) => ({
        url: `/wards/${wardId}/invite`,
        method: HTTP_METHODS.PUT
      }),
      invalidatesTags: ['Ward']
    }),
    createInvitation: builder.mutation<void, { wardId: string; invitation: Invitation }>({
      query: ({ wardId, invitation }) => ({
        url: `/wards/${wardId}/invite`,
        method: HTTP_METHODS.POST,
        body: invitation
      }),
      invalidatesTags: ['Ward']
    }),
    resend: builder.mutation<void, { wardId: string; renewedInvitation: RenewedInvitation }>({
      query: data => ({
        url: `/wards/${data.wardId}/resend`,
        method: HTTP_METHODS.PUT,
        body: data.renewedInvitation
      }),
      invalidatesTags: ['Ward']
    })
  }),
  overrideExisting: false
});

export const { useSendInvitesMutation, useCreateInvitationMutation, useResendMutation } = extendedApi;

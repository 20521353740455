import { InviteViaEmailForm } from '../inviteViaEmailForm';
import { CodeGenerator } from './codeGenerator';

type Props = {
  code?: string;
  expiration?: Date;
};

export const InvitationForm = (props: Props): JSX.Element => (
  <>
    {props.code && props.expiration && <CodeGenerator code={props.code} expiration={props.expiration} />}
    <InviteViaEmailForm />
  </>
);

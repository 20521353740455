import FullCalendar from '@fullcalendar/react';
import { theme } from '@medsi/mui-theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import { Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material';
import _ from 'lodash';
import { RefObject, useState } from 'react';
import { CalendarView } from 'shared/types/calendar';
import { Popper } from '../../ui/popper/popper';
import { EventOwnerFilter } from './eventOwnerFilter';
import { CalendarPrinter } from '../../ui/calendar/print/calendarPrinter';

type Props = {
  calendarRef?: RefObject<FullCalendar>;
  availableViews: CalendarView[];
  onCalendarViewChangeCallback?: (view: CalendarView) => void;
  onChangeEventsOwnerCallback?: (doctorName: string) => void;
};

export const DesktopMainCalendarToolbar = (props: Props) => {
  const [currentTab, setCurrentTab] = useState<CalendarView>(CalendarView.DAY_GRID_MONTH);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState<HTMLElement | null>(null);

  const { boxShadows, borders, palette } = theme;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchor(event.currentTarget);
    setIsPopperOpen(prev => !prev);
  };

  const calendarApi = props.calendarRef?.current?.getApi();
  if (!calendarApi) {
    return <></>;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" p={3} flexWrap={'wrap'} rowGap={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1} gap={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={4} flexGrow={0}>
          <Button variant="outlined" size="large" onClick={() => calendarApi.today()}>
            Dziś
          </Button>
          <EventOwnerFilter onChangeEventsOwnerCallback={props.onChangeEventsOwnerCallback} />
        </Box>

        <Box display="flex" gap={3} justifyContent={'center'} alignItems={'center'} flexGrow={1}>
          <Typography variant="h5">{_.capitalize(calendarApi?.view?.title ?? '')}</Typography>

          <IconButton aria-label="back" onClick={() => calendarApi.prev()}>
            <ArrowBackIcon fontSize="small" color="secondary" />
          </IconButton>

          <IconButton aria-label="forward" onClick={() => calendarApi.next()}>
            <ArrowForwardIcon fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      </Box>
      <Box flexGrow={0} justifyContent={'center'}>
        <Tabs value={currentTab} onChange={(_, tab) => setCurrentTab(tab)}>
          {props.availableViews.includes(CalendarView.MULTI_MONTH_YEAR) && (
            <Tab
              label="Rok"
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.MULTI_MONTH_YEAR)}
              value={CalendarView.MULTI_MONTH_YEAR}
            />
          )}
          {props.availableViews.includes(CalendarView.DAY_GRID_MONTH) && (
            <Tab
              label="Miesiąc"
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.DAY_GRID_MONTH)}
              value={CalendarView.DAY_GRID_MONTH}
            />
          )}
          {props.availableViews.includes(CalendarView.TIME_GRID_WEEK) && (
            <Tab
              label="Tydzień"
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.TIME_GRID_WEEK)}
              value={CalendarView.TIME_GRID_WEEK}
            />
          )}
          {props.availableViews.includes(CalendarView.TIME_GRID_DAY) && (
            <Tab
              label="Dzień"
              onClick={() => props.onCalendarViewChangeCallback?.(CalendarView.TIME_GRID_DAY)}
              value={CalendarView.TIME_GRID_DAY}
            />
          )}
        </Tabs>
      </Box>
      <Box flexGrow={0} justifyContent={'center'} pl={2}>
        <IconButton size="medium" color="secondary" onClick={handleOpenMenu}>
          <PrintIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Popper anchor={popperAnchor} isOpen={isPopperOpen} onClickAwayCallback={() => setIsPopperOpen(false)}>
        <Box
          boxShadow={boxShadows.elevation11}
          borderRadius={borders.borderRadius.xxxl}
          px={2}
          py={1}
          sx={{ backgroundColor: palette.white.main }}
        >
          <CalendarPrinter />
        </Box>
      </Popper>
    </Box>
  );
};

import { theme } from '@medsi/mui-theme';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, List, ListItem, Tooltip, Typography } from '@mui/material';
import { AddButton } from 'features/ui/buttons/addButton';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { ShiftSlot } from 'shared/types/shiftSlot';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateWeeklyShiftPattern } from 'store/slices/wardRegistrationFormSlice';
import { ShiftSlotCreator } from './dialog/shiftSlotCreator';
import { ShiftSlotCard } from './shiftSlotCard';
import { ShiftSlotConverter } from './shiftSlotConverter';
import { mergeDuplicates } from './utils';

export const ShiftSlots = (): JSX.Element => {
  const [shiftSlots, setShiftSlots] = useState<ShiftSlot[]>([]);
  const [isShiftSlotCreatorOpen, setIsShiftSlotCreatorOpen] = useState(false);
  const [shiftSlotInEditContext, setShiftSlotInEditContext] = useState<ShiftSlot | null>(null);
  const [indexOfSlotInEditContext, setIndexOfSlotInEditContext] = useState<number | null>(null);
  // redux
  const dispatch = useAppDispatch();
  const weeklyShiftPattern = useAppSelector(state => state.wardCreationFormSlice.weeklyShiftPattern);
  // other
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const { palette } = theme;

  useEffect(() => {
    weeklyShiftPattern && setShiftSlots(ShiftSlotConverter.convertWeeklyShiftPatternToShiftSlots(weeklyShiftPattern));
  }, [weeklyShiftPattern]);

  useEffect(() => {
    !isShiftSlotCreatorOpen && setShiftSlotInEditContext(null);
  }, [isShiftSlotCreatorOpen]);

  const onSave = (shiftSlot: ShiftSlot) => {
    setIsShiftSlotCreatorOpen(false);

    setShiftSlots(slots => {
      const existingSlotIndex = _.findIndex(slots, { id: shiftSlot.id });
      const clonedShiftSlot = { ...shiftSlot };

      const newSlots: ShiftSlot[] = [...slots];
      if (existingSlotIndex !== -1) {
        newSlots[existingSlotIndex] = clonedShiftSlot;
      } else {
        newSlots.push(clonedShiftSlot);
      }

      return newSlots.reduce((accumulator: ShiftSlot[], currentValue: ShiftSlot) => mergeDuplicates(currentValue, accumulator), []);
    });
  };

  const onRemove = (id: string) => {
    setIsShiftSlotCreatorOpen(false);
    setShiftSlots(slots => slots.filter(slot => slot.id !== id));
  };

  const openCreatorInEditMode = (slot: ShiftSlot, index: number) => {
    setIsShiftSlotCreatorOpen(true);
    setShiftSlotInEditContext(slot);
    setIndexOfSlotInEditContext(index);
  };

  const changePage = (path: string) => {
    dispatch(updateWeeklyShiftPattern(ShiftSlotConverter.convertShiftSlotsToWeeklyShiftPattern(shiftSlots)));
    navigate(path, { replace: true });
  };

  const addNewSlot = () => {
    setIsShiftSlotCreatorOpen(true);
  };

  const onCloseShiftSlotCreator = () => {
    setIsShiftSlotCreatorOpen(false);
    setShiftSlotInEditContext(null);
    setIndexOfSlotInEditContext(null);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center" gap={1} mb={4}>
        <Typography lineHeight={0} color={palette.text.primary} sx={{ textWrap: 'nowrap' }}>
          Dodawanie, usuwanie, edycja dyżurów.
        </Typography>
        <Tooltip title="Aby kontynuować, należy dodać przynajmniej jeden dyżur" placement="top">
          <InfoOutlined color="primary" fontSize="small" />
        </Tooltip>
      </Box>
      <Dialog open={isShiftSlotCreatorOpen} onClose={onCloseShiftSlotCreator} fullScreen={!isDesktop} fullWidth={isDesktop}>
        <ShiftSlotCreator
          onSaveCallback={onSave}
          onCloseCallback={onCloseShiftSlotCreator}
          shiftSlotInEdit={shiftSlotInEditContext}
          shiftIndex={indexOfSlotInEditContext ?? shiftSlots.length}
          existingSlots={shiftSlots}
        />
      </Dialog>
      <List>
        {shiftSlots.map((ss, index) => (
          <ListItem key={ss.id} sx={{ marginBottom: '1.5rem' }}>
            <ShiftSlotCard
              shiftSlot={ss}
              openCreatorInEditMode={() => openCreatorInEditMode(ss, index)}
              onRemoveShiftSlotCallback={onRemove}
              shiftIndex={index}
            />
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center">
        <AddButton onClickCallback={() => addNewSlot()} />
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={4.8}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          sx={{ textTransform: 'none' }}
          onClick={() => changePage(paths.dashboard.wards.create.wardDeadline)}
          fullWidth
          disabled={_.isEmpty(shiftSlots)}
        >
          Dalej
        </Button>
        <Button
          onClick={() => changePage(paths.dashboard.wards.create.address)}
          fullWidth
          sx={{
            marginTop: '1rem'
          }}
        >
          <Typography variant="body2" color="secondary" sx={{ display: 'block', textAlign: 'center', textTransform: 'none' }}>
            Wstecz
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

import { pxToRem, rgba, theme } from '@medsi/mui-theme';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import { Box, Button, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays, format, isAfter } from 'date-fns';
import { pl } from 'date-fns/locale';
import _ from 'lodash';
import { useState } from 'react';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { Invitation } from 'shared/types/invitation';
import { useCreateInvitationMutation } from 'store/api/endpoints/invitationEndpoints';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppSelector } from 'store/hooks';

interface CodeGeneratorProps {
  expiration: Date;
  code: string;
}

export const CodeGenerator = (props: CodeGeneratorProps): JSX.Element => {
  const [isClipBoardSnackBarOpen, setIsClipboardSnackbarOpen] = useState(false);
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const [createInvitation, { isSuccess: invCreateSuccess, isError: invCreateError }] = useCreateInvitationMutation();
  // other
  const { primary, text, action } = theme.palette;
  const fontWeightBold = theme.typography.fontWeightBold;

  const now = new Date();

  useRequestStatusAlert(
    invCreateSuccess,
    invCreateError,
    'Wygenerowano nowy kod zaproszenia',
    'Nie udało się wygenerować nowego kodu zaproszenia'
  );

  const createNewInvitation = () => {
    if (activeWard?.id) {
      const newInvitation: Invitation = { invitees: [], expiration: addDays(now, 2).toISOString() };
      createInvitation({ wardId: activeWard?.id, invitation: newInvitation });
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" pb={{ xs: 2, md: 0 }}>
        <Typography variant="h5" fontWeight={fontWeightBold} textAlign={{ xs: 'start', md: 'end' }}>
          Kod do rejestracji:
        </Typography>
        <Typography
          variant="h5"
          fontWeight={fontWeightBold}
          color={isAfter(props.expiration, now) ? rgba(primary.main, 0.87) : action.disabled}
          sx={{ position: 'relative', ml: 1 }}
        >
          {props.code}
        </Typography>

        {window.isSecureContext && (
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(props.code);
              setIsClipboardSnackbarOpen(true);
            }}
            disabled={isAfter(now, props.expiration)}
            color="primary"
            size="small"
          >
            <ContentCopyIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center" pb={0.75}>
          <Typography variant="caption" mr={0.5} color={text.secondary}>
            Kod ważny do:
          </Typography>
          <Typography variant="body2" color={isAfter(props.expiration, now) ? 'primary' : 'error'} fontSize={pxToRem(14)}>
            {isAfter(props.expiration, now) ? format(props.expiration, 'EEEE H:mm', { locale: pl }) : 'Przedawnione'}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={5} display="flex" alignItems="center" justifyContent="center">
          <Button
            startIcon={<RefreshIcon />}
            onClick={createNewInvitation}
            disabled={_.isNil(activeWard?.id)}
            variant="outlined"
            color="secondary"
            size="small"
          >
            Generuj nowy kod
          </Button>
        </Box>
      </Grid>
      <Snackbar
        open={isClipBoardSnackBarOpen}
        autoHideDuration={3000}
        onClose={() => setIsClipboardSnackbarOpen(false)}
        message="Skopiowano kod do schowka"
      />
    </Grid>
  );
};

import { useReducer } from 'react';

export const useReload = () => {
  // causes component reevaluation after interactions with calendar by its api
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const reload = () => {
    forceUpdate();
  };

  return reload;
};

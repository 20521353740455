import { theme } from '@medsi/mui-theme';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { blueGrey, teal } from '@mui/material/colors';
import { ShiftEventType } from 'shared/types/calendar';
import { ShiftBucket, ShiftBuckets } from 'shared/types/shiftBucket';
import { ERROR_LIGHT_COLOR, WARNING_LIGHT_COLOR } from 'shared/utils/palette';
import { Content } from './utils';

const UNDEFINED_SHIFT_TIME = 'Nieokreślony czas dyżuru';

interface Rule {
  matches(predicate: Predicate): boolean;
  apply(data: RuleData): Content;
}

interface Predicate {
  isAssigned: boolean;
  hasAsapOffer: boolean;
  hasAnyOffer: boolean;
  isOwner: boolean;
  type: ShiftEventType;
}

interface RuleData {
  shiftBucket: ShiftBucket;
  userId?: string;
}

class BaseRule implements Rule {
  constructor(
    private readonly matchCondition: (predicate: Predicate) => boolean,
    private readonly applyRule: (data: RuleData) => Content
  ) {}

  public matches(predicate: Predicate): boolean {
    return this.matchCondition(predicate);
  }

  public apply(data: RuleData): Content {
    return this.applyRule(data);
  }
}

class MyShiftRule extends BaseRule {
  constructor() {
    super(
      predicate => predicate.isOwner && !predicate.hasAnyOffer && !predicate.hasAsapOffer && predicate.type === ShiftEventType.SHIFT,

      ruleData => ({
        label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
        icons: [],
        backgroundColor: teal[700],
        borderColor: teal[700],
        color: theme.palette.white.main
      })
    );
  }
}

class OtherShiftWithAsapOfferRule extends BaseRule {
  constructor() {
    super(
      predicate => !predicate.isOwner && predicate.hasAsapOffer && predicate.type === ShiftEventType.SHIFT && predicate.isAssigned,

      ruleData => {
        const { error } = theme.palette;
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [<ReportProblemOutlinedIcon fontSize="small" key="report-problem-outlined-icon" />],
          backgroundColor: ERROR_LIGHT_COLOR,
          borderColor: error.dark,
          color: error.dark
        };
      }
    );
  }
}

class OtherShiftWithAnyOfferRule extends BaseRule {
  constructor() {
    super(
      predicate =>
        !predicate.isOwner &&
        !predicate.hasAsapOffer &&
        predicate.hasAnyOffer &&
        predicate.type === ShiftEventType.SHIFT &&
        predicate.isAssigned,

      ruleData => {
        const { warning } = theme.palette;
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [<StorefrontOutlinedIcon fontSize="small" key="storefront-outlined-icon" />],
          backgroundColor: WARNING_LIGHT_COLOR,
          borderColor: warning.dark,
          color: warning.dark
        };
      }
    );
  }
}

class MyShiftWithAsapOfferRule extends BaseRule {
  constructor() {
    super(
      predicate => predicate.isOwner && predicate.hasAsapOffer && predicate.type === ShiftEventType.SHIFT,

      ruleData => {
        const { error, white, black } = theme.palette;
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [<ReportProblemOutlinedIcon fontSize="small" key="report-problem-outlined-icon" />],
          backgroundColor: error.dark,
          borderColor: black.main,
          color: white.main
        };
      }
    );
  }
}

class MyShiftWithAnyOfferRule extends BaseRule {
  constructor() {
    super(
      predicate => predicate.isOwner && !predicate.hasAsapOffer && predicate.hasAnyOffer && predicate.type === ShiftEventType.SHIFT,

      ruleData => {
        const { warning, white } = theme.palette;
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [<StorefrontOutlinedIcon fontSize="small" key="storefront-outlined-icon" />],
          backgroundColor: warning.dark,
          borderColor: warning.dark,
          color: white.main
        };
      }
    );
  }
}

class MyPreferenceRule extends BaseRule {
  constructor() {
    super(
      predicate => predicate.isOwner && predicate.type === ShiftEventType.PREFERENCE,

      ruleData => {
        const { white, secondary, success } = theme.palette;

        const timeLabel = ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME;
        const userPreference = ruleData.shiftBucket.preferences.find(p => p.userId === ruleData.userId);

        let label = '';
        switch (userPreference?.type) {
          case 'ABSENCE_ON_DAY':
            label = 'Dzień bez dyżuru';
            break;
          case 'ABSENCE_ON_SHIFT':
            label = timeLabel;
            break;
          case 'CYCLIC':
            label = timeLabel;
            break;
          case 'HOLIDAY':
            label = 'Urlop';
            break;
          case 'PRESENCE':
            label = timeLabel;
            break;
          default:
            label = '';
        }

        const bucketColor = userPreference?.accepted ? success.main : secondary.light;
        return {
          label,
          icons: [],
          backgroundColor: bucketColor,
          borderColor: bucketColor,
          color: white.main
        };
      }
    );
  }
}

class MyPastShiftRule extends BaseRule {
  constructor() {
    super(
      predicate => predicate.isOwner && predicate.type === ShiftEventType.PAST_SHIFT,

      ruleData => {
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [],
          backgroundColor: blueGrey[200],
          borderColor: blueGrey[200],
          color: theme.palette.white.main
        };
      }
    );
  }
}

class OtherPastShiftRule extends BaseRule {
  constructor() {
    super(
      predicate => !predicate.isOwner && predicate.type === ShiftEventType.PAST_SHIFT,

      ruleData => {
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [],
          backgroundColor: blueGrey[50],
          borderColor: blueGrey[200],
          color: blueGrey[200]
        };
      }
    );
  }
}

class OtherShiftRule extends BaseRule {
  constructor() {
    super(
      predicate =>
        !predicate.isOwner &&
        !predicate.hasAnyOffer &&
        !predicate.hasAsapOffer &&
        predicate.isAssigned &&
        predicate.type === ShiftEventType.SHIFT,

      ruleData => {
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [],
          backgroundColor: blueGrey[50],
          borderColor: blueGrey[200],
          color: blueGrey[200]
        };
      }
    );
  }
}

class UnassignedShiftRule extends BaseRule {
  constructor() {
    super(
      predicate => !predicate.isAssigned && predicate.type === ShiftEventType.SHIFT,

      ruleData => {
        const { error } = theme.palette;
        return {
          label: ShiftBuckets.getShiftBucketTimeLabel(ruleData.shiftBucket) ?? UNDEFINED_SHIFT_TIME,
          icons: [],
          backgroundColor: ERROR_LIGHT_COLOR,
          borderColor: error.dark,
          color: error.dark
        };
      }
    );
  }
}

const rules: Rule[] = [
  new MyShiftRule(),
  new OtherShiftRule(),
  new OtherShiftWithAsapOfferRule(),
  new OtherShiftWithAnyOfferRule(),
  new MyShiftWithAsapOfferRule(),
  new MyShiftWithAnyOfferRule(),
  new MyPreferenceRule(),
  new MyPastShiftRule(),
  new OtherPastShiftRule(),
  new UnassignedShiftRule()
];

class RuleEngine {
  private rules: Rule[];
  constructor(rules: Rule[]) {
    this.rules = rules;
  }

  public getContent(predicate: Predicate, ruleData: RuleData): Content | undefined {
    const matchingRule = this.rules.find(rule => rule.matches(predicate));
    return matchingRule?.apply(ruleData);
  }
}

const ruleEngine = new RuleEngine(rules);
export default ruleEngine;

import { Box, CircularProgress } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { IconStatus } from 'shared/types/iconStatus';
import { useActivateQuery } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';
import { ResultMessage } from './ResultMessage';
import { AuthLayoutSize } from './layout/AuthLayout';

export const ActivateAccount = (): JSX.Element => {
  // rtk
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const { isError, isSuccess } = useActivateQuery(key ?? skipToken);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const navigate = useNavigate();

  useEffect(() => {
    loggedUser && navigate(paths.dashboard.calendar, { replace: true });
  }, [loggedUser, navigate]);

  if (isError) {
    return (
      <ResultMessage
        icon={IconStatus.info}
        size={AuthLayoutSize.medium}
        text="Ups, coś poszło nie tak..."
        additionalText="Spróbuj ponownie później"
      />
    );
  }

  if (isSuccess) {
    return (
      <ResultMessage
        icon={IconStatus.checked}
        size={AuthLayoutSize.auto}
        text="Adres mailowy został pomyślnie potwierdzony."
        additionalText="Możesz się teraz zalogować do swojego konta."
      />
    );
  }

  return (
    <Box position="absolute" width="100%" height="100%">
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress size="5rem" />
      </Box>
    </Box>
  );
};

import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { EMERGENCY_COLOR, WARNING_LIGHT_COLOR } from 'shared/utils/palette';

export const DesktopMonthCalendarStylesOverrides = styled(Box)<BoxProps>(({ theme }) => {
  const { palette, typography, functions, borders, boxShadows } = theme;

  const { primary, secondary, light, neutral, black, white } = palette;
  const { fontWeightBold, size } = typography;
  const { pxToRem } = functions;
  const { borderRadius } = borders;

  const todayCommon = {
    color: `${white.main} !important`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(30),
    height: pxToRem(30),
    borderRadius: '50%'
  };

  return {
    height: '100%',
    '& .fc-col-header-cell': {
      padding: `${pxToRem(32)} 0 !important`
    },
    // necessary selector to remove border from around of calendar
    '& .fc-scrollgrid-section > td': {
      border: 'none !important'
    },
    '& .fc-scrollgrid': {
      borderTop: `2px solid ${light.main}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none'
    },
    '& .fc-scrollgrid-section-header': {
      '& .fc-scroller': {
        overflow: 'hidden !important'
      }
    },
    '& .fc-day': {
      border: `2px solid ${light.main}`,
      padding: `${pxToRem(8)}`,
      cursor: 'pointer',
      '& .fc-daygrid-day-top': {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: `${pxToRem(8)} !important`,
        '& .fc-daygrid-day-number': {
          color: `${secondary.main} !important`,
          fontWeight: fontWeightBold
        }
      },
      '& .fc-daygrid-day-bottom': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& .fc-daygrid-more-link': {
          backgroundColor: 'transparent'
        }
      }
    },
    '& .date-is-free': {
      backgroundColor: `${neutral[200]} !important`,
      border: `2px solid ${light.main}`,
      cursor: 'default !important'
    },
    '& .date-has-asap-offer': {
      backgroundColor: `${EMERGENCY_COLOR} !important`
    },
    '& .date-has-any-offer': {
      backgroundColor: `${WARNING_LIGHT_COLOR} !important`
    },
    // "other" means days in previous and next months
    '& .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number': {
      color: `${secondary.light} !important`
    },
    '& .fc .fc-daygrid-day.fc-day-today.date-has-asap-offer': {
      backgroundColor: 'transparent',
      '& .fc-daygrid-day-number': {
        ...todayCommon,
        backgroundColor: `${black.main} !important`
      }
    },
    '& .fc .fc-daygrid-day.fc-day-today:not(.date-has-asap-offer)': {
      backgroundColor: 'transparent',
      '& .fc-daygrid-day-number': {
        ...todayCommon,
        backgroundColor: `${primary.main} !important`
      }
    },
    '& .fc-event': {
      padding: '0 !important',
      margin: '0 !important',
      borderRadius: `${borderRadius.lg}`
    },
    '& .fc-daygrid-dot-event': {
      '&:hover': {
        backgroundColor: 'inherit !important'
      }
    },
    // Modifying styles of this class imply the need of modifying styles of CalendarEventListPopper component
    '& .fc-more-popover': {
      borderRadius: borderRadius.xl,
      boxShadow: boxShadows.elevation24,
      '& .fc-popover-header': {
        backgroundColor: 'transparent !important',
        fontWeight: fontWeightBold
      },
      '& .fc-popover-close': {
        fontSize: size['2xl']
      }
    }
  };
});

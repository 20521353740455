import { theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogTitle, Grid, IconButton } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { InvitationCard } from 'features/settings/staff-settings/invitationCard';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { InvitationStatus } from 'shared/types/invitation';
import { User } from 'shared/types/users';
import { Ward } from 'shared/types/ward';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppSelector } from 'store/hooks';
import { RowData } from '../utils';
import { UsersRenderer } from './usersRenderer';

const defaultUsers: User[] = [];

export const StaffSettings = () => {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [expiration, setExpiration] = useState<Date | undefined>();
  const [code, setCode] = useState<string | undefined>();
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);

  const { palette, borders } = theme;

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (activeWard?.invitations && !_.isEmpty(activeWard?.invitations) && !_.isEmpty(users)) {
      const invitations = activeWard.invitations;
      const sortedInvitations = [...invitations].sort((a, b) => new Date(b.expiration).getTime() - new Date(a.expiration).getTime());
      const latestInvitation = sortedInvitations[0];
      setExpiration(new Date(latestInvitation.expiration));
      setCode(latestInvitation.code);

      const userRows: RowData[] = users
        .filter(u => u.id !== loggedUser?.id && !u.robot)
        .map(u => ({
          name: u.firstName && u.lastName ? `${u.firstName} ${u.lastName}` : undefined,
          email: u.email,
          phoneNumber: u.phoneNumber,
          position: 'Lekarz',
          status: InvitationStatus.FULFILLED,
          user: u
        }));

      const inviteeRows: RowData[] = Ward.extractUniqueInviteesFromInvitations(activeWard).map(i => ({
        name: i.invitee.firstName && i.invitee.lastName ? `${i.invitee.firstName} ${i.invitee.lastName}` : undefined,
        email: i.invitee.email,
        phoneNumber: i.invitee.phoneNumber,
        status: i.invitee.status,
        expiration: i.expiration,
        invitee: i.invitee
      }));

      setRowData([...userRows, ...inviteeRows]);
    }
  }, [activeWard, activeWard?.invitations, users, loggedUser]);

  return (
    <Grid
      container
      alignItems="stretch"
      p={3}
      sx={{ backgroundColor: palette.neutralExpand[200], borderRadius: borders.borderRadius.xxxl }}
    >
      {isDesktop ? (
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <InvitationCard code={code} expiration={expiration} />
          </Grid>
          <Grid item xs={12} md={8}>
            <UsersRenderer rowData={rowData} variant="desktop" />
          </Grid>
        </Grid>
      ) : (
        <Grid container item>
          <Grid item xs={8}>
            <Button variant="outlined" color="primary" onClick={() => setIsInvitationDialogOpen(true)}>
              Dodaj pracownika
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <UsersRenderer rowData={rowData} variant="mobile" />
          </Grid>
          <Dialog
            open={isInvitationDialogOpen}
            onClose={() => setIsInvitationDialogOpen(false)}
            fullScreen={!isDesktop}
            fullWidth={isDesktop}
          >
            <DialogTitle>
              <IconButton size="small" color="secondary" onClick={() => setIsInvitationDialogOpen(false)}>
                <Close />
              </IconButton>
            </DialogTitle>
            <InvitationCard code={code} expiration={expiration} />
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
};

import { pxToRem } from '@medsi/mui-theme';
import { Avatar, Box, Grid, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import { Doctor, ShiftAssignment } from 'shared/types/planning';
import { Users } from 'shared/types/users';
import { EMERGENCY_COLOR } from 'shared/utils/palette';

type Props = {
  shiftAssignment: ShiftAssignment;
};

export const DesktopAcceptedShiftAssignmentEventContent = (props: Props) => {
  const theme = useTheme();

  const { palette, typography } = theme;

  const initials = Users.getInitials<Doctor>(props.shiftAssignment.doctor);
  const stringifiedUser = Users.toString<Doctor>(props.shiftAssignment.doctor);

  const isAssigned = !!props.shiftAssignment.doctor;

  const cAssigned = { primary: green[200], secondary: green[50] };
  const cNotAssigned = { primary: EMERGENCY_COLOR, secondary: EMERGENCY_COLOR };

  const colors = isAssigned ? cAssigned : cNotAssigned;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      border={`2px solid ${colors.primary}`}
      borderRadius={theme.borders.borderRadius.md}
      sx={{ backgroundColor: colors.secondary, cursor: 'default' }}
      height={isAssigned ? 'auto' : pxToRem(38)}
      my={pxToRem(2)}
    >
      <Grid display="flex" flexDirection="column" gap={1} p={`${pxToRem(2)} ${pxToRem(4)}`}>
        {isAssigned && (
          <Avatar
            title={stringifiedUser}
            variant="circular"
            sx={{ bgcolor: palette.components.avatar.fill, width: 32, height: 32, fontSize: typography.size.sm }}
          >
            {initials}
          </Avatar>
        )}
      </Grid>
    </Box>
  );
};

import _ from 'lodash';
import { Invitation, InvitationStatus, Invitee } from './invitation';

export interface Ward {
  id?: string;
  name?: string;
  shortName?: string;
  address?: Address;
  contact?: Contact;
  invitations?: Invitation[];
  zoneId?: string;
  deadlineDay: number;
}

export interface Address {
  city?: string;
  street?: string;
  building?: string;
  postalCode?: string;
}

export interface Contact {
  email?: string;
  phoneNumber?: string;
}

export namespace Ward {
  export const extractUniqueInviteesFromInvitations = (ward: Ward) => {
    return [...getUniqueInviteesByKey(ward.invitations ?? [], 'email'), ...getUniqueInviteesByKey(ward.invitations ?? [], 'phoneNumber')];
  };

  const getUniqueInviteesByKey = (invitations: Invitation[], key: 'email' | 'phoneNumber') => {
    const invitees = _.chain(invitations)
      .map(invitation => _.map(invitation.invitees, invitee => ({ invitee, expiration: invitation.expiration })))
      .flatten()
      .filter(invitation => {
        const isActive =
          invitation.invitee.status !== InvitationStatus.FULFILLED && invitation.invitee.status !== InvitationStatus.CANCELED;
        const containsKey = !_.isEmpty(invitation.invitee[key]);
        return isActive && containsKey;
      })
      .groupBy(`invitee.${key}`)
      .map((group, _email) => {
        const sorted = group.sort((a, b) => new Date(b.expiration).getTime() - new Date(a.expiration).getTime());
        const latest = _.first(sorted);
        return latest;
      })
      .filter(i => !!i)
      .value() as { invitee: Invitee; expiration: string }[];

    return invitees;
  };
}

import { pxToRem, theme } from '@medsi/mui-theme';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface Props {
  title: string;
  content: string;
  onAcceptText: string;
  onAccept: () => void;
  onClose: () => void;
  open: boolean;
}

export const ConfirmationDialog = ({ title, content, onAccept, onAcceptText, onClose, open }: Props) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle textTransform={'capitalize'} component={'div'} display={'flex'} justifyContent={'space-between'}>
      <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent sx={{ pb: pxToRem(20) }}>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        {content}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" variant="text">
        Anuluj
      </Button>
      <Button onClick={onAccept} color="primary" variant="contained">
        {onAcceptText}
      </Button>
    </DialogActions>
  </Dialog>
);

import { pxToRem, theme } from '@medsi/mui-theme';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';
import { DayOfMonthInput } from 'features/ui/masked-inputs/dayOfMonthInput';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DEFAULT_DEADLINE_DAY, updateWardDeadline } from 'store/slices/wardRegistrationFormSlice';

export const WardDeadlineForm = (): JSX.Element => {
  const [value, setValue] = useState(DEFAULT_DEADLINE_DAY.toString());
  const dispatch = useAppDispatch();
  const wardDeadline = useAppSelector(state => state.wardCreationFormSlice.wardDeadline);
  const navigate = useNavigate();

  const { palette, typography } = theme;

  useEffect(() => {
    wardDeadline && setValue(wardDeadline.toString());
  }, [wardDeadline]);

  const changePage = (path: string) => {
    dispatch(updateWardDeadline(parseInt(value)));
    navigate(path, { replace: true });
  };

  return (
    <>
      <Box display="flex" alignItems="start" flexDirection="column" gap={3}>
        <Typography variant="h6" fontWeight={typography.fontWeightBold} color={palette.text.primary}>
          Do kiedy lekarze mogą zgłaszać dyspozycyjność?
        </Typography>
        <Box>
          <Typography variant="body1" color={palette.text.primary}>
            Lekarze mogą zgłaszać dyspozycyjność do:
          </Typography>
          <FormControlLabel
            control={
              <TextField
                sx={{ width: pxToRem(100) }}
                InputProps={{
                  inputComponent: DayOfMonthInput as any
                }}
                value={value}
                onChange={event => setValue(event.target.value.trim())}
              />
            }
            label={
              <Box display="flex" alignItems="center" gap={1}>
                dnia każdego miesiąca
                <Tooltip
                  title="Jeśli wpiszesz dzień, który nie istnieje w danym miesiącu, system ustawi ostateczny termin na ostatni dzień danego miesiąca."
                  placement="top"
                >
                  <InfoOutlined color="primary" fontSize="small" />
                </Tooltip>
              </Box>
            }
            labelPlacement="end"
          />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={{ xs: 3.5, md: 8.8 }}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => changePage(paths.dashboard.wards.create.invite)}
          disabled={!value}
          fullWidth
        >
          Dalej
        </Button>
        <Button
          onClick={() => changePage(paths.dashboard.wards.create.weeklyShiftPattern)}
          fullWidth
          sx={{
            marginTop: '1rem'
          }}
        >
          <Typography variant="body3" color="secondary" sx={{ display: 'block', textAlign: 'center', textTransform: 'none' }}>
            Wstecz
          </Typography>
        </Button>
      </Box>
    </>
  );
};

import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { CalendarView } from 'shared/types/calendar';
import { DesktopMonthCalendarStylesOverrides } from './desktopMonthCalendarStylesOverrides';
import { MobileMonthCalendarStylesOverrides } from './mobileMonthCalendarStylesOverrides';
import { WeekCalendarStylesOverrides } from './weekCalendarStylesOverrides';
import { YearCalendarStylesOverrides } from './yearCalendarStylesOverrides';

type Props = {
  children: ReactNode;
  calendarView: CalendarView;
};

export const CalendarStylesOverrides = (props: Props) => {
  const isDesktop = useIsDesktop();

  switch (props.calendarView) {
    case CalendarView.MULTI_MONTH_YEAR:
      return <YearCalendarStylesOverrides>{props.children}</YearCalendarStylesOverrides>;
    case CalendarView.DAY_GRID_MONTH:
      return isDesktop ? (
        <DesktopMonthCalendarStylesOverrides>{props.children}</DesktopMonthCalendarStylesOverrides>
      ) : (
        <MobileMonthCalendarStylesOverrides>{props.children}</MobileMonthCalendarStylesOverrides>
      );
    case CalendarView.TIME_GRID_WEEK:
      return <WeekCalendarStylesOverrides>{props.children}</WeekCalendarStylesOverrides>;
    case CalendarView.TIME_GRID_DAY:
      return <WeekCalendarStylesOverrides>{props.children}</WeekCalendarStylesOverrides>;
    default:
      return <Box>{props.children}</Box>;
  }
};

import { DayCellContentArg } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { pxToRem } from '@medsi/mui-theme';
import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { addMonths, format, startOfMonth } from 'date-fns';
import { ShiftPlanStatus, useGetShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';
import { CalendarSpeedDial } from 'features/ui/calendar/calendarSpeedDial';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useReload } from 'shared/hooks/useReload';
import { CalendarEventData, CalendarView } from 'shared/types/calendar';
import { User } from 'shared/types/users';
import { isAfterNextMonth, isInNextMonth } from 'shared/utils/calendarUtils';
import { findById } from 'shared/utils/commonUtils';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';
import { CalendarDateDialog } from '../../calendar/dialogs/calendarDateDialog';
import { CalendarEventDialog } from '../../calendar/dialogs/calendarEventDialog';
import { useGetShiftBuckets } from '../../calendar/hooks/useGetShiftBuckets';
import { Calendar } from '../../ui/calendar/calendar';
import { useGetUserPreferenceEvents } from '../hooks/useGetUserPreferenceEvents';
import { DesktopImpersonationCalendarToolbar } from './desktopImpersonationCalendarToolbar';
import { MobileImpersonationCalendarToolbar } from './mobileImpersonationCalendarToolbar';

type Props = {
  user?: User;
};

export const ImpersonationCalendar = (props: Props) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventData | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [isDateDialogOpen, setIsDateDialogOpen] = useState(false);
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  const planStatus = useGetShiftPlanStatus(shiftPlan);
  // other
  const { shiftBuckets } = useGetShiftBuckets();
  const isDesktop = useIsDesktop();

  const events = useGetUserPreferenceEvents(shiftBuckets, props.user?.id);
  const reload = useReload();

  const workingDays = useMemo(
    () =>
      shiftBuckets
        .filter(sb => {
          const sbStart = new Date(sb.start);
          return (isInNextMonth(sbStart) && planStatus !== ShiftPlanStatus.ACCEPTED) || isAfterNextMonth(sbStart);
        })
        .map(sb => format(new Date(sb.start), 'yyyy-MM-dd')),
    [shiftBuckets, planStatus]
  );
  const calendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    setSelectedEvent(prev => findById<CalendarEventData>(events, prev?.id) ?? null);
  }, [events]);

  const onEventClick = (id: string) => {
    setSelectedEvent(events.find(event => event.id === id) ?? null);
    setIsEventDialogOpen(true);
  };

  const onDateClick = (arg: DateClickArg) => {
    setSelectedDate(arg.date);

    const isWorkingDay = workingDays.includes(format(arg.date, 'yyyy-MM-dd'));

    if (isWorkingDay) {
      setIsDateDialogOpen(true);
      return;
    }
  };

  const getDayCellClassNames = (arg: DayCellContentArg) => {
    const date = format(arg.date, 'yyyy-MM-dd');
    const isFreeDay = !workingDays?.includes(date);
    return isFreeDay ? 'date-is-free' : '';
  };

  if (!props.user?.id) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} height="100%" p={{ xs: 0, md: 3 }} gap={{ xs: 0, md: pxToRem(20) }}>
      <CalendarEventDialog
        eventData={selectedEvent}
        isOpen={isEventDialogOpen}
        onClose={() => setIsEventDialogOpen(false)}
        userId={props.user.id}
      />
      <CalendarDateDialog date={selectedDate} isOpen={isDateDialogOpen} onClose={() => setIsDateDialogOpen(false)} userId={props.user.id} />
      <Calendar
        currentView={CalendarView.DAY_GRID_MONTH}
        toolbar={
          isDesktop ? (
            <DesktopImpersonationCalendarToolbar calendarRef={calendarRef} />
          ) : (
            <MobileImpersonationCalendarToolbar calendarRef={calendarRef} />
          )
        }
        events={events}
        calendarRef={calendarRef}
        workingDays={workingDays}
        availableViews={[CalendarView.DAY_GRID_MONTH]}
        getDayCellClassNames={getDayCellClassNames}
        onEventClick={onEventClick}
        onDateClick={onDateClick}
        initialDate={format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd')}
        showNonCurrentDates
        swipeable
        onRefresh={reload}
      />
      <CalendarSpeedDial userId={props.user?.id} position={{ bottom: isDesktop ? 16 : 32, right: 16 }} />
    </Box>
  );
};

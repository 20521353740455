import { pxToRem } from '@medsi/mui-theme';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import { Doctor, ShiftAssignment } from 'shared/types/planning';
import { Users } from 'shared/types/users';
import { EMERGENCY_COLOR } from 'shared/utils/palette';

type Props = {
  shiftAssignment: ShiftAssignment;
};

export const MobileAcceptedShiftAssignmentEventContent = (props: Props) => {
  const theme = useTheme();

  const { typography } = theme;

  const initials = Users.getInitials<Doctor>(props.shiftAssignment.doctor);

  const isAssigned = !!props.shiftAssignment.doctor;

  const cAssigned = { primary: green[200], secondary: green[50] };
  const cNotAssigned = { primary: EMERGENCY_COLOR, secondary: EMERGENCY_COLOR };

  const colors = isAssigned ? cAssigned : cNotAssigned;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      border={`2px solid ${colors.primary}`}
      borderRadius={theme.borders.borderRadius.md}
      sx={{ backgroundColor: colors.secondary, cursor: 'default' }}
      height={isAssigned ? 'auto' : pxToRem(18)}
      mx={pxToRem(1)}
      my={pxToRem(2)}
    >
      <Grid display="flex" flexDirection="column" paddingLeft={pxToRem(2)} pt={pxToRem(2)}>
        {initials && (
          <Typography color={colors.primary} fontWeight={typography.fontWeightBold} fontSize={pxToRem(8)}>
            {initials}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { InfoOutlined } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDownOutlined';
import { Box, Card, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { addMonths, format } from 'date-fns';
import { useGetUsersWithAcceptedPrefs } from 'features/planner/hooks/useGetUsersWithAcceptedPrefs';
import { useGetUsersWithUnacceptedPrefs } from 'features/planner/hooks/useGetUsersWithUnacceptedPrefs';
import { useGetUsersWithUnfilledPrefs } from 'features/planner/hooks/useGetUsersWithUnfilledPrefs';
import _ from 'lodash';
import { useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { User, Users } from 'shared/types/users';
import { findById, isNotNilGuard } from 'shared/utils/commonUtils';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { ImpersonationDialog } from './calendar/impersonationDialog';
import { PrefFilter } from './prefFilter';

const defaultUsers: User[] = [];

export const ImpersonationPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterState, setFilterState] = useState({
    isAcceptedChecked: true,
    isUnacceptedChecked: true,
    isUnfilledChecked: true
  });
  const [userInEditContext, setUserInEditContext] = useState<User>();
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  // other
  const usersWithAcceptedPrefs = useGetUsersWithAcceptedPrefs();
  const usersWithUnacceptedPrefs = useGetUsersWithUnacceptedPrefs();
  const usersWithUnfilledPrefs = useGetUsersWithUnfilledPrefs();
  const isDesktop = useIsDesktop();

  const openDialog = (u: User) => {
    setIsOpen(true);
    setUserInEditContext(u);
  };

  const mergeUsersByFilterState = () => {
    let resultUsers: string[] = [];

    if (filterState.isAcceptedChecked) {
      resultUsers = [...resultUsers, ...usersWithAcceptedPrefs];
    }
    if (filterState.isUnacceptedChecked) {
      resultUsers = [...resultUsers, ...usersWithUnacceptedPrefs];
    }
    if (filterState.isUnfilledChecked) {
      resultUsers = [...resultUsers, ...usersWithUnfilledPrefs];
    }
    return resultUsers;
  };

  const getPrefStatus = (u: User) => {
    if (usersWithUnacceptedPrefs.includes(u.id)) {
      return <Chip size="small" label="Niezatwierdzone" color="warning" />;
    } else if (usersWithUnfilledPrefs.includes(u.id)) {
      return <Chip size="small" label="Nieuzupełnione" color="error" />;
    } else {
      return <Chip size="small" label="Zatwierdzone" color="success" />;
    }
  };

  const filteredSortedUsers = _(mergeUsersByFilterState())
    .map(userId => findById<User>(users, userId))
    .filter(isNotNilGuard)
    .sortBy(u => u.firstName);

  return (
    <Box p={2} height="100%" display="flex" flexDirection="column">
      <ImpersonationDialog user={userInEditContext} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Grid item xs={12} display="flex" gap={1}>
        <Typography variant="body1">Tutaj możesz uzupełnić dyspozycyjności użytkowników (rzeczywistych i wirtualnych).</Typography>
        <Tooltip title="Administrator ma możliwość dodawania dyspozycyjności do systemu w imieniu lekarza." placement="top">
          <InfoOutlined color="primary" fontSize="small" />
        </Tooltip>
      </Grid>
      <Box pt={1}>
        <Typography variant="caption">
          (Status uzupełnienia dyspozycyjności lekarzy na miesiąc: {format(addMonths(new Date(), 1), 'MM.yyyy')})
        </Typography>
      </Box>

      <PrefFilter onChange={setFilterState} />
      <Box
        display="flex"
        justifyContent={isDesktop ? 'flex-start' : 'center'}
        flexWrap="wrap"
        gap={2}
        py={1}
        px={1}
        minHeight={pxToRem(1)}
        overflow="auto"
      >
        {filteredSortedUsers
          .map(u => (
            <Card key={u.id} sx={{ boxShadow: theme.boxShadows.elevation2, width: pxToRem(200), height: pxToRem(150) }}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" p={2} height="100%">
                <Box display="flex" gap={2}>
                  {u.robot ? <SmartToyIcon color="secondary" fontSize="small" /> : <PersonIcon color="primary" fontSize="small" />}
                  <Typography>{Users.toString<User>(u)}</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                  {getPrefStatus(u)}
                  <IconButton size="small" color="secondary" onClick={() => openDialog(u)}>
                    <ThumbsUpDownIcon />
                  </IconButton>
                </Box>
              </Box>
            </Card>
          ))
          .value()}
      </Box>
    </Box>
  );
};

import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import alertsReducer from 'store/slices/alertsSlice';
import authReducer, { LOGOUT_ACTION_TYPE } from 'store/slices/authSlice';
import facilityReducer from 'store/slices/facilitySlice';
import notificationsReducer from 'store/slices/notificationSlice';
import plannerReducer from 'store/slices/plannerSlice';
import shiftBucketsReducer from 'store/slices/shiftBucketsSlice';
import sidenavReducer from 'store/slices/sidenavSlice';
import wardCreationFormReducer from 'store/slices/wardRegistrationFormSlice';
import { apiMiddleware, apiReducer, apiReducerPath } from './api/api';
import { rtkQueryRequestMiddleware } from './middlewares/rtkQueryRequestMiddleware';
import websocketMiddleware from './middlewares/websocketMiddleware';
import invitationReducer from './slices/invitationSlice';
import offerReducer from './slices/offerSlice';
import { saveState } from './utils';

const reducers = {
  authSlice: authReducer,
  alertsSlice: alertsReducer,
  wardCreationFormSlice: wardCreationFormReducer,
  facilitySlice: facilityReducer,
  notificationsSlice: notificationsReducer,
  shiftBucketsSlice: shiftBucketsReducer,
  invitationSlice: invitationReducer,
  plannerSlice: plannerReducer,
  sidenavSlice: sidenavReducer,
  offerSlice: offerReducer,
  [apiReducerPath]: apiReducer
};

const combinedReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === LOGOUT_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([websocketMiddleware, apiMiddleware, rtkQueryRequestMiddleware])
});

store.subscribe(() => {
  saveState({
    authSlice: store.getState().authSlice
  });
});

export type AppDispatch = typeof store.dispatch;

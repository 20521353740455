import { Middleware } from '@reduxjs/toolkit';
import { ConnectedObjectType } from 'shared/types/notification';
import SockJS from 'sockjs-client';
import { extendedApi as notificationEndpoints } from 'store/api/endpoints/notificationEndpoint';
import { AuthState, LOGOUT_ACTION_TYPE } from 'store/slices/authSlice';
import { setIsSolving } from 'store/slices/plannerSlice';
import Stomp, { Client } from 'webstomp-client';
import { store } from '../store';

let stompClient: Client | null = null;
let isConnected = false;
let isPending = false;
let reconnectInterval: number | undefined;
let reconnectIntervalAttempts: number = 0;

const connect = (authState: Required<AuthState>) => {
  if (isPending || isConnected) {
    return;
  }
  isPending = true;

  const socket = new SockJS(`//${process.env.REACT_APP_SERVER_HOST}/websocket/notification`);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });

  stompClient.connect(
    { 'X-Authorization': authState.accessToken },
    () => onConnectedCallback(authState),
    () => onConnectionError(authState)
  );
};

const onConnectedCallback = (authState: Required<AuthState>) => {
  isPending = false;
  isConnected = true;

  stompClient?.subscribe('/topic/notifications/' + authState.loggedUser.id, (data: any) => {
    const type = JSON.parse(data.body) as ConnectedObjectType;
    handleNotification(type);
  });
};

const handleNotification = (type: ConnectedObjectType) => {
  switch (type) {
    case 'OFFER':
      store.dispatch(notificationEndpoints.util.invalidateTags([{ type: 'ShiftBucket' }, { type: 'Offer' }]));
      break;
    case 'PLAN':
      store.dispatch(setIsSolving(false));
      break;
    case 'SHIFT_BUCKET':
      store.dispatch(notificationEndpoints.util.invalidateTags([{ type: 'ShiftBucket' }]));
      break;
    default:
      break;
  }
  store.dispatch(notificationEndpoints.util.invalidateTags([{ type: 'Notification', id: 'LIST' }]));
};

const onConnectionError = (authState: Required<AuthState>) => {
  disconnect();
  if (authState.loggedUser && authState.accessToken && !isConnected && !isPending) {
    attemptReconnect({ accessToken: authState.accessToken, loggedUser: authState.loggedUser });
  }
};

const attemptReconnect = (authState: Required<AuthState>) => {
  reconnectInterval = window.setInterval(() => {
    if (isPending || isConnected || !authState.loggedUser || !authState.accessToken) {
      reconnectIntervalAttempts = 0;
      window.clearInterval(reconnectInterval);
      return;
    }
    console.debug(`Attempting websocket reconnect... (${reconnectIntervalAttempts})`);
    reconnectIntervalAttempts++;
    connect(authState);
  }, 5000);
};

export const disconnect = () => {
  if (stompClient !== null) {
    if (stompClient.connected) {
      stompClient.disconnect();
    }
    stompClient = null;
  }
  isConnected = false;
  isPending = false;
  window.clearInterval(reconnectInterval);
};

const websocketMiddleware: Middleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === LOGOUT_ACTION_TYPE) {
    disconnect();
    return next(action);
  }

  const authState: AuthState = store.getState().authSlice;
  if (authState.loggedUser && authState.accessToken && !isConnected && !isPending) {
    connect({ accessToken: authState.accessToken, loggedUser: authState.loggedUser });
  }

  if (!authState.loggedUser || !authState.accessToken) {
    window.clearInterval(reconnectInterval);
  }

  return next(action);
};

export default websocketMiddleware;

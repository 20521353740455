import { theme } from '@medsi/mui-theme';
import { Box, Button, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Loadable } from 'features/ui/loadable/Loadable';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useCreateOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

interface AbsenceReportCreatorProps {
  bucketId: string;
}

const enum AbsenceReason {
  SICK_LEAVE = 'Zwolnienie lekarskie',
  LEAVE_ON_DEMAND = 'Urlop na żądanie',
  OTHER = 'Inne'
}

export const AbsenceReportCreator = (props: AbsenceReportCreatorProps): JSX.Element => {
  const [selectedReason, setSelectedReason] = useState<string>(AbsenceReason.LEAVE_ON_DEMAND);
  const [description, setDescription] = useState('');
  // redux
  const dispatch = useAppDispatch();
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [createOffer, { isSuccess, isError, isLoading }] = useCreateOfferMutation();
  // other
  const fontWeightRegular = theme.typography.fontWeightRegular;

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się wysłać prośby oddania dyżuru' }));
  }, [isError, dispatch]);

  useEffect(() => {
    isSuccess && dispatch(addAlert({ color: 'success', text: 'Wysłano prośbę oddania dyżuru' }));
  }, [isSuccess, dispatch]);

  const reportAbsence = () => {
    if (!facilitySlice?.activeWardId || !loggedUser?.id) {
      return;
    }
    createOffer({
      type: 'ASAP_HAND_OVER',
      status: 'WAITING',
      wardId: facilitySlice.activeWardId,
      offerRequest: {
        offeredShift: props.bucketId,
        requester: loggedUser.id
      },
      offerResponses: [],
      description: selectedReason === AbsenceReason.OTHER ? `(${AbsenceReason.OTHER}) ${description}` : selectedReason
    });
  };

  const isValid = selectedReason !== AbsenceReason.OTHER || !_.isEmpty(description);

  return (
    <Loadable loading={isLoading}>
      <Grid container p={1}>
        <Grid item xs={12} md={5} py={1}>
          <InputLabel shrink sx={{ textAlign: 'start', fontWeight: fontWeightRegular }}>
            Wybierz powód
          </InputLabel>
          <Select disableUnderline variant="standard" value={selectedReason} onChange={e => setSelectedReason(e.target.value)} fullWidth>
            <MenuItem value={AbsenceReason.SICK_LEAVE}>{AbsenceReason.SICK_LEAVE}</MenuItem>
            <MenuItem value={AbsenceReason.LEAVE_ON_DEMAND}>{AbsenceReason.LEAVE_ON_DEMAND}</MenuItem>
            <MenuItem value={AbsenceReason.OTHER}>{AbsenceReason.OTHER}</MenuItem>
          </Select>
        </Grid>

        {selectedReason === AbsenceReason.OTHER && (
          <Grid item xs={12} py={1} textAlign="start">
            <TextField
              value={description}
              onChange={e => setDescription(e.target.value)}
              variant="outlined"
              maxRows={8}
              minRows={4}
              label={<InputLabel sx={{ mb: 1 }}>Dodaj opis</InputLabel>}
              fullWidth
              multiline
            />
          </Grid>
        )}

        <Grid item xs={12} py={1}>
          <Box width={'fit-content'} ml="auto">
            <Button
              disabled={!isValid}
              variant={isValid ? 'contained' : 'text'}
              color="primary"
              onClick={reportAbsence}
              sx={{ p: '0.375rem 1rem !important' }}
            >
              Wyślij
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Loadable>
  );
};

import { CircularProgress } from '@mui/material';
import { teal } from '@mui/material/colors';
import { ReactElement } from 'react';

interface ILoadableProps {
  loading: boolean;
  children: ReactElement;
  size?: number | string;
}

export const Loadable: React.FC<ILoadableProps> = props => {
  return props.loading ? <CircularProgress size={props.size} sx={{ color: teal[500] }} /> : props.children;
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import _ from 'lodash';
import { useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { Notification } from '../../shared/types/notification';
import { NotificationDetails } from './notificationDetails';
import { NotificationListItem } from './notificationListItem';

type Props = {
  notifications: Notification[];
  onCloseNotificationsCallback: VoidFunction;
};

export const NotificationList = (props: Props): JSX.Element => {
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>();
  const isDesktop = useIsDesktop();

  return (
    <>
      <Dialog
        open={Boolean(selectedNotification)}
        onClose={() => setSelectedNotification(null)}
        fullScreen={!isDesktop}
        fullWidth={isDesktop}
      >
        {selectedNotification && (
          <NotificationDetails onCloseDialog={() => setSelectedNotification(null)} notification={selectedNotification} />
        )}
      </Dialog>

      <Box
        display="flex"
        flexDirection="column"
        gap={0.5}
        px={1}
        sx={{
          maxHeight: isDesktop ? pxToRem(500) : 'auto',
          overflowY: 'auto'
        }}
      >
        {_.isEmpty(props.notifications) && (
          <Box px={2} paddingTop={2} display="flex" justifyContent="center">
            <Typography variant="body1" fontWeight={theme.typography.fontWeightBold} color={theme.palette.text.primary}>
              Brak powiadomień..
            </Typography>
          </Box>
        )}
        {props.notifications?.map(n => (
          <NotificationListItem
            key={n.id}
            notification={n}
            onViewDetailsCallback={setSelectedNotification}
            onCloseNotificationsCallback={props.onCloseNotificationsCallback}
          />
        ))}
      </Box>
    </>
  );
};

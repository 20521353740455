import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { Navigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useGetFacilitiesQuery } from 'store/api/endpoints/facilityEndpoints';

interface FacilitiesGuardProps {
  children: React.ReactNode;
}

export const FacilitiesGuard = ({ children }: FacilitiesGuardProps): JSX.Element => {
  const { data: facilities, isLoading } = useGetFacilitiesQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (_.isEmpty(facilities)) {
    return <>{children}</>;
  } else {
    return <Navigate to={paths.dashboard.settings.ward} />;
  }
};

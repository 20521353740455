// IMPORTANT!!! Keep this file clean, if you see code exceeds more than 50 - 100 lines, break common utilities to smaller files

import _ from 'lodash';

export const sanitizeEmptyFields = <T>(obj: T): T => {
  if (typeof obj !== 'object' || obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sanitizeEmptyFields) as T;
  }

  const sanitizedObj: Partial<T> = {};

  for (let key in obj) {
    const value = obj[key];

    if (value === '') {
      sanitizedObj[key] = undefined;
    } else {
      sanitizedObj[key] = sanitizeEmptyFields(value);
    }
  }

  return sanitizedObj as T;
};

export function findById<T extends { id?: string | number }>(objects?: T[], id?: string | number): T | undefined {
  return _.find(objects ?? [], o => o.id === id);
}

export function isNotNilGuard<T>(obj?: T): obj is T {
  return !_.isNil(obj);
}

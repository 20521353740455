import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { alertsSliceReset } from 'store/slices/alertsSlice';

export const RoutingRoot = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(alertsSliceReset());
  }, [pathname, dispatch]);

  return <Outlet />;
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Box, useMediaQuery } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { Action, usePermission } from 'features/rbac/permissions';
import _ from 'lodash';

import { useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useGetFacilitiesQuery } from 'store/api/endpoints/facilityEndpoints';
import { useGetOffersByWardIdQuery } from 'store/api/endpoints/offerEndpoint';
import { useGetWardsQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setActiveFacilityId, setActiveWardId } from 'store/slices/facilitySlice';
import { setIsSidenavHidden } from 'store/slices/sidenavSlice';
import { useIsAuthorizedOnOtherTab } from 'useIsAuthorizedOnOtherTab';
import { DashboardLayout } from './dashboard-layout/dashboardLayout';
import { DashboardNavbar } from './navbar/dashboardNavbar';
import navigatorRule from './navigatorRule';
import BottomBar from './sidenav/bottomBar';
import { DesktopSidenav } from './sidenav/desktopSidenav';
import { MobileSidenav } from './sidenav/mobileSidenav';
import { SIDENAV_FULL_WIDTH, SIDENAV_RIBBON_WIDTH } from './sidenav/sidenavRoot';

export const Dashboard = (): JSX.Element => {
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const isSidenavHidden = useAppSelector(state => state.sidenavSlice.isSidenavHidden);
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: facilities, isSuccess: facilitiesFetchingSuccess, isError: facilitiesFetchingError } = useGetFacilitiesQuery();
  const { data: wards } = useGetWardsQuery();
  useGetOffersByWardIdQuery(activeWardId ?? skipToken);
  // other
  const { hasPermissionTo } = usePermission();
  const canInitializeFacility = hasPermissionTo(Action.INITIALIZE_FACILITY);
  const isDesktop = useIsDesktop();
  const matchesWardsPath = useMatch(`${paths.dashboard.wards.base}/*`);
  const navigate = useNavigate();
  const isAuthorizedOnOtherTab = useIsAuthorizedOnOtherTab();
  const isHiddenSidenavBreakpointMet = useMediaQuery(theme.breakpoints.down('xl'));

  const predicates = {
    shouldInitializeFacility: facilitiesFetchingSuccess && _.isEmpty(facilities) && canInitializeFacility,
    isFacilityInitializationInProgress: !_.isNil(matchesWardsPath),
    isUserLoggedIn: !_.isNil(loggedUser),
    isAuthorizedOnOtherTab: isAuthorizedOnOtherTab,
    isFacilityFetchingError: facilitiesFetchingError,
    areTermsAccepted: loggedUser?.termsAccepted ?? false
  };

  const redirectPath = navigatorRule.getRedirectPath(predicates);

  useEffect(() => {
    redirectPath && navigate(redirectPath);
  }, [redirectPath, navigate]);

  useEffect(() => {
    if (!_.isEmpty(facilities)) {
      const facility = _.first(facilities);
      dispatch(setActiveFacilityId(facility?.id));
    }
  }, [facilities, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(wards)) {
      dispatch(setActiveWardId(_.first(wards)?.id));
    }
  }, [wards, dispatch]);

  useEffect(() => {
    dispatch(setIsSidenavHidden(isHiddenSidenavBreakpointMet));
  }, [dispatch, isHiddenSidenavBreakpointMet]);

  const sidenavWidth = isSidenavHidden ? SIDENAV_RIBBON_WIDTH : SIDENAV_FULL_WIDTH;

  return (
    <>
      {isDesktop ? <DesktopSidenav /> : <MobileSidenav />}
      <Box
        display="flex"
        flexDirection="column"
        ml={{ xs: 0, xl: sidenavWidth, md: sidenavWidth }}
        mr={pxToRem(28)}
        pl={{ xs: 0, xl: 2 }}
        width={{
          xs: 1,
          xl: `calc(100% - ${sidenavWidth})`,
          md: `calc(100% - ${sidenavWidth})`
        }}
        height="100vh"
      >
        {isDesktop && <DashboardNavbar />}
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </Box>
      {!isDesktop && <BottomBar />}
    </>
  );
};

import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import { FormError } from 'features/ui/formError/FormError';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { validateEmail } from 'shared/utils/formUtils';
import { useRequestResetPasswordLinkMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { IconStatus } from '../../shared/types/iconStatus';
import { ResultMessage } from './ResultMessage';
import { AuthLayout, AuthLayoutSize } from './layout/AuthLayout';

export const ResetPassword = (): JSX.Element => {
  // redux
  const dispatch = useDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [reset, { isError, isSuccess }] = useRequestResetPasswordLinkMutation();
  // other
  const materialTheme = useTheme();
  const navigate = useNavigate();

  const cTextNeutral = materialTheme.palette.neutral[600];

  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm<{ email: string }>({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się wysłać maila aby zresetować hasło' }));
  }, [isError, dispatch]);

  useEffect(() => {
    loggedUser && navigate(paths.dashboard.calendar, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: { email: string }) => {
    reset(data.email);
  };

  return (
    <>
      {isSuccess ? (
        <ResultMessage
          icon={IconStatus.info}
          size={AuthLayoutSize.auto}
          text="Na podany adres mailowy została wysłana instrukcja resetowania hasła."
          additionalText="Sprawdź skrzynkę mailową."
        />
      ) : (
        <AuthLayout>
          <Card sx={{ px: 3, pt: 4, pb: 5 }}>
            <Typography variant="h3" fontWeight={materialTheme.typography.fontWeightBold} color="primary" pb={5} textAlign="center">
              Nie pamiętasz hasła?
            </Typography>
            <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Box mb={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Adres email" fullWidth />
                        {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                      </>
                    );
                  }}
                  rules={{
                    validate: validateEmail
                  }}
                />
              </Box>
              <Typography variant="caption" color={cTextNeutral}>
                Podaj adres email podany podczas rejestracji, a my wyślemy Ci instrukcję jak zresetować hasło.
              </Typography>
              <Button variant="contained" color="primary" type="submit" disabled={!isValid} fullWidth sx={{ mt: 5, mb: 2 }}>
                Wyślij
              </Button>

              <Box textAlign="center">
                <Link to={paths.root}>
                  <Button variant="text" color="secondary" fullWidth>
                    Wróć do logowania
                  </Button>
                </Link>
              </Box>
            </Box>
          </Card>
        </AuthLayout>
      )}
    </>
  );
};

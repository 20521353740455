import { pxToRem, theme } from '@medsi/mui-theme';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { BOTTOM_BAR_HEIGHT_PX } from 'features/dashboard/sidenav/bottomBar';
import { ConfirmationDialog } from 'features/ui/dialogs/ConfirmationDialog';
import _ from 'lodash';
import { useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { ShiftPlan } from 'shared/types/planning';
import { SPEED_DIAL_Z_INDEX } from 'shared/utils/zindex';
import { useSolveMutation } from 'store/api/endpoints/planningProblemEndpoint';
import { useAcceptShiftPlanMutation, useGetShiftPlanQuery, useUpdateShiftPlanMutation } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsSolving } from 'store/slices/plannerSlice';
import { ShiftPlanStatus } from './hooks/useGetShiftPlanStatus';
import { useGetUsersWithUnacceptedPrefs } from './hooks/useGetUsersWithUnacceptedPrefs';
import { useGetUsersWithUnfilledPrefs } from './hooks/useGetUsersWithUnfilledPrefs';

type Props = {
  shiftPlanStatus: ShiftPlanStatus;
};

export const PlannerSpeedDial = (props: Props) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  // redux
  const dispatch = useAppDispatch();
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const [solve] = useSolveMutation();
  const [acceptShiftPlan] = useAcceptShiftPlanMutation();
  const [updateShiftPlan] = useUpdateShiftPlanMutation();
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  // other
  const isDesktop = useIsDesktop();
  const unfilledPrefsUserIds = useGetUsersWithUnfilledPrefs();
  const unacceptedPrefsUserIds = useGetUsersWithUnacceptedPrefs();

  if (props.shiftPlanStatus === ShiftPlanStatus.ACCEPTED) {
    return null;
  }

  const generateFirstTime = () => {
    if (_.isEmpty(unfilledPrefsUserIds) && _.isEmpty(unacceptedPrefsUserIds)) {
      generateSchedule();
    } else {
      setIsConfirmationDialogOpen(true);
    }
  };

  const generateSchedule = () => {
    const nextMonth = addMonths(new Date(), 1);
    const fromDate = format(startOfMonth(nextMonth), 'yyyy-MM-dd');
    const toDate = format(endOfMonth(nextMonth), 'yyyy-MM-dd');
    if (activeWardId) {
      solve({ wardId: activeWardId, fromDate, toDate });
      dispatch(setIsSolving(true));
    }
  };

  const onResetAndGenerateSchedule = () => {
    const shiftPlanCopy: ShiftPlan = JSON.parse(JSON.stringify(shiftPlan));
    shiftPlanCopy.shiftAssignments?.forEach(sa => {
      sa.modifiedManually = false;
      sa.pinned = false;
    });

    updateShiftPlan(shiftPlanCopy)
      .unwrap()
      .then(() => {
        generateSchedule();
      });
  };

  const acceptSchedule = () => {
    if (activeWardId) {
      acceptShiftPlan(activeWardId);
    }
  };

  const actions =
    props.shiftPlanStatus === ShiftPlanStatus.NOT_EXISTING
      ? [{ icon: <CalendarMonthIcon fontSize="medium" />, name: 'Wygeneruj', executor: generateFirstTime }]
      : [
          { icon: <RefreshOutlinedIcon fontSize="medium" />, name: 'Wygeneruj ponownie', executor: generateSchedule },
          {
            icon: <RefreshOutlinedIcon fontSize="medium" />,
            name: 'Wygeneruj resetując zmiany',
            executor: onResetAndGenerateSchedule
          },
          { icon: <CheckCircleOutline fontSize="medium" />, name: 'Zatwierdź', executor: acceptSchedule }
        ];

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Nieuzupełnione/Niezatwierdzone dyspozycyjności'}
        content={
          'Nie wszyscy lekarze uzupełnili/zatwierdzili swoje dyspozycyjności. Mimo tego możesz wygenerować grafik. (Niezatwierdzone dyspozycyjności lekarzy będą wzięte pod uwagę podczas generowania grafiku)'
        }
        onAccept={generateSchedule}
        onAcceptText={'Wygeneruj mimo to'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
      <SpeedDial
        sx={{
          position: 'fixed',
          bottom: pxToRem(isDesktop ? 16 : BOTTOM_BAR_HEIGHT_PX + 32),
          right: pxToRem(16),
          zIndex: SPEED_DIAL_Z_INDEX
        }}
        icon={<CalendarMonthOutlinedIcon fontSize="medium" />}
        ariaLabel={'planner-action-buttons'}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => action.executor()}
            tooltipOpen
            sx={{
              '& .MuiSpeedDialAction-staticTooltipLabel': {
                fontSize: theme.typography.size.lg,
                display: 'inline-block',
                whiteSpace: 'nowrap'
              }
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
};

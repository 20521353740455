import { Grid, Box, Typography } from '@mui/material';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const ErrorPage = (): JSX.Element => {
  const error = useRouteError();

  return (
    <Box position="absolute" width="100%" minHeight="100vh" display="flex" alignItems="center">
      <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
        <Grid item xs={8}>
          <Box mx={2} mt={-3} p={2} mb={1} textAlign="center" sx={{ backgroundColor: 'warning', borderRadius: 'lg' }}>
            {isRouteErrorResponse(error) ? (
              <>
                <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {error.status}
                </Typography>
                <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {error.statusText}
                </Typography>
                <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {error.data?.message && <p>{error.data.message}</p>}
                </Typography>
              </>
            ) : (
              <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
                Przepraszamy, wystąpił nieznany błąd.
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

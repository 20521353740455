import { pxToRem, theme } from '@medsi/mui-theme';
import { InfoOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { CreateEditRobotDialog } from 'features/settings/robot-settings/createEditRobotDialog';
import { AddButton } from 'features/ui/buttons/addButton';
import { ConfirmationDialog } from 'features/ui/dialogs/ConfirmationDialog';
import { useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { User, Users } from 'shared/types/users';
import { useDeleteUserByIdMutation, useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';

const defaultUsers: User[] = [];

export const RobotSettings = () => {
  const [isCreateEditDialogOpen, setIsCreateEditDialogOpen] = useState(false);
  const [robotInEditContext, setRobotInEditContext] = useState<User | null>(null);
  const [robotInRemoveContext, setRobotInRemoveContext] = useState<User | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  const [deleteUser, { isError: userDeletingError }] = useDeleteUserByIdMutation();
  // other
  const isDesktop = useIsDesktop();
  useRequestStatusAlert(undefined, userDeletingError, undefined, 'Nie udało się usunąć użytkownika');

  const { palette, borders } = theme;

  const onAdd = () => {
    setRobotInEditContext(null);
    setIsCreateEditDialogOpen(true);
  };

  const onEdit = (robot: User) => {
    setRobotInEditContext(robot);
    setIsCreateEditDialogOpen(true);
  };

  const onDelete = (robot: User) => {
    setRobotInRemoveContext(robot);
    setIsConfirmationDialogOpen(true);
  };

  const onDeleteConfirmation = () => {
    setRobotInRemoveContext(null);
    robotInRemoveContext && deleteUser(robotInRemoveContext.id);
    setIsConfirmationDialogOpen(false);
  };

  return (
    <Grid
      container
      alignItems="stretch"
      p={3}
      sx={{ backgroundColor: palette.neutralExpand[200], borderRadius: borders.borderRadius.xxxl }}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Usuń wirtualnego użytkownika'}
        content={'Czy na pewno chcesz usunąć wirtualnego użytkownika?'}
        onAccept={onDeleteConfirmation}
        onAcceptText={'Usuń'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
      <CreateEditRobotDialog isOpen={isCreateEditDialogOpen} onClose={() => setIsCreateEditDialogOpen(false)} robot={robotInEditContext} />
      <Grid container columnSpacing={3}>
        <Grid item xs={12} pb={pxToRem(20)} display="flex" gap={1}>
          <Typography variant="body1">Tutaj możesz zarządzać użytkownikami wirtualnymi.</Typography>
          <Tooltip
            title="Użytkownik wirtualny służy do zarządzania grafikiem lekarza, który nie posiada konta w aplikacji."
            placement="top"
          >
            <InfoOutlined color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
        <Grid container item xs={12} pb={{ xs: pxToRem(20), md: 0 }}>
          <Box display="flex" flexWrap="wrap" gap={2} justifyContent={isDesktop ? 'flex-start' : 'center'}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={pxToRem(200)}
              height={pxToRem(150)}
              sx={{ backgroundColor: palette.light.main, borderRadius: borders.borderRadius.xxxl }}
            >
              <AddButton onClickCallback={onAdd} />
            </Box>
            {users
              .filter(u => u.robot)
              .map(u => (
                <Card key={u.id} sx={{ boxShadow: theme.boxShadows.elevation2, width: pxToRem(200), height: pxToRem(150) }}>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" p={2} height="100%">
                    <Box display="flex" gap={2}>
                      <SmartToyIcon color="secondary" fontSize="small" />
                      <Typography>{Users.toString<User>(u)}</Typography>
                    </Box>
                    <Box display="flex" gap={2} justifyContent="flex-end">
                      <IconButton size="small" color="secondary" onClick={() => onEdit(u)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton size="small" color="secondary" onClick={() => onDelete(u)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Card>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

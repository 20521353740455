import _ from 'lodash';
import { useCallback } from 'react';
import { AuthorityType } from 'shared/types/account';
import { useAppSelector } from 'store/hooks';

export enum Action {
  INITIALIZE_FACILITY = 'INITIALIZE_FACILITY',
  MANUAL_SHIFT_ASSIGNMENT = 'MANUAL_SHIFT_ASSIGNMENT',
  PLANNER = 'PLANNER',
  IMPERSONATION_PANEL = 'IMPERSONATION_PANEL',
  CALENDAR = 'CALENDAR',
  WARD_HEAD_SETTINGS = 'WARD_HEAD_SETTINGS',
  BASE = 'BASE'
}

const permissions: {
  [key in AuthorityType]?: {
    actions: Action[];
  };
} = {
  [AuthorityType.ADMIN]: {
    actions: [
      Action.INITIALIZE_FACILITY,
      Action.MANUAL_SHIFT_ASSIGNMENT,
      Action.PLANNER,
      Action.IMPERSONATION_PANEL,
      Action.CALENDAR,
      Action.WARD_HEAD_SETTINGS,
      Action.BASE
    ]
  },
  [AuthorityType.USER]: {
    actions: [Action.BASE, Action.CALENDAR]
  },
  [AuthorityType.WARD_HEAD]: {
    actions: [
      Action.INITIALIZE_FACILITY,
      Action.MANUAL_SHIFT_ASSIGNMENT,
      Action.PLANNER,
      Action.IMPERSONATION_PANEL,
      Action.CALENDAR,
      Action.WARD_HEAD_SETTINGS,
      Action.BASE
    ]
  }
};

export const usePermission = () => {
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  const hasPermissionTo = useCallback(
    (action: Action) =>
      loggedUser?.authorities?.some((authority: AuthorityType) => {
        return permissions[authority]?.actions.includes(action);
      }) ?? false,
    [loggedUser]
  );

  return {
    isLogged: !_.isNil(loggedUser),
    loggedUser,
    hasPermissionTo
  };
};

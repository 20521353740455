import { Box, Button, Snackbar, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { Facility, extractWards } from 'shared/types/facility';
import { InvitationStatus } from 'shared/types/invitation';
import { Ward } from 'shared/types/ward';
import { sanitizeEmptyFields } from 'shared/utils/commonUtils';
import { useCreateFacilityMutation, useGetPredefinedFacilitiesQuery } from 'store/api/endpoints/facilityEndpoints';
import { useSendInvitesMutation } from 'store/api/endpoints/invitationEndpoints';
import { useCreateWeeklyShiftPatternMutation } from 'store/api/endpoints/weeklyShiftPatternEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { clearNewInvitees } from 'store/slices/invitationSlice';
import { InvitationForm } from '../../management/invitationForm';
import _ from 'lodash';
import { DEFAULT_DEADLINE_DAY } from 'store/slices/wardRegistrationFormSlice';

export const InviteForm = (): JSX.Element => {
  const [clipboardSnackbar, setClipboardSnackbar] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const newInvitees = useAppSelector(state => state.invitationSlice.newInvitees);
  const formStateValues = useAppSelector(state => state.wardCreationFormSlice);
  // rtk
  const { data: predefinedFacilities } = useGetPredefinedFacilitiesQuery();
  const [sendInvites, { isError: invitingError }] = useSendInvitesMutation();
  const [createFacility, createFacilityState] = useCreateFacilityMutation();
  const [createWeeklyShiftPattern, { isError: weeklyShiftPatternCreationError }] = useCreateWeeklyShiftPatternMutation();
  // other
  const navigate = useNavigate();

  useEffect(() => {
    createFacilityState.isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się stworzyć placówki' }));
  }, [createFacilityState.isError, dispatch]);

  useEffect(() => {
    weeklyShiftPatternCreationError && onShiftPatternCreationError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyShiftPatternCreationError]);

  useEffect(() => {
    invitingError && onInvitingError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitingError]);

  useEffect(() => {
    if (createFacilityState.isSuccess) {
      dispatch(addAlert({ color: 'success', text: 'Stworzono placówkę', clearable: false }));
    }
  }, [createFacilityState.isSuccess, dispatch]);

  useEffect(() => {
    if (createFacilityState.data) {
      const wardId = extractWards(createFacilityState.data)[0]?.id;
      setupWardEnvironment(wardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFacilityState.data]);

  const setupWardEnvironment = async (wardId?: string) => {
    if (wardId) {
      await sendInvites(wardId);
    } else {
      onInvitingError();
    }
    if (wardId && formStateValues.weeklyShiftPattern) {
      await createWeeklyShiftPattern({ ...formStateValues.weeklyShiftPattern, wardId });
    } else {
      onShiftPatternCreationError();
    }

    router.navigate(paths.dashboard.calendar, { replace: true });
  };

  const onShiftPatternCreationError = () => {
    dispatch(addAlert({ color: 'error', text: 'Kalendarz nie został zainicjalizowany proprawnie' }));
  };

  const onInvitingError = () => {
    dispatch(addAlert({ color: 'error', text: 'Zaproszenia nie zostały wysłane' }));
  };

  const onSubmit = () => {
    const predefinedFacility = predefinedFacilities?.find(f => f.name === formStateValues.predefinedFacilityName);
    const facility: Facility = {
      name: formStateValues.predefinedFacilityName,
      predefinedFacilityId: predefinedFacility?.id
    };

    const ward: Ward = {
      deadlineDay: formStateValues.wardDeadline ?? DEFAULT_DEADLINE_DAY,
      zoneId: 'Europe/Warsaw', // TODO choosing timezone of a ward
      name: formStateValues.wardName,
      shortName: formStateValues.shortWardName,
      contact: { email: formStateValues.email, phoneNumber: formStateValues.phoneNumber },
      address: {
        city: formStateValues.city,
        street: formStateValues.street,
        building: formStateValues.building,
        postalCode: formStateValues.postalCode
      },
      invitations: [
        {
          expiration: addDays(new Date(), 2).toISOString(),
          invitees: newInvitees.map(i => ({
            ...i,
            status: InvitationStatus.NOT_SENT
          }))
        }
      ]
    };

    if (formStateValues.clinicName) {
      facility.clinics = [{ name: formStateValues.clinicName, wards: [ward] }];
    } else {
      facility.wards = [ward];
    }

    createFacility(sanitizeEmptyFields<Facility>(facility));
    dispatch(clearNewInvitees());
  };

  const changePage = () => {
    navigate(paths.dashboard.wards.create.wardDeadline, { replace: true });
  };

  return (
    <Box>
      <InvitationForm />
      <Box display="flex" flexWrap={'wrap'} justifyContent="space-between" mt={4.5}>
        <Button color="primary" size="large" variant="contained" fullWidth type="submit" onClick={onSubmit}>
          {_.isEmpty(newInvitees) ? 'Zakończ tworzenie placówki' : 'Wyślj'}
        </Button>
        <Button
          onClick={changePage}
          fullWidth
          sx={{
            marginTop: '1rem'
          }}
        >
          <Typography variant="body2" color="secondary" sx={{ display: 'block', textAlign: 'center', textTransform: 'none' }}>
            Wstecz
          </Typography>
        </Button>
      </Box>
      <Snackbar
        open={clipboardSnackbar}
        autoHideDuration={3000}
        onClose={() => setClipboardSnackbar(false)}
        message="Skopiowano kod do schowka"
      />
    </Box>
  );
};

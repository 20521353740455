import _ from 'lodash';
import { DayOfWeek } from 'shared/types/shiftBucket';
import { ShiftSlot } from 'shared/types/shiftSlot';

export const daysOfWeekInOrder: DayOfWeek[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const getDayLabel = (day: DayOfWeek) => {
  switch (day) {
    case 'MONDAY':
      return 'P';
    case 'TUESDAY':
      return 'W';
    case 'WEDNESDAY':
      return 'Ś';
    case 'THURSDAY':
      return 'C';
    case 'FRIDAY':
      return 'P';
    case 'SATURDAY':
      return 'S';
    case 'SUNDAY':
      return 'N';
  }
};

export const mergeDuplicates = (newSlot: ShiftSlot, existingSlots: ShiftSlot[]): ShiftSlot[] => {
  const mergedSlots: ShiftSlot[] = [];

  for (const existingSlot of existingSlots) {
    if (canSlotBeMerged(newSlot, existingSlot)) {
      existingSlot.days = _.union(existingSlot.days, newSlot.days);
      return existingSlots;
    }
  }

  mergedSlots.push(...existingSlots, newSlot);
  return mergedSlots;
};

const canSlotBeMerged = (newSlot: ShiftSlot, existingSlot: ShiftSlot): boolean => {
  return (
    existingSlot.startTime.hour === newSlot.startTime.hour &&
    existingSlot.startTime.minute === newSlot.startTime.minute &&
    existingSlot.endTime.hour === newSlot.endTime.hour &&
    existingSlot.endTime.minute === newSlot.endTime.minute &&
    existingSlot.isOvernight === newSlot.isOvernight
  );
};

import { theme } from '@medsi/mui-theme';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { FormError } from 'features/ui/formError/FormError';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Invitee } from 'shared/types/invitation';
import { User } from 'shared/types/users';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addNewUniqueInvitee, removeNewInvitee } from 'store/slices/invitationSlice';

const defaultUsers: User[] = [];

export const InviteViaEmailForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const newInvitees = useAppSelector(state => state.invitationSlice.newInvitees);

  const { data: users = defaultUsers } = useGetAllUsersQuery();

  const { functions, borders } = theme;
  const { pxToRem } = functions;
  const [invitees, setInvitees] = useState<Invitee[]>([]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    newInvitees && setInvitees(newInvitees.filter(invitee => !!invitee.email));
  }, [newInvitees]);

  const addInvitee = (data: { email: string }) => {
    dispatch(addNewUniqueInvitee({ email: data.email.toLowerCase() }));
    reset();
  };

  const validateUniqueness = (value: string) => {
    if (invitees.some(invitee => invitee.email === value.toLowerCase())) {
      return 'Ten adres email już istnieje na liście zaproszeń';
    }
    return !users.some(user => user.email === value.toLowerCase()) || 'Lekarz o podanym adresie email został już dodany do personelu';
  };

  const renderInvitees = () => {
    return invitees.map(invitee => (
      <ListItem
        key={invitee.email}
        sx={{ marginBottom: '0.75rem' }}
        secondaryAction={
          <IconButton edge="end" aria-label="delete" onClick={() => dispatch(removeNewInvitee(invitee))}>
            <DeleteOutlinedIcon fontSize="small" color="secondary" />
          </IconButton>
        }
      >
        <ListItemText primary={<Typography color="dark">{invitee.email}</Typography>} />
      </ListItem>
    ));
  };

  return (
    <Box>
      <Box flexGrow={1} component="form" role="form" onSubmit={handleSubmit(addInvitee)} noValidate>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <>
                <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Adres email" fullWidth />
                {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
              </>
            );
          }}
          rules={{
            required: validationMessage.required,
            validate: {
              valid: validateEmail,
              unique: validateUniqueness
            }
          }}
        />
        <Button
          startIcon={<AddIcon color="primary" fontSize="large" />}
          color="primary"
          size="small"
          variant="outlined"
          type="submit"
          disabled={!isValid}
          sx={{ marginTop: 1 }}
          fullWidth
        >
          dodaj do listy
        </Button>
      </Box>
      <Box
        px={1}
        pt={2}
        my={2}
        height="14rem"
        sx={theme => ({ backgroundColor: theme.palette.neutral[400], borderRadius: borders.borderRadius.xxxl })}
      >
        <List
          sx={{
            maxHeight: pxToRem(192),
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingRight: '0.5rem'
          }}
        >
          {renderInvitees()}
        </List>
      </Box>
    </Box>
  );
};

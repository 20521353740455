import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import _ from 'lodash';
import { User } from 'shared/types/users';
import { isInNextMonth } from 'shared/utils/calendarUtils';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';

const defaultUsers: User[] = [];

export const useGetUsersWithUnfilledPrefs = (): string[] => {
  const { shiftBuckets } = useGetShiftBuckets();
  const { data: users = defaultUsers } = useGetAllUsersQuery();

  const allUserIds = users.map(user => user.id);
  const usersIdsWithPreferences = _.uniq(
    shiftBuckets
      .filter(sb => isInNextMonth(new Date(sb.start)))
      .flatMap(sb => sb.preferences)
      .map(p => p.userId)
  );

  return _.difference(allUserIds, usersIdsWithPreferences);
};

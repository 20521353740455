import { WeeklyShiftPattern } from 'shared/types/shiftBucket';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createWeeklyShiftPattern: builder.mutation<WeeklyShiftPattern, WeeklyShiftPattern>({
      query: body => ({
        url: '/weekly-shift-patterns',
        method: HTTP_METHODS.POST,
        body
      })
    }),
    updateWeeklyShiftPattern: builder.mutation<WeeklyShiftPattern, WeeklyShiftPattern>({
      query: body => ({
        url: `/weekly-shift-patterns`,
        method: HTTP_METHODS.PUT,
        body
      })
    })
  }),
  overrideExisting: false
});

export const { useCreateWeeklyShiftPatternMutation, useUpdateWeeklyShiftPatternMutation } = extendedApi;

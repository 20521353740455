import { Ward } from './ward';

export interface Facility {
  id?: string;
  name?: string;
  predefinedFacilityId?: string;
  clinics?: Clinic[];
  wards?: Ward[];
}

export interface Clinic {
  id?: string;
  name?: string;
  wards?: Ward[];
}

export const extractWards = (facility: Facility) => {
  const wards = [];
  facility.clinics?.forEach(clinic => {
    wards.push(...(clinic.wards ?? []));
  });
  wards.push(...(facility.wards ?? []));
  return wards;
};

export interface PredefinedFacility {
  id?: string;
  name?: string;
}

import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const WeekCalendarStylesOverrides = styled(Box)<BoxProps>(({ theme }) => {
  const { palette, functions, borders } = theme;

  const { primary, light, neutral } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    height: '100%',
    '& .fc-col-header-cell': {
      padding: `${pxToRem(32)} 0 !important`,
      border: `2px solid ${light.main} !important`
    },
    '& .fc-timegrid-slot-minor': {
      border: 'none !important'
    },
    '& .fc-scrollgrid-section-header .fc-scroller': {
      overflow: 'hidden !important'
    },
    '& .fc-daygrid-body': {
      display: 'none !important'
    },
    // remove border from the bottom of calendar
    '& .fc-scrollgrid-section > td ': {
      borderBottom: 'none !important'
    },
    // remove border from around of calendar
    '& .fc-scrollgrid': {
      borderTop: `2px solid ${light.main}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none'
    },
    // it is used to style small section of border - bottom border of header of time axis
    '& .fc-timegrid-axis': {
      borderBottom: `2px solid ${light.main}`
    },
    '& .fc-timegrid-slot': {
      border: `2px solid ${light.main} !important`,
      height: pxToRem(64)
    },
    '& .fc-timegrid-slot-label': {
      width: pxToRem(64)
    },
    '& .fc-day': {
      border: `2px solid ${light.main} !important`,
      padding: pxToRem(8),
      cursor: 'pointer'
    },
    '& .date-is-free': {
      backgroundColor: `${neutral[200]} !important`,
      border: `2px solid ${light.main}`,
      cursor: 'default !important'
    },
    '& .fc-day.fc-day-today': {
      backgroundColor: 'transparent',
      '& .fc-scrollgrid-sync-inner': {
        display: 'flex',
        justifyContent: 'center',
        '& .fc-col-header-cell-cushion': {
          backgroundColor: `${primary.main} !important`,
          color: `white !important`,
          width: 'fit-content',
          padding: `${pxToRem(4)} ${pxToRem(8)}`,
          borderRadius: borderRadius.xxl
        }
      }
    },
    '& .fc-event ': {
      padding: '0 !important',
      margin: '0 !important',
      borderRadius: borderRadius.lg
    },
    '& .fc-v-event': {
      backgroundColor: 'transparent !important',
      border: 'none'
    }
  };
});

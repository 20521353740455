import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const SLICE_NAME = 'facilitySlice';

interface FacilityState {
  activeFacilityId?: string;
  activeWardId?: string;
}

const initialState: FacilityState = {};

export const facilitySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setActiveFacilityId(state, action: PayloadAction<string | undefined>) {
      state.activeFacilityId = action.payload;
    },
    setActiveWardId(state, action: PayloadAction<string | undefined>) {
      state.activeWardId = action.payload;
    },
    facilitySliceReset() {
      return initialState;
    }
  }
});

export const { setActiveFacilityId, setActiveWardId, facilitySliceReset } = facilitySlice.actions;

export default facilitySlice.reducer;

import { InputAdornment } from '@mui/material';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';

export const useGetInputAdornmentRenderer = (adornmentIcon: JSX.Element) => {
  const isDesktop = useIsDesktop();

  return isDesktop
    ? null
    : {
        textField: {
          InputProps: {
            endAdornment: <InputAdornment position="end">{adornmentIcon}</InputAdornment>
          }
        }
      };
};

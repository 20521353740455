import {
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  setDate,
  startOfMonth
} from 'date-fns';
import { pl } from 'date-fns/locale';
import _ from 'lodash';
import { ShiftBucket } from 'shared/types/shiftBucket';

export const getDayOfAWeek = (date: Date) => {
  return format(date, 'EEEE', { locale: pl });
};

/**
 * Returns a formatted date string (Sobota, 2 Kwietnia) with the day name, date, and month in Polish locale.
 *
 * @param date - The input date string in a valid date format.
 * @returns A formatted date string with the day name, date, and month or invalid date string.
 */
export const getDateWithDayName = (date?: string): string => {
  if (!date) {
    return 'Nieprawidłowa data';
  }
  return _.capitalize(format(new Date(date), 'EEEE, d MMMM', { locale: pl }));
};

export const getShiftBucketsStartingBetweenDates = (shiftBuckets: ShiftBucket[], start: Date, end: Date): ShiftBucket[] => {
  return shiftBuckets.filter(shiftBucket => {
    const sbStart = new Date(shiftBucket.start);
    return isBetween(sbStart, start, end, '[]');
  });
};

export const getCyclicShiftBucketsForSameMonth = (shiftBuckets: ShiftBucket[], start: Date, end: Date): ShiftBucket[] => {
  const month = start.getMonth();
  const dayOfWeek = start.getDay();
  const duration = end.getTime() - start.getTime();

  return shiftBuckets.filter(sb => {
    const sbStartDate = new Date(sb.start);
    const shiftMonth = sbStartDate.getMonth();
    const shiftDayOfWeek = sbStartDate.getDay();
    const sbStartHour = sbStartDate.getHours();
    const sbStartMinute = sbStartDate.getMinutes();
    const startHour = start.getHours();
    const startMinute = start.getMinutes();
    const shiftDuration = new Date(sb.end).getTime() - sbStartDate.getTime();
    return (
      shiftMonth === month &&
      shiftDayOfWeek === dayOfWeek &&
      sbStartHour === startHour &&
      sbStartMinute === startMinute &&
      shiftDuration === duration
    );
  });
};

export const isInPastMonths = (date: Date): boolean => {
  const currentDate = new Date();
  const startOfThisMonth = startOfMonth(currentDate);
  return isBefore(date, startOfThisMonth);
};

export const isInNextMonth = (date: Date): boolean => {
  const nextMonth = addMonths(new Date(), 1);
  return isSameMonth(nextMonth, date);
};

export const isAfterNextMonth = (date: Date): boolean => {
  return isAfter(date, endOfMonth(addMonths(new Date(), 1)));
};

export const isValidDate = (d: Date) => {
  return !isNaN(d.getTime());
};

type Inclusivity = '()' | '[]' | '(]' | '[)';

const isBetween = (date: Date, from: Date, to: Date, inclusivity: Inclusivity = '[]') => {
  const isBeforeInclusive = inclusivity[0] === '[',
    isAfterInclusive = inclusivity[1] === ']';

  return (
    (isBeforeInclusive ? isEqual(from, date) || isBefore(from, date) : isBefore(from, date)) &&
    (isAfterInclusive ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};

export const isDateAfterDeadline = (date: Date, deadline: number) => {
  const deadlineDay = _.min([getDaysInMonth(date), deadline]);
  if (!deadlineDay) {
    return false;
  }
  const deadlineDate = endOfDay(setDate(date, deadlineDay));
  const isAfterDeadline = isAfter(new Date(), deadlineDate);

  return isAfterDeadline;
};

import { Box, Button, Typography } from '@mui/material';
import { AddressAndContactFormFields } from 'features/ui/facility/addressAndContactFormFields';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateAddress } from 'store/slices/wardRegistrationFormSlice';

type FormFields = {
  city: string;
  street: string;
  building: string;
  postalCode: string;
  phoneNumber: string;
  email: string;
};

export const AddressAndContactForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const formStateValues = useAppSelector(state => state.wardCreationFormSlice);
  const navigate = useNavigate();

  const useFormReturn = useForm<FormFields>({
    mode: 'onBlur',
    defaultValues: {
      city: formStateValues.city,
      street: formStateValues.street,
      building: formStateValues.building,
      postalCode: formStateValues.postalCode,
      phoneNumber: formStateValues.phoneNumber,
      email: formStateValues.email
    }
  });

  const changePage = (path: string) => {
    const data = useFormReturn.getValues();
    dispatch(
      updateAddress({
        city: data.city,
        street: data.street,
        building: data.building,
        postalCode: data.postalCode,
        phoneNumber: data.phoneNumber,
        email: data.email
      })
    );
    navigate(path, { replace: true });
  };

  return (
    <FormProvider {...useFormReturn}>
      <Box
        noValidate
        component="form"
        role="form"
        onSubmit={useFormReturn.handleSubmit(() => changePage(paths.dashboard.wards.create.weeklyShiftPattern))}
      >
        <AddressAndContactFormFields />

        <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={{ xs: 3.5, md: 8.8 }}>
          <Button color="primary" size="large" variant="contained" type="submit" fullWidth>
            Dalej
          </Button>
          <Button
            onClick={() => changePage(paths.dashboard.wards.create.names)}
            fullWidth
            sx={{
              marginTop: '1rem'
            }}
          >
            <Typography variant="body3" color="secondary" sx={{ display: 'block', textAlign: 'center', textTransform: 'none' }}>
              Wstecz
            </Typography>
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

import { format } from 'date-fns';
import { getDateWithDayName } from 'shared/utils/calendarUtils';

export interface WeeklyShiftPattern {
  wardId?: string;
  dayTimeRanges: DayTimeRange[];
}

export interface DayTimeRange {
  fromDay: DayOfWeek;
  startTime: Time;
  toDay: DayOfWeek;
  endTime: Time;
}

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export interface Time {
  hour: number;
  minute: number;
}

export interface ShiftBucket {
  id: string;
  start: string;
  end: string;
  wardId: string;
  requiredDoctorAmount: number;
  doctors: string[];
  preferences: Preference[];
}

export interface Preference {
  userId: string;
  type: PreferenceType;
  note: string;
  accepted: boolean;
}

export enum PreferenceEnum {
  ABSENCE_ON_DAY = 'ABSENCE_ON_DAY',
  ABSENCE_ON_SHIFT = 'ABSENCE_ON_SHIFT',
  HOLIDAY = 'HOLIDAY',
  PRESENCE = 'PRESENCE',
  CYCLIC = 'CYCLIC'
}

export type PreferenceType = keyof typeof PreferenceEnum; // This ensures type safety

export namespace ShiftBuckets {
  export const getShiftBucketTimeLabel = (shiftBucket?: ShiftBucket) => {
    if (!shiftBucket) {
      return 'Nieznany termin dyżuru';
    }
    return format(new Date(shiftBucket.start), 'HH:mm') + '-' + format(new Date(shiftBucket.end), 'HH:mm');
  };

  export const getShiftBucketDayTimeLabel = (shiftBucket?: ShiftBucket) => {
    if (!shiftBucket) {
      return 'Nieznany termin dyżuru';
    }
    const bucketStartDate = getDateWithDayName(shiftBucket.start);
    const bucketEndDate = getDateWithDayName(shiftBucket.end);
    const bucketStartTime = format(new Date(shiftBucket.start), 'HH:mm');
    const bucketEndTime = format(new Date(shiftBucket.end), 'HH:mm');

    if (bucketStartDate === bucketEndDate) {
      return `${bucketStartDate} ${bucketStartTime}-${bucketEndTime}`;
    }
    return `${bucketStartDate} ${bucketStartTime} - ${bucketEndDate} ${bucketEndTime}`;
  };

  export const getAbsenceInfo = (preference?: Preference) => {
    if (preference?.type === PreferenceEnum.ABSENCE_ON_DAY) {
      return { reason: 'Dzień bez dyżuru', note: preference.note };
    }
    if (preference?.type === PreferenceEnum.ABSENCE_ON_SHIFT) {
      return { reason: 'Nie chcę tego dyżuru', note: preference.note };
    }
    if (preference?.type === PreferenceEnum.HOLIDAY) {
      return { reason: 'Urlop', note: preference.note };
    }
    return { reason: '', note: '' };
  };
}

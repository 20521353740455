import { skipToken } from '@reduxjs/toolkit/dist/query';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Offer } from 'shared/types/offer';
import { useGetOffersByWardIdQuery } from 'store/api/endpoints/offerEndpoint';
import { useAppSelector } from 'store/hooks';

const defaultOffers: Offer[] = [];

export const useGetMyActiveOffer = (bucketId: string): { isLoading: boolean; activeOffer: Offer | undefined } => {
  const [activeOffer, setActiveOffer] = useState<Offer | undefined>();
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data: offers = defaultOffers, isLoading } = useGetOffersByWardIdQuery(activeWardId ?? skipToken);

  useEffect(() => {
    const foundOffer = _.find(
      offers,
      o =>
        o.status === 'WAITING' &&
        !!loggedUser?.id &&
        o.offerRequest?.requester === loggedUser.id &&
        o.offerRequest?.offeredShift === bucketId
    );

    setActiveOffer(foundOffer);
  }, [offers, bucketId, loggedUser]);

  return {
    isLoading,
    activeOffer
  };
};

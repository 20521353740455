import { pxToRem } from '@medsi/mui-theme';
import { Box, Grid } from '@mui/material';
import PageLayout from 'features/ui/layout-containers/PageLayout';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import paths from 'routing/utils';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';

interface AuthLayoutProps {
  children: ReactNode;
  size?: AuthLayoutSize;
}
export enum AuthLayoutSize {
  narrow = 'narrow',
  medium = 'medium',
  wide = 'wide',
  auto = 'auto'
}
const sizes = {
  [AuthLayoutSize.narrow]: {
    xs: 11,
    sm: 9,
    md: 5,
    lg: 4,
    xl: 3,
    xxl: 2
  },
  [AuthLayoutSize.medium]: {
    xs: 11,
    sm: 9,
    md: 5,
    lg: 4,
    xl: 3,
    xxl: 3
  },
  [AuthLayoutSize.wide]: {
    xs: 11,
    sm: 9,
    md: 9,
    lg: 7,
    xl: 4,
    xxl: 6
  },
  [AuthLayoutSize.auto]: {}
};
const image = '/bg.png';
export const DESKTOP_BACKGROUND_SHIFT = pxToRem(100);
export const MOBILE_BACKGROUND_SHIFT = pxToRem(155);
export const AuthLayout = ({ children, size }: AuthLayoutProps): JSX.Element => {
  const isDesktop = useIsDesktop();
  return (
    <PageLayout>
      <Box
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: isDesktop ? `calc(50% + ${DESKTOP_BACKGROUND_SHIFT}rem)` : `calc(50% - ${MOBILE_BACKGROUND_SHIFT}rem)`,
          backgroundPositionY: 'bottom'
        }}
      />
      <Box
        pt={pxToRem(11)}
        width={1}
        mt={{ xs: pxToRem(3), md: pxToRem(13) }}
        pl={{ xs: 0, md: pxToRem(44) }}
        display="flex"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        <Link to={paths.root}>
          <Box component="img" src="/logo.png" width="137px" height="34px" />
        </Link>
      </Box>
      <Box width="100%" m="auto" py={{ xs: 3, md: 0 }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item {...sizes[size ?? AuthLayoutSize.medium]}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};

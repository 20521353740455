import { pxToRem, theme } from '@medsi/mui-theme';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, Button, Chip, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import { useMemo } from 'react';
import { Doctor, ShiftPlan } from 'shared/types/planning';
import { ShiftBuckets } from 'shared/types/shiftBucket';
import { Users } from 'shared/types/users';
import { WARNING_LIGHT_COLOR } from 'shared/utils/palette';
import { useGetShiftBucketByIdQuery } from 'store/api/endpoints/shiftBucketEndpoint';
import { useGetShiftPlanQuery, useUpdateShiftPlanMutation } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';
import { JustificationFilter } from '../justificationFilter';

type Props = {
  doctor: Doctor;
  bucketId: string;
  isAssigned: boolean;
  onSetDoctorCallback: (doctor: Doctor | null) => void;
};

export const ShiftCandidate = (props: Props): JSX.Element => {
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: shiftBucket } = useGetShiftBucketByIdQuery(props.bucketId ?? skipToken);
  const [updateShiftPlan] = useUpdateShiftPlanMutation();
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  // other
  const { palette } = useTheme();

  const justificationMessages = useMemo(() => {
    if (!shiftPlan?.justifications || !props.bucketId || !props.doctor?.id) {
      return [];
    }
    return new JustificationFilter(shiftPlan?.justifications).withBucket(props.bucketId).withDoctor(props.doctor?.id).stringify();
  }, [shiftPlan?.justifications, props.bucketId, props.doctor?.id]);

  const onAssign = () => {
    updateDoctor(props.doctor);
    props.onSetDoctorCallback(props.doctor);
  };

  const onUnassign = () => {
    updateDoctor(null);
    props.onSetDoctorCallback(null);
  };

  const updateDoctor = (doctor: Doctor | null) => {
    const shiftPlanCopy: ShiftPlan = JSON.parse(JSON.stringify(shiftPlan));
    const sa = shiftPlanCopy.shiftAssignments?.find(sa => sa.shift?.shiftBucketId === props.bucketId);
    if (sa) {
      sa.doctor = doctor ?? undefined;
      sa.pinned = true;
      sa.modifiedManually = true;
    }
    updateShiftPlan(shiftPlanCopy);
  };

  const userPreference = shiftBucket?.preferences?.find(p => p.userId === props.doctor?.id);
  const absenceInfo = ShiftBuckets.getAbsenceInfo(userPreference);

  const actionButton = props.isAssigned ? (
    <IconButton color="primary" onClick={onUnassign}>
      <DeleteIcon />
    </IconButton>
  ) : (
    <Button startIcon={<CheckIcon />} onClick={onAssign} variant="outlined">
      Wybierz
    </Button>
  );

  return (
    <Box py={1} width="100%">
      <Box display="flex" justifyContent="flex-start">
        {props.isAssigned && (
          <Typography variant="caption" color={palette.text.secondary} sx={{ paddingBottom: 2, paddingTop: 1 }}>
            Lekarz przypisany do dyżuru:
          </Typography>
        )}
      </Box>
      <Grid
        container
        borderRadius={theme.borders.borderRadius.xxxl}
        p={1}
        gap={1}
        flexWrap="nowrap"
        sx={{
          backgroundColor: _.isEmpty(justificationMessages) ? palette.neutral[200] : WARNING_LIGHT_COLOR
        }}
      >
        <Grid item lg={1} display="flex" alignItems="center">
          <Avatar sx={{ bgcolor: palette.grey[500] }}>{Users.getInitials<Doctor>(props.doctor)}</Avatar>
        </Grid>
        <Grid item lg={11} display="flex" justifyContent="space-between" flexGrow={1}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <Typography variant="body3">{Users.toString<Doctor>(props.doctor)}</Typography>
            <Typography variant="body1">Lekarz</Typography>
            {absenceInfo?.reason && (
              <Box pt={pxToRem(2)}>
                <Chip label={absenceInfo.reason} color="secondary" size="small" />
              </Box>
            )}
            {absenceInfo?.note && (
              <Typography sx={{ paddingTop: 1 }} variant="caption" color={palette.text.primary}>
                {absenceInfo.note}
              </Typography>
            )}
          </Box>
          <Box>{actionButton}</Box>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {justificationMessages?.map((jm, idx) => (
          <Typography key={idx} variant="caption" color={palette.warning.dark} sx={{ paddingTop: pxToRem(4) }}>
            {jm}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

import { Alert } from '@mui/material';
import { useGetMyActiveOffer } from 'features/calendar/hooks/useGetMyActiveOffer';
import { Loadable } from 'features/ui/loadable/Loadable';
import { SwapShiftCreator } from './swapShiftCreator';
import { SwapShiftDetails } from './swapShiftDetails';
import { theme } from '@medsi/mui-theme';

interface SwapShiftTabProps {
  bucketId: string;
}

export const SwapShiftTab = (props: SwapShiftTabProps): JSX.Element => {
  const { isLoading, activeOffer } = useGetMyActiveOffer(props.bucketId);

  const borderRadiusXxxl = theme.borders.borderRadius.xxxl;

  let content;

  if (!activeOffer) {
    content = <SwapShiftCreator bucketId={props.bucketId} />;
  } else if (activeOffer.type === 'SWAP_ONE_TO_ONE') {
    content = <SwapShiftDetails activeOffer={activeOffer} />;
  } else {
    content = (
      <Alert severity="warning" sx={{ width: '100%', borderRadius: borderRadiusXxxl, textAlign: 'start', my: 3 }}>
        Wymiana dyżuru jest niemożliwa. Została zgłoszona nieobecność.
      </Alert>
    );
  }

  return <Loadable loading={isLoading}>{content}</Loadable>;
};

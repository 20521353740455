"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var functions_1 = require("../../functions");
var colors = {
    background: {
        default: "#ffffff",
        paper: "#FCFCFF",
    },
    text: {
        main: "#7B809A",
        primary: "#344767DE",
        secondary: "#34476799",
        disabled: "#34476761",
    },
    transparent: "transparent",
    white: {
        main: "#ffffff",
        focus: "#ffffff",
    },
    black: {
        light: "#000000",
        main: "#000000",
        focus: "#000000",
    },
    primary: {
        main: "#E93B77",
        focus: "#E93B771F",
        light: "#F9DCE6",
        dark: "#BE3668",
    },
    secondary: {
        main: "#344767",
        focus: "#10223F",
        light: "#5C7399",
    },
    error: {
        light: "#FFA470",
        main: "#FF8946",
        dark: "#D74E01",
        focus: "".concat((0, functions_1.rgba)("#FF8946", 0.6)),
    },
    warning: {
        main: "#FAAD14",
        focus: "#D2910F",
    },
    info: {
        main: "#1890FF",
        focus: "#186AB6",
    },
    success: {
        main: "#47BC0D",
        focus: "#409715",
    },
    light: {
        main: "#f0f2f5",
        focus: "#f0f2f5",
        light: "#fafbfc",
    },
    dark: {
        main: "#344767",
        focus: "#2c3c58",
    },
    events: {
        first: "#0064E5",
        second: "#A87CA9",
        third: "#009C10",
        fourth: "#03847C",
        fifth: "#282FA3",
    },
    grey: {
        100: "#f8f9fa",
        200: "#f0f2f5",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#6c757d",
        700: "#495057",
        800: "#343a40",
        900: "#212529",
    },
    neutral: {
        100: "#ffffff",
        200: "#F8F9FA",
        300: "#FAFBFC",
        400: "#F0F2F5",
        500: "#CCCED7",
        600: "#7B809A",
        700: "#344767",
        800: "#474043",
    },
    neutralExpand: {
        200: "#FCFCFF",
    },
    gradients: {
        primary: {
            main: "#E93B77",
            state: "#D81B60",
        },
        secondary: {
            main: "#344767",
            state: "#495361",
        },
        error: {
            main: "#FF8946",
            state: "#E53935",
        },
        warning: {
            main: "#FAAD14",
            state: "#D2910F",
        },
        info: {
            main: "#1890FF",
            state: "#1A73E8",
        },
        success: {
            main: "#52C41A",
            state: "#43A047",
        },
        light: {
            main: "#EBEFF4",
            state: "#CED4DA",
        },
        dark: {
            main: "#42424a",
            state: "#191919",
        },
    },
    socialMediaColors: {
        facebook: {
            main: "#3b5998",
            dark: "#344e86",
        },
        twitter: {
            main: "#55acee",
            dark: "#3ea1ec",
        },
        instagram: {
            main: "#125688",
            dark: "#0e456d",
        },
        linkedin: {
            main: "#0077b5",
            dark: "#00669c",
        },
        pinterest: {
            main: "#cc2127",
            dark: "#b21d22",
        },
        youtube: {
            main: "#e52d27",
            dark: "#d41f1a",
        },
        vimeo: {
            main: "#1ab7ea",
            dark: "#13a3d2",
        },
        slack: {
            main: "#3aaf85",
            dark: "#329874",
        },
        dribbble: {
            main: "#ea4c89",
            dark: "#e73177",
        },
        github: {
            main: "#24292e",
            dark: "#171a1d",
        },
        reddit: {
            main: "#ff4500",
            dark: "#e03d00",
        },
        tumblr: {
            main: "#35465c",
            dark: "#2a3749",
        },
    },
    coloredShadows: {
        primary: "#e93b77",
        secondary: "#344767",
        info: "#344767",
        success: "#344767",
        warning: "#344767",
        error: "#344767",
        light: "#adb5bd",
        dark: "#404040",
        main: "#a8b8be",
    },
    inputBorderColor: "transparent",
    tabs: {
        indicator: { boxShadow: "#ddd" },
    },
    action: {
        disabled: "#7b809a61",
        disabledBackground: "#f0f2f51f",
    },
    components: {
        avatar: {
            fill: "#ced4da",
        },
    },
};
exports.default = colors;

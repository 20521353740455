import { theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import _ from 'lodash';
import React from 'react';
import { User, Users } from 'shared/types/users';
import { getShiftBucketsStartingBetweenDates } from 'shared/utils/calendarUtils';
import { findById } from 'shared/utils/commonUtils';
import { EMERGENCY_COLOR } from 'shared/utils/palette';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';

type Props = {
  start: Date;
  end: Date;
};

interface DateShifts {
  date: string;
  shifts: {
    time: string;
    doctor: string;
  }[];
}

const defaultUsers: User[] = [];

export const ShiftsPrintout = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  // other
  const { shiftBuckets } = useGetShiftBuckets();

  const { size } = theme.typography;

  const printableShiftDate: DateShifts[] = _(getShiftBucketsStartingBetweenDates(shiftBuckets, props.start, props.end))
    .groupBy(sb => format(new Date(sb.start), "yyyy-MM-dd '('EEEE')'", { locale: pl }))
    .map((group, key) => {
      const shifts: { time: string; doctor: string }[] = [];
      group
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
        .forEach(shiftBucket => {
          const time = `${format(new Date(shiftBucket.start), 'kk:mm')}-${format(new Date(shiftBucket.end), 'kk:mm')}`;
          var doctor = '';
          if (!_.isEmpty(shiftBucket.doctors)) {
            const user = findById<User>(users, shiftBucket.doctors[0]);
            doctor = Users.toString<User>(user);
          }

          shifts.push({ doctor, time });
        });

      return { date: key, shifts };
    })
    .value();

  return (
    <Box ref={ref} pl={4} pt={2}>
      <Box pb={2}>
        <Typography color={theme.palette.primary.main} fontSize={size.xl} fontWeight={theme.typography.fontWeightBold}>
          Dyżury na okres {format(props.start, 'dd-MM-yyyy')} - {format(props.end, 'dd-MM-yyyy')}
        </Typography>
      </Box>

      {printableShiftDate.map(tms => (
        <Box key={tms.date} pb={1} displayPrint={'block'} className="pagebreak">
          <Typography color={theme.palette.secondary.main} fontSize={size.sm} fontWeight={theme.typography.fontWeightBold}>
            {tms.date}
          </Typography>

          {tms.shifts.map(shift => (
            <Box key={shift.time} pl={2}>
              <Typography
                color={shift.doctor ? theme.palette.secondary.main : EMERGENCY_COLOR}
                fontSize={size.sm}
                fontWeight={theme.typography.fontWeightRegular}
              >
                {shift.time} - {shift.doctor ? shift.doctor : 'brak'}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
});

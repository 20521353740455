import { Box, Button, Card, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import _ from 'lodash';
import { useEffect } from 'react';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { InvitationStatus } from 'shared/types/invitation';
import { Ward } from 'shared/types/ward';
import { sanitizeEmptyFields } from 'shared/utils/commonUtils';
import { useSendInvitesMutation } from 'store/api/endpoints/invitationEndpoints';
import { useGetWardByIdQuery, useUpdateWardMutation } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { InvitationForm } from '../../facilities/wards/management/invitationForm';
import { pxToRem, theme } from '@medsi/mui-theme';
import { isAfter } from 'date-fns';
import { clearNewInvitees } from 'store/slices/invitationSlice';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';

interface InvitationCardProps {
  expiration?: Date;
  code?: string;
}

export const InvitationCard = (props: InvitationCardProps): JSX.Element => {
  // redux
  const dispatch = useAppDispatch();
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const newInvitees = useAppSelector(state => state.invitationSlice.newInvitees);
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const [updateWard, { isSuccess: wardUpdateSuccess, isError: wardUpdateError }] = useUpdateWardMutation();
  const [invite, { isError: invitingError }] = useSendInvitesMutation();
  // other
  const isDesktop = useIsDesktop();
  useRequestStatusAlert(undefined, wardUpdateError, undefined, 'Nie udało się wysłać zaproszenia');
  useRequestStatusAlert(undefined, invitingError, undefined, 'Nie udało się wysłać zaproszenia');

  useEffect(() => {
    if (wardUpdateSuccess && activeWard?.id) {
      dispatch(clearNewInvitees());
      invite(activeWard.id);
    }
  }, [wardUpdateSuccess, activeWard, invite, dispatch]);

  const updateInvitees = () => {
    const wardCopy: Ward = JSON.parse(JSON.stringify(activeWard));
    const currentInvitation = wardCopy.invitations?.find(i => i.code === props.code);
    currentInvitation?.invitees.push(
      ...newInvitees.map(i => ({
        email: i.email,
        phoneNumber: i.phoneNumber,
        name: i.firstName,
        surname: i.lastName,
        status: InvitationStatus.NOT_SENT
      }))
    );
    updateWard(sanitizeEmptyFields<Ward>(wardCopy));
  };

  return (
    <Card sx={theme => ({ px: 3, pt: 4, pb: 5, borderRadius: isDesktop ? theme.borders.borderRadius.xxxl : 0 })}>
      <Typography variant="body3" fontSize={pxToRem(26)} color={theme.palette.primary.main} alignSelf={'center'} pb={5}>
        Wyślij zaproszenie
      </Typography>
      <InvitationForm code={props.code} expiration={props.expiration} />
      <Box display="flex" flexWrap={'wrap'} justifyContent="space-between">
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          onClick={updateInvitees}
          disabled={_.isEmpty(newInvitees) || (props.expiration && isAfter(new Date(), props.expiration))}
        >
          Wyślij
        </Button>
      </Box>
    </Card>
  );
};

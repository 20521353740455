import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import { addAlert } from 'store/slices/alertsSlice';
import { AuthState } from 'store/slices/authSlice';
import { store } from 'store/store';

export const rtkQueryRequestMiddleware: Middleware = (api: MiddlewareAPI) => next => action => {
  if (isRejectedWithValue(action) && action.payload?.status === 401) {
    const authState: AuthState = store.getState().authSlice;

    const isUserLoggedIn = authState.loggedUser && authState.accessToken;

    if (isUserLoggedIn) {
      store.dispatch(
        addAlert({
          color: 'error',
          text: 'Nie udało się przetworzyć Twojego żądania. Może to być spowodowane brakiem dostępu do tej funkcji lub wygaśnięciem sesji. Zaloguj się ponownie, aby kontynuować.'
        })
      );
    }
  }

  return next(action);
};

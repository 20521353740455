import { Box, Card, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Time } from 'shared/types/shiftBucket';
import { ShiftSlot, WeekDaysState } from 'shared/types/shiftSlot';
import { daysOfWeekInOrder } from './utils';
import { theme } from '@medsi/mui-theme';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ShiftWeekDays } from './shiftWeekDays';
import { ShiftTitle } from './dialog/shiftColor';

interface ShiftSlotCardProps {
  shiftSlot: ShiftSlot;
  shiftIndex: number;
  openCreatorInEditMode: () => void;
  onRemoveShiftSlotCallback: (id: string) => void;
}

export const ShiftSlotCard = (props: ShiftSlotCardProps): JSX.Element => {
  const { functions, palette, typography } = theme;
  const { pxToRem, boxShadow, rgba } = functions;
  const { secondary } = palette;
  const { fontWeightRegular } = typography;

  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onRemoveShiftSlotCallback(props.shiftSlot.id);
  };

  const shiftDuration = () => {
    const { startTime, endTime, isOvernight } = props.shiftSlot;

    const start = startTime.hour * 60 + startTime.minute;
    const end = endTime.hour * 60 + endTime.minute;

    const diff = end - start;
    const hours = (isOvernight ? 24 : 0) + Math.floor(diff / 60);
    const minutes = diff % 60;
    const showHours = `${String(hours).padStart(1)}h`;
    const showMinutes = `${String(minutes).padStart(2)}min`;

    return showHours + (minutes > 0 ? ` ${showMinutes}` : '');
  };

  const getFormattedTime = (time: Time) => {
    const date = new Date(0);
    date.setHours(time.hour);
    date.setMinutes(time.minute);
    return format(date, 'HH:mm');
  };

  const startTime = getFormattedTime(props.shiftSlot.startTime);
  const endTime = getFormattedTime(props.shiftSlot.endTime);

  return (
    <Card sx={{ width: '100%', padding: `${pxToRem(9)} ${pxToRem(11)}`, boxShadow: boxShadow([0, 1], [3, 0], palette.dark.main, 0.25) }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <ShiftTitle shiftIndex={props.shiftIndex} />
        <Box>
          <IconButton onClick={() => props.openCreatorInEditMode()} sx={{ padding: '0.25rem !important' }}>
            <EditIcon color="secondary" sx={{ fontSize: '1.15rem !important' }} />
          </IconButton>
          <IconButton size="large" onClick={onDeleteClick} sx={{ padding: '0.25rem !important', paddingRight: '0 !important' }}>
            <DeleteIcon color="secondary" sx={{ fontSize: '1.15rem !important' }} />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ marginTop: '.5rem' }}>
        <Box width="100%">
          <Box mb={3} display="flex" alignItems="center" height={pxToRem(32)}>
            <Box display="flex" alignItems="flex-end">
              <Typography
                mr={pxToRem(8)}
                variant="h6"
                fontWeight={fontWeightRegular}
                fontSize={pxToRem(12)}
                sx={{ color: `${rgba(secondary.main, 0.87)} !important`, letterSpacing: '0.022rem' }}
              >
                od
              </Typography>
              <Typography
                mr={pxToRem(10)}
                variant="h4"
                fontWeight={fontWeightRegular}
                fontSize={pxToRem(26)}
                sx={{ color: `${rgba(secondary.main, 0.87)} !important` }}
              >
                {startTime}
              </Typography>
            </Box>
            <Box display="flex" alignItems="flex-end">
              <Typography
                mr={pxToRem(6)}
                variant="h6"
                fontWeight={fontWeightRegular}
                fontSize={pxToRem(12)}
                sx={{ color: `${rgba(secondary.main, 0.87)} !important`, letterSpacing: '0.022rem' }}
              >
                do
              </Typography>
              <Typography
                mr={pxToRem(4)}
                variant="h4"
                fontWeight={fontWeightRegular}
                fontSize={pxToRem(26)}
                sx={{ color: `${rgba(secondary.main, 0.87)} !important` }}
              >
                {endTime}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              sx={{
                color: `${rgba(secondary.main, 0.6)} !important`,
                marginBottom: pxToRem(4),
                marginTop: 'auto !important'
              }}
            >
              ({shiftDuration()})
            </Typography>
          </Box>
          <ShiftWeekDays
            readOnly={true}
            days={Object.fromEntries(daysOfWeekInOrder.map(day => [day, props.shiftSlot.days.includes(day)])) as any as WeekDaysState}
          />
        </Box>
      </Box>
    </Card>
  );
};

import { Close } from '@mui/icons-material';
import { AppBar, Box, Dialog, DialogTitle, IconButton, Theme, Toolbar, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { NotificationAnchor } from 'features/notifications/notificationAnchor';
import { NotificationList } from 'features/notifications/notificationList';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarElement } from 'shared/types/routes';
import { User, Users } from 'shared/types/users';
import { useGetNotificationsQuery } from 'store/api/endpoints/notificationEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsSidenavHidden } from 'store/slices/sidenavSlice';
import { Notification } from '../../../shared/types/notification';
import { calendarLink, plannerLink } from './utils';

const sidebarElements: SidebarElement[] = [calendarLink, plannerLink];

export const BOTTOM_BAR_HEIGHT_PX = 60;
const defaultNotifications: Notification[] = [];

const BottomBar = () => {
  const [areNotificationsOpen, setAreNotificationsOpen] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const isSidenavHidden = useAppSelector(state => state.sidenavSlice.isSidenavHidden);
  // rtk
  const { data: notifications = defaultNotifications } = useGetNotificationsQuery(loggedUser?.id ?? skipToken);
  // other
  const theme = useTheme();

  const { palette, boxShadows, functions, borders } = theme;
  const { pxToRem } = functions;

  return (
    <>
      <Dialog open={areNotificationsOpen} fullScreen onClose={() => setAreNotificationsOpen(false)}>
        <DialogTitle>
          <IconButton size="small" color="secondary" onClick={() => setAreNotificationsOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <NotificationList notifications={notifications} onCloseNotificationsCallback={() => setAreNotificationsOpen(false)} />
      </Dialog>
      <AppBar
        position="fixed"
        sx={{
          top: 'auto',
          right: '1rem',
          bottom: '1rem',
          left: '1rem',
          width: 'auto',
          height: pxToRem(BOTTOM_BAR_HEIGHT_PX),
          borderRadius: borders.borderRadius.xxxl,
          backgroundColor: palette.white.main,
          boxShadow: boxShadows.elevation8
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {sidebarElements.map(se => (
            <Box
              key={se.route}
              sx={{
                '& > a': {
                  height: '100%',
                  width: '100%',
                  display: 'grid',
                  borderRadius: '100%'
                },
                '& > .active': {
                  backgroundColor: palette.primary.light
                },
                '& > .active span': {
                  color: palette.primary.dark
                },
                '& .MuiIcon-root': {
                  position: 'relative',
                  zIndex: 1
                }
              }}
            >
              <NavLink
                to={se.route ?? '/'}
                style={{
                  textDecoration: 'none'
                }}
              >
                <IconButton color="secondary" key={se.route}>
                  {se.prefix}
                </IconButton>
              </NavLink>
            </Box>
          ))}
          <Box
            sx={{
              backgroundColor: isSidenavHidden ? 'transparent' : theme.palette.primary.light,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& > a': {
                height: '100%',
                width: '100%',
                display: 'grid',
                borderRadius: '100%'
              }
            }}
          >
            <IconButton color="secondary" aria-label="open drawer" onClick={() => dispatch(setIsSidenavHidden(!isSidenavHidden))}>
              <Box
                sx={(theme: Theme) => ({
                  width: pxToRem(32),
                  height: pxToRem(32),
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1rem'
                })}
              >
                {Users.getInitials<User>(loggedUser)}
              </Box>
            </IconButton>
          </Box>
          <NotificationAnchor
            isNotificationListVisible={areNotificationsOpen}
            onToggleNotificationList={() => setAreNotificationsOpen(prev => !prev)}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default BottomBar;

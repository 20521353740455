import { skipToken } from '@reduxjs/toolkit/query';
import { endOfDay, format, startOfDay } from 'date-fns';
import { ShiftPlanStatus, useGetShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';
import { usePermission } from 'features/rbac/permissions';
import { SHIFT_EVENT_POSTFIX, getEventType, isAllDayUserPreferenceEvent } from 'features/ui/calendar/utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CalendarEventData, CalendarTypeGuard, CalendarView, ShiftEventType } from 'shared/types/calendar';
import { ShiftBucket } from 'shared/types/shiftBucket';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';

const isEventOwner = (sb: ShiftBucket, userId: string, shiftEventType: ShiftEventType): boolean => {
  if (shiftEventType === ShiftEventType.PREFERENCE) {
    return sb.preferences.some(p => p.userId === userId);
  } else {
    return sb.doctors.includes(userId);
  }
};

const isShiftOwner = (sb: ShiftBucket, userId: string, shiftEventType: ShiftEventType): boolean => {
  if (shiftEventType === ShiftEventType.SHIFT || shiftEventType === ShiftEventType.PAST_SHIFT) {
    return sb.doctors.includes(userId);
  } else {
    return false;
  }
};

const canBucketBeConvertedToEvent = (sb: ShiftBucket, userId: string, planStatus: ShiftPlanStatus): boolean => {
  if (getEventType(new Date(sb.start), planStatus) === ShiftEventType.PREFERENCE) {
    const isLoggedUserPreference = sb.preferences.some(p => p.userId === userId);
    return isLoggedUserPreference;
  } else {
    return true;
  }
};

export const useConstructEventData = (
  calendarView: CalendarView,
  shiftBuckets?: ShiftBucket[],
  selectedDoctor?: string
): CalendarEventData[] => {
  const [events, setEvents] = useState<CalendarEventData[]>([]);
  const [doctorEvents, setDoctorEvents] = useState<CalendarEventData[]>([]);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const bucketOffers = useAppSelector(state => state.offerSlice.bucketOffers);
  // rtk
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  // other
  const planStatus = useGetShiftPlanStatus(shiftPlan);
  const { hasPermissionTo } = usePermission();

  useEffect(() => {
    const canPassToNextDay = calendarView === CalendarView.TIME_GRID_DAY || calendarView === CalendarView.TIME_GRID_WEEK;

    const userId = loggedUser?.id;

    if (!userId) {
      setEvents([]);
      return;
    }

    const calendarEvents: CalendarEventData[] = [];

    const allDayUniqueEvents = new Set<string>();

    shiftBuckets?.forEach(sb => {
      const sbStart = new Date(sb.start);
      const sbFormattedStart = format(sbStart, 'yyyy-MM-dd');

      if (!allDayUniqueEvents.has(sbFormattedStart)) {
        const sbEnd = new Date(sb.end);

        const shiftEventType = getEventType(sbStart, planStatus);
        const isConvertable = canBucketBeConvertedToEvent(sb, userId, planStatus);
        const isLoggedUserEvent = isEventOwner(sb, userId, shiftEventType);
        const isAllDayEvent = isAllDayUserPreferenceEvent(sb, userId, shiftEventType);

        if (isAllDayEvent) {
          allDayUniqueEvents.add(sbFormattedStart);
        }

        if (isConvertable) {
          const isEventUnassigned = shiftEventType === ShiftEventType.SHIFT && _.isEmpty(sb.doctors);

          const offers = bucketOffers.find(bo => bo.bucketId === sb.id);

          calendarEvents.push({
            id: sb.id + SHIFT_EVENT_POSTFIX,
            start: isAllDayEvent ? startOfDay(sbStart) : sbStart,
            end: isAllDayEvent ? endOfDay(sbStart) : canPassToNextDay ? sbEnd : sbStart,
            editable: isLoggedUserEvent && shiftEventType !== ShiftEventType.PAST_SHIFT,
            extendedProps: {
              type: 'SHIFT_BUCKET',
              shiftBucket: sb,
              shiftEventType,
              isAssigned: !isEventUnassigned,
              viewAsOwner: isLoggedUserEvent,
              eventViewerId: userId,
              hasAsapOffer: offers?.hasAsapOffer,
              hasAnyOffer: !!offers
            }
          });
        }
      }
    });

    setEvents(calendarEvents);
  }, [shiftBuckets, loggedUser, hasPermissionTo, planStatus, calendarView, bucketOffers]);

  useEffect(() => {
    const filteredEvents = events.filter(e => {
      if (!selectedDoctor) {
        return true;
      }
      if (!CalendarTypeGuard.isShiftBucketExtendedProps(e.extendedProps)) {
        return false;
      }

      if (selectedDoctor === loggedUser?.id) {
        return isEventOwner(e.extendedProps.shiftBucket, selectedDoctor, e.extendedProps.shiftEventType);
      } else {
        return isShiftOwner(e.extendedProps.shiftBucket, selectedDoctor, e.extendedProps.shiftEventType);
      }
    });

    setDoctorEvents(filteredEvents);
  }, [events, selectedDoctor, loggedUser]);

  return doctorEvents;
};

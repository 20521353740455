import { DayHeaderContentArg, MoreLinkContentArg, SlotLabelContentArg } from '@fullcalendar/core';
import { pxToRem } from '@medsi/mui-theme';
import { Box, Button } from '@mui/material';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { CalendarView } from 'shared/types/calendar';

interface CalendarViewConfiguration {
  slotDuration?: { hours: 1 };
  slotLabelContent?: (arg: SlotLabelContentArg) => JSX.Element;
  shouldShowEvents: boolean;
  dayHeaderContent?: (arg: DayHeaderContentArg) => string;
  moreLinkContent?: (arg: MoreLinkContentArg) => JSX.Element;
}

const getTwoDigitsTimeFormatLabelContent = (arg: SlotLabelContentArg) => (
  <Box width={pxToRem(64)} display="flex" justifyContent="center">
    {arg.text}
  </Box>
);

const getFullTimeFormatLabelContent = (arg: SlotLabelContentArg) => (
  <Box width={pxToRem(64)} display="flex" justifyContent="center">
    {format(arg.date, 'HH:mm')}
  </Box>
);

const getWeekDay = (arg: DayHeaderContentArg) => format(arg.date, 'EEE', { locale: pl }).toLocaleUpperCase();
const getWeekDayAndDayNumber = (arg: DayHeaderContentArg) => format(arg.date, 'EEE d', { locale: pl }).toLocaleUpperCase();
const getShortenedWeekDay = (arg: DayHeaderContentArg) => format(arg.date, 'EEEEE', { locale: pl }).toLocaleUpperCase();
const getShortenedWeekDayAndDayNumber = (arg: DayHeaderContentArg) => format(arg.date, 'EEEEE d', { locale: pl }).toLocaleUpperCase();

const desktopMoreLinkContent = (arg: MoreLinkContentArg) => (
  <Box width="100%" display="flex" justifyContent="center">
    <Button variant="text" color="primary">
      {arg.num} więcej
    </Button>
  </Box>
);

const mobileMoreLinkContent = (arg: MoreLinkContentArg) => (
  <Box width="100%" display="flex" justifyContent="center">
    +{arg.num}
  </Box>
);

export const useGetCalendarViewConfiguration = (calendarView: CalendarView): CalendarViewConfiguration => {
  const isDesktop = useIsDesktop();

  switch (calendarView) {
    case CalendarView.MULTI_MONTH_YEAR:
      return {
        slotDuration: undefined,
        slotLabelContent: undefined,
        shouldShowEvents: false,
        dayHeaderContent: undefined,
        moreLinkContent: undefined
      };
    case CalendarView.DAY_GRID_MONTH:
      return {
        slotDuration: undefined,
        slotLabelContent: undefined,
        shouldShowEvents: isDesktop,
        dayHeaderContent: isDesktop ? getWeekDay : getShortenedWeekDay,
        moreLinkContent: isDesktop ? desktopMoreLinkContent : mobileMoreLinkContent
      };
    case CalendarView.TIME_GRID_WEEK:
      return {
        slotDuration: { hours: 1 },
        slotLabelContent: getTwoDigitsTimeFormatLabelContent,
        shouldShowEvents: true,
        dayHeaderContent: isDesktop ? getWeekDayAndDayNumber : getShortenedWeekDayAndDayNumber,
        moreLinkContent: undefined
      };
    case CalendarView.TIME_GRID_DAY:
      return {
        slotDuration: { hours: 1 },
        slotLabelContent: getFullTimeFormatLabelContent,
        shouldShowEvents: true,
        dayHeaderContent: getWeekDayAndDayNumber,
        moreLinkContent: undefined
      };
    default:
      return {
        slotDuration: undefined,
        slotLabelContent: undefined,
        shouldShowEvents: false,
        dayHeaderContent: undefined,
        moreLinkContent: undefined
      };
  }
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';

type Props = {
  shiftIndex: number;
};

export const ShiftTitle = (props: Props) => (
  <Box display="flex" alignItems="center">
    <Typography variant="body3" sx={{ marginLeft: pxToRem(16), color: theme.palette.text.secondary }}>
      Dyżur {props.shiftIndex + 1}
    </Typography>
  </Box>
);

import { pxToRem } from '@medsi/mui-theme';
import { Box, Button, Card } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { useAcceptTermsMutation, useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useGetTermsQuery } from 'store/api/endpoints/termsEndpoints';

export const Terms = () => {
  // rtk
  const { data: terms } = useGetTermsQuery();
  const [accept, acceptanceStatus] = useAcceptTermsMutation();
  const { refetch } = useGetLoggedUserQuery();
  // other
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  useRequestStatusAlert(undefined, acceptanceStatus.isError, undefined, 'Nie udało się zaakceptować regulaminu');

  useEffect(() => {
    if (acceptanceStatus.isSuccess) {
      refetch()
        .unwrap()
        .then(() => {
          navigate(paths.dashboard.calendar, { replace: true });
        });
    }
  }, [acceptanceStatus, refetch, navigate]);

  if (!terms) {
    return <></>;
  }

  const dirtyHTML = terms.content;

  const sanitizedHTML = DOMPurify.sanitize(dirtyHTML, {
    RETURN_TRUSTED_TYPE: true,
    FORCE_BODY: true,
    ALLOWED_TAGS: ['html', 'head', 'style', 'body', 'p', 'span', 'div', 'br', 'li', 'ol'],
    ADD_ATTR: ['class', 'id']
  });

  const acceptTerms = async () => {
    accept();
  };

  const contentPadding = pxToRem(36);

  return (
    <Box p={{ xs: 0, md: 3 }}>
      <Card sx={theme => ({ width: '100%', borderRadius: isDesktop ? theme.borders.borderRadius.xxxl : 0 })}>
        <div
          style={{ padding: `${contentPadding} ${contentPadding} 0 ${contentPadding}` }}
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        ></div>
        <Box display="flex" justifyContent="flex-end" px={2} pb={2}>
          <Button size="large" variant="contained" color="primary" type="submit" onClick={acceptTerms}>
            Zaakceptuj
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

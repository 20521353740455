import { Alert, Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { theme } from '@medsi/mui-theme';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import { Loadable } from 'features/ui/loadable/Loadable';
import { useEffect } from 'react';
import { Offer } from 'shared/types/offer';
import { ShiftBuckets } from 'shared/types/shiftBucket';
import { User, Users } from 'shared/types/users';
import { findById } from 'shared/utils/commonUtils';
import { useGetFacilityByIdQuery } from 'store/api/endpoints/facilityEndpoints';
import { useCancelOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useGetUserByIdQuery } from 'store/api/endpoints/userEndpoint';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

interface SwapShiftDetailsProps {
  activeOffer: Offer;
}

export const SwapShiftDetails = (props: SwapShiftDetailsProps): JSX.Element => {
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const dispatch = useAppDispatch();
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const { data: activeFacility } = useGetFacilityByIdQuery(facilitySlice?.activeFacilityId ?? skipToken);
  const { data: recipient } = useGetUserByIdQuery(props.activeOffer.offerRequest?.recipient ?? skipToken);
  const { data: requester } = useGetUserByIdQuery(props.activeOffer.offerRequest?.requester ?? skipToken);
  const [cancelOffer, { isSuccess, isError, isLoading }] = useCancelOfferMutation();
  // other
  const { shiftBuckets } = useGetShiftBuckets();

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się anulować prośby wymiany dyżuru' }));
  }, [isError, dispatch]);

  useEffect(() => {
    isSuccess && dispatch(addAlert({ color: 'success', text: 'Anulowano prośbę wymiany dyżuru' }));
  }, [isSuccess, dispatch]);

  const onCancelAbsence = () => {
    props.activeOffer.id
      ? cancelOffer(props.activeOffer.id)
      : dispatch(addAlert({ color: 'error', text: 'Nie udało się anulować prośby wymiany dyżuru' }));
  };

  const cNeutral200 = theme.palette.neutral[200];
  const cGrey200 = theme.palette.grey[200];
  const cPrimary = theme.palette.primary.main;

  const elevation1 = theme.boxShadows.elevation1;
  const borderRadiusXxxl = theme.borders.borderRadius.xxxl;

  const { pxToRem } = theme.functions;

  const facilityName = activeFacility?.name;
  const wardName = activeWard?.name;
  const offeredShift = findById(shiftBuckets, props.activeOffer.offerRequest?.offeredShift);
  const wantedShift = findById(shiftBuckets, props.activeOffer.offerRequest?.wantedShift);
  const description = props.activeOffer.description;

  return (
    <Loadable loading={isLoading}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={3} pt={3}>
        <Alert severity="info" sx={{ width: '100%', borderRadius: borderRadiusXxxl, textAlign: 'start' }}>
          Prośba o wymianę dyżuru została wysłana. Czekaj na odpowiedź.
        </Alert>
        <Box
          width={1}
          textAlign={'start'}
          borderRadius={2}
          boxShadow={elevation1}
          p={3}
          sx={{
            backgroundColor: cGrey200
          }}
        >
          <Typography variant="body3" display={'block'}>
            Dyżur
          </Typography>
          <Typography variant="body3">{ShiftBuckets.getShiftBucketTimeLabel(offeredShift)}</Typography>
          <Typography variant="body1" pt={2}>
            {wardName}
          </Typography>
          <Typography variant="body1">{facilityName}</Typography>
          <Typography variant="body1" pt={2}>
            Wymiana z:
          </Typography>
          <Typography variant="body3">{Users.toString<User>(recipient)}</Typography>
          <Typography variant="body1" pt={2}>
            Wymiana na dyżur
          </Typography>
          <Typography variant="body3">{ShiftBuckets.getShiftBucketDayTimeLabel(wantedShift)}</Typography>
        </Box>
        {description && (
          <Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" pb={1}>
              <Typography variant="h6">Notatki:</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              p={1}
              borderLeft={`2px solid ${cPrimary}`}
              sx={{
                backgroundColor: cNeutral200
              }}
            >
              <Typography variant="caption" pb={pxToRem(6)}>
                {Users.toString<User>(requester)}
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
          </Box>
        )}

        <Box py={2} alignSelf={'flex-end'}>
          <Button variant="text" color="primary" onClick={onCancelAbsence}>
            Anuluj wymianę dyżuru
          </Button>
        </Box>
      </Box>
    </Loadable>
  );
};

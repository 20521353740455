import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store/store';

export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      (process.env.REACT_APP_PROTOCOL ?? 'UNKNOWN_APP_PROTOCOL') +
      (process.env.REACT_APP_SERVER_HOST ?? 'UNKNOWN HOST') +
      (process.env.REACT_APP_API_URL ?? 'UNKNOWN_API_URL'),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accessToken;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: builder => ({}),
  tagTypes: ['Facility', 'Ward', 'PredefinedFacility', 'CalendarEvent', 'User', 'ShiftBucket', 'Offer', 'Notification', 'ShiftPlan']
});

export const { reducer: apiReducer, middleware: apiMiddleware, reducerPath: apiReducerPath } = apiSlice;

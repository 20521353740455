import { DayCellContentArg, EventContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { theme } from '@medsi/mui-theme';
import { Card } from '@mui/material';
import { format, isSameDay } from 'date-fns';
import { pl } from 'date-fns/locale';
import { CalendarMobileDayContent } from 'features/ui/calendar/calendarMobileDayContent';
import { useGetCalendarViewConfiguration } from 'features/ui/calendar/hooks/useGetCalendarViewConfiguration';
import { MutableRefObject, useEffect, useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useReload } from 'shared/hooks/useReload';
import { CalendarEventData, CalendarExtendedProps, CalendarView } from 'shared/types/calendar';
import { useAppSelector } from 'store/hooks';
import { CalendarEventListPopper } from './calendarEventListPopper';
import { EventContentRenderer } from './eventContentRenderer';
import { useFullCalendarSwipeable } from './hooks/useFullCalendarSwipeable';
import { CalendarStylesOverrides } from './style-overrides/calendarStylesOverrides';

type Props = {
  events: CalendarEventData[];
  calendarRef: MutableRefObject<FullCalendar | null>;
  workingDays: string[];
  toolbar: JSX.Element;
  availableViews: CalendarView[];
  initialDate: string;
  currentView: CalendarView;
  showNonCurrentDates: boolean;
  swipeable?: boolean;
  getDayCellClassNames: (arg: DayCellContentArg) => string;
  onEventClick?: (id: string) => void;
  onDateClick?: (arg: DateClickArg) => void;
  onRefresh: () => void;
};

export const Calendar = (props: Props): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [eventListPopperAnchor, setEventListPopperAnchor] = useState<HTMLElement | null>(null);
  // redux
  const isSidenavHidden = useAppSelector(state => state.sidenavSlice.isSidenavHidden);
  // other
  const calendarViewConfiguration = useGetCalendarViewConfiguration(props.currentView);
  const isDesktop = useIsDesktop();
  // causes component reevaluation after interactions with calendar by its api
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reload = useReload();
  const handlers = useFullCalendarSwipeable(props.calendarRef);

  const isMobileMonthView = !isDesktop && props.currentView === CalendarView.DAY_GRID_MONTH;

  useEffect(() => {
    props.calendarRef?.current?.doResize();
    setTimeout(() => {
      reload();
      // calendar has to wait with resizing until sidebar collapse animation is not finished
    }, theme.transitions.duration.shorter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidenavHidden, props.calendarRef]);

  useEffect(() => {
    // firstly styles need to be applied (component with proper currentCalendarView rendered), after that api view can be changed
    const calendarApi = props.calendarRef?.current?.getApi();
    // workaround to avoid warnings "flushSync was called from inside a lifecycle method" https://github.com/fullcalendar/fullcalendar/issues/7448
    setTimeout(() => {
      switch (props.currentView) {
        case CalendarView.MULTI_MONTH_YEAR:
          calendarApi?.changeView(CalendarView.MULTI_MONTH_YEAR);
          break;
        case CalendarView.DAY_GRID_MONTH:
          calendarApi?.changeView(CalendarView.DAY_GRID_MONTH);
          break;
        case CalendarView.TIME_GRID_WEEK:
          calendarApi?.changeView(CalendarView.TIME_GRID_WEEK);
          break;
        case CalendarView.TIME_GRID_DAY:
          calendarApi?.changeView(CalendarView.TIME_GRID_DAY);
          break;
        default:
          calendarApi?.changeView(CalendarView.MULTI_MONTH_YEAR);
          break;
      }
    }, 1);
  }, [props.currentView, props.calendarRef]);

  const renderEventContent = (eventInfo: EventContentArg) => {
    const extendedProps = eventInfo.event?.extendedProps as CalendarExtendedProps;
    if (!extendedProps) {
      return null;
    }

    return <EventContentRenderer extendedProps={extendedProps} calendarView={props.currentView} />;
  };

  const onDateClick = (arg: DateClickArg) => {
    setSelectedDate(arg.date);
    const eventExistsForDate = props.events.some(e => isSameDay(e.start, arg.date));

    const isWorkingDay = props.workingDays.includes(format(arg.date, 'yyyy-MM-dd'));
    const isYearView = arg.view.type === CalendarView.MULTI_MONTH_YEAR;

    if ((isYearView || isMobileMonthView) && isWorkingDay && eventExistsForDate) {
      arg.dayEl.classList.add('selected-date');
      setEventListPopperAnchor(arg.dayEl);
      return;
    }

    props.onDateClick?.(arg);
  };

  const onClosePopper = () => {
    setEventListPopperAnchor((prev: HTMLElement | null) => {
      prev && prev.classList.remove('selected-date');
      return null;
    });
    setSelectedDate(null);
  };

  const swipeableHandlers = props.swipeable ? { ...handlers } : {};

  return (
    <Card
      sx={theme => ({ height: '100%', width: '100%', borderRadius: isDesktop ? theme.borders.borderRadius.xxxl : 0 })}
      {...swipeableHandlers}
    >
      {props.toolbar}
      <CalendarEventListPopper
        events={props.events}
        popperAnchor={eventListPopperAnchor}
        popperDate={selectedDate}
        onClosePopperCallback={onClosePopper}
        onEventClickCallback={e => props.onEventClick?.(e.id)}
      />
      <CalendarStylesOverrides calendarView={props.currentView}>
        <FullCalendar
          ref={props.calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
          initialView={CalendarView.DAY_GRID_MONTH}
          initialDate={props.initialDate}
          events={calendarViewConfiguration.shouldShowEvents ? props.events : []}
          eventContent={renderEventContent}
          eventClick={e => props.onEventClick?.(e.event.id)}
          dateClick={onDateClick}
          dayHeaderContent={calendarViewConfiguration.dayHeaderContent}
          dayCellContent={(arg: DayCellContentArg) =>
            isMobileMonthView ? <CalendarMobileDayContent events={props.events} dayCellContentArg={arg} /> : <>{arg.dayNumberText}</>
          }
          firstDay={1}
          height={'100%'}
          locale={pl.code}
          eventStartEditable={false}
          eventAllow={() => false}
          dayCellClassNames={props.getDayCellClassNames}
          headerToolbar={false}
          eventDurationEditable={false}
          fixedWeekCount={false}
          datesSet={() => {
            reload();
            props.onRefresh();
          }}
          dayMaxEventRows={3}
          moreLinkContent={calendarViewConfiguration.moreLinkContent}
          slotLabelContent={calendarViewConfiguration.slotLabelContent}
          defaultTimedEventDuration={{ minutes: 1 }}
          showNonCurrentDates={props.showNonCurrentDates}
          allDaySlot={false}
          slotDuration={calendarViewConfiguration.slotDuration}
        />
      </CalendarStylesOverrides>
    </Card>
  );
};

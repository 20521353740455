import { DayCellContentArg } from '@fullcalendar/core';
import { pxToRem, theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';
import { isSameDay } from 'date-fns';
import { EventContentRenderer } from 'features/ui/calendar/eventContentRenderer';
import React from 'react';

import { CalendarEventData } from 'shared/types/calendar';

type Props = {
  events: CalendarEventData[];
  dayCellContentArg: DayCellContentArg;
};

export const CalendarMobileDayContent = (props: Props) => {
  const eventsOnDate = props.events.filter(e => isSameDay(e.start, props.dayCellContentArg.date));

  const eventList = eventsOnDate.map(e => (
    <React.Fragment key={e.id}>
      <EventContentRenderer extendedProps={e.extendedProps} />
    </React.Fragment>
  ));

  const constructContent = (eventList: JSX.Element[]) => {
    if (eventList.length > 3) {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {eventList[0]}
          {eventList[1]}
          <Typography variant="body1" fontSize={pxToRem(10)} fontWeight={theme.typography.fontWeightBold}>
            {<>+{eventList.length - 2}</>}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          {eventList}
        </Box>
      );
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" className={props.dayCellContentArg.isToday ? 'fc-daygrid-today-override' : ''}>
        {props.dayCellContentArg.dayNumberText}
      </Box>

      {constructContent(eventList)}
    </Box>
  );
};

import { theme } from '@medsi/mui-theme';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { ShiftOffer } from './shiftOffer';

type Props = {
  bucketId: string;
};

export const ShiftOfferList = (props: Props): JSX.Element => {
  const bucketOffers = useAppSelector(state => state.offerSlice.bucketOffers.find(bo => bo.bucketId === props.bucketId));

  if (!bucketOffers || _.isEmpty(bucketOffers.offers)) {
    return <></>;
  }

  const offersCopy = [...bucketOffers.offers];
  var asapOffers = _.remove(offersCopy, o => o.type === 'ASAP_HAND_OVER');

  return (
    <Grid display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="caption" color={theme.palette.text.secondary}>
        Oferty:
      </Typography>
      {asapOffers.map(o => (
        <React.Fragment key={o.id}>
          <ShiftOffer offer={o} isAsap={true} />
        </React.Fragment>
      ))}
      {offersCopy.map(o => (
        <React.Fragment key={o.id}>
          <ShiftOffer offer={o} isAsap={false} />
        </React.Fragment>
      ))}
    </Grid>
  );
};

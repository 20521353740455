import { Grid } from '@mui/material';
import { useState } from 'react';
import { Doctor, ShiftAssignment } from 'shared/types/planning';
import { User } from 'shared/types/users';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { ShiftCandidate } from './shiftCandidate';

type Props = {
  shiftAssignment?: ShiftAssignment;
};

const defaultUsers: User[] = [];

export const ShiftCandidates = (props: Props): JSX.Element => {
  const [assignedDoctor, setAssignedDoctor] = useState<Doctor | undefined | null>(props.shiftAssignment?.doctor);
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  const bucketId = props.shiftAssignment?.shift?.shiftBucketId;

  if (!bucketId) {
    return <></>;
  }

  return (
    <Grid
      container
      sx={{
        maxHeight: '40vh',
        overflow: 'auto'
      }}
    >
      {assignedDoctor ? (
        <ShiftCandidate doctor={assignedDoctor} bucketId={bucketId} onSetDoctorCallback={setAssignedDoctor} isAssigned={true} />
      ) : (
        <Grid display="flex" flexDirection="column" width="100%" pb={1}>
          {users.map(u => (
            <ShiftCandidate
              key={u.id}
              doctor={{
                id: u.id,
                firstName: u.firstName,
                lastName: u.lastName
              }}
              bucketId={bucketId}
              onSetDoctorCallback={setAssignedDoctor}
              isAssigned={false}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

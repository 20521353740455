import { pxToRem, rgba, theme } from '@medsi/mui-theme';
import { Box, Grid, Typography } from '@mui/material';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { CalendarView, ShiftEventType } from 'shared/types/calendar';
import { ShiftBucket } from 'shared/types/shiftBucket';
import { User, Users } from 'shared/types/users';
import { findById } from 'shared/utils/commonUtils';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { useAppSelector } from 'store/hooks';
import ruleEngine from './rule';
import { getPreferenceIcon } from './utils';

type Props = {
  shiftBucket: ShiftBucket;
  eventType: ShiftEventType;
  calendarView?: CalendarView;
  isAssigned: boolean;
  isOwnerEvent: boolean;
  eventViewerId: string;
};

const defaultUsers: User[] = [];

export const DesktopShiftBucketEventContent = (props: Props) => {
  // redux
  const bucketOffers = useAppSelector(state => state.offerSlice.bucketOffers.find(bo => bo.bucketId === props.shiftBucket?.id));
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  // other
  const isDesktop = useIsDesktop();

  const { isAssigned, shiftBucket, eventType, isOwnerEvent } = props;

  const ownerPreference = shiftBucket.preferences.find(p => p.userId === props.eventViewerId);
  const hasAsapOffer = !!bucketOffers?.hasAsapOffer;
  const hasAnyOffer = !!bucketOffers;

  const content = ruleEngine.getContent(
    {
      isOwner: isOwnerEvent,
      type: eventType,
      isAssigned,
      hasAsapOffer,
      hasAnyOffer
    },
    { shiftBucket, userId: props.eventViewerId }
  );

  if (ownerPreference) {
    content?.icons.push(getPreferenceIcon(ownerPreference.type));
  }

  const isMobile = !isDesktop;
  const isEventContentHidden = isMobile && props.calendarView === CalendarView.TIME_GRID_WEEK;
  const areAssigneesVisible = props.calendarView === CalendarView.TIME_GRID_DAY || props.calendarView === CalendarView.TIME_GRID_WEEK;
  const assignedPeople = props.shiftBucket.doctors.map(userId => Users.toString<User>(findById<User>(users, userId)));

  if (!content) {
    return <></>;
  }

  return (
    <Box
      key={props.shiftBucket.id}
      width="100%"
      height="100%"
      sx={{
        backgroundColor: theme.palette.white.main,
        borderRadius: theme.borders.borderRadius.lg
      }}
      my={pxToRem(2)}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        minHeight={pxToRem(32)}
        justifyContent="flex-start"
        sx={{
          backgroundColor: content.backgroundColor,
          '&:hover': { backgroundColor: rgba(content.backgroundColor, 0.8) },
          border: `2px solid ${content.borderColor}`,
          borderRadius: theme.borders.borderRadius.lg,
          cursor: 'pointer'
        }}
      >
        <Grid display="flex" flexDirection="column" p={0.5} width="100%">
          {!isEventContentHidden && (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" color={content.color}>
                <Typography variant="caption" fontWeight={theme.typography.fontWeightBold} sx={{ textWrap: 'wrap' }}>
                  {content.label}
                </Typography>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {content.icons}
                </Box>
              </Box>

              {areAssigneesVisible && (
                <Box pt={2}>
                  {assignedPeople.map(assignee => (
                    <Typography variant="body1" color={content.color} fontWeight={theme.typography.fontWeightBold} key={assignee}>
                      {assignee}
                    </Typography>
                  ))}
                </Box>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

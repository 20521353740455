import FullCalendar from '@fullcalendar/react';
import { RefObject } from 'react';
import { LEFT, RIGHT, useSwipeable } from 'react-swipeable';
import { fullCalendarSwiped } from 'shared/observers/observers';

export const useFullCalendarSwipeable = (calendarRef?: RefObject<FullCalendar>) => {
  return useSwipeable({
    onSwiped: eventData => {
      if (eventData.dir === LEFT) {
        calendarRef?.current?.getApi().next();
        fullCalendarSwiped.notify();
      } else if (eventData.dir === RIGHT) {
        calendarRef?.current?.getApi().prev();
        fullCalendarSwiped.notify();
      }
      eventData.event.stopPropagation();
    }
  });
};

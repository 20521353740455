import { ShiftPreferenceDialog } from './shift-preference/shiftPreferenceDialog';

interface CalendarDialogProps {
  userId: string;
  date: Date | null;
  isOpen: boolean;
  onClose: () => void;
}

export const CalendarDateDialog = (props: CalendarDialogProps): JSX.Element => {
  if (!props.date) {
    return <></>;
  }
  return <ShiftPreferenceDialog date={props.date} isOpen={props.isOpen} onClose={props.onClose} userId={props.userId} />;
};

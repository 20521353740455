import { pxToRem } from '@medsi/mui-theme';
import { Box, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { FormError } from '../formError/FormError';
import { PhoneNumberInput } from '../masked-inputs/phoneNumberInput';
import { PostalCodeInput } from '../masked-inputs/postalCodeInput';

export const AddressAndContactFormFields = () => {
  const { control } = useFormContext();

  return (
    <Box display="flex" flexDirection="column" gap={pxToRem(20)} pt={pxToRem(20)}>
      <Controller
        name="street"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Ulica" required fullWidth />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
        rules={{ required: validationMessage.required }}
      />
      <Controller
        name="building"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Nr budynku" required fullWidth />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
        rules={{ required: validationMessage.required }}
      />
      <Controller
        name="city"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Miasto" required fullWidth />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
        rules={{ required: validationMessage.required }}
      />
      <Controller
        name="postalCode"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField
                InputProps={{
                  inputComponent: PostalCodeInput as any
                }}
                {...field}
                className={fieldState.invalid ? 'error' : ''}
                label="Kod pocztowy"
                required
                fullWidth
              />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
        rules={{ required: validationMessage.required }}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField
                {...field}
                InputProps={{
                  inputComponent: PhoneNumberInput
                }}
                className={fieldState.invalid ? 'error' : ''}
                label="Numer telefonu placówki"
                fullWidth
              />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="E-mail placówki" fullWidth />
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          );
        }}
        rules={{
          validate: validateEmail
        }}
      />
    </Box>
  );
};

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import datePickerStyles from '../calendar/styles/datePickerStyles';
import { useGetInputAdornmentRenderer } from 'shared/hooks/useGetInputAdornment';

interface CalendarDatePickerProps {
  value?: string;
  label?: string;
  min?: Date;
  max?: Date;
  onChangeCallback: (value: string) => void;
  shouldDisableDateCallback?: (date: Date) => boolean;
}

export const CalendarDatePicker = (props: CalendarDatePickerProps): JSX.Element => {
  const inputAdornment = useGetInputAdornmentRenderer(<CalendarTodayOutlinedIcon color="primary" fontSize="small" />);

  const onChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      props.onChangeCallback(format(date, 'yyyy-MM-dd'));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <DatePicker
        minDate={props.min}
        maxDate={props.max}
        label={props.label ?? undefined}
        disablePast={true}
        value={props.value ? new Date(props.value) : null}
        shouldDisableDate={date => props.shouldDisableDateCallback?.(date) ?? false}
        onChange={onChange}
        sx={{ textAlign: 'start', width: 1, ...datePickerStyles }}
        slots={{ openPickerIcon: CalendarTodayOutlinedIcon, toolbar: undefined }}
        slotProps={{
          openPickerIcon: { color: 'primary', fontSize: 'small' },
          ...inputAdornment
        }}
      />
    </LocalizationProvider>
  );
};

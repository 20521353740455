import _ from 'lodash';
import { DateBuckets, Justification, JustificationType } from 'shared/types/planning';

const ERROR_MESSAGE = 'Została złamana nieznana reguła';

export class JustificationFilter {
  private justifications: Justification[];
  private doctorId: string | undefined;
  private bucketId: string | undefined;
  private type: string | undefined;

  constructor(justifications: Justification[]) {
    this.justifications = justifications;
  }

  public withDoctor(doctorId: string): JustificationFilter {
    if (this.doctorId) {
      console.warn(`Doctor id has been already provided: ${this.doctorId} and cannot be overwritten`);
      return this;
    }
    this.doctorId = doctorId;
    this.justifications = this.justifications.filter(j => j.doctorId === doctorId);
    return this;
  }

  public withBucket(bucketId: string): JustificationFilter {
    if (this.bucketId) {
      console.warn(`Bucket id has been already provided: ${this.bucketId} and cannot be overwritten`);
      return this;
    }
    this.bucketId = bucketId;
    this.justifications = this.justifications.filter(
      j =>
        j.consecutiveDateBuckets?.find(sequence => sequence.some(dayBuckets => dayBuckets.buckets?.includes(bucketId))) ||
        j.shiftBucketId === bucketId ||
        j.shiftBucketIds?.includes(bucketId) ||
        j.consecutiveBuckets?.find(sequence => sequence.some(id => id === bucketId))
    );
    return this;
  }

  public withType(type: string): JustificationFilter {
    if (this.type) {
      console.warn(`Type has been already provided: ${this.type} and cannot be overwritten`);
    }
    this.type = type;
    this.justifications = this.justifications.filter(j => j.type === type);
    return this;
  }

  public getJustifications(): Justification[] {
    return this.justifications;
  }

  public stringify(): string[] {
    return this.justifications.map(j => {
      switch (j?.type) {
        case JustificationType.WORKLOAD_FAIRNESS_CONSTRAINT:
          return this.handleWorkloadFairnessJustification();

        case JustificationType.WORKLOAD_PERSONAL_LIMIT_CONSTRAINT:
          return this.handlePersonalLimitJustification();

        case JustificationType.CONSECUTIVE_DAYS_CONSTRAINT:
          return this.handleConsecutiveDaysJustification(j);

        case JustificationType.CONSECUTIVE_WEEKENDS_CONSTRAINT:
          return this.handleConsecutiveWeekendsJustification(j);

        case JustificationType.CONSECUTIVE_WEEKEND_DAYS_CONSTRAINT:
          return this.handleConsecutiveWeekendDaysJustification(j);

        case JustificationType.CONSECUTIVE_SHIFTS_CONSTRAINT:
          return this.handleConsecutiveShiftsJustification(j);

        default:
          return ERROR_MESSAGE;
      }
    });
  }

  private handleWorkloadFairnessJustification(): string {
    return 'Dyżury zostały przypisane z wyraźnym brakiem balansu';
  }

  private handleConsecutiveDaysJustification(j: Justification): string {
    const consecutiveGroup = this.getConsecutiveDateBucketsGroup(j);
    const amount = consecutiveGroup.length;
    if (consecutiveGroup.length < 2) {
      return ERROR_MESSAGE;
    }

    return `Lekarz jest przypisany do dyżuru ${amount} dni z rzędu`;
  }

  private handleConsecutiveWeekendsJustification(j: Justification): string {
    if (!_.isFinite(j.consecutiveWeekends) || j.consecutiveWeekends === 0) {
      return ERROR_MESSAGE;
    }

    let conjugation = (j.consecutiveWeekends ?? 0) + 1 > 4 ? 'weekendów' : 'weekendy';

    return `Lekarz jest przypisany do dyżuru ${j.consecutiveWeekends! + 1} ${conjugation} z rzędu`;
  }

  private handleConsecutiveWeekendDaysJustification(j: Justification): string {
    const consecutiveGroup = this.getConsecutiveDateBucketsGroup(j);
    if (consecutiveGroup.length !== 2) {
      return ERROR_MESSAGE;
    }

    return `Lekarz jest przypisany do dyżuru 2 dni weekendowe z rzędu`;
  }

  private handleConsecutiveShiftsJustification(j: Justification): string {
    const consecutiveGroup = _.find(j.consecutiveBuckets, sequence => sequence.some(id => this.bucketId === id)) ?? [];
    if (consecutiveGroup.length < 2) {
      return ERROR_MESSAGE;
    }

    return `Lekarz jest przypisany do ${consecutiveGroup.length} dyżurów z rzędu`;
  }

  private handlePersonalLimitJustification(): string {
    return 'Został przekroczony personalny limit dyżurów lekarza';
  }

  private getConsecutiveDateBucketsGroup = (j: Justification): DateBuckets[] => {
    return (
      _.find(j.consecutiveDateBuckets, sequence =>
        sequence.some(dateBuckets => this.bucketId && dateBuckets?.buckets?.includes(this.bucketId))
      ) ?? []
    );
  };
}

import { pxToRem, theme } from '@medsi/mui-theme';
import { Grid, Hidden, MobileStepper, Tab, Tabs } from '@mui/material';
import { Action, usePermission } from 'features/rbac/permissions';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';

enum SettingsTab {
  USER = 'Użytkownik',
  SHIFT = 'Kalendarz dyżurów',
  STAFF = 'Personel',
  ROBOT = 'Wirtualni użytkownicy',
  WARD = 'Oddział'
}
const settingsTabValues = Object.values(SettingsTab);

export const Settings = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const navigate = useNavigate();
  const { hasPermissionTo } = usePermission();

  const { palette, borders } = theme;

  useEffect(() => {
    switch (settingsTabValues[currentTabIndex]) {
      case SettingsTab.USER:
        navigate(paths.dashboard.settings.user);
        break;
      case SettingsTab.SHIFT:
        navigate(paths.dashboard.settings.shift);
        break;
      case SettingsTab.STAFF:
        navigate(paths.dashboard.settings.staff);
        break;
      case SettingsTab.ROBOT:
        navigate(paths.dashboard.settings.robot);
        break;
      case SettingsTab.WARD:
        navigate(paths.dashboard.settings.ward);
        break;
    }
  }, [currentTabIndex, navigate]);

  return (
    <Grid display={'grid'} rowGap={{ xs: 0, md: 1.5 }}>
      {hasPermissionTo(Action.WARD_HEAD_SETTINGS) && (
        <>
          <Hidden smUp implementation="css">
            <MobileStepper
              backButton={''}
              nextButton={''}
              steps={settingsTabValues.length}
              activeStep={currentTabIndex}
              position="static"
              sx={{ justifyContent: 'center' }}
            />
          </Hidden>
          <Tabs
            value={currentTabIndex}
            onChange={(_, index: number) => setCurrentTabIndex(index)}
            sx={{
              py: { xs: 0.5, md: 3 },
              px: { xs: 1, md: 3 },
              ml: { xs: 0.5, md: 0 },
              mt: { xs: pxToRem(10), md: 0 },
              backgroundColor: palette.neutralExpand[200],
              borderRadius: { xs: borders.borderRadius.lg, md: borders.borderRadius.xxxl }
            }}
            variant="scrollable"
            scrollButtons={false}
          >
            {settingsTabValues.map((tab, index) => (
              <Tab label={tab} value={index} key={tab} />
            ))}
          </Tabs>
        </>
      )}
      <Outlet />
    </Grid>
  );
};

import { theme } from '@medsi/mui-theme';
import { Alert } from '@mui/material';
import { useGetMyActiveOffer } from 'features/calendar/hooks/useGetMyActiveOffer';
import { Loadable } from 'features/ui/loadable/Loadable';
import { AbsenceReportCreator } from './absenceReportCreator';
import { AbsenceReportDetails } from './absenceReportDetails';

interface AbsenceReportTabProps {
  bucketId: string;
}

export const AbsenceReportTab = (props: AbsenceReportTabProps): JSX.Element => {
  const { isLoading, activeOffer } = useGetMyActiveOffer(props.bucketId);

  const borderRadiusXxxl = theme.borders.borderRadius.xxxl;

  let content;

  if (!activeOffer) {
    content = <AbsenceReportCreator bucketId={props.bucketId} />;
  } else if (activeOffer.type === 'ASAP_HAND_OVER') {
    content = <AbsenceReportDetails activeOffer={activeOffer} />;
  } else {
    content = (
      <Alert severity="warning" sx={{ width: '100%', borderRadius: borderRadiusXxxl, textAlign: 'start', my: 3 }}>
        Zgłoszenie nieobecności jest niemożliwe. Została wysłana prośba o wymianę dyżuru.
      </Alert>
    );
  }

  return <Loadable loading={isLoading}>{content}</Loadable>;
};

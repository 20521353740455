import { DayCellContentArg } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { pxToRem } from '@medsi/mui-theme';
import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import { DesktopMainCalendarToolbar } from 'features/calendar/toolbar/desktopMainCalendarToolbar';
import { MobileMainCalendarToolbar } from 'features/calendar/toolbar/mobileMainCalendarToolbar';
import { BOTTOM_BAR_HEIGHT_PX } from 'features/dashboard/sidenav/bottomBar';
import { ShiftPlanStatus, useGetShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useReload } from 'shared/hooks/useReload';
import { CalendarEventData, CalendarView } from 'shared/types/calendar';
import { isAfterNextMonth, isInNextMonth } from 'shared/utils/calendarUtils';
import { findById } from 'shared/utils/commonUtils';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';
import { Calendar } from '../ui/calendar/calendar';
import { CalendarSpeedDial } from '../ui/calendar/calendarSpeedDial';
import { CalendarDateDialog } from './dialogs/calendarDateDialog';
import { CalendarEventDialog } from './dialogs/calendarEventDialog';
import { useConstructEventData } from './hooks/useConstructEventData';
import { useDateOffers } from './hooks/useDateOffers';
import { useGetShiftBuckets } from './hooks/useGetShiftBuckets';

const mobileAvailableVies = [CalendarView.DAY_GRID_MONTH, CalendarView.TIME_GRID_DAY, CalendarView.TIME_GRID_WEEK];
const desktopAvailableVies = [
  CalendarView.DAY_GRID_MONTH,
  CalendarView.MULTI_MONTH_YEAR,
  CalendarView.TIME_GRID_DAY,
  CalendarView.TIME_GRID_WEEK
];

export const MainCalendar = () => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventData | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [isDateDialogOpen, setIsDateDialogOpen] = useState(false);
  const [currentCalendarView, setCurrentCalendarView] = useState<CalendarView>(CalendarView.DAY_GRID_MONTH);
  const [currentDoctor, setCurrentDoctor] = useState<string>('');
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  const planStatus = useGetShiftPlanStatus(shiftPlan);
  // other
  const { shiftBuckets } = useGetShiftBuckets();
  const events = useConstructEventData(currentCalendarView, shiftBuckets, currentDoctor);
  const workingDays = useMemo(() => shiftBuckets.map(sb => format(new Date(sb.start), 'yyyy-MM-dd')), [shiftBuckets]);
  const { datesWithAsapOffers, datesWithAnyOffers } = useDateOffers(events);
  const isDesktop = useIsDesktop();
  const reload = useReload();

  const calendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    setSelectedEvent(prev => findById<CalendarEventData>(events, prev?.id) ?? null);
  }, [events]);

  const onEventClick = (id: string) => {
    setSelectedEvent(events.find(event => event.id === id) ?? null);
    setIsEventDialogOpen(true);
  };

  const onDateClick = (arg: DateClickArg) => {
    setSelectedDate(arg.date);

    const isPreferenceDay = (isInNextMonth(arg.date) && planStatus !== ShiftPlanStatus.ACCEPTED) || isAfterNextMonth(arg.date);

    if (isPreferenceDay) {
      setIsDateDialogOpen(true);
      return;
    }
  };

  const getDayCellClassNames = (arg: DayCellContentArg) => {
    const date = format(arg.date, 'yyyy-MM-dd');
    const isFreeDay = !workingDays?.includes(date);
    const hasAsapOffer = datesWithAsapOffers.includes(date);
    const hasAnyOffer = datesWithAnyOffers.includes(date);
    return `${isFreeDay ? 'date-is-free' : ''} ${hasAsapOffer ? 'date-has-asap-offer' : hasAnyOffer ? 'date-has-any-offer' : ''}`;
  };

  const onAsideCalendarChange = (date: Date) => {
    calendarRef?.current?.getApi().gotoDate(date);
  };

  const onCurrentDoctorChange = (doctor: string) => {
    setCurrentDoctor(doctor);
  };

  if (!loggedUser?.id) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} height="100%" p={{ xs: 0, md: 3 }} gap={{ xs: 0, md: pxToRem(20) }}>
      <CalendarEventDialog
        eventData={selectedEvent}
        isOpen={isEventDialogOpen}
        onClose={() => setIsEventDialogOpen(false)}
        userId={loggedUser.id}
      />
      <CalendarDateDialog date={selectedDate} isOpen={isDateDialogOpen} onClose={() => setIsDateDialogOpen(false)} userId={loggedUser.id} />
      <Calendar
        currentView={currentCalendarView}
        toolbar={
          isDesktop ? (
            <DesktopMainCalendarToolbar
              calendarRef={calendarRef}
              onCalendarViewChangeCallback={setCurrentCalendarView}
              availableViews={desktopAvailableVies}
              onChangeEventsOwnerCallback={onCurrentDoctorChange}
            />
          ) : (
            <MobileMainCalendarToolbar
              onChangeEventsOwnerCallback={onCurrentDoctorChange}
              availableViews={mobileAvailableVies}
              calendarRef={calendarRef}
              onCalendarViewChangeCallback={setCurrentCalendarView}
              calendarView={currentCalendarView}
              onDateSelectedCallback={onAsideCalendarChange}
            />
          )
        }
        events={events}
        calendarRef={calendarRef}
        workingDays={workingDays}
        availableViews={isDesktop ? desktopAvailableVies : mobileAvailableVies}
        getDayCellClassNames={getDayCellClassNames}
        onEventClick={onEventClick}
        onDateClick={onDateClick}
        initialDate={format(new Date(), 'yyyy-MM-dd')}
        showNonCurrentDates
        swipeable
        onRefresh={reload}
      />
      <CalendarSpeedDial userId={loggedUser.id} position={{ bottom: isDesktop ? 16 : BOTTOM_BAR_HEIGHT_PX + 32, right: 16 }} />
    </Box>
  );
};

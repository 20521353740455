import { theme } from '@medsi/mui-theme';
import { Box, Button, TextField, Typography } from '@mui/material';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { pl } from 'date-fns/locale';
import { ConfirmationDialog } from 'features/ui/dialogs/ConfirmationDialog';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Preference, ShiftBucket } from 'shared/types/shiftBucket';
import { getShiftBucketsStartingBetweenDates } from 'shared/utils/calendarUtils';
import { useUpdateShiftPreferencesMutation } from 'store/api/endpoints/shiftBucketEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { useStylesDatePicker } from '../hooks/useStylesDatePicker';
import { PreferencesStateOnDay } from '../shiftPreferenceDialog';

interface Props {
  userId: string;
  date: Date;
  isEditable: boolean;
  dayShiftBuckets: ShiftBucket[];
  allShiftBuckets: ShiftBucket[];
  preferencesStateOnDay: PreferencesStateOnDay;
  onPreferenceUpdated: VoidFunction;
}

export interface HolidaysFormState {
  startDate: Date;
  endDate: Date;
  note: string;
}

const initialFormState = (date: Date): HolidaysFormState => ({ startDate: date, endDate: date, note: '' });

export const HolidaysPreferenceForm = (props: Props): JSX.Element => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [originalFormState, setOriginalFormState] = useState<HolidaysFormState>(initialFormState(props.date));
  const [currentFormState, setCurrentFormState] = useState<HolidaysFormState>(initialFormState(props.date));
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const dispatch = useAppDispatch();
  // rtk
  const [updatePreferences, { isSuccess, isError }] = useUpdateShiftPreferencesMutation();
  // other
  const classes = useStylesDatePicker();
  const isDirty = useMemo(() => !_.isEqual(originalFormState, currentFormState), [originalFormState, currentFormState]);
  const maxDate = useMemo(() => {
    const lastBucket = _.last([...props.allShiftBuckets].sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()));
    return lastBucket ? endOfMonth(new Date(lastBucket?.start)) : null;
  }, [props.allShiftBuckets]);

  useEffect(() => {
    if (props.preferencesStateOnDay !== PreferencesStateOnDay.HOLIDAY) {
      setOriginalFormState(initialFormState(props.date));
      setCurrentFormState(initialFormState(props.date));
    } else {
      const note = props.dayShiftBuckets.flatMap(dsb => dsb.preferences).find(p => p.userId === props.userId)?.note ?? '';
      setOriginalFormState({ startDate: props.date, endDate: props.date, note });
      setCurrentFormState({ startDate: props.date, endDate: props.date, note });
    }
  }, [props.dayShiftBuckets, props.preferencesStateOnDay, props.date, props.userId]);

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się dodać preferencji' }));
  }, [isError, dispatch]);

  const { onPreferenceUpdated } = props;
  useEffect(() => {
    isSuccess && onPreferenceUpdated();
  }, [isSuccess, onPreferenceUpdated]);

  const changeRangeDates = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setCurrentFormState(prev => ({ ...prev, startDate: start, endDate: end }));
  };

  const onNoteChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFormState(prev => ({ ...prev, note: event.target.value }));
  };

  const addPreferences = () => {
    const bucketIdPreferenceMap = new Map<string, Preference>();
    getShiftBucketsStartingBetweenDates(
      props.allShiftBuckets,
      startOfDay(currentFormState.startDate),
      endOfDay(currentFormState.endDate ?? currentFormState.startDate)
    ).forEach(shiftBucket => {
      shiftBucket.id &&
        bucketIdPreferenceMap.set(shiftBucket.id, {
          userId: props.userId,
          type: 'HOLIDAY',
          note: currentFormState.note,
          accepted: false
        });
    });

    activeWardId && updatePreferences({ wardId: activeWardId, preferences: bucketIdPreferenceMap, userId: props.userId });
  };

  const clearPreferences = () => {
    const bucketIdPreferenceMap = new Map<string, Preference | null>();
    props.dayShiftBuckets.forEach(shiftBucket => {
      shiftBucket.id && bucketIdPreferenceMap.set(shiftBucket.id, null);
    });

    activeWardId && updatePreferences({ wardId: activeWardId, preferences: bucketIdPreferenceMap, userId: props.userId });
  };

  const formatWeekDay = (nameOfDay: string) => (
    <Typography color={theme.palette.text.disabled}>{_.first(nameOfDay)?.toLocaleUpperCase()}</Typography>
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Uwaga!'}
        content={
          'Jeżeli posiadasz już jakiekolwiek dyspozycje dyżurowe w okresie urlopu, zostaną one zamienione na urlop. Czy na pewno chcesz kontynuować?'
        }
        onAccept={addPreferences}
        onAcceptText={'Tak'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />

      {props.preferencesStateOnDay === PreferencesStateOnDay.HOLIDAY ? (
        <Box display="flex" flexDirection="column" gap={3}>
          {currentFormState.note && <Typography variant="body1">{currentFormState.note}</Typography>}
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={clearPreferences} disabled={!props.isEditable}>
              Usuń urlop tego dnia
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box width="100%" display="flex" justifyContent="center">
            <DatePicker
              locale={pl}
              onChange={changeRangeDates}
              minDate={startOfMonth(props.date)}
              // hack - special setting of maxDate and excludeDates properties to achieve disabled view when preference is not editable
              maxDate={props.isEditable ? maxDate : startOfMonth(props.date)}
              excludeDates={props.isEditable ? [] : [startOfMonth(props.date)]}
              selected={currentFormState.startDate}
              startDate={currentFormState.startDate}
              endDate={currentFormState.endDate}
              calendarClassName={classes.root}
              monthsShown={2}
              dateFormatCalendar="LLLL yyyy"
              formatWeekDay={formatWeekDay}
              selectsRange
              inline
            />
          </Box>
          <TextField
            label="Dodaj notatkę"
            fullWidth
            multiline
            minRows={3}
            onChange={onNoteChanged}
            disabled={!props.isEditable}
            value={currentFormState.note}
          />

          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={() => setIsConfirmationDialogOpen(true)} disabled={!isDirty || !props.isEditable}>
              Zapisz
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

import { Facility, PredefinedFacility } from 'shared/types/facility';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createFacility: builder.mutation<Facility, Facility>({
      query: body => ({
        url: '/facilities',
        method: HTTP_METHODS.POST,
        body
      }),
      invalidatesTags: [{ type: 'Facility', id: 'LIST' }]
    }),
    updateFacility: builder.mutation<Facility, Facility>({
      query: body => ({
        url: '/facilities',
        method: HTTP_METHODS.PUT,
        body
      }),
      invalidatesTags: [{ type: 'Facility' }]
    }),
    getFacilities: builder.query<Facility[], void>({
      query: () => ({
        url: `/facilities`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Facility' as const, id })), { type: 'Facility', id: 'LIST' }]
          : [{ type: 'Facility', id: 'LIST' }]
    }),
    getFacilityById: builder.query<Facility, string>({
      query: id => ({
        url: `/facilities/${id}`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result => (result ? [{ type: 'Facility' as const, id: result.id }] : [])
    }),
    getPredefinedFacilities: builder.query<PredefinedFacility[], void>({
      query: () => ({
        url: `/predefined-facilities`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'PredefinedFacility' as const, id })), { type: 'PredefinedFacility', id: 'LIST' }]
          : [{ type: 'PredefinedFacility', id: 'LIST' }]
    })
  }),
  overrideExisting: false
});

export const {
  useCreateFacilityMutation,
  useGetFacilitiesQuery,
  useGetPredefinedFacilitiesQuery,
  useGetFacilityByIdQuery,
  useUpdateFacilityMutation
} = extendedApi;

import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { InvitationStatus } from 'shared/types/invitation';
import { RowData } from '../utils';
import { useAppDispatch } from 'store/hooks';
import { addNewUniqueInvitee } from 'store/slices/invitationSlice';
import { isAfter } from 'date-fns';

interface UserTableProps {
  rowData: RowData[];
  renderJobTitleCell: (rowData: RowData) => JSX.Element;
  onTrashIconClick: (row: RowData) => void;
}

export const UserTable = (props: UserTableProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell align="left">Imię i nazwisko lekarza</TableCell>
          <TableCell align="left">E-mail</TableCell>
          <TableCell align="left">Nr telefonu</TableCell>
          <TableCell align="left">Stanowisko</TableCell>
          <TableCell align="left">Akcje</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rowData.map(row => {
          const canReinvite =
            row.status &&
            row.invitee &&
            ([InvitationStatus.NOT_SENT, InvitationStatus.CANCELED].includes(row.status) ||
              (row.expiration && isAfter(new Date(), new Date(row.expiration))));

          return (
            <TableRow key={row.email || row.phoneNumber}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="left">{row.phoneNumber}</TableCell>
              <TableCell align="left">{props.renderJobTitleCell(row)}</TableCell>
              <TableCell align="left">
                {canReinvite && (
                  <IconButton color="primary" onClick={() => dispatch(addNewUniqueInvitee(row.invitee!))}>
                    <PersonAddIcon />
                  </IconButton>
                )}
                <IconButton size="small" color="secondary" onClick={() => props.onTrashIconClick(row)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

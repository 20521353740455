import _ from 'lodash';
import { Preference, PreferenceType, ShiftBucket } from 'shared/types/shiftBucket';
import { getCyclicShiftBucketsForSameMonth } from 'shared/utils/calendarUtils';
import { findById } from 'shared/utils/commonUtils';
import { v4 as uuid } from 'uuid';
import { PreferencesStateOnDay } from '../shiftPreferenceDialog';

export interface ShiftFormState {
  uuid: string;
  bucketId: string;
  preferenceType: PreferenceType;
  note: string;
}

export interface ShiftPreferenceMenuItem {
  id: PreferenceType;
  label: string | JSX.Element;
  icon: JSX.Element;
}

export const addNewEntry = (shiftPreference: ShiftFormState[], availableBuckets: ShiftBucket[]): ShiftFormState[] => {
  const nextBucketId = _.first(availableBuckets)?.id;
  if (nextBucketId) {
    return [...shiftPreference, { uuid: uuid(), bucketId: nextBucketId, preferenceType: 'PRESENCE', note: '' }];
  } else {
    return shiftPreference;
  }
};

export const applyBucketIdToEntry = (shiftPreference: ShiftFormState[], uuid: string, bucketId: string): ShiftFormState[] => {
  const preferenceEntriesCopy: ShiftFormState[] = JSON.parse(JSON.stringify(shiftPreference));
  const entry = preferenceEntriesCopy.find(pec => pec.uuid === uuid);
  if (entry) {
    entry.bucketId = bucketId;
  }

  return [...preferenceEntriesCopy];
};

export const applyNoteToEntry = (shiftPreference: ShiftFormState[], uuid: string, note: string): ShiftFormState[] => {
  const preferenceEntriesCopy: ShiftFormState[] = JSON.parse(JSON.stringify(shiftPreference));
  const entry = preferenceEntriesCopy.find(pec => pec.uuid === uuid);
  if (entry) {
    entry.note = note;
  }

  return [...preferenceEntriesCopy];
};

export const applyPreferenceTypeIdToEntry = (
  shiftPreference: ShiftFormState[],
  uuid: string,
  preferenceType: PreferenceType
): ShiftFormState[] => {
  const preferenceEntriesCopy: ShiftFormState[] = JSON.parse(JSON.stringify(shiftPreference));
  const entry = preferenceEntriesCopy.find(pec => pec.uuid === uuid);
  if (entry) {
    entry.preferenceType = preferenceType;
  }

  return [...preferenceEntriesCopy];
};

export const getConfirmationDialogContent = (dayPreferencesState: PreferencesStateOnDay, containsCyclicPreference: boolean) => {
  if (dayPreferencesState === PreferencesStateOnDay.ABSENCE_ON_DAY) {
    return 'Po wskazaniu dyspozycyjności, preferencja: "Dzień bez dyżuru" w tym dniu zostanie usunięta. Czy na pewno chcesz kontynuować?';
  } else if (dayPreferencesState === PreferencesStateOnDay.HOLIDAY) {
    return 'Po wskazaniu dyspozycyjności, urlop w tym dniu zostanie usunięty. Czy na pewno chcesz kontynuować?';
  } else if (containsCyclicPreference) {
    return (
      'Lista dyspozycyjności zawiera dyspozycyjność cykliczną, dni bez dyżuru oraz urlopy w dniach cyklicznych zostaną zamienione na tą dyspozycyjność, ' +
      'będziesz musiał raz jeszcze oznaczyć Urlop/Dzień bez dyżuru w danym dniu ręcznie'
    );
  } else {
    return 'Nieprawidłowy stan preferencji! Czy na pewno chcesz kontynuować?';
  }
};

export const createBucketIdPreferenceMap = (shiftPreference: ShiftFormState[], monthShiftBuckets: ShiftBucket[], userId: string) => {
  const bucketIdPreferenceMap = new Map<string, Preference | null>();

  shiftPreference.forEach(preference => {
    if (preference.preferenceType === 'CYCLIC') {
      handleCyclicPreference(preference, bucketIdPreferenceMap, monthShiftBuckets, userId);
    } else {
      handleNonCyclicPreference(preference, bucketIdPreferenceMap, userId);
    }
  });

  return bucketIdPreferenceMap;
};

const handleCyclicPreference = (
  preference: ShiftFormState,
  bucketIdPreferenceMap: Map<string, Preference | null>,
  monthShiftBuckets: ShiftBucket[],
  userId: string
) => {
  const selectedBucket = findById<ShiftBucket>(monthShiftBuckets, preference.bucketId);

  if (!selectedBucket) {
    return;
  }

  resetAllDayPreferences(monthShiftBuckets, bucketIdPreferenceMap, selectedBucket, userId);

  getCyclicShiftBucketsForSameMonth(monthShiftBuckets, new Date(selectedBucket.start), new Date(selectedBucket.end)).forEach(sb => {
    bucketIdPreferenceMap.set(sb.id, { userId, type: preference.preferenceType, note: preference.note, accepted: false });
  });
};

const resetAllDayPreferences = (
  monthShiftBuckets: ShiftBucket[],
  bucketIdPreferenceMap: Map<string, Preference | null>,
  selectedBucket: ShiftBucket,
  userId: string
) => {
  const day = new Date(selectedBucket.start).getDay();
  const sameWeekDayBuckets = monthShiftBuckets.filter(sb => day === new Date(sb.start).getDay());

  sameWeekDayBuckets.forEach(sb => {
    const userPreference = sb.preferences.find(p => p.userId === userId);

    if (userPreference && (userPreference.type === 'ABSENCE_ON_DAY' || userPreference.type === 'HOLIDAY')) {
      bucketIdPreferenceMap.set(sb.id, null);
    }
  });
};

const handleNonCyclicPreference = (preference: ShiftFormState, bucketIdPreferenceMap: Map<string, Preference | null>, userId: string) => {
  bucketIdPreferenceMap.set(preference.bucketId, { userId, type: preference.preferenceType, note: preference.note, accepted: false });
};

export const clearOutOtherPreferences = (bucketIdPreferenceMap: Map<string, Preference | null>, dayShiftBuckets: ShiftBucket[]) => {
  dayShiftBuckets.forEach(dsb => {
    if (dsb?.id && !bucketIdPreferenceMap.has(dsb.id)) {
      bucketIdPreferenceMap.set(dsb.id, null);
    }
  });
};

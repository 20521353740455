import { theme } from '@medsi/mui-theme';
import { Box, Button, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { endOfMonth, format, isSameDay, startOfMonth } from 'date-fns';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import { Loadable } from 'features/ui/loadable/Loadable';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ShiftBuckets } from 'shared/types/shiftBucket';
import { User, Users } from 'shared/types/users';
import { findById } from 'shared/utils/commonUtils';
import { useCreateOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { CalendarDatePicker } from '../../../../ui/date-picker/calendarDatePicker';

interface SwapShiftCreatorProps {
  bucketId: string;
}

export const SwapShiftCreator = (props: SwapShiftCreatorProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedBucket, setSelectedBucket] = useState('');
  const [description, setDescription] = useState('');
  // redux
  const dispatch = useAppDispatch();
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data: users = [] } = useGetAllUsersQuery();
  const [createOffer, { isSuccess, isError, isLoading }] = useCreateOfferMutation();
  // other
  const { shiftBuckets } = useGetShiftBuckets();
  const fontWeightRegular = theme.typography.fontWeightRegular;

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się wysłać prośby wymiany dyżuru' }));
  }, [isError, dispatch]);

  useEffect(() => {
    isSuccess && dispatch(addAlert({ color: 'success', text: 'Wysłano prośbę wymiany dyżuru' }));
  }, [isSuccess, dispatch]);

  const minDate = useMemo(() => {
    const bucketStart = findById(shiftBuckets, props.bucketId)?.start;
    const date = bucketStart ? new Date(bucketStart) : new Date();
    return startOfMonth(date);
  }, [shiftBuckets, props.bucketId]);

  const maxDate = useMemo(() => {
    const bucketStart = findById(shiftBuckets, props.bucketId)?.start;
    const date = bucketStart ? new Date(bucketStart) : new Date();
    return endOfMonth(date);
  }, [shiftBuckets, props.bucketId]);

  const swapShifts = () => {
    if (!facilitySlice.activeWardId || !loggedUser?.id) {
      return;
    }
    createOffer({
      type: 'SWAP_ONE_TO_ONE',
      status: 'WAITING',
      wardId: facilitySlice.activeWardId,
      offerRequest: {
        offeredShift: props.bucketId,
        requester: loggedUser.id,
        recipient: selectedDoctor,
        wantedShift: selectedBucket
      },
      offerResponses: [],
      description: description
    });
  };

  const generateShiftBucketOptions = () => {
    return shiftBuckets
      ?.filter(sb => {
        return format(new Date(sb.start), 'yyyy-MM-dd') === selectedDate && !_.includes(sb.doctors, loggedUser?.id);
      })
      .map(sb => (
        <MenuItem key={sb.id} value={sb.id}>
          {ShiftBuckets.getShiftBucketTimeLabel(sb)}
        </MenuItem>
      ));
  };

  const generateDoctorOptions = () => {
    return findById(shiftBuckets, selectedBucket)
      ?.doctors?.map(d => {
        const user = findById<User>(users, d);

        return user ? (
          <MenuItem key={user.id} value={user.id}>
            {Users.toString<User>(user)}
          </MenuItem>
        ) : null;
      })
      .filter(o => !_.isNull(o));
  };

  const onSelectedDate = (date: string) => {
    setSelectedDate(date);
    setSelectedBucket('');
    setSelectedDoctor('');
  };

  const onSelectedBucket = (bucketId: string) => {
    setSelectedBucket(bucketId);
    setSelectedDoctor('');
  };

  const onShouldDisableDate = (date: Date): boolean => {
    const isDateAssignable = shiftBuckets.some(sb => {
      const shiftStartDate = new Date(sb.start);
      const hasBucket = isSameDay(shiftStartDate, date);
      const hasRealDoctors = !_.isEmpty(sb.doctors.map(d => findById<User>(users, d)).filter(d => !!d && !d.robot));
      const isNotLoggedUserShift = loggedUser && !sb.doctors.includes(loggedUser.id!);

      return hasBucket && hasRealDoctors && isNotLoggedUserShift;
    });

    return !isDateAssignable;
  };

  const isValid = !isLoading && selectedDate && selectedBucket && selectedDoctor;

  return (
    <Loadable loading={isLoading}>
      <Grid container alignItems={'flex-start'} rowSpacing={3} pt={3}>
        <Grid container item rowGap={3} xs={12} md={5}>
          <Grid item xs={12}>
            <CalendarDatePicker
              min={minDate}
              max={maxDate}
              onChangeCallback={onSelectedDate}
              shouldDisableDateCallback={onShouldDisableDate}
              value={selectedDate}
              label="Podaj datę dyżuru, który chcesz wziąć"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ textAlign: 'start', fontWeight: fontWeightRegular }}>Wybierz dyżur</InputLabel>
            <Select
              disabled={!selectedDate}
              disableUnderline
              variant="standard"
              value={selectedBucket}
              onChange={e => onSelectedBucket(e.target.value)}
              fullWidth
            >
              {generateShiftBucketOptions()}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ textAlign: 'start', fontWeight: fontWeightRegular }}>Wybierz lekarza</InputLabel>
            <Select
              disabled={!selectedDate || !selectedBucket}
              disableUnderline
              variant="standard"
              value={selectedDoctor}
              onChange={e => setSelectedDoctor(e.target.value)}
              fullWidth
            >
              {generateDoctorOptions()}
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={description}
            onChange={e => setDescription(e.target.value)}
            variant="outlined"
            maxRows={8}
            minRows={3}
            label={<InputLabel sx={{ mb: 1 }}>Dodaj opis</InputLabel>}
            multiline
            fullWidth
            sx={{ textAlign: 'start' }}
          />
        </Grid>
        <Grid item xs={12} py={1}>
          <Box width={'fit-content'} ml="auto">
            <Button disabled={!isValid} variant={isValid ? 'contained' : 'text'} color="primary" fullWidth onClick={swapShifts}>
              Wyślij prośbę
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Loadable>
  );
};

import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import _ from 'lodash';
import { isInNextMonth } from 'shared/utils/calendarUtils';

export const useGetUsersWithUnacceptedPrefs = (): string[] => {
  const { shiftBuckets } = useGetShiftBuckets();

  return _.uniq(
    shiftBuckets
      .filter(sb => isInNextMonth(new Date(sb.start)))
      .flatMap(sb => sb.preferences)
      .filter(p => !p.accepted)
      .map(p => p.userId)
  );
};

import { Observer } from './observers.models';

export class DefaultObserver<P, O extends Observer<P>> {
  private observers: O[] = [];

  public attach(observer: O) {
    this.observers.push(observer);
  }

  public detach(observer: O) {
    this.observers = this.observers.filter(o => o !== observer);
  }

  public notify(p: P) {
    this.observers.forEach(observer => observer(p));
  }

  public clearAll() {
    this.observers = [];
  }
}

import { useMemo } from 'react';
import { CalendarEventData } from 'shared/types/calendar';
import { ShiftPlan } from 'shared/types/planning';
import { ShiftPlanStatus } from './useGetShiftPlanStatus';

export const useGetAcceptedShiftAssignmentEvents = (shiftPlanStatus: ShiftPlanStatus, shiftPlan?: ShiftPlan): CalendarEventData[] => {
  const events = useMemo(() => {
    if (shiftPlanStatus !== ShiftPlanStatus.ACCEPTED) {
      return [];
    }
    return (
      shiftPlan?.shiftAssignments.map(sa => {
        const event: CalendarEventData = {
          id: sa.shift.shiftBucketId,
          start: new Date(sa.shift.start),
          end: new Date(sa.shift.start),
          editable: false,
          extendedProps: {
            type: 'ACCEPTED_SHIFT_ASSIGNMENT',
            shiftAssignment: sa
          }
        };

        return event;
      }) ?? []
    );
  }, [shiftPlan, shiftPlanStatus]);

  return events;
};

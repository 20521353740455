import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Offer } from 'shared/types/offer';

const SLICE_NAME = 'offerSlice';

export interface BucketOffers {
  bucketId: string;
  offers: Offer[];
  hasAsapOffer: boolean;
}

interface OfferState {
  bucketOffers: BucketOffers[];
}

const initialState: OfferState = {
  bucketOffers: []
};

export const offerSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateBucketOffers(state, action: PayloadAction<BucketOffers[]>) {
      state.bucketOffers = action.payload;
    },
    offerSliceReset() {
      return initialState;
    }
  }
});

export const { updateBucketOffers, offerSliceReset } = offerSlice.actions;

export default offerSlice.reducer;

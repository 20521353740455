import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface PageLayoutProps {
  background?: 'white' | 'light' | 'default';
  children: ReactNode;
}

function PageLayout({ background, children }: PageLayoutProps): JSX.Element {
  return (
    <Box width="100%" height="100%" minHeight="100vh" display="flex" flexDirection="column" sx={{ overflowX: 'hidden', backgroundColor: background }}>
      {children}
    </Box>
  );
}

PageLayout.defaultProps = {
  background: 'default'
};

export default PageLayout;

import { theme } from '@medsi/mui-theme';
import { FormLabel, Switch } from '@mui/material';

interface SwitchOvernightProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const SwitchOvernight = (props: SwitchOvernightProps): JSX.Element => {
  const { functions, palette } = theme;
  const { rgba } = functions;
  const { secondary } = palette;
  return (
    <FormLabel sx={{ color: `${rgba(secondary.main, 0.87)}` }}>
      <Switch onChange={(_event, checked) => props.onChange(checked)} checked={props.checked} />
      Zakończenie dyżuru następnego dnia
    </FormLabel>
  );
};

import { CalendarEventData, CalendarTypeGuard, ShiftEventType } from 'shared/types/calendar';
import { ShiftEventDialog } from './shift-event/shiftEventDialog';
import { ShiftPreferenceDialog } from './shift-preference/shiftPreferenceDialog';

interface CalendarDialogProps {
  userId: string;
  eventData: CalendarEventData | null;
  isOpen: boolean;
  onClose: () => void;
}

export const CalendarEventDialog = (props: CalendarDialogProps): JSX.Element => {
  if (!props.eventData) {
    return <></>;
  }

  if (CalendarTypeGuard.isShiftBucketEvent(props.eventData)) {
    if (props.eventData.extendedProps.shiftEventType === ShiftEventType.PREFERENCE) {
      return <ShiftPreferenceDialog date={props.eventData.start} isOpen={props.isOpen} onClose={props.onClose} userId={props.userId} />;
    } else {
      return <ShiftEventDialog isOpen={props.isOpen} onClose={props.onClose} eventData={props.eventData} />;
    }
  }
  return <></>;
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Card, FormControlLabel, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { DayOfMonthInput } from 'features/ui/masked-inputs/dayOfMonthInput';
import { useEffect, useState } from 'react';
import { useGetWardByIdQuery, useUpdateWardMutation } from 'store/api/endpoints/wardEndpoints';
import { useAppSelector } from 'store/hooks';

export const ShiftSettings = () => {
  const [deadlineDay, setDeadlineDay] = useState<string>('');
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: ward } = useGetWardByIdQuery(activeWardId ?? skipToken);
  const [updateWard] = useUpdateWardMutation();

  const { palette, borders, typography } = theme;

  useEffect(() => {
    ward && setDeadlineDay(ward.deadlineDay.toString());
  }, [ward]);

  const onUpdateDeadlineDay = () => {
    deadlineDay && updateWard({ ...ward, deadlineDay: parseInt(deadlineDay) });
  };

  return (
    <Grid
      container
      alignItems="stretch"
      p={3}
      sx={{ backgroundColor: palette.neutralExpand[200], borderRadius: borders.borderRadius.xxxl }}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12} pb={pxToRem(20)}>
          <Typography variant="body1">Tutaj możesz zmienić informacje dotyczące kalendarza.</Typography>
        </Grid>
        <Grid container item xs={12} pb={{ xs: pxToRem(20), md: 0 }}>
          <Grid item xs={12} md={3} pb={{ xs: pxToRem(20), md: 0 }} minWidth={pxToRem(280)}>
            <Card sx={{ borderRadius: borders.borderRadius.xxxl, padding: 2 }}>
              <Box display="flex" alignItems="start" flexDirection="column" gap={3}>
                <Typography variant="h6" fontWeight={typography.fontWeightBold} color={palette.text.primary}>
                  Do kiedy lekarze mogą zgłaszać dyspozycyjność?
                </Typography>
                <Box>
                  <Typography variant="body1" color={palette.text.primary}>
                    Lekarze mogą zgłaszać dyspozycyjność do:
                  </Typography>
                  <FormControlLabel
                    control={
                      <TextField
                        sx={{ width: pxToRem(100) }}
                        InputProps={{
                          inputComponent: DayOfMonthInput as any
                        }}
                        value={deadlineDay}
                        onChange={event => setDeadlineDay(event.target.value.trim())}
                        disabled={!ward}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" gap={1}>
                        dnia każdego miesiąca
                        <Tooltip
                          title="Jeśli wpiszesz dzień, który nie istnieje w danym miesiącu, system ustawi ostateczny termin na ostatni dzień danego miesiąca."
                          placement="top"
                        >
                          <InfoOutlined color="primary" fontSize="small" />
                        </Tooltip>
                      </Box>
                    }
                    labelPlacement="end"
                  />
                </Box>
                <Box width="100%" display="flex" justifyContent="end">
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    disabled={!deadlineDay || ward?.deadlineDay.toString() === deadlineDay}
                    onClick={onUpdateDeadlineDay}
                  >
                    Zapisz
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Avatar, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Action, usePermission } from 'features/rbac/permissions';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { SidebarElement } from 'shared/types/routes';
import { User, Users } from 'shared/types/users';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsSidenavHidden } from 'store/slices/sidenavSlice';
import SidenavRoot from './sidenavRoot';
import { calendarLink, plannerLink, impersonationPanelLink, settingsLink, logoutLink } from './utils';

export const MobileSidenav = (): JSX.Element => {
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const location = useLocation();
  const { hasPermissionTo } = usePermission();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setIsSidenavHidden(true));
  }, [location, dispatch]);

  const renderSidenavItem = (sidebarElement: SidebarElement) => {
    const isUserCreatingFacility = location.pathname.startsWith(paths.dashboard.wards.create.base);
    const isUserAcceptingTerms = location.pathname.startsWith(paths.dashboard.terms);
    if (!hasPermissionTo(sidebarElement.action) || ((isUserCreatingFacility || isUserAcceptingTerms) && !sidebarElement.mandatory)) {
      return <></>;
    }

    const route = sidebarElement.route;

    const isCurrentLocation = route && location.pathname.startsWith(route);

    return (
      <Box
        p={1}
        display="flex"
        alignItems="center"
        gap={2}
        borderRadius={theme.borders.borderRadius.xl}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: grey[50]
          }
        }}
        onClick={() => route && navigate(route)}
      >
        <Box display="flex" alignItems="center" color={isCurrentLocation ? theme.palette.primary.main : theme.palette.text.primary}>
          {sidebarElement.prefix}
        </Box>

        <Typography
          whiteSpace="break-spaces"
          fontSize={pxToRem(14)}
          fontWeight={isCurrentLocation ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular}
        >
          {sidebarElement.text}
        </Typography>

        {sidebarElement.postfix}
      </Box>
    );
  };

  return (
    <SidenavRoot variant="permanent">
      <Box pt={1} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" flexDirection="column" gap={2}>
          {renderSidenavItem({
            action: Action.BASE,
            prefix: (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  fontSize: pxToRem(14)
                }}
              >
                {Users.getInitials<User>(loggedUser)}
              </Avatar>
            ),
            text: Users.toString<User>(loggedUser),
            mandatory: true
          })}
          {renderSidenavItem(calendarLink)}
          {renderSidenavItem(plannerLink)}
          {renderSidenavItem(impersonationPanelLink)}
          {renderSidenavItem(settingsLink)}
        </Box>
        <Box>{renderSidenavItem(logoutLink)}</Box>
      </Box>
    </SidenavRoot>
  );
};

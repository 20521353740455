import { pxToRem, theme } from '@medsi/mui-theme';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';

type Props = {
  onClickCallback: VoidFunction;
  disabled?: boolean;
};

export const AddButton = (props: Props) => {
  const { palette } = theme;
  const { secondary, white } = palette;

  return (
    <IconButton
      size="large"
      onClick={props.onClickCallback}
      disabled={props.disabled}
      sx={{
        backgroundColor: secondary.main,
        color: white.main,
        width: pxToRem(56),
        height: pxToRem(56),
        transition: 'all .3s',
        '&:hover': {
          backgroundColor: secondary.main,
          color: white.main,
          transform: 'rotate(90deg)'
        }
      }}
    >
      <AddIcon />
    </IconButton>
  );
};

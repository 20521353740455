import { pxToRem, theme } from '@medsi/mui-theme';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon
} from '@mui/icons-material';
import { Avatar, Box, Collapse, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Action, usePermission } from 'features/rbac/permissions';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { SidebarElement } from 'shared/types/routes';
import { User, Users } from 'shared/types/users';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsSidenavHidden } from 'store/slices/sidenavSlice';
import { SidenavLogo } from './sidenavLogo';
import SidenavRoot from './sidenavRoot';
import { calendarLink, impersonationPanelLink, logoutLink, plannerLink, settingsLink } from './utils';

export const DesktopSidenav = (): JSX.Element => {
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  // redux
  const dispatch = useAppDispatch();
  const isSidenavHidden = useAppSelector(state => state.sidenavSlice.isSidenavHidden);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const location = useLocation();
  const { hasPermissionTo } = usePermission();
  const navigate = useNavigate();

  const toggleSidenav = () => dispatch(setIsSidenavHidden(!isSidenavHidden));

  const renderSidenavItem = (sidebarElement: SidebarElement) => {
    const isUserCreatingFacility = location.pathname.startsWith(paths.dashboard.wards.create.base);
    const isUserAcceptingTerms = location.pathname.startsWith(paths.dashboard.terms);
    if (!hasPermissionTo(sidebarElement.action) || ((isUserCreatingFacility || isUserAcceptingTerms) && !sidebarElement.mandatory)) {
      return <></>;
    }

    const route = sidebarElement.route;

    const isCurrentLocation = route && location.pathname.startsWith(route);

    return (
      <Box
        p={isSidenavHidden ? 1 : 2}
        display="flex"
        alignItems="center"
        justifyContent={isSidenavHidden ? 'center' : 'flex-start'}
        gap={isSidenavHidden ? 1 : 2}
        borderRadius={theme.borders.borderRadius.xl}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: grey[50]
          }
        }}
        onClick={() => route && navigate(route)}
      >
        <Box display="flex" alignItems="center" color={isCurrentLocation ? theme.palette.primary.main : theme.palette.text.primary}>
          {sidebarElement.prefix}
        </Box>
        {!isSidenavHidden && (
          <Typography
            whiteSpace="break-spaces"
            fontSize={pxToRem(14)}
            fontWeight={isCurrentLocation ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular}
          >
            {sidebarElement.text}
          </Typography>
        )}
        {sidebarElement.postfix}
      </Box>
    );
  };

  return (
    <SidenavRoot variant="permanent">
      <SidenavLogo />
      <Box pt={6} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" flexDirection="column" gap={5}>
          {renderSidenavItem(calendarLink)}
          {renderSidenavItem(plannerLink)}
          {renderSidenavItem(impersonationPanelLink)}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box onClick={() => setOpenCollapse(prev => !prev)}>
            {renderSidenavItem({
              action: Action.BASE,
              prefix: (
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    fontSize: pxToRem(14)
                  }}
                >
                  {Users.getInitials<User>(loggedUser)}
                </Avatar>
              ),
              text: Users.toString<User>(loggedUser),
              postfix: openCollapse ? <ArrowDropDownIcon fontSize="small" /> : <ArrowDropUpIcon fontSize="small" />,
              mandatory: true
            })}
          </Box>
          <Collapse in={openCollapse} unmountOnExit sx={{ paddingLeft: isSidenavHidden ? 0 : 2 }}>
            {renderSidenavItem(settingsLink)}
            {renderSidenavItem(logoutLink)}
          </Collapse>
          <Box
            onClick={toggleSidenav}
            p={isSidenavHidden ? 1 : 2}
            display="flex"
            alignItems="center"
            gap={2}
            justifyContent="flex-end"
            sx={{
              cursor: 'pointer'
            }}
          >
            {!isSidenavHidden && <Typography fontSize={pxToRem(12)}>ukryj menu</Typography>}
            {isSidenavHidden ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </Box>
        </Box>
      </Box>
    </SidenavRoot>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Invitee } from 'shared/types/invitation';

const SLICE_NAME = 'invitation';

interface InvitationSliceState {
  newInvitees: Invitee[];
}

const initialState: InvitationSliceState = {
  newInvitees: []
};

export const invitationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearNewInvitees(state) {
      state.newInvitees = [];
    },
    addNewUniqueInvitee(state, action: PayloadAction<Invitee>) {
      if (!state.newInvitees.some(i => Invitee.equals(i, action.payload))) {
        state.newInvitees.push(action.payload);
      }
    },
    removeNewInvitee(state, action: PayloadAction<Invitee>) {
      state.newInvitees = state.newInvitees.filter(i => !Invitee.equals(i, action.payload));
    },
    invitationSliceReset() {
      return initialState;
    }
  }
});

export const { clearNewInvitees, addNewUniqueInvitee, removeNewInvitee, invitationSliceReset } = invitationSlice.actions;

export default invitationSlice.reducer;

import { pxToRem, rgba, theme } from '@medsi/mui-theme';

const { palette, typography } = theme;

const datePickerStyles = {
  '& .MuiDateCalendar-root': {
    height: 'fit-content'
  },
  '& .MuiPickersDay-root': {
    fontWeight: typography.fontWeightMedium,
    fontSize: typography.size.sm
  },
  '& .MuiTouchRipple-root, & .MuiPickersLayout-toolbar': {
    display: 'none !important'
  },
  '& .MuiPickersLayout-actionBar': {
    display: 'none'
  },
  '& .MuiPickersCalendarHeader-label': {
    fontSize: pxToRem(16),
    textTransform: 'capitalize',
    cursor: 'default'
  },
  '.MuiDayCalendar-weekDayLabel': {
    color: rgba(theme.palette.text.disabled, 0.38),
    fontWeight: theme.typography.fontWeightRegular
  },
  '& MuiPickersDay-root:hover, & .MuiPickersDay-dayOutsideMonth:hover': {
    background: `inherit !important`
  },
  '& .MuiPickersDay-today, & .MuiPickersDay-today:focus': {
    border: '0 !important',
    background: palette.primary.main,
    color: palette.white.main
  },
  '& .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:focus': {
    background: rgba(theme.palette.primary.main, 0.12),
    color: palette.text.primary
  },
  '& .MuiPickersDay-dayOutsideMonth.Mui-selected, & .MuiPickersDay-dayOutsideMonth.Mui-selected:focus': {
    background: palette.neutral[400],
    color: palette.text.secondary
  },
  '& .MuiPickersDay-today.Mui-selected, & .MuiPickersDay-today.Mui-selected:focus': {
    background: palette.primary.main,
    color: palette.white.main
  }
};

export default datePickerStyles;

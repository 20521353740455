import { theme } from '@medsi/mui-theme';
import { Chip } from '@mui/material';

interface ChipProps {
  readOnly?: boolean;
  label?: string;
  color?: 'primary' | 'secondary';
  isConflicting?: boolean;
  onClick?: () => void;
}

export const WeekDayChip = (props: ChipProps): JSX.Element => {
  const { functions, palette, typography } = theme;
  const { pxToRem, rgba } = functions;
  const { secondary, neutral, primary, light, white, error } = palette;
  const { fontWeightMedium } = typography;

  const getBackgroundColor = () => {
    if (props.isConflicting) {
      return error.main;
    } else if (props.readOnly) {
      return secondary.main;
    } else {
      return primary.main;
    }
  };

  return (
    <Chip
      label={props?.label}
      color={props?.color}
      onClick={props.onClick}
      sx={{
        fontSize: pxToRem(14),
        lineHeight: pxToRem(22),
        fontWeight: fontWeightMedium,
        width: pxToRem(32),
        height: pxToRem(32),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: neutral[400],
        color: rgba(secondary.main, 0.87),
        '&.MuiChip-filledPrimary': {
          backgroundColor: `${getBackgroundColor()} !important`,
          color: white.main
        },
        '&.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorPrimary:hover': {
          backgroundColor: `${getBackgroundColor()} !important`,
          color: white.main,
          cursor: 'unset !important'
        },
        '&.MuiChip-clickable:hover': {
          backgroundColor: props.readOnly ? light.main : rgba(primary.main, 0.7),
          color: props.readOnly ? secondary.main : rgba(white.main, 0.7),

          cursor: props.readOnly ? 'unset !important' : 'pointer !important'
        },
        '& > span': {
          overflow: 'visible !important',
          textOverflow: 'clip !important'
        }
      }}
    />
  );
};

import _ from 'lodash';
import { Offer, OfferResponse } from 'shared/types/offer';
import { BucketOffers, updateBucketOffers } from 'store/slices/offerSlice';
import { RootState } from 'store/store';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOffer: builder.mutation<Offer, Offer>({
      query: body => ({
        url: `/offers`,
        method: HTTP_METHODS.POST,
        body
      }),
      invalidatesTags: [{ type: 'Offer', id: 'LIST' }]
    }),
    acceptOfferResponse: builder.mutation<Offer, { offerId: string; responseId: string }>({
      query: data => ({
        url: `/offers/${data.offerId}/accept-response?responseId=${data.responseId}`,
        method: HTTP_METHODS.PUT
      }),
      invalidatesTags: ['Offer']
    }),
    rejectOffer: builder.mutation<Offer, string>({
      query: offerId => ({
        url: `/offers/${offerId}/reject`,
        method: HTTP_METHODS.PUT
      }),
      invalidatesTags: ['Offer']
    }),
    cancelOffer: builder.mutation<Offer, string>({
      query: offerId => ({
        url: `/offers/${offerId}/cancel`,
        method: HTTP_METHODS.PUT
      }),
      invalidatesTags: ['Offer']
    }),
    addOfferResponse: builder.mutation<Offer, { offerId: string; offerResponse: OfferResponse }>({
      query: data => ({
        url: `/offers/${data.offerId}/add-response`,
        method: HTTP_METHODS.PUT,
        body: data.offerResponse
      }),
      invalidatesTags: ['Offer']
    }),
    getOffersByWardId: builder.query<Offer[], string>({
      query: wardId => ({
        url: `/wards/${wardId}/offers`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Offer' as const, id })), { type: 'Offer', id: 'LIST' }]
          : [{ type: 'Offer', id: 'LIST' }],
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
        try {
          const { data: offers } = await queryFulfilled;

          const loggedUserId = (getState() as RootState).authSlice.loggedUser?.id;

          const bucketOffers: BucketOffers[] = _.chain(offers)
            .filter(o => o.status === 'WAITING')
            .filter(o => {
              const isForAllUsers = !o.offerRequest.recipient;
              const isForLoggedInUser = !!loggedUserId && o.offerRequest.recipient === loggedUserId;
              const isNotCreatedByLoggedUser = !!loggedUserId && o.offerRequest.requester !== loggedUserId;
              return isNotCreatedByLoggedUser && (isForAllUsers || isForLoggedInUser);
            })
            .groupBy(o => o.offerRequest.offeredShift)
            .map((group, key) => {
              const hasAsapOffer = group.some(o => o.type === 'ASAP_HAND_OVER');
              return { bucketId: key, offers: group, hasAsapOffer };
            })
            .value();

          dispatch(updateBucketOffers(bucketOffers));
        } catch {
          // no-op
        }
      }
    }),
    getSingleOffer: builder.query<Offer, string>({
      query: offerId => ({
        url: `/offers/${offerId}`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result => (result ? [{ type: 'Offer' as const, id: result.id }] : [])
    })
  }),
  overrideExisting: false
});

export const {
  useCreateOfferMutation,
  useAcceptOfferResponseMutation,
  useCancelOfferMutation,
  useRejectOfferMutation,
  useAddOfferResponseMutation,
  useGetOffersByWardIdQuery,
  useGetSingleOfferQuery
} = extendedApi;

import { Robot, User } from 'shared/types/users';
import { HTTP_METHODS, apiSlice } from '../api';

export type RobotSaveRequest = Omit<Robot, 'id'>;

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllUsers: builder.query<User[], void>({
      query: () => ({
        url: `/users`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: 'User' as const, id })), { type: 'User', id: 'LIST' }] : [{ type: 'User', id: 'LIST' }]
    }),
    getUserById: builder.query<User, string>({
      query: id => ({
        url: `/users/${id}`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result => (result ? [{ type: 'User' as const, id: result.id }] : [])
    }),
    deleteUserById: builder.mutation<void, string>({
      query: id => ({
        url: `/users/${id}`,
        method: HTTP_METHODS.DELETE
      }),
      invalidatesTags: ['User', 'ShiftBucket', 'ShiftPlan']
    }),
    changeDeviceOwner: builder.mutation<void, { userId: string; deviceToken: string }>({
      query: data => ({
        url: `/users/${data.userId}/mobile-devices/add`,
        method: HTTP_METHODS.PATCH,
        body: data.deviceToken
      })
    }),
    createRobot: builder.mutation<User, RobotSaveRequest>({
      query: robot => ({
        url: `/users`,
        method: HTTP_METHODS.POST,
        body: robot
      }),
      invalidatesTags: ['User', 'ShiftBucket', 'ShiftPlan']
    }),
    updateRobot: builder.mutation<User, Robot>({
      query: robot => ({
        url: `/users`,
        method: HTTP_METHODS.PUT,
        body: robot
      }),
      invalidatesTags: ['User', 'ShiftBucket', 'ShiftPlan']
    })
  }),
  overrideExisting: false
});

export const {
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useDeleteUserByIdMutation,
  useChangeDeviceOwnerMutation,
  useCreateRobotMutation,
  useUpdateRobotMutation
} = extendedApi;

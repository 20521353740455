import { Box, Button, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import { useEffect } from 'react';
import { User, Users } from 'shared/types/users';
import { getDateWithDayName } from 'shared/utils/calendarUtils';
import { useAddOfferResponseMutation, useGetSingleOfferQuery, useRejectOfferMutation } from 'store/api/endpoints/offerEndpoint';
import { useGetUserByIdQuery } from 'store/api/endpoints/userEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { Notification } from '../../shared/types/notification';

type Props = {
  notification: Notification;
  onCloseDialog: () => void;
};

export const NotificationDetails = (props: Props): JSX.Element => {
  // rtk
  const [addResponse, { isSuccess: addResponseSuccess, isError: addResponseError }] = useAddOfferResponseMutation();
  const { data: offer, isLoading } = useGetSingleOfferQuery(props.notification.connectedObject?.objectId ?? skipToken);
  const [rejectOffer, { isSuccess: rejectOfferSuccess, isError: rejectOfferError }] = useRejectOfferMutation();
  const { data: requester } = useGetUserByIdQuery(offer?.offerRequest?.requester ?? skipToken);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const { refetch: refetchShiftBuckets } = useGetShiftBuckets();

  useEffect(() => {
    addResponseSuccess && dispatch(addAlert({ color: 'success', text: 'Prośba została zaakceptowana' }));
  }, [addResponseSuccess, dispatch]);

  useEffect(() => {
    rejectOfferSuccess && dispatch(addAlert({ color: 'success', text: 'Prośba została odrzucona' }));
  }, [rejectOfferSuccess, dispatch]);

  useEffect(() => {
    addResponseError && dispatch(addAlert({ color: 'error', text: 'Nie udało się zaakceptować prośby' }));
  }, [addResponseError, dispatch]);

  useEffect(() => {
    rejectOfferError && dispatch(addAlert({ color: 'error', text: 'Nie udało się odrzucić prośby' }));
  }, [rejectOfferError, dispatch]);

  const onAccept = async () => {
    if (!offer?.id || !loggedUser?.id) {
      return;
    }
    const response = {
      offerId: offer.id,
      offerResponse: {
        responder: loggedUser.id,
        shiftOfferedInReturn: offer?.offerRequest?.wantedShift
      }
    };

    if (offer?.type !== 'SWAP_ONE_TO_ONE') {
      delete response.offerResponse.shiftOfferedInReturn;
    }

    await addResponse(response);
    refetchShiftBuckets();
  };

  const onReject = () => {
    offer?.id ? rejectOffer(offer.id) : dispatch(addAlert({ color: 'error', text: 'Nie udało się odrzucić prośby' }));
  };

  const generateActionButtons = () => {
    return offer?.type === 'ASAP_HAND_OVER' ? (
      <Button disabled={isLoading} startIcon={<CheckCircleIcon />} onClick={onAccept} variant="outlined">
        Potwierdź
      </Button>
    ) : (
      <Box display="flex" justifyContent="space-between">
        <Button disabled={isLoading} startIcon={<CloseRoundedIcon />} onClick={onReject} variant="outlined">
          Odrzuć
        </Button>
        <Button disabled={isLoading} startIcon={<CheckCircleIcon />} onClick={onAccept} variant="outlined">
          Potwierdź
        </Button>
      </Box>
    );
  };

  const cGrey100 = theme.palette.grey[100];
  const cGrey200 = theme.palette.grey[200];
  const cPrimary = theme.palette.primary.main;
  const cTextPrimary = theme.palette.text.primary;

  return (
    <Box>
      <DialogTitle component={'div'}>
        <Typography variant="h5" fontWeight={theme.typography.fontWeightBold} color={cTextPrimary}>
          {getDateWithDayName(offer?.createdDate)}
        </Typography>

        <IconButton size="small" color="secondary" onClick={props.onCloseDialog} sx={{ marginLeft: 'auto' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: 3
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius={2}
          p={3}
          sx={{
            backgroundColor: cGrey200
          }}
        >
          <Box display="flex" alignItems="center" my={2} gap={1}>
            <InfoOutlinedIcon fontSize="large" color="info" />
            <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
              {props.notification.title}
            </Typography>
          </Box>

          <Box>{<Typography variant="body1">{props.notification.content}</Typography>}</Box>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-start" pt={3} pb={1}>
          <Typography variant="h4">Opis:</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          p={1}
          borderLeft={`3px solid ${cPrimary}`}
          sx={{
            backgroundColor: cGrey100
          }}
        >
          <Typography variant="h6">{Users.toString<User>(requester)}</Typography>
          <Typography variant="body2">{offer?.description}</Typography>
        </Box>
        {offer?.status === 'WAITING' && <Box pt={3}>{generateActionButtons()}</Box>}
      </DialogContent>
    </Box>
  );
};

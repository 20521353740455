import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addYears, format, subYears } from 'date-fns';

const SLICE_NAME = 'shiftBucketsSlice';

interface Range {
  from: string;
  to: string;
}

interface ShiftBucketsState {
  currentRange: Range | null;
}

const initialState: ShiftBucketsState = {
  currentRange: {
    from: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
    to: format(addYears(new Date(), 1), 'yyyy-MM-dd')
  }
};

export const shiftBucketsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCurrentRange(state, action: PayloadAction<Range | null>) {
      state.currentRange = action.payload;
    },
    shiftBucketsSliceReset() {
      return initialState;
    }
  }
});

export const { setCurrentRange, shiftBucketsSliceReset } = shiftBucketsSlice.actions;

export default shiftBucketsSlice.reducer;

import { rgba, theme } from '@medsi/mui-theme';
import { Box, CircularProgress } from '@mui/material';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';

type Props = {
  isVisible: boolean;
};

export const CalendarOverlay = (props: Props) => {
  const isDesktop = useIsDesktop();
  return (
    props.isVisible && (
      <Box
        display="flex"
        position="absolute"
        justifyContent="center"
        alignItems="center "
        height="100%"
        width="100%"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          position: 'absolute',
          backgroundColor: rgba(theme.palette.coloredShadows.main, 0.25),
          borderRadius: theme => (isDesktop ? theme.borders.borderRadius.xxxl : 0)
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <CircularProgress color="primary" />
        </Box>
      </Box>
    )
  );
};

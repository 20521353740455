"use strict";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
Object.defineProperty(exports, "__esModule", { value: true });
var listItem = {
    defaultProps: {
        disableGutters: true
    },
    styleOverrides: {
        root: {
            paddingTop: 0,
            paddingBottom: 0
        }
    }
};
exports.default = listItem;

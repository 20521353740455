import { theme } from '@medsi/mui-theme';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Card, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FormError } from 'features/ui/formError/FormError';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { IconStatus } from 'shared/types/iconStatus';
import { validateEmail, validatePassword, validationMessage } from 'shared/utils/formUtils';
import { RequiredFieldsCaption } from 'shared/utils/requiredFieldsCaption';
import { useRegisterMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { PasswordRules } from './PasswordRules';
import { ResultMessage } from './ResultMessage';
import { AuthLayout, AuthLayoutSize } from './layout/AuthLayout';

interface SignUpFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  code: string;
  checkPassword: string;
}

export const SignUp = (): JSX.Element => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [highlightRules, setHighlightRules] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [register, { isSuccess, isError, isLoading }] = useRegisterMutation();
  // other
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();

  const { control, formState, handleSubmit, getValues, watch } = useForm<SignUpFormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      code: code || ''
    }
  });

  watch('password');

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nieudana rejestracja' }));
  }, [isError, dispatch]);

  useEffect(() => {
    setHighlightRules(!!formState.errors.password);
  }, [formState, formState.errors.password]);

  useEffect(() => {
    loggedUser && navigate(paths.dashboard.calendar, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: SignUpFormValues) => {
    register({
      email: data.email.toLowerCase(),
      password: data.password,
      code: data.code,
      firstName: data.firstName,
      lastName: data.lastName,
      langKey: 'pl'
    });
  };

  return isSuccess ? (
    <ResultMessage
      icon={IconStatus.info}
      size={AuthLayoutSize.auto}
      text="Na podany adres mailowy został wysłany link aktywujący konto."
      additionalText="Sprawdź skrzynkę mailową i kliknij link aby dokończyć proces rejestracji. W razie braku wiadomości, sprawdź folder Spam."
    />
  ) : (
    <AuthLayout>
      <Card sx={{ p: 3, boxShadow: theme.boxShadows.elevation24 }}>
        <Box pb={3} textAlign="center" sx={{ backgroundColor: 'info', borderRadius: 'lg', coloredShadow: 'info' }}>
          <Typography variant="h4" fontWeight="medium" color="primary">
            Zarejestruj
          </Typography>
        </Box>
        <Box mb={2}>
          <Controller
            name="code"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <>
                  <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Kod weryfikacyjny" fullWidth required />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              );
            }}
            rules={{ required: 'Podaj kod weryfikacyjny' }}
          />
        </Box>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box mb={2}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Imię" fullWidth required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{ required: validationMessage.required }}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Nazwisko" fullWidth required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{ required: validationMessage.required }}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Adres email" fullWidth required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                required: validationMessage.required,
                validate: validateEmail
              }}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      className={fieldState.invalid ? 'error' : ''}
                      label="Hasło"
                      type={passwordVisible ? 'text' : 'password'}
                      fullWidth
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setPasswordVisible(prev => !prev)} edge="end">
                              {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                validate: validatePassword
              }}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="checkPassword"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      className={fieldState.invalid ? 'error' : ''}
                      label="Powtórz hasło"
                      type={passwordVisible ? 'text' : 'password'}
                      fullWidth
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setPasswordVisible(prev => !prev)} edge="end">
                              {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                validate: (value, formValues) => value === formValues.password || 'Hasła nie są identyczne'
              }}
            />
          </Box>
          <RequiredFieldsCaption display="inline-block" mb={2} />
          <PasswordRules password={getValues('password')} disabled={!highlightRules} />
          <Box mt={3} mb={2}>
            <Button variant="contained" color="primary" fullWidth type="submit" disabled={!formState.isValid || isLoading}>
              Zarejestruj się
            </Button>
          </Box>
          <Link to={paths.root}>
            <Button variant="text" color="secondary" fullWidth>
              Wróć do logowania
            </Button>
          </Link>
        </Box>
      </Card>
    </AuthLayout>
  );
};

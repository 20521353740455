import { pxToRem } from '@medsi/mui-theme';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import { useMemo } from 'react';
import { Doctor, ShiftAssignment } from 'shared/types/planning';
import { Users } from 'shared/types/users';
import { ERROR_LIGHT_COLOR, WARNING_LIGHT_COLOR } from 'shared/utils/palette';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';
import { JustificationFilter } from '../justificationFilter';

type Props = {
  shiftAssignment: ShiftAssignment;
};

export const MobileShiftAssignmentEventContent = (props: Props) => {
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  // other
  const theme = useTheme();

  const bucketId = props.shiftAssignment?.shift?.shiftBucketId;
  const justifications = shiftPlan?.justifications;

  const justificationsOnBucket = useMemo(() => {
    if (!bucketId || !justifications) {
      return [];
    }
    return new JustificationFilter(justifications).withBucket(bucketId).getJustifications();
  }, [justifications, bucketId]);

  const { palette } = theme;

  const isModifiedManually = props.shiftAssignment.modifiedManually;
  const isNotAssigned = !props.shiftAssignment.doctor;
  const isWithWarning = !_.isEmpty(justificationsOnBucket);

  let borderColor = '';
  let backgroundColor = '';

  if (isModifiedManually) {
    borderColor = palette.neutral[600];
    backgroundColor = palette.neutral[500];
  } else if (isNotAssigned) {
    borderColor = palette.error.dark;
    backgroundColor = ERROR_LIGHT_COLOR;
  } else if (isWithWarning) {
    borderColor = palette.warning.dark;
    backgroundColor = WARNING_LIGHT_COLOR;
  } else {
    borderColor = palette.neutral[600];
    backgroundColor = palette.neutral[400];
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      border={`2px solid ${borderColor}`}
      borderRadius={theme.borders.borderRadius.md}
      sx={{ backgroundColor: backgroundColor, cursor: 'default' }}
      height={isNotAssigned ? pxToRem(18) : 'auto'}
      mx={pxToRem(1)}
      my={pxToRem(2)}
    >
      <Grid display="flex" flexDirection="column" paddingLeft={pxToRem(2)} pt={pxToRem(2)}>
        <Typography color={borderColor} fontWeight={theme.typography.fontWeightBold} fontSize={pxToRem(8)}>
          {props.shiftAssignment.doctor ? Users.getInitials<Doctor>(props.shiftAssignment.doctor) : ''}
        </Typography>
      </Grid>
    </Box>
  );
};

import _ from 'lodash';
import { useMemo } from 'react';
import { ShiftFormState } from '../shift/utils';

export const useIsShiftPreferenceFormDirty = (originalFormState: ShiftFormState[], currentFormState: ShiftFormState[]) => {
  return useMemo(() => {
    if (_.isEmpty(originalFormState) && _.isEmpty(currentFormState)) {
      return false;
    }
    const sorted1 = _.sortBy(originalFormState, 'uuid');
    const sorted2 = _.sortBy(currentFormState, 'uuid');

    return !_.isEqual(sorted1, sorted2);
  }, [originalFormState, currentFormState]);
};

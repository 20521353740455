import { isThisMonth } from 'date-fns';
import { ShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';
import { ShiftEventType } from 'shared/types/calendar';
import { ShiftBucket } from 'shared/types/shiftBucket';
import { isInPastMonths, isInNextMonth } from 'shared/utils/calendarUtils';

export const SHIFT_EVENT_POSTFIX = '_SHIFT_BUCKET';

export const getEventType = (date: Date, planStatus: ShiftPlanStatus): ShiftEventType => {
  if (isInPastMonths(date)) {
    return ShiftEventType.PAST_SHIFT;
  } else if (isThisMonth(date)) {
    return ShiftEventType.SHIFT;
  } else if (isInNextMonth(date) && planStatus === ShiftPlanStatus.ACCEPTED) {
    return ShiftEventType.SHIFT;
  } else {
    return ShiftEventType.PREFERENCE;
  }
};

export const isAllDayUserPreferenceEvent = (sb: ShiftBucket, userId: string, shiftEventType: ShiftEventType): boolean => {
  if (shiftEventType === ShiftEventType.PREFERENCE) {
    const userPreference = sb.preferences.find(p => p.userId === userId);
    return userPreference?.type === 'ABSENCE_ON_DAY' || userPreference?.type === 'HOLIDAY';
  }
  return false;
};

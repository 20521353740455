import { theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ruleEngine from 'features/ui/calendar/event-content/rule';
import { CalendarEventData, ShiftBucketExtendedProps } from 'shared/types/calendar';
import { ShiftBuckets } from 'shared/types/shiftBucket';
import { useGetFacilityByIdQuery } from 'store/api/endpoints/facilityEndpoints';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppSelector } from 'store/hooks';

type Props = {
  eventData: CalendarEventData & { extendedProps: ShiftBucketExtendedProps };
};

export const ShiftEventDetails = (props: Props): JSX.Element => {
  const { shiftBucket, viewAsOwner, hasAnyOffer, hasAsapOffer, isAssigned, shiftEventType, eventViewerId } = props.eventData.extendedProps;
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const { data: activeFacility } = useGetFacilityByIdQuery(facilitySlice?.activeFacilityId ?? skipToken);

  const content = ruleEngine.getContent(
    {
      isOwner: !!viewAsOwner,
      type: shiftEventType,
      hasAsapOffer: !!hasAsapOffer,
      hasAnyOffer: !!hasAnyOffer,
      isAssigned
    },
    { shiftBucket, userId: eventViewerId }
  );

  if (!content) {
    return <></>;
  }

  return (
    <Box textAlign="left" display="flex" flexDirection="column" alignItems="flex-start" gap={3}>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        Szczegóły dotyczące dyżuru
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
        borderRadius={theme.borders.borderRadius.lg}
        boxShadow={theme.boxShadows.elevation1}
        p={2}
        width={1}
        color={content.color}
        sx={{
          backgroundColor: content?.backgroundColor
        }}
      >
        <Box>
          <Typography variant="body3" display={'block'}>
            Dyżur
          </Typography>
          <Typography variant="body3">{ShiftBuckets.getShiftBucketTimeLabel(shiftBucket)}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{activeWard?.name}</Typography>
          <Typography variant="body1">{activeFacility?.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

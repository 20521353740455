import { Grid } from '@mui/material';
import { CalendarEventData, ShiftBucketExtendedProps } from 'shared/types/calendar';
import { ShiftBucketCandidateList } from './shiftBucketCandidateList';
import { ShiftEventDetails } from './shiftEventDetails';
import { ShiftOfferList } from './shiftOfferList';

type Props = {
  eventData: CalendarEventData & { extendedProps: ShiftBucketExtendedProps };
};

export const ShiftDetailsTab = (props: Props): JSX.Element => {
  const bucketId = props.eventData.extendedProps.shiftBucket.id;

  return (
    <>
      <ShiftEventDetails eventData={props.eventData} />
      <Grid pt={2} display="flex" flexDirection="column" gap={2}>
        <ShiftOfferList bucketId={bucketId} />
        <ShiftBucketCandidateList eventData={props.eventData} />
      </Grid>
    </>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { skipToken } from '@reduxjs/toolkit/query';
import { isSameDay, isSameMonth, subMonths } from 'date-fns';
import { useGetShiftBuckets } from 'features/calendar/hooks/useGetShiftBuckets';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { getDateWithDayName, isDateAfterDeadline, isInNextMonth } from 'shared/utils/calendarUtils';
import { EMERGENCY_COLOR } from 'shared/utils/palette';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { AbsencePreferenceForm } from './absence/absencePreferenceForm';
import { HolidaysPreferenceForm } from './holidays/holidaysPreferenceForm';
import { ShiftPreferenceForm } from './shift/shiftPreferenceForm';

type Props = {
  date: Date;
  userId: string;
  isOpen: boolean;
  onClose: () => void;
};

export enum PreferencesStateOnDay {
  NONE = 'NONE',
  SHIFT = 'SHIFT',
  ABSENCE_ON_DAY = 'ABSENCE_ON_DAY',
  HOLIDAY = 'HOLIDAY'
}

export const ShiftPreferenceDialog = (props: Props): JSX.Element => {
  const [preferencesStateOnDay, setPreferencesStateOnDay] = useState<PreferencesStateOnDay>(PreferencesStateOnDay.NONE);
  const [currentTab, setCurrentTab] = useState<1 | 2 | 3>(1);
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const dispatch = useAppDispatch();
  // rtk
  const { data: ward } = useGetWardByIdQuery(activeWardId ?? skipToken);
  // other
  const { shiftBuckets } = useGetShiftBuckets();
  const isDesktop = useIsDesktop();

  const monthShiftBuckets = useMemo(() => {
    return shiftBuckets.filter(sb => isSameMonth(new Date(sb.start), props.date));
  }, [shiftBuckets, props.date]);

  const dayShiftBuckets = useMemo(() => {
    return monthShiftBuckets.filter(sb => isSameDay(new Date(sb.start), props.date));
  }, [monthShiftBuckets, props.date]);

  const { onClose: closeDialog } = props;
  useEffect(() => {
    const userPreferences = dayShiftBuckets.flatMap(dsb => dsb.preferences).filter(preference => preference.userId === props.userId);

    const isAbsenceOnDayPreference = !_.isEmpty(userPreferences) && _.every(userPreferences, p => p.type === 'ABSENCE_ON_DAY');
    const isHolidayPreference = !_.isEmpty(userPreferences) && _.every(userPreferences, p => p.type === 'HOLIDAY');

    if (isAbsenceOnDayPreference) {
      setPreferencesStateOnDay(PreferencesStateOnDay.ABSENCE_ON_DAY);
      setCurrentTab(2);
    } else if (isHolidayPreference) {
      setPreferencesStateOnDay(PreferencesStateOnDay.HOLIDAY);
      setCurrentTab(3);
    } else if (!_.isEmpty(userPreferences) && !isAbsenceOnDayPreference && !isHolidayPreference) {
      setPreferencesStateOnDay(PreferencesStateOnDay.SHIFT);
      setCurrentTab(1);
    } else if (_.isEmpty(userPreferences)) {
      setPreferencesStateOnDay(PreferencesStateOnDay.NONE);
      setCurrentTab(1);
    } else {
      dispatch(addAlert({ color: 'error', text: 'Błąd! Nieprawidłowy stan preferencji.' }));
      closeDialog();
    }
  }, [dayShiftBuckets, props.userId, closeDialog, dispatch]);

  const nextMonthUserPreferences = _(shiftBuckets)
    .filter(sb => isInNextMonth(new Date(sb.start)))
    .flatMap(sb => sb.preferences)
    .filter(p => p.userId === props.userId)
    .value();

  const arePreferencesAccepted = !_.isEmpty(nextMonthUserPreferences) && nextMonthUserPreferences.every(p => p.accepted);

  const DEADLINE_DAY_MESSAGE = `Dyspozycyjność możesz zgłosić najpóźniej do ${ward?.deadlineDay} dnia poprzedzającego miesiąca`;
  const MISSED_DEADLINE_MESSAGE = `Minął termin składania dyspozycyjności (${ward?.deadlineDay} dnia miesiąca)`;
  const PREFERENCES_ACCEPTED_MESSAGE = `Dyspozycyjności na ten miesiąc są zatwierdzone, anuluj zatwierdzenie, jeżeli chcesz je edytować`;

  const getEditabilityInfo = () => {
    if (!ward) {
      return { message: '', color: '', isEditable: false };
    }
    if (isDateAfterDeadline(subMonths(props.date, 1), ward.deadlineDay)) {
      return { message: MISSED_DEADLINE_MESSAGE, color: EMERGENCY_COLOR, isEditable: false };
    } else if (arePreferencesAccepted && isInNextMonth(props.date)) {
      return { message: PREFERENCES_ACCEPTED_MESSAGE, color: theme.palette.warning.main, isEditable: false };
    } else {
      return { message: DEADLINE_DAY_MESSAGE, color: theme.palette.text.secondary, isEditable: true };
    }
  };

  const { message, color, isEditable } = getEditabilityInfo();

  const dialogContent = () => {
    switch (currentTab) {
      case 1:
        return (
          <ShiftPreferenceForm
            monthShiftBuckets={monthShiftBuckets}
            dayShiftBuckets={dayShiftBuckets}
            preferencesStateOnDay={preferencesStateOnDay}
            isEditable={isEditable}
            onPreferenceUpdated={closeDialog}
            userId={props.userId}
          />
        );
      case 2:
        return (
          <AbsencePreferenceForm
            dayShiftBuckets={dayShiftBuckets}
            preferencesStateOnDay={preferencesStateOnDay}
            isEditable={isEditable}
            onPreferenceUpdated={closeDialog}
            userId={props.userId}
          />
        );
      case 3:
        return (
          <HolidaysPreferenceForm
            date={props.date}
            allShiftBuckets={shiftBuckets}
            dayShiftBuckets={dayShiftBuckets}
            preferencesStateOnDay={preferencesStateOnDay}
            isEditable={isEditable}
            onPreferenceUpdated={closeDialog}
            userId={props.userId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={props.isOpen} onClose={closeDialog} fullScreen={!isDesktop} fullWidth={isDesktop}>
      <DialogTitle textTransform={'capitalize'} component={'div'}>
        <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
          {getDateWithDayName(props.date.toDateString())}
        </Typography>
        <IconButton size="small" color="secondary" onClick={closeDialog} sx={{ marginLeft: 'auto' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: pxToRem(20) }}>
        <Grid container spacing={3} flexDirection={'column'}>
          <Grid width={1}>
            <Tabs value={currentTab} onChange={(_, tab) => setCurrentTab(tab)} variant="scrollable" scrollButtons={false}>
              <Tab label="Dyżur" value={1} />
              <Tab label="Dzień bez dyżuru" value={2} />
              <Tab label="Urlop" value={3} />
            </Tabs>
          </Grid>
          <Grid width={1}>
            <Typography variant="caption" color={color} fontWeight={theme.typography.fontWeightBold}>
              {message}
            </Typography>
          </Grid>
          <Grid width={1}>{dialogContent()}</Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

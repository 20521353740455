import { AuthorityType } from './account';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  activated: boolean;
  langKey: string;
  phoneNumber?: string;
  authorities?: AuthorityType[];
  robot: boolean;
  termsAccepted: boolean;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export interface Robot {
  id: string;
  firstName: string;
  lastName: string;
  wardId: string;
}

export namespace Users {
  export const toString = <T extends { firstName?: string; lastName?: string; email?: string }>(obj?: T): string =>
    obj?.firstName && obj?.lastName ? `${obj.firstName} ${obj.lastName}` : obj?.email ?? 'Użytkownik nieznany';

  export const getInitials = <T extends { firstName?: string; lastName?: string }>(obj?: T): string =>
    obj?.firstName && obj?.lastName ? obj?.firstName?.[0] + obj?.lastName?.[0] : 'b.d.';
}

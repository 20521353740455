export interface Invitation {
  id?: string;
  expiration: string;
  code?: string;
  invitees: Invitee[];
}

export interface Invitee {
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  status?: InvitationStatus;
}

export enum InvitationStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  FULFILLED = 'FULFILLED',
  CANCELED = 'CANCELED'
}

export interface RenewedInvitation {
  code: string;
  invitee: Invitee;
}

export namespace Invitee {
  export const equals = (i1: Invitee, i2: Invitee) => {
    if (!!i1.email && !!i2.email) {
      return i1.email === i2.email;
    } else if (!!i1.phoneNumber && !!i2.phoneNumber) {
      return i1.phoneNumber === i2.phoneNumber;
    } else {
      console.group();
      console.warn('Invitees should have at least one comparator property filled (email or phoneNumber)');
      console.warn(`Invitee 1 mail: ${i1.email}, phoneNumber: ${i1.phoneNumber}`);
      console.warn(`Invitee 2 mail: ${i2.email}, phoneNumber: ${i2.phoneNumber}`);
      console.groupEnd();
      return false;
    }
  };
}

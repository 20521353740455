export enum AuthorityType {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  WARD_HEAD = 'ROLE_WARD_HEAD',
}

export interface Account {
  email?: string;
  password?: string;
  code?: string;
  firstName?: string;
  lastName?: string;
  activated?: boolean;
  id?: string;
  langKey?: string;
  authorities?: AuthorityType[];
  phoneNumber?: string;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}

export interface Credentials {
  username: string;
  password: string;
}

import { pxToRem, theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { SlideUp } from 'features/ui/transitions/slideUp';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { User, Users } from 'shared/types/users';
import { POPPER_Z_INDEX } from 'shared/utils/zindex';
import { ImpersonationCalendar } from './impersonationCalendar';

type Props = {
  user?: User;
  isOpen: boolean;
  onClose: () => void;
};

export const ImpersonationDialog = (props: Props) => {
  const isDesktop = useIsDesktop();

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullScreen={!isDesktop}
      TransitionComponent={SlideUp}
      sx={
        isDesktop
          ? {
              zIndex: POPPER_Z_INDEX - 1,
              m: '1%',
              width: '100%',
              height: '100%',
              maxHeight: 'none',
              maxWidth: 'none',
              '& .MuiDialog-paper': {
                width: '100%',
                height: '100%',
                maxHeight: 'none',
                maxWidth: 'none'
              }
            }
          : {
              zIndex: POPPER_Z_INDEX - 1,
              '& .MuiDialogContent-root': {
                p: 0
              },
              '& .MuiDialogTitle-root': {
                p: 0.5
              }
            }
      }
    >
      <DialogTitle component={'div'}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width={'100%'}>
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            <Box display="flex" alignItems="center" justifyContent="center" p={1}>
              <Box display="flex" gap={1}>
                {isDesktop && <Typography variant="h5">Uzupełnianie dyspozycyjności dla</Typography>}
                <Typography variant="h5" color={theme.palette.primary.main}>
                  {Users.toString<User>(props.user)}
                </Typography>
              </Box>
            </Box>
          </Typography>
          <IconButton size="medium" color="secondary" onClick={props.onClose} sx={{ marginLeft: 'auto' }}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: pxToRem(20) }}>
        <ImpersonationCalendar user={props.user} />
      </DialogContent>
    </Dialog>
  );
};

import { Box, Typography } from '@mui/material';
import { ShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';

type Props = {
  month: string;
  shiftPlanStatus: ShiftPlanStatus;
};

export const PlannerToolbar = (props: Props) => {
  const getHeaderTitle = () => {
    switch (props.shiftPlanStatus) {
      case ShiftPlanStatus.NOT_EXISTING:
        return `Dyspozycyjności złożone na ${props.month}`;
      case ShiftPlanStatus.NOT_ACCEPTED:
        return `Wygenerowany plan na ${props.month}`;
      case ShiftPlanStatus.ACCEPTED:
        return `Zaakceptowany plan na ${props.month}`;
      default:
        return 'Błąd! Nieznany stan planera.';
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <Typography variant="h5">{getHeaderTitle()}</Typography>
    </Box>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { FormError } from 'features/ui/formError/FormError';
import { PhoneNumberInput } from 'features/ui/masked-inputs/phoneNumberInput';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { User } from 'shared/types/users';
import { sanitizeEmptyFields } from 'shared/utils/commonUtils';
import { validateEmail, validatePhoneNumber, validationMessage } from 'shared/utils/formUtils';
import { RequiredFieldsCaption } from 'shared/utils/requiredFieldsCaption';
import { useGetLoggedUserQuery, useRequestResetPasswordLinkMutation, useUpdateAccountMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';

interface UserSettingsFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export const UserSettings = () => {
  const [passwordEmail, setPasswordEmail] = useState('');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [reset, resetRequestStatus] = useRequestResetPasswordLinkMutation();
  const [update, updateRequestStatus] = useUpdateAccountMutation();
  const { refetch, isSuccess: userRefetchingSuccess } = useGetLoggedUserQuery();
  const { fontWeightBold } = theme.typography;

  const { palette, borders } = theme;

  const {
    control,
    reset: resetForm,
    formState: { isValid, isDirty },
    handleSubmit
  } = useForm<UserSettingsFormValues>({
    mode: 'onBlur',
    defaultValues: {
      firstName: loggedUser?.firstName ?? '',
      lastName: loggedUser?.lastName ?? '',
      email: loggedUser?.email ?? '',
      phoneNumber: loggedUser?.phoneNumber ?? ''
    }
  });

  useRequestStatusAlert(
    resetRequestStatus.isSuccess,
    resetRequestStatus.isError,
    'Wysłano instrukcję zmany hasła',
    'Nie udało się wysłać instrukcji zmiany hasła'
  );

  useRequestStatusAlert(
    updateRequestStatus.isSuccess,
    updateRequestStatus.isError,
    'Zaktualizowano dane',
    'Nie udało się zaktualizować danych'
  );

  useEffect(() => {
    updateRequestStatus.isSuccess && refetch();
  }, [updateRequestStatus.isSuccess, refetch]);

  useEffect(() => {
    if (userRefetchingSuccess && loggedUser) {
      resetForm({
        firstName: loggedUser.firstName ?? '',
        lastName: loggedUser.lastName ?? '',
        email: loggedUser.email ?? '',
        phoneNumber: loggedUser.phoneNumber ?? ''
      });
    }
  }, [userRefetchingSuccess, loggedUser, resetForm]);

  const onSubmit = (data: UserSettingsFormValues) => {
    if (loggedUser) {
      const user: User = {
        id: loggedUser.id!,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        activated: loggedUser.activated!,
        langKey: loggedUser.langKey!,
        phoneNumber: data.phoneNumber,
        authorities: loggedUser.authorities,
        termsAccepted: loggedUser.termsAccepted,
        robot: false
      };
      update(sanitizeEmptyFields<User>(user));
    }
  };

  const resetPassword = () => {
    reset(passwordEmail);
  };

  return (
    <Grid
      container
      alignItems="stretch"
      component="form"
      role="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      p={3}
      sx={{ backgroundColor: palette.neutralExpand[200], borderRadius: borders.borderRadius.xxxl }}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12} pb={pxToRem(20)}>
          <Typography variant="body1">Tutaj możesz zmienić informacje dotyczące konta.</Typography>
        </Grid>
        <Grid item xs={12} md={4} pb={{ xs: pxToRem(20), md: 0 }}>
          <Typography variant="h5" fontWeight={fontWeightBold}>
            Podstawowe dane
          </Typography>
          <Box display="flex" flexDirection="column" gap={pxToRem(20)} pt={pxToRem(20)}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Imię" fullWidth required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{ required: validationMessage.required }}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Nazwisko" fullWidth required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{ required: validationMessage.required }}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Adres email" fullWidth disabled />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                required: validationMessage.required,
                validate: validateEmail
              }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      InputProps={{
                        inputComponent: PhoneNumberInput
                      }}
                      className={fieldState.invalid ? 'error' : ''}
                      label="Numer telefonu"
                      fullWidth
                      disabled
                    />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                );
              }}
              rules={{
                validate: validatePhoneNumber
              }}
            />
            <RequiredFieldsCaption />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h5" fontWeight={fontWeightBold}>
            Zmień hasło
          </Typography>
          <Box pt={pxToRem(20)}>
            <TextField label="Adres email" value={passwordEmail} onChange={e => setPasswordEmail(e.target.value)} fullWidth />
            <Typography variant="body1" pt={pxToRem(20)} pb={pxToRem(28)}>
              Podaj adres email podany podczas rejestracji, a my wyślemy Ci instrukcję jak zresetować hasło.
            </Typography>
            <Button
              size="large"
              variant={'outlined'}
              color="primary"
              disabled={_.isEmpty(passwordEmail)}
              onClick={resetPassword}
              sx={{ float: 'right' }}
            >
              Zmień hasło
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="flex-end" pt={3}>
        <Button size="large" variant="contained" color="primary" type="submit" disabled={!isValid || !isDirty}>
          Zapisz zmiany
        </Button>
      </Grid>
    </Grid>
  );
};

import { theme } from '@medsi/mui-theme';
import { Box, Grid, Alert as MuiAlert } from '@mui/material';
import { ALERT_Z_INDEX } from 'shared/utils/zindex';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Alert, dismissAlert } from 'store/slices/alertsSlice';

export const Alerts = (): JSX.Element => {
  const alerts = useAppSelector(state => state.alertsSlice.alerts);
  const dispatch = useAppDispatch();

  const generateAlert = (alert: Alert) => (
    <Grid item key={alert.id} xs={8}>
      <MuiAlert
        severity={alert.color}
        onClose={() => dispatch(dismissAlert(alert.id))}
        sx={{
          borderRadius: theme.borders.borderRadius.xxxl,
          '&.MuiAlert-root': {
            display: 'flex',
            alignItems: 'center'
          }
        }}
      >
        {alert.text}
      </MuiAlert>
    </Grid>
  );

  return (
    <Box position="absolute" top="32px" width="100%" zIndex={ALERT_Z_INDEX}>
      <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
        {alerts.map(a => generateAlert(a))}
      </Grid>
    </Box>
  );
};

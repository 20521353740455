import { theme } from '@medsi/mui-theme';
import { Grid, Typography } from '@mui/material';
import { Action, usePermission } from 'features/rbac/permissions';
import _ from 'lodash';
import { CalendarEventData, ShiftBucketExtendedProps } from 'shared/types/calendar';
import { User } from 'shared/types/users';
import { findById } from 'shared/utils/commonUtils';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { ShiftBucketCandidate } from './shiftBucketCandidate';

type Props = {
  eventData: CalendarEventData & { extendedProps: ShiftBucketExtendedProps };
};

const defaultUsers: User[] = [];

export const ShiftBucketCandidateList = (props: Props): JSX.Element => {
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  // other
  const { hasPermissionTo } = usePermission();

  const shiftBucket = props.eventData.extendedProps.shiftBucket;
  const assignedDoctor = findById<User>(users, _.first(shiftBucket.doctors));
  const hasPermissionToAssign = hasPermissionTo(Action.MANUAL_SHIFT_ASSIGNMENT);

  return (
    <Grid
      container
      sx={{
        maxHeight: '30vh',
        overflow: 'auto'
      }}
    >
      {assignedDoctor && (
        <>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Lekarz przypisany do dyżuru:
          </Typography>
          <ShiftBucketCandidate doctor={assignedDoctor} shiftBucket={shiftBucket} isAssigned={true} isAssignable={hasPermissionToAssign} />
        </>
      )}
      {!assignedDoctor && hasPermissionToAssign && (
        <Grid display="flex" flexDirection="column" width="100%" pb={1}>
          <Grid display="flex" justifyContent="flex-start">
            <Typography variant="caption" color={theme.palette.text.secondary}>
              Przypisz lekarza do dyżuru:
            </Typography>
          </Grid>

          {users.map(u => (
            <ShiftBucketCandidate
              isAssignable={hasPermissionToAssign}
              key={u.id}
              doctor={{
                id: u.id,
                firstName: u.firstName,
                lastName: u.lastName
              }}
              shiftBucket={shiftBucket}
              isAssigned={false}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

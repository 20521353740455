import _ from 'lodash';
import { useSyncExternalStore } from 'react';

interface ExternalStorage {
  getSnapshot: () => string | null;
  subscribe: (listener: VoidFunction) => VoidFunction;
}

const externalLocalStorageAuthState: ExternalStorage = {
  getSnapshot: () => localStorage.getItem('authSlice'),
  subscribe: (listener: VoidFunction) => {
    window.addEventListener('storage', listener); // this event is emitted only on other tab storage change
    return () => window.removeEventListener('storage', listener);
  }
};

export const useIsAuthorizedOnOtherTab = (): boolean => {
  const state = useSyncExternalStore(externalLocalStorageAuthState.subscribe, externalLocalStorageAuthState.getSnapshot);

  return !!state && !_.isEmpty(JSON.parse(state));
};

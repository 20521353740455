import {
  CalendarToday as CalendarTodayIcon,
  EventRounded as EventRoundedIcon,
  Logout as LogoutIcon,
  ThumbsUpDownOutlined as ThumbsUpDownIcon,
  Tune as TuneIcon
} from '@mui/icons-material';
import { Action } from 'features/rbac/permissions';
import paths from 'routing/utils';
import { SidebarElement } from 'shared/types/routes';

export const calendarLink: SidebarElement = {
  text: 'Mój kalendarz',
  prefix: <CalendarTodayIcon fontSize="small" />,
  action: Action.CALENDAR,
  route: paths.dashboard.calendar,
  mandatory: false
};

export const plannerLink: SidebarElement = {
  text: 'Planer dyżurów',
  prefix: <EventRoundedIcon fontSize="small" />,
  action: Action.PLANNER,
  route: paths.dashboard.planner,
  mandatory: false
};

export const impersonationPanelLink: SidebarElement = {
  text: 'Dyspozycyjności lekarzy',
  prefix: <ThumbsUpDownIcon fontSize="small" />,
  action: Action.IMPERSONATION_PANEL,
  route: paths.dashboard.impersonation,
  mandatory: false
};

export const settingsLink: SidebarElement = {
  text: 'Ustawienia',
  prefix: <TuneIcon fontSize="small" />,
  action: Action.BASE,
  route: paths.dashboard.settings.base,
  mandatory: false
};

export const logoutLink: SidebarElement = {
  text: 'Wyloguj',
  prefix: <LogoutIcon fontSize="small" />,
  action: Action.BASE,
  route: paths.logout,
  mandatory: true
};

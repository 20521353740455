import { pxToRem, rgba, theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';
import { CalendarView, ShiftEventType } from 'shared/types/calendar';
import { ShiftBucket } from 'shared/types/shiftBucket';
import { useAppSelector } from 'store/hooks';
import ruleEngine from './rule';
import { getPreferenceIcon } from './utils';

type Props = {
  shiftBucket: ShiftBucket;
  eventType: ShiftEventType;
  calendarView?: CalendarView;
  isAssigned: boolean;
  isOwnerEvent: boolean;
  eventViewerId: string;
};

export const MobileShiftBucketEventContent = (props: Props) => {
  // redux
  const bucketOffers = useAppSelector(state => state.offerSlice.bucketOffers.find(bo => bo.bucketId === props.shiftBucket?.id));

  const { isAssigned, shiftBucket, eventType, isOwnerEvent } = props;

  const userPreference = shiftBucket.preferences.find(p => p.userId === props.eventViewerId);
  const hasAsapOffer = !!bucketOffers?.hasAsapOffer;
  const hasAnyOffer = !!bucketOffers;

  const content = ruleEngine.getContent(
    {
      isOwner: isOwnerEvent,
      type: eventType,
      isAssigned,
      hasAsapOffer,
      hasAnyOffer
    },
    { shiftBucket, userId: props.eventViewerId }
  );

  if (userPreference) {
    content?.icons.push(getPreferenceIcon(userPreference.type));
  }

  if (!content) {
    return <></>;
  }

  return (
    <Box
      key={props.shiftBucket.id}
      width="100%"
      height="100%"
      sx={{
        backgroundColor: theme.palette.white.main,
        borderRadius: theme.borders.borderRadius.lg
      }}
      my={pxToRem(2)}
    >
      <Box
        height="100%"
        display="flex"
        justifyContent="flex-start"
        p={pxToRem(2)}
        mx={pxToRem(1)}
        sx={{
          backgroundColor: content.backgroundColor,
          '&:hover': { backgroundColor: rgba(content.backgroundColor, 0.8) },
          borderRadius: theme.borders.borderRadius.lg,
          cursor: 'pointer'
        }}
      >
        <Typography
          variant="caption"
          fontSize={pxToRem(8)}
          color={content.color}
          fontWeight={theme.typography.fontWeightBold}
          sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {content.label}
        </Typography>
      </Box>
    </Box>
  );
};

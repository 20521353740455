import { theme } from '@medsi/mui-theme';
import Close from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { ShiftAssignment } from 'shared/types/planning';
import { getDateWithDayName } from 'shared/utils/calendarUtils';
import { useGetShiftBucketByIdQuery } from 'store/api/endpoints/shiftBucketEndpoint';
import { ShiftAssignmentDetails } from './shiftAssignmentDetails';
import { ShiftCandidates } from './shiftCandidates';

type Props = {
  shiftAssignment?: ShiftAssignment;
  isOpen: boolean;
  onClose: () => void;
};

export const ShiftAssignmentDialog = (props: Props): JSX.Element => {
  // rtk
  const { data: shiftBucket } = useGetShiftBucketByIdQuery(props.shiftAssignment?.shift?.shiftBucketId ?? skipToken);
  // other
  const isDesktop = useIsDesktop();

  const cError = theme.palette.error.main;
  const cTextPrimary = theme.palette.text.primary;

  if (!props.shiftAssignment || !shiftBucket) {
    return <></>;
  }

  const isShiftAssigned = !!props.shiftAssignment?.doctor;

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullScreen={!isDesktop} fullWidth={isDesktop}>
      {props.shiftAssignment && (
        <Box textAlign="center" p={1}>
          <DialogTitle component={'div'}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h5" fontWeight={theme.typography.fontWeightBold} color={isShiftAssigned ? cTextPrimary : cError}>
                {getDateWithDayName(shiftBucket.start)}
              </Typography>
              <Box pt={1}>
                {!isShiftAssigned && (
                  <Typography variant="caption" color={cError}>
                    Dyżur nieprzypisany
                  </Typography>
                )}
              </Box>
            </Box>
            <IconButton size="small" color="secondary" onClick={props.onClose} sx={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <ShiftAssignmentDetails isAssigned={isShiftAssigned} shiftBucket={shiftBucket} />
            <Grid pt={2}>
              <ShiftCandidates shiftAssignment={props.shiftAssignment} />
            </Grid>
          </DialogContent>
        </Box>
      )}
    </Dialog>
  );
};

import { Chip } from '@mui/material';
import { differenceInHours, isAfter, subHours } from 'date-fns';
import { InvitationStatus, Invitee } from 'shared/types/invitation';
import { User } from 'shared/types/users';

export const renderPosition = (position: string): JSX.Element => {
  return <>{position}</>;
};

export const renderNotSentStatus = (): JSX.Element => {
  return <Chip size="small" label={'Zaproszenie nie wysłane'} color="error" sx={{ width: 'fit-content' }} />;
};

export const renderSentStatus = (expiration?: string): JSX.Element => {
  if (!expiration) {
    return <Chip size="small" label={`Oczekujący`} color="info" sx={{ width: 'fit-content' }} />;
  }
  const currentDate = new Date();
  const expirationDate = new Date(expiration);

  if (isAfter(currentDate, expirationDate)) {
    return <Chip size="small" label={'Przedawnione'} color="secondary" sx={{ width: 'fit-content' }} />;
  }

  const codeGenerationDate = subHours(expirationDate, 48);
  const hoursDifference = differenceInHours(currentDate, codeGenerationDate);

  return <Chip size="small" label={`Oczekujący - ${hoursDifference}h`} color="info" sx={{ width: 'fit-content', padding: 1 }} />;
};

export interface RowData {
  name?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
  expiration?: string;
  status?: InvitationStatus;
  invitee?: Invitee;
  user?: User;
}

export enum SolverStatus {
  SOLVING_SCHEDULED = 'SOLVING_SCHEDULED',
  SOLVING_ACTIVE = 'SOLVING_ACTIVE',
  NOT_SOLVING = 'NOT_SOLVING'
}

export interface ShiftPlan {
  wardId: string;
  shiftAssignments: ShiftAssignment[];
  justifications: Justification[];
  accepted: boolean;
}

export interface ShiftAssignment {
  shift: Shift;
  doctor?: Doctor;
  pinned: Boolean;
  modifiedManually: boolean;
}

export interface Shift {
  shiftBucketId: string;
  start: Date;
  end: Date;
  requiredDoctorAmount: number;
}

export interface Doctor {
  id: string;
  firstName: string;
  lastName: string;
  shiftsLimit?: number;
}

export interface DateBuckets {
  date?: string;
  buckets?: string[];
}

type ConsecutiveDateBuckets = DateBuckets[];

export interface Justification {
  type: JustificationType;
  consecutiveDateBuckets?: ConsecutiveDateBuckets[];
  consecutiveBuckets?: string[][];
  shiftBucketId?: string;
  shiftBucketIds?: string[];
  doctorId?: string;
  score?: number;
  numberOfShifts?: number;
  consecutiveWeekends?: number;
}

export enum JustificationType {
  CYCLIC_PREFERENCE_CONSTRAINT = 'cyclicPreference',
  PRESENCE_PREFERENCE_CONSTRAINT = 'presencePreference',
  ABSENCE_PREFERENCE_CONSTRAINT = 'absencePreference',
  WORKLOAD_FAIRNESS_CONSTRAINT = 'workloadFairness',
  WORKLOAD_PERSONAL_LIMIT_CONSTRAINT = 'workloadPersonalLimit',
  CONSECUTIVE_DAYS_CONSTRAINT = 'consecutiveDays',
  CONSECUTIVE_WEEKENDS_CONSTRAINT = 'consecutiveWeekends',
  CONSECUTIVE_WEEKEND_DAYS_CONSTRAINT = 'consecutiveWeekendDays',
  EACH_SHIFT_COVERED_CONSTRAINT = 'eachShiftCovered',
  CONSECUTIVE_SHIFTS_CONSTRAINT = 'consecutiveShifts'
}

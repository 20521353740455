import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { ConfirmationDialog } from 'features/ui/dialogs/ConfirmationDialog';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Preference, ShiftBucket } from 'shared/types/shiftBucket';
import { useUpdateShiftPreferencesMutation } from 'store/api/endpoints/shiftBucketEndpoint';
import { useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { PreferencesStateOnDay } from '../shiftPreferenceDialog';

interface Props {
  userId: string;
  isEditable: boolean;
  dayShiftBuckets: ShiftBucket[];
  preferencesStateOnDay: PreferencesStateOnDay;
  onPreferenceUpdated: VoidFunction;
}

export interface AbsenceFormState {
  isChecked: boolean;
  note: string;
}

const initialFormState: AbsenceFormState = { isChecked: false, note: '' };

export const AbsencePreferenceForm = (props: Props): JSX.Element => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [originalFormState, setOriginalFormState] = useState<AbsenceFormState>(initialFormState);
  const [currentFormState, setCurrentFormState] = useState<AbsenceFormState>(initialFormState);
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const dispatch = useDispatch();
  // rtk
  const [updatePreferences, { isSuccess, isError }] = useUpdateShiftPreferencesMutation();
  // other
  const isDirty = useMemo(() => !_.isEqual(originalFormState, currentFormState), [originalFormState, currentFormState]);

  useEffect(() => {
    if (props.preferencesStateOnDay !== PreferencesStateOnDay.ABSENCE_ON_DAY) {
      setOriginalFormState(initialFormState);
      setCurrentFormState(initialFormState);
    } else {
      const note = props.dayShiftBuckets.flatMap(dsb => dsb.preferences).find(p => p.userId === props.userId)?.note ?? '';

      setOriginalFormState({ isChecked: true, note });
      setCurrentFormState({ isChecked: true, note });
    }
  }, [props.dayShiftBuckets, props.preferencesStateOnDay, props.userId]);

  const { onPreferenceUpdated } = props;
  useEffect(() => {
    isSuccess && onPreferenceUpdated();
  }, [isSuccess, onPreferenceUpdated]);

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się dodać preferencji' }));
  }, [isError, dispatch]);

  const onCheckedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCurrentFormState(prev => ({ ...prev, isChecked, note: isChecked ? prev.note : '' }));
  };

  const onNoteChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFormState(prev => ({ ...prev, note: event.target.value }));
  };

  const onSaveClicked = () => {
    props.preferencesStateOnDay === PreferencesStateOnDay.NONE || props.preferencesStateOnDay === PreferencesStateOnDay.ABSENCE_ON_DAY
      ? save()
      : setIsConfirmationDialogOpen(true);
  };

  const save = () => {
    if (!props.userId) {
      return;
    }
    const bucketIdPreferenceMap = new Map<string, Preference | null>();
    props.dayShiftBuckets.forEach(bucket => {
      if (bucket.id) {
        bucketIdPreferenceMap.set(
          bucket.id,
          currentFormState.isChecked ? { userId: props.userId, type: 'ABSENCE_ON_DAY', note: currentFormState.note, accepted: false } : null
        );
      }
    });

    activeWardId && updatePreferences({ wardId: activeWardId, preferences: bucketIdPreferenceMap, userId: props.userId });
  };

  const getConfirmationDialogContent = () => {
    switch (props.preferencesStateOnDay) {
      case PreferencesStateOnDay.SHIFT:
        return 'Po wybraniu dnia bez dyżuru, wszystkie Twoje dyspozycje dyżurowe istniejące w tym dniu zostaną anulowane. Czy na pewno chcesz kontynuować?';
      case PreferencesStateOnDay.HOLIDAY:
        return 'Po wybraniu dnia bez dyżuru, Twój urlop istniejący w tym dniu zostanie anulowany. Czy na pewno chcesz kontynuować?';
      default:
        return 'Nieprawidłowy stan preferencji! Czy na pewno chcesz kontynuować?';
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Uwaga!'}
        content={getConfirmationDialogContent()}
        onAccept={save}
        onAcceptText={'Tak'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />

      <FormControlLabel
        control={<Checkbox onChange={onCheckedChanged} checked={currentFormState.isChecked} disabled={!props.isEditable} />}
        label={
          <Box display="flex" justifyItems="center" gap={1}>
            <Typography variant="body1">Dzień bez dyżuru</Typography>
            <StarOutlineIcon color={props.isEditable ? 'secondary' : 'disabled'} fontSize="small" />
          </Box>
        }
      />
      <TextField
        label="Dodaj notatkę"
        fullWidth
        multiline
        minRows={3}
        value={currentFormState.note}
        onChange={onNoteChanged}
        disabled={!currentFormState.isChecked || !props.isEditable}
      />

      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={onSaveClicked} disabled={!isDirty || !props.isEditable}>
          Zapisz
        </Button>
      </Box>
    </Box>
  );
};

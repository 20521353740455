import { pxToRem } from '@medsi/mui-theme';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { SIDENAV_Z_INDEX } from 'shared/utils/zindex';
import { useAppSelector } from 'store/hooks';

export const SIDENAV_FULL_WIDTH = pxToRem(225);
export const SIDENAV_RIBBON_WIDTH = pxToRem(78);

export default styled(Drawer)<DrawerProps>(({ theme }) => {
  // redux
  const isSidenavHidden = useAppSelector(state => state.sidenavSlice.isSidenavHidden);
  // other
  const isDesktop = useIsDesktop();

  const { palette, transitions, breakpoints, boxShadows } = theme;
  const { white } = palette;

  const drawerOpenStyles = () => ({
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter
    }),
    '& span.MuiTypography-body1': {
      maxWidth: pxToRem(116)
    },

    [breakpoints.up('sm')]: {
      background: white,
      marginBottom: 'inherit',
      marginRight: '0rem',
      height: '100%',
      margin: '0 !important',
      width: SIDENAV_FULL_WIDTH,
      transform: 'translateX(0)',
      overflowX: 'hidden',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen
      })
    }
  });

  const drawerCloseStyles = () => ({
    transform: `translateX(${pxToRem(320)})`,
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter
    }),
    [breakpoints.up('sm')]: {
      background: white,
      margin: '0 !important',
      marginRight: '0rem',
      marginBottom: 'inherit',
      height: '100%',
      left: '0',
      width: SIDENAV_RIBBON_WIDTH,
      alignItems: 'center',
      overflowX: 'hidden',
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter
      })
    }
  });

  return {
    '& .MuiDrawer-paper': {
      zIndex: SIDENAV_Z_INDEX,
      borderRadius: isDesktop ? pxToRem(12) : pxToRem(20),
      background: white,
      left: isDesktop ? '0' : 'auto',
      right: isDesktop ? 'auto' : '0',
      top: isDesktop ? undefined : '3.5rem',
      bottom: isDesktop ? undefined : '4rem',
      height: isDesktop ? undefined : 'auto !important',
      padding: isDesktop ? `${pxToRem(34)} ${pxToRem(21)}` : `${pxToRem(24)}`,
      boxShadow: isDesktop ? 0 : boxShadows.elevation24,
      ...(isSidenavHidden ? drawerCloseStyles() : drawerOpenStyles())
    }
  };
});

import { pxToRem, theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { format, isSameDay } from 'date-fns';
import { pl } from 'date-fns/locale';
import { CalendarEventData } from 'shared/types/calendar';
import { Popper } from '../popper/popper';
import { EventContentRenderer } from './eventContentRenderer';

type Props = {
  events: CalendarEventData[];
  popperDate: Date | null;
  popperAnchor: HTMLElement | null;
  onClosePopperCallback: () => void;
  onEventClickCallback: (e: CalendarEventData) => void;
};

// Modifying styles of this component imply the need of modifying fc-more-popover styles
export const CalendarEventListPopper = (props: Props) => {
  const { palette, borders, boxShadows, typography } = theme;

  if (!props.popperDate) {
    props.onClosePopperCallback();
    return <></>;
  }

  const isValidEvent = (e: CalendarEventData) => isSameDay(e.start, props.popperDate!);

  return (
    <Popper anchor={props.popperAnchor} isOpen={!!props.popperAnchor} onClickAwayCallback={props.onClosePopperCallback}>
      <Box
        width={pxToRem(240)}
        display="flex"
        p={1}
        borderRadius={borders.borderRadius.xl}
        boxShadow={boxShadows.elevation24}
        margin={1}
        flexDirection="column"
        sx={{
          backgroundColor: palette.background.default
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
          <Typography variant="body1" fontWeight={typography.fontWeightBold}>
            {format(props.popperDate, 'd EEEE', { locale: pl })}
          </Typography>
          <Box onClick={() => props.onClosePopperCallback()} sx={{ cursor: 'pointer' }}>
            <Close fontSize="medium" />
          </Box>
        </Box>
        {props.events.filter(isValidEvent).map(e => (
          <Box onClick={() => props.onEventClickCallback(e)} key={e.id}>
            <EventContentRenderer extendedProps={e.extendedProps} explicitScreenVersion="DESKTOP" />
          </Box>
        ))}
      </Box>
    </Popper>
  );
};

import { pxToRem } from '@medsi/mui-theme';
import { Divider, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { User, Users } from 'shared/types/users';
import { useGetAllUsersQuery } from 'store/api/endpoints/userEndpoint';
import { useAppSelector } from 'store/hooks';

const ALL_SHIFTS_MODIFIER = 'ALL_SHIFTS';
const defaultUsers: User[] = [];

type Props = {
  onChangeEventsOwnerCallback?: (doctorName: string) => void;
};

export const EventOwnerFilter = (props: Props) => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data: users = defaultUsers } = useGetAllUsersQuery();
  const getUserMenuItems = () =>
    users
      ?.filter(u => u.id !== loggedUser?.id)
      .map(u => (
        <MenuItem key={u.id} value={u.id}>
          {Users.toString<User>(u)}
        </MenuItem>
      ));

  const onChange = (e: SelectChangeEvent<string>) => {
    props.onChangeEventsOwnerCallback?.(e.target.value === ALL_SHIFTS_MODIFIER ? '' : e.target.value);
  };

  return (
    <Select defaultValue={ALL_SHIFTS_MODIFIER} onChange={onChange} sx={{ margin: 0 }}>
      <MenuItem value={ALL_SHIFTS_MODIFIER}>Wszystkie dyżury</MenuItem>
      <MenuItem value={loggedUser?.id}>Moje dyżury</MenuItem>
      <Divider sx={{ opacity: 1, borderWidth: pxToRem(1) }} />
      {getUserMenuItems()}
    </Select>
  );
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { BOTTOM_BAR_HEIGHT_PX } from '../sidenav/bottomBar';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';

export const DashboardLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const isDesktop = useIsDesktop();

  return (
    <Grid
      sx={({ breakpoints, transitions }) => ({
        pt: isDesktop ? 1.5 : 'none',
        mb: { xs: pxToRem(BOTTOM_BAR_HEIGHT_PX + 20), md: 1.5 },
        px: isDesktop ? 2 : 'none',
        height: 1,
        position: 'relative',
        borderRadius: isDesktop ? theme.borders.borderRadius.xxxl : 0,
        backgroundColor: theme.palette.neutralExpand[200],
        overflowY: 'auto',
        [breakpoints.up('sm')]: {
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard
          })
        }
      })}
    >
      {children}
    </Grid>
  );
};

import { Box, Button } from '@mui/material';
import { AddButton } from 'features/ui/buttons/addButton';
import { ConfirmationDialog } from 'features/ui/dialogs/ConfirmationDialog';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { PreferenceType, ShiftBucket } from 'shared/types/shiftBucket';
import { useUpdateShiftPreferencesMutation } from 'store/api/endpoints/shiftBucketEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { v4 as uuid } from 'uuid';
import { useIsShiftPreferenceFormDirty } from '../hooks/useIsShiftPreferenceFormDirty';
import { PreferencesStateOnDay } from '../shiftPreferenceDialog';
import { ShiftPreferenceEntry } from './shiftPreferenceEntry';
import {
  ShiftFormState,
  addNewEntry,
  applyBucketIdToEntry,
  applyNoteToEntry,
  applyPreferenceTypeIdToEntry,
  clearOutOtherPreferences,
  createBucketIdPreferenceMap,
  getConfirmationDialogContent
} from './utils';

interface Props {
  userId: string;
  isEditable: boolean;
  dayShiftBuckets: ShiftBucket[];
  monthShiftBuckets: ShiftBucket[];
  preferencesStateOnDay: PreferencesStateOnDay;
  onPreferenceUpdated: VoidFunction;
}

export const ShiftPreferenceForm = (props: Props): JSX.Element => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [originalFormState, setOriginalFormState] = useState<ShiftFormState[]>([]);
  const [currentFormState, setCurrentFormState] = useState<ShiftFormState[]>([]);
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const dispatch = useAppDispatch();
  // rtk
  const [updatePreferences, { isSuccess, isError }] = useUpdateShiftPreferencesMutation();
  // other
  const isDirty = useIsShiftPreferenceFormDirty(originalFormState, currentFormState);

  const takenBucketIds = _.flatMap(currentFormState, 'bucketId');
  const bucketsWithoutPreference = props.dayShiftBuckets.filter(dsb => !takenBucketIds.includes(dsb.id));

  useEffect(() => {
    const sfs: ShiftFormState[] = [];
    props.dayShiftBuckets.forEach(dsb => {
      const userPreference = dsb.preferences.find(p => p.userId === props.userId);
      dsb.id &&
        userPreference &&
        userPreference.type !== 'ABSENCE_ON_DAY' &&
        userPreference.type !== 'HOLIDAY' &&
        sfs.push({ uuid: uuid(), bucketId: dsb.id, note: userPreference.note, preferenceType: userPreference.type });
    });
    setOriginalFormState(sfs);
    setCurrentFormState(sfs);
  }, [props.dayShiftBuckets, props.userId]);

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'error', text: 'Nie udało się dodać preferencji' }));
  }, [isError, dispatch]);

  const { onPreferenceUpdated } = props;
  useEffect(() => {
    if (isSuccess) {
      onPreferenceUpdated();
    }
  }, [isSuccess, onPreferenceUpdated]);

  const onAddNewEntry = () => {
    setCurrentFormState(prev => addNewEntry(prev, bucketsWithoutPreference));
  };

  const onSelectShiftBucket = (uuid: string, bucketId: string) => {
    setCurrentFormState(prev => applyBucketIdToEntry(prev, uuid, bucketId));
  };

  const onSelectPreferenceType = (uuid: string, preferenceType: PreferenceType) => {
    setCurrentFormState(prev => applyPreferenceTypeIdToEntry(prev, uuid, preferenceType));
  };

  const onNoteChanged = (uuid: string, note: string) => {
    setCurrentFormState(prev => applyNoteToEntry(prev, uuid, note));
  };

  const onRemoveEntry = (uuid: string) => {
    setCurrentFormState(prev => prev.filter(p => p.uuid !== uuid));
  };

  const onSave = () => {
    const canOtherPreferencesBeOverwritten =
      _.some(currentFormState, shift => shift.preferenceType === 'CYCLIC') ||
      props.preferencesStateOnDay === PreferencesStateOnDay.HOLIDAY ||
      props.preferencesStateOnDay === PreferencesStateOnDay.ABSENCE_ON_DAY;

    canOtherPreferencesBeOverwritten ? setIsConfirmationDialogOpen(true) : addPreferences();
  };

  const addPreferences = () => {
    const bucketIdPreferenceMap = createBucketIdPreferenceMap(currentFormState, props.monthShiftBuckets, props.userId);
    clearOutOtherPreferences(bucketIdPreferenceMap, props.dayShiftBuckets);
    activeWardId && updatePreferences({ wardId: activeWardId, preferences: bucketIdPreferenceMap, userId: props.userId });
  };

  return (
    <Box>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Uwaga!'}
        content={getConfirmationDialogContent(
          props.preferencesStateOnDay,
          _.some(currentFormState, shift => shift.preferenceType === 'CYCLIC')
        )}
        onAccept={addPreferences}
        onAcceptText={'Tak'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />

      {currentFormState.map(pe => {
        return (
          <ShiftPreferenceEntry
            key={pe.uuid}
            uuid={pe.uuid}
            selectedBucketId={pe.bucketId}
            selectedPreferenceType={pe.preferenceType}
            note={pe.note}
            isEditable={props.isEditable}
            bucketsWithoutPreference={bucketsWithoutPreference}
            onSelectShiftBucket={onSelectShiftBucket}
            onSelectPreferenceType={onSelectPreferenceType}
            onChangeNote={onNoteChanged}
            onRemoveEntry={onRemoveEntry}
          />
        );
      })}

      <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
        <AddButton onClickCallback={onAddNewEntry} disabled={_.isEmpty(bucketsWithoutPreference) || !props.isEditable} />

        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button variant="contained" disabled={!isDirty || !props.isEditable} onClick={onSave}>
            Zapisz
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

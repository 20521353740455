import { pxToRem, theme } from '@medsi/mui-theme';
import { Button, Grid, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AddressAndContactFormFields } from 'features/ui/facility/addressAndContactFormFields';
import { WardFormFields } from 'features/ui/facility/wardFormFields';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { Facility } from 'shared/types/facility';
import { Ward } from 'shared/types/ward';
import { sanitizeEmptyFields } from 'shared/utils/commonUtils';
import { RequiredFieldsCaption } from 'shared/utils/requiredFieldsCaption';
import { useGetFacilityByIdQuery, useGetPredefinedFacilitiesQuery, useUpdateFacilityMutation } from 'store/api/endpoints/facilityEndpoints';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppSelector } from 'store/hooks';

type FormFields = {
  predefinedFacilityName: string;
  clinicName: string;
  wardName: string;
  shortWardName: string;
  city: string;
  street: string;
  building: string;
  postalCode: string;
  phoneNumber: string;
  email: string;
};

const defaultPredefinedFacilities: Facility[] = [];

export const WardSettings = () => {
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  // rtk
  const { data: activeFacility, refetch: refetchFacility } = useGetFacilityByIdQuery(facilitySlice?.activeFacilityId ?? skipToken);
  const { data: activeWard, refetch: refetchWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const { data: predefinedFacilities = defaultPredefinedFacilities } = useGetPredefinedFacilitiesQuery();
  const [updateFacility, { isSuccess, isError }] = useUpdateFacilityMutation();

  const { palette, borders } = theme;

  useRequestStatusAlert(isSuccess, isError, 'Zaktualizowano dane', 'Nie udało się zaktualizować danych placówki');

  const getDefaultValues = useCallback((): FormFields => {
    return {
      predefinedFacilityName: activeFacility?.name ?? '',
      clinicName: _.first(activeFacility?.clinics)?.name ?? '',
      wardName: activeWard?.name ?? '',
      shortWardName: activeWard?.shortName ?? '',
      city: activeWard?.address?.city ?? '',
      street: activeWard?.address?.street ?? '',
      building: activeWard?.address?.building ?? '',
      postalCode: activeWard?.address?.postalCode ?? '',
      phoneNumber: activeWard?.contact?.phoneNumber ?? '',
      email: activeWard?.contact?.email ?? ''
    };
  }, [activeFacility, activeWard]);

  const useFormReturn = useForm<FormFields>({
    mode: 'onBlur',
    defaultValues: getDefaultValues()
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty }
  } = useFormReturn;

  useEffect(() => {
    if (isSuccess) {
      refetchFacility();
      refetchWard();
    }
  }, [isSuccess, refetchWard, refetchFacility, reset]);

  useEffect(() => {
    if (activeWard && activeFacility) {
      reset(getDefaultValues());
    }
  }, [activeWard, activeFacility, reset, getDefaultValues]);

  const onSubmit = (data: FormFields) => {
    const facilityCopy: Facility = JSON.parse(JSON.stringify(activeFacility));

    facilityCopy.name = data.predefinedFacilityName;
    facilityCopy.predefinedFacilityId = predefinedFacilities.find(f => f.name === data.predefinedFacilityName)?.id;

    const clinic = facilityCopy.clinics?.[0];

    let ward: Ward | undefined;

    if (clinic) {
      clinic.name = data.clinicName;
      ward = _.first(clinic.wards);
    } else {
      ward = _.first(facilityCopy.wards);
    }

    if (ward) {
      ward.name = data.wardName;
      ward.shortName = data.shortWardName;
      ward.address = {
        city: data.city,
        street: data.street,
        building: data.building,
        postalCode: data.postalCode
      };
      ward.contact = { phoneNumber: data.phoneNumber, email: data.email };
    }

    updateFacility(sanitizeEmptyFields<Facility>(facilityCopy));
  };

  return (
    <FormProvider {...useFormReturn}>
      <Grid
        container
        alignItems="stretch"
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        p={3}
        sx={{ backgroundColor: palette.neutralExpand[200], borderRadius: borders.borderRadius.xxxl }}
      >
        <Grid container gap={pxToRem(20)}>
          <Grid item xs={12}>
            <Typography variant="body1">Tutaj możesz zmienić informacje dotyczące oddziału.</Typography>
          </Grid>
          <Grid item xs={12} md={4} pb={{ xs: pxToRem(20), md: 0 }}>
            <Typography variant="h5" fontWeight="700">
              Podstawowe dane
            </Typography>
            <WardFormFields isClinicInputVisible={!_.isNil(activeFacility?.clinics?.[0])} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" fontWeight="700">
              Adres Placówki
            </Typography>
            <AddressAndContactFormFields />
          </Grid>
          <Grid item xs={12}>
            <RequiredFieldsCaption />
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="flex-end" pt={3}>
          <Button size="large" variant="contained" color="primary" type="submit" disabled={!isValid || !isDirty}>
            Zapisz zmiany
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

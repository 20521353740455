import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WeeklyShiftPattern } from 'shared/types/shiftBucket';

const SLICE_NAME = 'wardRegistrationForm';

export const DEFAULT_DEADLINE_DAY = 31;

export interface WardRegistrationFormFields {
  predefinedFacilityName?: string;
  customFacilityName?: string;
  clinicName?: string;
  wardName?: string;
  shortWardName?: string;
  street?: string;
  city?: string;
  building?: string;
  postalCode?: string;
  phoneNumber?: string;
  email?: string;
  weeklyShiftPattern?: WeeklyShiftPattern;
  wardDeadline?: number;
}

const initialState: WardRegistrationFormFields = {
  customFacilityName: '',
  clinicName: '',
  wardName: '',
  shortWardName: '',
  street: '',
  city: '',
  building: '',
  postalCode: '',
  phoneNumber: '',
  email: '',
  wardDeadline: DEFAULT_DEADLINE_DAY
};

export const wardRegistrationFormSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateNames(state, action: PayloadAction<WardRegistrationFormFields>) {
      state.predefinedFacilityName = action.payload.predefinedFacilityName;
      state.customFacilityName = action.payload.customFacilityName;
      state.clinicName = action.payload.clinicName;
      state.wardName = action.payload.wardName;
      state.shortWardName = action.payload.shortWardName;
    },
    updateAddress(state, action: PayloadAction<WardRegistrationFormFields>) {
      state.city = action.payload.city;
      state.street = action.payload.street;
      state.building = action.payload.building;
      state.postalCode = action.payload.postalCode;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
    },
    updateWeeklyShiftPattern(state, action: PayloadAction<WeeklyShiftPattern>) {
      state.weeklyShiftPattern = action.payload;
    },
    updateWardDeadline(state, action: PayloadAction<number>) {
      state.wardDeadline = action.payload;
    },
    wardRegistrationFormSliceReset() {
      return initialState;
    }
  }
});

export const { updateNames, updateAddress, updateWeeklyShiftPattern, wardRegistrationFormSliceReset, updateWardDeadline } =
  wardRegistrationFormSlice.actions;

export default wardRegistrationFormSlice.reducer;

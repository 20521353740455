export const PalmIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00004 4.99992C7.06004 10.0733 4.66671 13.6666 4.66671 13.6666H6.66671C7.92004 9.79992 7.33337 7.05992 7.00004 5.66659M8.44004 3.77325C8.55337 3.91325 8.66671 4.05992 8.75337 4.21325C9.29658 5.07198 9.54903 6.08271 9.4734 7.09601C9.39776 8.1093 8.99805 9.07136 8.33337 9.83992C8.6214 9.14169 8.73839 8.38474 8.67461 7.63213C8.61083 6.87952 8.36812 6.15306 7.96671 5.51325C7.91337 5.42659 7.85337 5.35325 7.80004 5.27325C7.41671 4.73821 6.92633 4.2887 6.36004 3.95325C5.40008 4.25964 4.56239 4.86329 3.96801 5.67697C3.37362 6.49066 3.05331 7.47225 3.05337 8.47992C3.05337 8.97325 3.12671 9.44659 3.26004 9.88659C2.682 9.08252 2.37178 8.11687 2.37337 7.12659C2.37337 5.55992 3.13337 4.17325 4.30004 3.30659C3.33337 3.23325 2.30671 3.44659 1.41337 3.99992C1.00004 4.27325 0.640041 4.58659 0.333374 4.93992C0.700041 4.05325 1.33337 3.25992 2.20004 2.71325C3.20004 2.08659 4.33337 1.87992 5.42671 2.03992C5.15337 1.66659 4.82004 1.31325 4.42004 0.999919C4.03337 0.719919 3.61337 0.493252 3.17337 0.333252C4.13337 0.359919 5.09337 0.666585 5.91337 1.28659C6.33337 1.59992 6.66671 1.97992 6.93337 2.37992C7.00004 2.37992 7.06004 2.37325 7.12671 2.37325C9.26004 2.37325 11.0667 3.77992 11.6667 5.71992C11.2916 5.19103 10.8115 4.74518 10.2563 4.41024C9.70111 4.07529 9.08281 3.85845 8.44004 3.77325Z"
        fill="currentColor"
      />
    </svg>
  );
};

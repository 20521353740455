import { format } from 'date-fns';
import { useMemo } from 'react';
import { CalendarEventData, CalendarTypeGuard } from 'shared/types/calendar';

interface DateOffers {
  datesWithAnyOffers: string[];
  datesWithAsapOffers: string[];
}

export const useDateOffers = (events: CalendarEventData[]): DateOffers => {
  return useMemo(() => {
    const datesWithAnyOffers: string[] = [];
    const datesWithAsapOffers: string[] = [];

    events.filter(CalendarTypeGuard.isShiftBucketEvent).forEach(({ start, extendedProps }) => {
      const date = format(start, 'yyyy-MM-dd');

      if (extendedProps.hasAsapOffer) {
        datesWithAsapOffers.push(date);
      }

      if (extendedProps.hasAnyOffer) {
        datesWithAnyOffers.push(date);
      }
    });

    return { datesWithAsapOffers, datesWithAnyOffers };
  }, [events]);
};

export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export interface ReactNativeWindow {
  isNativeApp: boolean;
  mobileDeviceToken: string;
  ReactNativeWebView: {
    postMessage: (msg: string) => void;
  };
}

const isReactNativeWindow = (obj: Object): obj is ReactNativeWindow => {
  return obj.hasOwnProperty('isNativeApp') && obj.hasOwnProperty('ReactNativeWebView') && obj.hasOwnProperty('mobileDeviceToken');
};

const getReactNativeWindow = (): ReactNativeWindow | null => {
  if (isReactNativeWindow(window)) {
    return {
      isNativeApp: window.isNativeApp,
      mobileDeviceToken: window.mobileDeviceToken,
      ReactNativeWebView: window.ReactNativeWebView
    };
  }
  return null;
};

export const reactNativeWindow = getReactNativeWindow();
import { useEffect } from 'react';

const useShowAlertBeforeUnload = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const confirmationMessage = 'Czy na pewno chcesz wyjść?'; // however setting custom message is blocked in modern browsers for security reasons
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useShowAlertBeforeUnload;

import { theme } from '@medsi/mui-theme';
import { Chip, Grid } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import { Justification, JustificationType } from 'shared/types/planning';
import { JustificationFilter } from './justificationFilter';

type Props = {
  justifications?: Justification[];
};

export const GlobalJustificationMessage = (props: Props) => {
  const justificationMessages = useMemo(() => {
    if (!props.justifications) {
      return [];
    }
    return new JustificationFilter(props.justifications).withType(JustificationType.WORKLOAD_FAIRNESS_CONSTRAINT).stringify();
  }, [props.justifications]);

  if (_.isEmpty(justificationMessages)) {
    return null;
  }

  return (
    <Grid width="100%" p={1}>
      {justificationMessages.map((jm, index) => (
        <Chip
          key={index}
          color="warning"
          label={jm}
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', color: theme.palette.white.main }}
        />
      ))}
    </Grid>
  );
};

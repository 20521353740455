import { skipToken } from '@reduxjs/toolkit/query';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useGetShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';
import { SHIFT_EVENT_POSTFIX, getEventType, isAllDayUserPreferenceEvent } from 'features/ui/calendar/utils';
import { useEffect, useState } from 'react';
import { CalendarEventData, ShiftEventType } from 'shared/types/calendar';
import { ShiftBucket } from 'shared/types/shiftBucket';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useAppSelector } from 'store/hooks';

export const useGetUserPreferenceEvents = (shiftBuckets?: ShiftBucket[], userId?: string): CalendarEventData[] => {
  const [events, setEvents] = useState<CalendarEventData[]>([]);
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  // other
  const planStatus = useGetShiftPlanStatus(shiftPlan);

  useEffect(() => {
    if (!userId) {
      setEvents([]);
      return;
    }

    const calendarEvents: CalendarEventData[] = [];
    const allDayUniqueEvents = new Set<string>();

    shiftBuckets
      ?.filter(sb => getEventType(new Date(sb.start), planStatus) === ShiftEventType.PREFERENCE)
      ?.filter(sb => sb.preferences.some(p => p.userId === userId))
      .forEach(sb => {
        const sbStart = new Date(sb.start);
        const sbFormattedStart = format(sbStart, 'yyyy-MM-dd');

        if (!allDayUniqueEvents.has(sbFormattedStart)) {
          const isAllDayEvent = isAllDayUserPreferenceEvent(sb, userId, ShiftEventType.PREFERENCE);

          if (isAllDayEvent) {
            allDayUniqueEvents.add(sbFormattedStart);
          }

          calendarEvents.push({
            id: sb.id + SHIFT_EVENT_POSTFIX,
            start: isAllDayEvent ? startOfDay(sbStart) : sbStart,
            end: isAllDayEvent ? endOfDay(sbStart) : sbStart,
            editable: true,
            extendedProps: {
              type: 'SHIFT_BUCKET',
              shiftBucket: sb,
              shiftEventType: ShiftEventType.PREFERENCE,
              isAssigned: false,
              eventViewerId: userId,
              viewAsOwner: true
            }
          });
        }
      });

    setEvents(calendarEvents);
  }, [shiftBuckets, userId, planStatus]);

  return events;
};

import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

export const useRequestStatusAlert = (isSuccess?: boolean, isError?: boolean, successMsg?: string, errorMsg?: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess && successMsg) {
      dispatch(addAlert({ color: 'success', text: successMsg }));
    } else if (isError && errorMsg) {
      dispatch(addAlert({ color: 'error', text: errorMsg }));
    }
  }, [isSuccess, isError, successMsg, errorMsg, dispatch]);
};

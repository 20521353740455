import { AuthorityType } from 'shared/types/account';
import { FacilitiesGuard } from './facilitiesGuard';
import { SecuredGuard } from './securedGuard';

export const securedGuard = (component: JSX.Element, authority: AuthorityType = AuthorityType.USER) => {
  return <SecuredGuard authority={authority}>{component}</SecuredGuard>;
};

export const facilitiesGuard = (component: JSX.Element) => {
  return <FacilitiesGuard>{component}</FacilitiesGuard>;
};

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ConfirmationDialog } from 'features/ui/dialogs/ConfirmationDialog';
import { useState } from 'react';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { InvitationStatus, Invitee } from 'shared/types/invitation';
import { User } from 'shared/types/users';
import { Ward } from 'shared/types/ward';
import { useDeleteUserByIdMutation } from 'store/api/endpoints/userEndpoint';
import { useGetWardByIdQuery, useUpdateWardMutation } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { RowData, renderNotSentStatus, renderPosition, renderSentStatus } from '../utils';
import { UserTable } from './userTable';
import { UsersCards } from './usersCards';

interface UsersRendererProps {
  rowData: RowData[];
  variant: 'mobile' | 'desktop';
}

export const UsersRenderer = (props: UsersRendererProps): JSX.Element => {
  const [inviteeInRemoveContext, setInviteeInRemoveContext] = useState<Invitee | null>();
  const [userInRemoveContext, setUserInRemoveContext] = useState<User | null>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  const dispatch = useAppDispatch();
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const [deleteUser, { isError: userDeletingError }] = useDeleteUserByIdMutation();
  const [updateWard] = useUpdateWardMutation();
  // other
  useRequestStatusAlert(undefined, userDeletingError, undefined, 'Nie udało się usunąć użytkownika');

  const renderJobTitleCell = (rowData: RowData): JSX.Element => {
    if (rowData.position) {
      return renderPosition(rowData.position);
    }

    if (rowData.status === InvitationStatus.NOT_SENT) {
      return renderNotSentStatus();
    }

    if (rowData.status === InvitationStatus.SENT) {
      return renderSentStatus(rowData.expiration);
    }
    return <></>;
  };

  const onTrashIconClick = (row: RowData) => {
    if (row.invitee) {
      setInviteeInRemoveContext(row.invitee);
    } else {
      setUserInRemoveContext(row.user);
    }

    setIsConfirmationDialogOpen(true);
  };

  const onDeleteUserClick = () => {
    if (inviteeInRemoveContext) {
      deleteInvitee();
    } else if (userInRemoveContext) {
      deleteUser(userInRemoveContext.id);
    } else {
      dispatch(addAlert({ color: 'error', text: 'Błąd usuwania' }));
    }

    setIsConfirmationDialogOpen(false);
    setUserInRemoveContext(null);
    setInviteeInRemoveContext(null);
  };

  const deleteInvitee = () => {
    const wardCopy: Ward = JSON.parse(JSON.stringify(activeWard));
    wardCopy.invitations?.forEach(invitation => {
      invitation.invitees.forEach(invitee => {
        if (inviteeInRemoveContext && Invitee.equals(invitee, inviteeInRemoveContext)) {
          invitee.status = InvitationStatus.CANCELED;
        }
      });
    });
    updateWard(wardCopy)
      .unwrap()
      .catch(() => {
        dispatch(addAlert({ color: 'error', text: 'Nie udało się usunąć zaproszonego użytkownika' }));
      });
  };

  return (
    <>
      {props.variant === 'mobile' ? (
        <UsersCards rowData={props.rowData} renderJobTitleCell={renderJobTitleCell} onTrashIconClick={onTrashIconClick} />
      ) : (
        <UserTable rowData={props.rowData} renderJobTitleCell={renderJobTitleCell} onTrashIconClick={onTrashIconClick} />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Usuń użytkownika'}
        content={'Czy na pewno chcesz usunąć użytkownika?'}
        onAccept={onDeleteUserClick}
        onAcceptText={'Usuń'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
    </>
  );
};

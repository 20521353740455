import { pxToRem, theme } from '@medsi/mui-theme';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { ShiftPlanStatus, useGetShiftPlanStatus } from 'features/planner/hooks/useGetShiftPlanStatus';
import _ from 'lodash';
import { useEffect } from 'react';
import { isDateAfterDeadline, isInNextMonth } from 'shared/utils/calendarUtils';
import { SPEED_DIAL_Z_INDEX } from 'shared/utils/zindex';
import { useTogglePreferencesAcceptanceMutation } from 'store/api/endpoints/shiftBucketEndpoint';
import { useGetShiftPlanQuery } from 'store/api/endpoints/shiftPlanEndpoint';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { useGetShiftBuckets } from '../../calendar/hooks/useGetShiftBuckets';

type Props = {
  userId?: string;
  position: { bottom: number; right: number };
};

export const CalendarSpeedDial = (props: Props) => {
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  const dispatch = useAppDispatch();
  // rtk
  const [togglePreferencesAcceptance, { isError, isLoading }] = useTogglePreferencesAcceptanceMutation();
  const { data: shiftPlan } = useGetShiftPlanQuery(activeWardId ?? skipToken);
  const { data: ward } = useGetWardByIdQuery(activeWardId ?? skipToken);
  // other
  const { shiftBuckets } = useGetShiftBuckets();
  const shiftPlanStatus = useGetShiftPlanStatus(shiftPlan);

  const userPreferences = _(shiftBuckets)
    .filter(sb => isInNextMonth(new Date(sb.start)))
    .flatMap(sb => sb.preferences)
    .filter(p => p.userId === props.userId)
    .value();

  const arePreferencesAccepted = !_.isEmpty(userPreferences) && userPreferences.every(p => p.accepted);

  useEffect(() => {
    isError && arePreferencesAccepted && dispatch(addAlert({ color: 'error', text: 'Nie udało się cofnąć zatwierdzenia dyspozycyjności' }));
    isError && !arePreferencesAccepted && dispatch(addAlert({ color: 'error', text: 'Nie udało się zatwierdzić dyspozycyjności' }));
  }, [isError, dispatch, arePreferencesAccepted]);

  const onTogglePreferenceAcceptance = () => {
    if (activeWardId && props.userId) {
      togglePreferencesAcceptance({ wardId: activeWardId, userId: props.userId });
    }
  };

  const actions = arePreferencesAccepted
    ? [
        {
          icon: <CancelOutlinedIcon fontSize="medium" />,
          name: 'Anuluj zatwierdzenie',
          executor: onTogglePreferenceAcceptance
        }
      ]
    : [{ icon: <CheckCircleOutlinedIcon fontSize="medium" />, name: 'Zatwierdź dyspozycyjności', executor: onTogglePreferenceAcceptance }];

  if (
    shiftPlanStatus === ShiftPlanStatus.ACCEPTED ||
    _.isEmpty(userPreferences) ||
    !ward ||
    isDateAfterDeadline(new Date(), ward.deadlineDay)
  ) {
    return null;
  }

  return (
    <SpeedDial
      sx={{
        position: 'fixed',
        bottom: pxToRem(props.position.bottom),
        right: pxToRem(props.position.right),
        zIndex: SPEED_DIAL_Z_INDEX
      }}
      icon={<CalendarMonthOutlinedIcon fontSize="medium" />}
      ariaLabel={'planner-action-buttons'}
    >
      {actions.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => action.executor()}
          tooltipOpen
          FabProps={{ disabled: isLoading }}
          sx={{
            '& .MuiSpeedDialAction-staticTooltipLabel': {
              fontSize: theme.typography.size.lg,
              display: 'inline-block',
              whiteSpace: 'nowrap'
            }
          }}
        />
      ))}
    </SpeedDial>
  );
};

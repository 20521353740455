import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import { useEffect } from 'react';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useGetNotificationsQuery, useToggleReadStatusMutation } from 'store/api/endpoints/notificationEndpoint';
import { useAppSelector } from 'store/hooks';
import { Notification } from '../../shared/types/notification';

type Props = {
  isNotificationListVisible: boolean;
  onToggleNotificationList: (event: React.MouseEvent<HTMLElement>) => void;
};

const defaultNotifications: Notification[] = [];

export const NotificationAnchor = (props: Props) => {
  const [toggleReadStatus] = useToggleReadStatusMutation();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const previousIsNotificationListOpen = usePrevious(props.isNotificationListVisible);
  const { data: notifications = defaultNotifications } = useGetNotificationsQuery(loggedUser?.id ?? skipToken);

  useEffect(() => {
    if (previousIsNotificationListOpen === true && props.isNotificationListVisible === false) {
      if (!_.isEmpty(notifications)) {
        const unreadNotifications = notifications?.filter(n => !n.read).map(n => ({ id: n.id, read: true }));
        if (!_.isEmpty(unreadNotifications) && loggedUser?.id) {
          toggleReadStatus({ userId: loggedUser?.id, readStatuses: unreadNotifications });
        }
      }
    }
  }, [props.isNotificationListVisible, loggedUser?.id, notifications, previousIsNotificationListOpen, toggleReadStatus]);

  return (
    <IconButton size="medium" color="secondary" onClick={props.onToggleNotificationList}>
      <Badge badgeContent={notifications?.filter(n => !n.read).length ?? 0} color="primary">
        <NotificationsIcon fontSize="medium" />
      </Badge>
    </IconButton>
  );
};

import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ThumbDownIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { PalmIcon } from 'shared/icons/PalmIcon';
import { PreferenceType } from 'shared/types/shiftBucket';

export interface Content {
  label: string;
  icons: JSX.Element[];
  color: string;
  backgroundColor: string;
  borderColor: string;
}

export const getPreferenceIcon = (preferenceType: PreferenceType) => {
  switch (preferenceType) {
    case 'ABSENCE_ON_DAY':
      return <StarOutlineIcon fontSize="small" key={preferenceType} />;
    case 'ABSENCE_ON_SHIFT':
      return <ThumbDownIcon fontSize="small" key={preferenceType} />;
    case 'CYCLIC':
      return <RefreshIcon fontSize="small" key={preferenceType} />;
    case 'HOLIDAY':
      return <PalmIcon key={preferenceType} />;
    case 'PRESENCE':
      return <ThumbUpIcon fontSize="small" key={preferenceType} />;
    default:
      const icon: never = preferenceType;
      return icon;
  }
};

import { theme } from '@medsi/mui-theme';
import { Box, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { skipToken } from '@reduxjs/toolkit/query';
import { ShiftBucket, ShiftBuckets } from 'shared/types/shiftBucket';
import { ERROR_LIGHT_COLOR } from 'shared/utils/palette';
import { useGetFacilityByIdQuery } from 'store/api/endpoints/facilityEndpoints';
import { useGetWardByIdQuery } from 'store/api/endpoints/wardEndpoints';
import { useAppSelector } from 'store/hooks';

type Props = {
  isAssigned: boolean;
  shiftBucket: ShiftBucket;
};

export const ShiftAssignmentDetails = (props: Props): JSX.Element => {
  // redux
  const facilitySlice = useAppSelector(state => state.facilitySlice);
  // rtk
  const { data: activeWard } = useGetWardByIdQuery(facilitySlice?.activeWardId ?? skipToken);
  const { data: activeFacility } = useGetFacilityByIdQuery(facilitySlice?.activeFacilityId ?? skipToken);

  const cAssigned = { primary: green[900], secondary: green[50] };
  const cNotAssigned = { primary: theme.palette.error.dark, secondary: ERROR_LIGHT_COLOR };

  const color = props.isAssigned ? cAssigned : cNotAssigned;

  return (
    <Box textAlign="left" display="flex" flexDirection="column" alignItems="flex-start" gap={3}>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        Szczegóły dotyczące dyżuru
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
        borderRadius={theme.borders.borderRadius.lg}
        boxShadow={theme.boxShadows.elevation1}
        p={2}
        width={1}
        sx={{
          backgroundColor: color.secondary
        }}
      >
        <Box color={color.primary}>
          <Typography variant="body3" display="block">
            Dyżur
          </Typography>
          <Typography variant="body3">{ShiftBuckets.getShiftBucketTimeLabel(props.shiftBucket)}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{activeWard?.name}</Typography>
          <Typography variant="body1">{activeFacility?.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Button } from '@mui/material';
import { WardFormFields } from 'features/ui/facility/wardFormFields';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateNames } from 'store/slices/wardRegistrationFormSlice';

type FormFields = {
  predefinedFacilityName: string;
  clinicName: string;
  wardName: string;
  shortWardName: string;
};

export const WardForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const formStateValues = useAppSelector(state => state.wardCreationFormSlice);
  const navigate = useNavigate();

  const useFormReturn = useForm<FormFields>({
    mode: 'onBlur',
    defaultValues: {
      predefinedFacilityName: formStateValues.predefinedFacilityName,
      clinicName: formStateValues.clinicName,
      wardName: formStateValues.wardName,
      shortWardName: formStateValues.shortWardName
    }
  });

  const onSubmit = (data: FormFields) => {
    dispatch(
      updateNames({
        predefinedFacilityName: data.predefinedFacilityName,
        clinicName: data.clinicName,
        wardName: data.wardName,
        shortWardName: data.shortWardName
      })
    );
    navigate(paths.dashboard.wards.create.address, { replace: true });
  };

  return (
    <FormProvider {...useFormReturn}>
      <Box noValidate component="form" role="form" onSubmit={useFormReturn.handleSubmit(onSubmit)}>
        <WardFormFields isClinicInputVisible={true} />

        <Box display="flex" justifyContent="center" mt={{ xs: 3.5, md: 8.8 }}>
          <Button color="primary" size="large" variant="contained" type="submit" fullWidth>
            Dalej
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

import _ from 'lodash';
import { useEffect } from 'react';
import { router } from 'routing/routes';
import { AuthorityType } from 'shared/types/account';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import paths from '../utils';

interface SecuredGuardProps {
  children: React.ReactNode;
  authority: AuthorityType;
}

export const SecuredGuard = ({ children, authority }: SecuredGuardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const isAuthorized = _.includes(loggedUser?.authorities, authority);

  useEffect(() => {
    if (!isAuthorized) {
      dispatch(addAlert({ color: 'error', text: 'Brak Dostępu.', clearable: false }));
      router.navigate(paths.root);
    }
  }, [isAuthorized, dispatch]);

  if (isAuthorized) {
    return <>{children}</>;
  }
  return <></>;
};

import { pxToRem, theme } from '@medsi/mui-theme';
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { FormError } from 'features/ui/formError/FormError';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIsDesktop } from 'shared/hooks/useIsDesktop';
import { useRequestStatusAlert } from 'shared/hooks/useRequestStatusAlert';
import { User } from 'shared/types/users';
import { validationMessage } from 'shared/utils/formUtils';
import { useCreateRobotMutation, useUpdateRobotMutation } from 'store/api/endpoints/userEndpoint';
import { useAppSelector } from 'store/hooks';

interface Name {
  firstName: string;
  lastName: string;
}

type Props = {
  robot: User | null;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateEditRobotDialog = (props: Props) => {
  // redux
  const activeWardId = useAppSelector(state => state.facilitySlice.activeWardId);
  // rtk
  const [createRobot, creationStatus] = useCreateRobotMutation();
  const [updateRobot, updateStatus] = useUpdateRobotMutation();
  // other
  const isDesktop = useIsDesktop();
  useRequestStatusAlert(undefined, creationStatus.isError, undefined, 'Nie udało się utworzyć wirtualnego użytkownika');
  useRequestStatusAlert(undefined, updateStatus.isError, undefined, 'Nie udało się edytować wirtualnego użytkownika');

  const {
    control,
    reset: resetForm,
    formState: { isValid, isDirty },
    handleSubmit
  } = useForm<Name>({
    mode: 'onChange',
    defaultValues: {
      firstName: props.robot?.firstName ?? '',
      lastName: props.robot?.lastName ?? ''
    }
  });

  useEffect(() => {
    if (props.isOpen) {
      resetForm({
        firstName: props.robot?.firstName ?? '',
        lastName: props.robot?.lastName ?? ''
      });
    }
  }, [props.isOpen, resetForm, props.robot]);

  const onSubmit = (data: Name) => {
    if (!activeWardId) {
      return;
    }
    if (props.robot) {
      updateRobot({ id: props.robot.id, firstName: data.firstName, lastName: data.lastName, wardId: activeWardId });
    } else {
      createRobot({ firstName: data.firstName, lastName: data.lastName, wardId: activeWardId });
    }
    props.onClose();
  };

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullScreen={!isDesktop}>
      <DialogTitle textTransform={'capitalize'} component={'div'}>
        <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
          {`${props.robot ? 'Edytuj' : 'Dodaj'} wirtualnego użytkownika`}
        </Typography>
        <IconButton size="small" color="secondary" onClick={props.onClose} sx={{ marginLeft: 'auto' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: pxToRem(20) }}>
        <Grid container flexDirection={'column'} component="form" role="form" onSubmit={handleSubmit(onSubmit)} noValidate gap={2}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Box>
                  <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Imię" fullWidth required />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </Box>
              );
            }}
            rules={{ required: validationMessage.required }}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Box>
                  <TextField {...field} className={fieldState.invalid ? 'error' : ''} label="Nazwisko" fullWidth required />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </Box>
              );
            }}
            rules={{ required: validationMessage.required }}
          />
          <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="flex-end" pt={3}>
            <Button size="large" variant="contained" color="primary" type="submit" disabled={!isValid || !isDirty}>
              {props.robot ? 'Edytuj' : 'Dodaj'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
